<template>
  <div class="ViewModule">
    <site-navbar />
    <div class="page-content">
      <div class="products-widget">
        <viewAgricultureModule />
      </div>
    </div>
  </div>
</template>

<script>
import viewAgricultureModule from "@/views/site/pages/viewAgricultureModule.vue";
export default {
  components: {
    viewAgricultureModule,
  },
  data() {
    return {
      formStep: 0,
      ismodule: null,
      userLogged: false,
    };
  },
  methods: {
  },
  mounted() {
    if (
      !this.$getUser() ||
      !this.$getToken() ||
      this.$getUser() == "undefined" ||
      this.$getToken() == "undefined"
    ) {
      this.userLogged = false;
    } else {
      this.userLogged = true;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.ViewModule {
  .page-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    .jumbotron {
      width: 100%;
      height: calc(100vh - 130px);
      min-height: 600px;
      background-position: right;
      background-size: 50% 100%;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      &.ipsf {
        background-size: 37% 100%;
      }
      .left {
        width: 70%;
        max-width: 70%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #eee;
        padding: 0 5rem;
        button {
          display: inline-block !important;
        }
        // make it a trapazoid
        clip-path: polygon(0 0, 100% 0, 90% 100%, 0% 100%);
        @media screen and (max-width: 768px) {
          clip-path: none;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 3rem 2rem;
        }

        .module-info {
          width: 50%;
          h1 {
            font-size: 3rem;
            font-weight: 700;
            margin-bottom: 20px;
          }
          p {
            font-size: 1.2rem;
            line-height: 1.5;
          }
        }
        .module-actions {
          width: 40%;
          display: flex;
          justify-content: flex-end;
          button {
            padding: 1rem 2rem;
            border-radius: 5px;
            background: $blue;
            border: 1px solid $blue;
            font-size: 1.2rem;
            font-weight: 700;
            cursor: pointer;
            transition: all 0.3s ease;
            color: #fff;
            &:hover {
              background: darken($blue, 10%);
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .page-content {
      .jumbotron {
        flex-direction: column-reverse;
        height: auto;
        padding: 0;
        background-size: 100% auto;
        min-height: auto;
        .left {
          width: 100%;
          max-width: 100%;
          height: auto;
          clip-path: none;
          .module-info,
          .module-actions {
            width: 100%;
            max-width: 100%;
            text-align: center;
          }
          .module-info {
            margin-bottom: 1.5rem;
          }

          .btn {
            padding: 10px !important;
            height: 80px;
            width: 200% !important;
            margin-left: -60px !important;
            @media screen and (max-width: 768px) {
              width: 100% !important;
              margin-left: 0px !important;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 576px) {
    .page-content {
      .jumbotron {
        .left {
          .module-actions {
            button {
              width: 100%;
              margin-bottom: 1rem;
            }
          }
        }
      }
    }
  }
}
</style>