<template>
  <div class="container mx-auto p-6" id="EditVehicle">
    <form @submit.prevent="addVehicle()">
      <div class="form-group group-3">
        <div class="input-group">
          <label>{{ $txt("Car Body") }}</label>
          <select name="" id="" v-model="newItem.car_body_id">
            <option value="">Select</option>
            <option
              v-for="carBody in carTypes"
              :key="carBody.car_body_id"
              :value="carBody.car_body_id"
            >
              {{ carBody.car_body_name }}
            </option>
          </select>
        </div>
        <div class="input-group">
          <label for="">{{ $txt("Car brand") }}</label>
          <select name="" id="" v-model="newItem.vehicle_brand">
            <option value="">{{ $txt("Select brand") }}</option>
            <option
              v-for="carBrand in carBrands"
              :key="carBrand.name"
              :value="carBrand.name"
            >
              {{ carBrand.name }}
            </option>
          </select>
        </div>
        <div class="input-group">
          <label for="">{{ $txt("Driver names") }}</label>
          <input
            type="text"
            name=""
            id=""
            v-model="newItem.vehicle_driver"
            placeholder="Driver names"
          />
        </div>
      </div>
      <div class="form-group group-3">
        <div class="input-group">
          <label for="">{{ $txt("Driver phone") }}</label>
          <input
            type="text"
            name=""
            id=""
            v-model="newItem.vehicle_driver_phone"
            placeholder="Driver phone"
          />
        </div>
        <div class="input-group">
          <label for="">{{ $txt("Current status") }}</label>
          <select name="" id="" v-model="newItem.vehicle_status">
            <option value="1">{{ $txt("Available") }}</option>
            <option value="0">{{ $txt("Not available") }}</option>
          </select>
        </div>
        <div class="input-group">
          <label for="">{{ $txt("Is constantly moving") }}</label>
          <select name="" id="" v-model="newItem.is_for_transport">
            <option value="1">{{ $txt("Yes") }}</option>
            <option value="0">{{ $txt("No") }}</option>
          </select>
        </div>
      </div>
      <fieldset style="border: 1px solid lightgray; padding: 10px">
        <legend>Current location</legend>
        <div class="form-group group-4">
          <div class="input-group">
            <label class="block mb-2">{{ $txt("Province") }}</label>
            <select
              name="province"
              id=""
              v-model="newItem.province"
              @change="getDistricts(newItem.province)"
              class="w-full md:w-1/4 p-2"
            >
              <option value="All">{{ $txt("Province") }}</option>
              <option
                v-for="province in provinces"
                :key="province.provinceId"
                :value="province.provinceId"
              >
                {{ province.provinceName }}
              </option>
            </select>
          </div>
          <div class="input-group">
            <label class="block mb-2">{{ $txt("District") }}</label>
            <select
              name="district"
              id=""
              v-model="newItem.district"
              @change="getSectors(newItem.district)"
              class="w-full md:w-1/4 p-2"
            >
              <option value="All">{{ $txt("District") }}</option>
              <option
                v-for="district in districts"
                :key="district.districtId"
                :value="district.districtId"
              >
                {{ district.districtName }}
              </option>
            </select>
          </div>
          <div class="input-group">
            <label class="block mb-2">{{ $txt("Sector") }}</label>
            <select
              name="sector"
              id=""
              v-model="newItem.sector"
              @change="getCells(newItem.sector)"
              class="w-full md:w-1/4 p-2"
            >
              <option value="All">{{ $txt("Sector") }}</option>
              <option
                v-for="sector in sectors"
                :key="sector.sectorId"
                :value="sector.sectorId"
              >
                {{ sector.sectorName }}
              </option>
            </select>
          </div>
          <div class="input-group">
            <label class="block mb-2">{{ $txt("Cell") }}</label>
            <select
              name="cell"
              id=""
              v-model="newItem.cell"
              class="w-full md:w-1/4 p-2"
            >
              <option value="All">{{ $txt("Cell") }}</option>
              <option
                v-for="cell in cells"
                :key="cell.cellId"
                :value="cell.cellId"
              >
                {{ cell.cellName }}
              </option>
            </select>
          </div>
        </div>
      </fieldset>
      <fieldset
        style="border: 1px solid lightgray; padding: 10px"
        v-if="newItem.is_for_transport == '1'"
        class="mt-10"
      >
        <legend>
          {{ $txt("Destination location")
          }}<span>(Where you are planning to go)</span>
        </legend>
        <div class="form-group group-4">
          <div class="input-group">
            <label class="block mb-2">{{ $txt("Province") }}</label>
            <select
              name="province"
              id=""
              v-model="newItem.destination_province"
              @change="getDestDistricts(newItem.destination_province)"
              class="w-full md:w-1/4 p-2"
            >
              <option value="All">{{ $txt("All Province") }}</option>
              <option
                v-for="province in provinces"
                :key="province.provinceId"
                :value="province.provinceId"
              >
                {{ province.provinceName }}
              </option>
            </select>
          </div>
          <div class="input-group">
            <label class="block mb-2">{{ $txt("District") }}</label>
            <select
              name="district"
              id=""
              v-model="newItem.destination_district"
              @change="getDestSectors(newItem.destination_district)"
              class="w-full md:w-1/4 p-2"
            >
              <option value="All">{{ $txt(" All District") }}</option>
              <option
                v-for="district in Destdistricts"
                :key="district.districtId"
                :value="district.districtId"
              >
                {{ district.districtName }}
              </option>
            </select>
          </div>
          <div class="input-group">
            <label class="block mb-2">{{ $txt("Sector") }}</label>
            <select
              name="sector"
              id=""
              v-model="newItem.destination_sector"
              @change="getDestCells(newItem.destination_sector)"
              class="w-full md:w-1/4 p-2"
            >
              <option value="All">{{ $txt(" All Sectors") }}</option>
              <option
                v-for="sector in Destsectors"
                :key="sector.sectorId"
                :value="sector.sectorId"
              >
                {{ sector.sectorName }}
              </option>
            </select>
          </div>
          <div class="input-group">
            <label class="block mb-2">{{ $txt("Cell") }}</label>
            <select
              name="cell"
              id=""
              v-model="newItem.destination_cell"
              class="w-full md:w-1/4 p-2"
            >
              <option value="All">{{ $txt("Cell") }}</option>
              <option
                v-for="cell in Destcells"
                :key="cell.cellId"
                :value="cell.cellId"
              >
                {{ cell.cellName }}
              </option>
            </select>
          </div>
        </div>
      </fieldset>
      <fieldset
        style="border: 1px solid lightgray; padding: 10px"
        v-if="newItem.is_for_transport == '1'"
        class="mt-10"
      >
        <legend>
          {{ $txt("Departure date") }}<span>(Where do you want to move)</span>
        </legend>
        <div class="form-group group-2">
          <div class="input-group">
            <label class="block mb-2">{{ $txt("Departure date") }}</label>
            <input type="date" name="" id="" v-model="newItem.departure_date" />
          </div>
          <div class="input-group">
            <label class="block mb-2">{{ $txt("Departure time") }}</label>
            <input type="time" name="" id="" v-model="newItem.departure_time" />
          </div>
        </div>
      </fieldset>
      <div class="form-group group-1 mt-10">
        <div class="input-group">
          <label for="">{{ $txt("Car images") }}</label>
          <o-upload
            :multiple="true"
            :uploadUrl="'files/upload'"
            @imagesUploaded="
              (data) => {
                newItem.vehicle_images = data;
              }
            "
            @imagesUploadedFailed="
              (data) => {
                console.log('failed');
              }
            "
            :value="newItem.vehicle_images"
            @startLoader="() => $startLoader()"
            @stopLoader="() => $stopLoader()"
          />
        </div>
      </div>
      <div class="form-group group-1">
        <div class="input-group">
          <label for="">{{ $txt("Message") }}</label>
          <textarea
            name=""
            id=""
            cols="30"
            rows="10"
            placeholder="ex: I have an available car moving to Rubavu on 22nd, December 2023"
            v-model="newItem.Message"
          >
          </textarea>
        </div>
      </div>
      <div class="flex">
        <button type="submit">
          {{ $txt("Update Vehicle") }}
        </button>
      </div>
    </form>
  </div>
</template>
  
  <script>
export default {
  name: "EditVehicle",
  props: {
    newItem: {
      type: Object,
    },
  },
  data() {
    return {
      body: "",
      capacity: "",
      plateNumber: "",
      status: "moving",
      driver: "",
      contact: "",
      selectedCarType: "",
      carBrands: [
        { name: "Toyota", country: "Japan", founded: 1937 },
        { name: "Ford", country: "United States", founded: 1903 },
        { name: "BMW", country: "Germany", founded: 1916 },
        { name: "Honda", country: "Japan", founded: 1946 },
        { name: "Chevrolet", country: "United States", founded: 1911 },
        { name: "Mercedes-Benz", country: "Germany", founded: 1926 },
        { name: "Volkswagen", country: "Germany", founded: 1937 },
        { name: "Nissan", country: "Japan", founded: 1933 },
        { name: "Audi", country: "Germany", founded: 1910 },
        { name: "Hyundai", country: "South Korea", founded: 1967 },
        { name: "Kia", country: "South Korea", founded: 1944 },
        { name: "Mazda", country: "Japan", founded: 1920 },
        { name: "Subaru", country: "Japan", founded: 1953 },
        { name: "Volvo", country: "Sweden", founded: 1927 },
        { name: "Porsche", country: "Germany", founded: 1931 },
        { name: "Jaguar", country: "United Kingdom", founded: 1922 },
        { name: "Land Rover", country: "United Kingdom", founded: 1948 },
        { name: "Ferrari", country: "Italy", founded: 1939 },
        { name: "Lamborghini", country: "Italy", founded: 1963 },
        { name: "Tesla", country: "United States", founded: 2003 },
        { name: "Chrysler", country: "United States", founded: 1925 },
        { name: "Dodge", country: "United States", founded: 1900 },
        { name: "Jeep", country: "United States", founded: 1941 },
        { name: "Ram", country: "United States", founded: 2010 },
        { name: "Buick", country: "United States", founded: 1899 },
        { name: "Cadillac", country: "United States", founded: 1902 },
        { name: "GMC", country: "United States", founded: 1911 },
        { name: "Fiat", country: "Italy", founded: 1899 },
        { name: "Alfa Romeo", country: "Italy", founded: 1910 },
        { name: "Maserati", country: "Italy", founded: 1914 },
        { name: "Aston Martin", country: "United Kingdom", founded: 1913 },
        { name: "McLaren", country: "United Kingdom", founded: 1963 },
        { name: "Bugatti", country: "France", founded: 1909 },
        { name: "Lexus", country: "Japan", founded: 1989 },
        { name: "Infiniti", country: "Japan", founded: 1989 },
        { name: "Genesis", country: "South Korea", founded: 2015 },
        { name: "Mini", country: "United Kingdom", founded: 1959 },
        { name: "Smart", country: "Germany", founded: 1994 },
      ],
      carTypes: [],
      provinces: [],
      districts: [],
      sectors: [],
      cells: [],
      Destdistricts: [],
      Destsectors: [],
      Destcells: [],
    };
  },
  methods: {
    addVehicle() {
      // check if all fields are filled
      if (
        this.newItem.car_body_id == "" ||
        this.newItem.vehicle_brand == "" ||
        this.newItem.vehicle_images == [] ||
        this.newItem.vehicle_driver == "" ||
        this.newItem.vehicle_driver_phone == "" ||
        this.newItem.vehicle_status == "" ||
        this.newItem.is_for_transport == "" ||
        this.newItem.province == "" ||
        this.newItem.district == "" ||
        this.newItem.sector == "" ||
        this.newItem.cell == "" ||
        this.newItem.destination_province == "" ||
        this.newItem.destination_district == "" ||
        this.newItem.destination_sector == "" ||
        this.newItem.destination_cell == "" ||
        this.newItem.Message == ""
      ) {
        this.$notify({
          title: "Error",
          text: "Please fill all fields",
          type: "error",
        });
        return;
      } else {
        this.$startLoader();
        this.newItem.vehicle_images = JSON.stringify(
          this.newItem.vehicle_images
        );
        this.$store
          .dispatch("EDIT_VEHICLE", {
            token: this.$getToken(),
            data: this.newItem,
          })
          .then((res) => {
            this.$stopLoader();
            this.$notify({
              title: "Success",
              text: "Vehicle updated successfully.",
              type: "success",
            });
            this.closeModel();
          })
          .catch((err) => {
            console.log(err);
            this.$notify({
              title: "Error",
              text: "Something went wrong",
              type: "error",
            });
          });
      }
    },
    closeModel() {
      document.body.style.overflowY = "auto";
      this.$emit("closePopup");
    },
    getCarBodies() {
      this.$startLoader();
      this.$store
        .dispatch("GET_CAR_BODIES", {
          token: this.$getToken(),
        })
        .then((res) => {
          this.carTypes = res.data.data;
          this.$stopLoader();
        })
        .catch((err) => {
          console.log(err);
          this.$stopLoader();
        });
    },
    getProvinces() {
      this.$startLoader();
      this.$store
        .dispatch("GET_PROVINCES", { token: this.$getToken() })
        .then((res) => {
          this.$stopLoader();
          this.provinces = res.data.data.data;
        });
    },
    getDistricts(provinceId) {
      this.$startLoader();
      this.$store
        .dispatch("GET_DISTRICTS", {
          token: this.$getToken(),
          provinceId: provinceId,
        })
        .then((res) => {
          this.$stopLoader();
          this.districts = res.data.data.data;
        });
    },
    getSectors(districtId) {
      this.$startLoader();
      this.$store
        .dispatch("GET_SECTORS", {
          token: this.$getToken(),
          districtId: districtId,
        })
        .then((res) => {
          this.$stopLoader();
          this.sectors = res.data.data.data;
        });
    },
    getCells(sectorId) {
      this.$startLoader();
      this.$store
        .dispatch("GET_CELLS", {
          token: this.$getToken(),
          sectorId: sectorId,
        })
        .then((res) => {
          this.$stopLoader();
          this.cells = res.data.data.data;
        });
    },
    getDestDistricts(provinceId) {
      this.$startLoader();
      this.$store
        .dispatch("GET_DISTRICTS", {
          token: this.$getToken(),
          provinceId: provinceId,
        })
        .then((res) => {
          this.$stopLoader();
          this.Destdistricts = res.data.data.data;
        });
    },
    getDestSectors(districtId) {
      this.$startLoader();
      this.$store
        .dispatch("GET_SECTORS", {
          token: this.$getToken(),
          districtId: districtId,
        })
        .then((res) => {
          this.$stopLoader();
          this.Destsectors = res.data.data.data;
        });
    },
    getDestCells(sectorId) {
      this.$startLoader();
      this.$store
        .dispatch("GET_CELLS", {
          token: this.$getToken(),
          sectorId: sectorId,
        })
        .then((res) => {
          this.$stopLoader();
          this.Destcells = res.data.data.data;
        });
    },
  },
  mounted() {
    this.getCarBodies();
    this.getProvinces();
    // this.newItem.vehicle_images = JSON.parse(this.newItem.vehicle_images)
    if (this.newItem.province) {
      this.getDistricts(this.newItem.province);
    }
    this.getSectors(this.newItem.district);
    this.getCells(this.newItem.sector);

    if (this.newItem.destination_province) {
      this.getDestDistricts(this.newItem.destination_province);
    }
    this.getDestSectors(this.newItem.destination_district);
    this.getDestCells(this.newItem.destination_sector);
  },
};
</script>
  
  <style scoped lang="scss">
@import "@/assets/scss/main.scss";

button {
  border: none;
  background-color: #1d3557;
  // margin-left: 1rem;
  border-radius: 5px;
  padding: 15px 10px;
  color: #fff;
  display: block;
  width: 300px;
}
</style>
  