<template>
    <div class="max-w-4xl mx-auto p-8">
      <h1 class="text-2xl font-bold mb-4">Add New Worker</h1>
      <form @submit.prevent="addWorker">
        <div class="mb-4">
          <label for="name" class="block font-bold text-gray-700 mb-2">Name</label>
          <input v-model="worker.name" type="text" id="name" name="name" class="w-full border p-2 rounded" />
        </div>

        <div class="flex mb-4 -mx-2">
            <div class="w-1/2 px-2">
          <label for="Province" class="block font-bold text-gray-700 mb-2">Province</label>
          <select v-model="worker.province" id="province" name="province" class="w-full border p-2 rounded">
            <option value="Kigali">Kigali</option>
          </select>
        </div>
        <div class="w-1/2 px-2">
          <label for="district" class="block font-bold text-gray-700 mb-2">District</label>
          <select v-model="worker.district" id="district" name="district" class="w-full border p-2 rounded">
            <option value="Kicukiro">Kicukiro</option>
          </select>
        </div>
        <div class="w-1/2 px-2">
          <label for="sector" class="block font-bold text-gray-700 mb-2">Sector</label>
          <select v-model="worker.sector" id="sector" name="sector" class="w-full border p-2 rounded">
            <option value="Busanza">Busanza</option>
          </select>
        </div>
      </div>
        <div class="flex mb-4 -mx-2">
         <div class="w-1/2 px-2">
        <label for="skills" class="block font-bold text-gray-700 mb-2">Skills</label>
        <select v-model="worker.skills" type="text" id="skills" name="skills" class="w-full border p-2 rounded" >
        </select>
      </div>
        <div class="w-1/2 px-2">
          <label for="skillSector" class="block font-bold text-gray-700 mb-2">Skill Sector</label>
          <select v-model="worker.skillSector" id="skillSector" name="skillSector" class="w-full border p-2 rounded">
            <option value="Skill Sector A">Skill Sector A</option>
          </select>
        </div>
        <div class="w-1/2 px-2">
          <label for="category" class="block font-bold text-gray-700 mb-2">Category</label>
          <select v-model="worker.category" id="category" name="category" class="w-full border p-2 rounded">
            <option value="Category A">Category A</option>
          </select>
        </div>
      </div>
        <div class="mb-4">
        <label class="block font-bold text-gray-700 mb-2">Availability</label>
        <div>
          <label class="inline-flex items-center">
            <input v-model="worker.availability" type="radio" value="Available" class="form-radio h-5 w-5 text-blue-600" />
            <span class="ml-2">Available</span>
          </label>
          <label class="inline-flex items-center ml-6">
            <input v-model="worker.availability" type="radio" value="Not Available" class="form-radio h-5 w-5 text-red-600" />
            <span class="ml-2">Not Available</span>
          </label>
        </div>
      </div>
        <div class="mb-4">
          <label for="title" class="block font-bold text-gray-700 mb-2">Job Title</label>
          <input v-model="worker.title" type="text" id="title" name="title" class="w-full border p-2 rounded" />
        </div>
        <div class="mb-4">
          <label for="bio" class="block font-bold text-gray-700 mb-2">Bio</label>
          <quill-editor theme="snow"></quill-editor>
        </div>

        <button type="submit" class="bg-blue-500 text-white px-4 py-2 rounded">Add Worker</button>
      </form>
    </div>
  </template>
  
  <script>
    import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
  export default {
    data() {
      return {
        worker: {
          name: '',
          skills: '',
          province: '',
          district: '',
          sector: '',
          skillSector: '',
          category: '',
          availability: '',
          title: '',
          bio: '',
          reviews: ''
        }
      };
    },
    methods: {
      addWorker() {
        console.log('Adding worker:', this.worker);
      }
    }
  };
  </script>
  
  <style scoped>
  button {
    background-color: #1d3557;
  }
  </style>
  