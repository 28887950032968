import axios from 'axios';
import { API_URL, DEALS_API_URL } from '../../variables';
const GET_DEALCATEGORY_URL = DEALS_API_URL + '/categories/getAllCategories';
const GET_DEALCATEGORYBYID_URL = DEALS_API_URL + '/categories/getCategoryById';
const DELETE_DEALCATEGORY_URL = DEALS_API_URL + '/categories/deleteDealsCategory';
const ADD_DEALCATEGORY_URL = DEALS_API_URL + '/categories/createDealsCategory';
const EDIT_DEALCATEGORY_URL = DEALS_API_URL + '/categories/updateDealsCategory';

const dealCategories = {
    state: {
    },
    getters: {},
    mutations: {},
    actions: {
        ['GET_DEALCATEGORY']: ({ commit }, payload) => {
            return axios({
                url: GET_DEALCATEGORY_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
        ['GET_DEALCATEGORYBYID']: ({ commit }, payload) => {
            return axios({
                url: GET_DEALCATEGORYBYID_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
        ['DELETE_DEALCATEGORY']: ({ commit }, payload) => {
            return axios({
                url: DELETE_DEALCATEGORY_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
        ['ADD_DEALCATEGORY']: ({ commit }, payload) => {
            return axios({
                url: ADD_DEALCATEGORY_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
        ['EDIT_DEALCATEGORY']: ({ commit }, payload) => {
            return axios({
                url: EDIT_DEALCATEGORY_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
    },
    modules: {},
}
export default dealCategories;