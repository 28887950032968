<template>
  <div class="loggedUser" v-if="user">
    <div
      class="img"
      @click="
        toggle = true;
        showDrop = true;
      "
    >
      <img src="/assets/images/av.png" alt="avatar" />
      <label for="">{{ getOneWord(user.names) }}</label>
    </div>
    <div class="dropdown-content" v-if="toggle">
      <div class="name">
        <span>{{ user.names }}</span>
      </div>
      <div class="links">
        <router-link :to="{ name: 'Profile' }" class="a">{{
          $txt("Profile")
        }}</router-link>
        <router-link :to="{ name: 'ForgotPassword' }" class="a">{{
          $txt("Change password")
        }}</router-link>
        <button class="a" @click="$userLogout()">{{ $txt("Logout") }}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "loggedUser",
  props: {
    showDrop: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      user: null,
      toggle: false,
    };
  },
  methods: {
    getOneWord(name) {
      return name.split(" ")[0];
    },
  },
  mounted() {
    this.user = this.$getUser();
  },
  watch: {
    showDrop: function (val) {
      if (this.showDrop == false) {
        this.toggle = false;
      } else {
        this.toggle = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.loggedUser {
  position: relative;
  .img {
    overflow: hidden;
    cursor: pointer;
    display: flex;
    justify-content: baseline;
    align-items: center;
    flex-direction: column;
    img {
      background: #eee;
      padding: 5px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      object-fit: cover;
      @media screen and (max-width: 1500px) {
        width: 30px;
        height: 30px;
      }
    }
    label {
      font-size: 14px;
      font-weight: 600;
      margin-top: -5px;
      display: none;
      @media screen and (max-width: 1500px) {
        font-size: 10px;
      }
    }
  }
  .dropdown-content {
    position: absolute;
    top: 50px;
    right: 0;
    width: 200px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding: 10px;
    z-index: 1;
    @media screen and (max-width: 1500px) {
      width: 150px;
      top: 35px;
    }
    .name {
      text-align: center;
      margin-bottom: 10px;
      padding-bottom: 5px;
      border-bottom: 1px solid #ccc;
      background: #f8feff;
      span {
        font-weight: bold;
        font-size: 10px;
        @media screen and (max-width: 1500px) {
          font-size: 8px;
        }
      }
    }
    .links {
      .a {
        display: block;
        padding: 5px 10px;
        text-align: left;
        color: #000;
        font-size: 15px;
        width: 100%;
        @media screen and (max-width: 1500px) {
          font-size: 10px;
        }
        &:hover {
          color: #000;
          background: #f0f0f0;
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .loggedUser {
    font-size: 5px !important;
    img {
      height: 24px !important;
      width: 24px !important;
    }
  }
}
</style>