<template>
  <div class="agriProfileMenu">
    <ul>
      <li>
        <router-link :to="{ name: 'MyProduction' }">{{
          $txt("My Production")
        }}</router-link>
        <router-link :to="{ name: 'MyOrders' }">{{
          $txt("Client orders")
        }}</router-link>
        <router-link :to="{ name: 'MyCooperatives' }">{{
          $txt("My Cooperatives")
        }}</router-link>
        <!-- <router-link :to="{ name: 'home' }">{{
          $txt("All Cooperatives")
        }}</router-link> -->
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "agriProfileMenu",
  data() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.agriProfileMenu {
  ul {
    list-style: none;
    display: flex;
    li {
      a {
        padding: 10px 20px;
        display: inline-block;
        border: 1px #ccc solid;
        border-radius: 7px 7px 0 0;
        background: #eee;
      }
      .router-link-active,
      .router-link-exact-active {
        color: $blue;
        background: #f7f7f7;
        border-bottom: 0px #ccc solid;
      }
    }
  }
}
</style>