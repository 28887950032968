<template>
  <div class="Deals">
    <super-admin-topbar :pageTitle="'Special Deals'" />
    <div class="page-content">
      <div class="levels-table">
        <div class="table-header">
          <div class="left">
            <div class="search">
              <input
                type="text"
                placeholder="Search"
                @keyup="searchData()"
                v-model="search"
              />
            </div>
          </div>
          <div class="right">
            <button @click="popupOpen = true">Add Deal</button>
          </div>
        </div>
        <div class="item-per-page">
          <label for="">Items per page: </label>
          <select name="" id="" v-model="itemsPerPage" @change="changePage(1)">
            <option>5</option>
            <option>10</option>
            <option>25</option>
            <option>50</option>
            <option>100</option>
            <option>500</option>
          </select>
        </div>
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Owner</th>
              <th>Price</th>
              <th>Location</th>
              <th>Category</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="deal in deals" :key="deal.id">
              <td class="img">
                <img :src="$file(deal.product_coverImage)" alt="" />
              </td>
              <td>{{ deal.product_name }}</td>
              <td>{{ deal.ownerNames }}</td>
              <td>{{ deal.product_price }}</td>
              <td>{{ deal.product_location }}</td>
              <td>{{ deal.category.name }}</td>
              <td>
                <button @click="editdeal(deal)">
                  <img src="/assets/images/edit.svg" alt="" />
                </button>
                <button @click="deletedeal(deal)">
                  <img src="/assets/images/delete.svg" alt="" />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <pagination
          :totalItems="allDeals.length"
          :itemsPerPage="itemsPerPage"
          @pageChanged="
            (page) => {
              changePage(page);
            }
          "
        />
      </div>
      <model
        :modelTitle="'Add deal'"
        :largeModel="true"
        v-show="popupOpen"
        @closeModel="popupOpen = false"
      >
        <form action="" class="addLevel" @submit.prevent="confirmAdd()">
          <div class="form-group group-2">
            <div class="input-group">
              <label for="">Deal Name</label>
              <input
                type="text"
                placeholder="Deal Name"
                v-model="newData.product_name"
              />
            </div>
            <div class="input-group">
              <label for="">Deal Price</label>
              <input
                type="number"
                placeholder="Deal Price"
                v-model="newData.product_price"
              />
            </div>
          </div>
          <div class="form-group group-2">
            <div class="input-group">
              <label for="">Deal Location</label>
              <input
                type="text"
                placeholder="Deal Location"
                v-model="newData.product_location"
              />
            </div>
            <div class="input-group">
              <label for="">Deal Category</label>
              <select
                name=""
                id=""
                v-model="newData.category_id"
                class="select"
              >
                <option value="">Select Category</option>
                <option
                  v-for="category in categories"
                  :key="category.category_id"
                  :value="category.category_id"
                >
                  {{ category.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group group-2">
            <div class="input-group">
              <label for="">Owner Names</label>
              <input
                type="text"
                placeholder="Owner Names"
                v-model="newData.ownerNames"
              />
            </div>
            <div class="input-group">
              <label for="">Owner Phone</label>
              <input
                type="text"
                placeholder="Owner Phone"
                v-model="newData.ownerPhone"
              />
            </div>
          </div>
          <div class="form-group group-2">
            <div class="input-group">
              <label for="">Owner Email</label>
              <input
                type="text"
                placeholder="Owner Email"
                v-model="newData.ownerEmail"
              />
            </div>
            <div class="input-group">
              <label for="">Is New</label>
              <select name="" id="" v-model="newData.is_new" class="select">
                <option value="">Select</option>
                <option :value="1">Yes</option>
                <option :value="0">No</option>
              </select>
            </div>
          </div>
          <div class="form-group group-2">
            <div class="input-group">
              <label for="">Deal Cover Image</label>
              <o-upload
                :multiple="false"
                :uploadUrl="'files/upload'"
                @imagesUploaded="
                  (data) => {
                    newData.product_coverImage = data;
                  }
                "
                @imagesUploadedFailed="
                  (data) => {
                    console.log('failed');
                  }
                "
                :value="newData.product_coverImage"
                @startLoader="() => $startLoader()"
                @stopLoader="() => $stopLoader()"
              />
            </div>
            <div class="input-group">
              <label for="">Deal Images</label>
              <o-upload
                :multiple="true"
                :uploadUrl="'files/upload'"
                @imagesUploaded="
                  (data) => {
                    newData.all_product_images = data;
                  }
                "
                @imagesUploadedFailed="
                  (data) => {
                    console.log('failed');
                  }
                "
                :value="newData.all_product_images"
                @startLoader="() => $startLoader()"
                @stopLoader="() => $stopLoader()"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="">Deal Description</label>
            <textarea
              name=""
              id=""
              cols="30"
              rows="6"
              placeholder="Deal Description"
              v-model="newData.product_description"
            ></textarea>
          </div>

          <div class="form-group">
            <button type="submit">Save</button>
          </div>
        </form>
      </model>
      <model
        :modelTitle="'Edit deal'"
        :largeModel="true"
        :smallModel="false"
        v-show="editPopupOpen"
        @closeModel="editPopupOpen = false"
      >
        <form action="" class="addLevel" @submit.prevent="confirmEdit()">
          <div class="form-group group-2">
            <div class="input-group">
              <label for="">Deal Name</label>
              <input
                type="text"
                placeholder="Deal Name"
                v-model="selecteddeal.product_name"
              />
            </div>
            <div class="input-group">
              <label for="">Deal Price</label>
              <input
                type="number"
                placeholder="Deal Price"
                v-model="selecteddeal.product_price"
              />
            </div>
          </div>
          <div class="form-group group-2">
            <div class="input-group">
              <label for="">Deal Location</label>
              <input
                type="text"
                placeholder="Deal Location"
                v-model="selecteddeal.product_location"
              />
            </div>
            <div class="input-group">
              <label for="">Deal Category</label>
              <select
                name=""
                id=""
                v-model="selecteddeal.category_id"
                class="select"
              >
                <option value="">Select Category</option>
                <option
                  v-for="category in categories"
                  :key="category.category_id"
                  :value="category.category_id"
                >
                  {{ category.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group group-2">
            <div class="input-group">
              <label for="">Owner Names</label>
              <input
                type="text"
                placeholder="Owner Names"
                v-model="selecteddeal.ownerNames"
              />
            </div>
            <div class="input-group">
              <label for="">Owner Phone</label>
              <input
                type="text"
                placeholder="Owner Phone"
                v-model="selecteddeal.ownerPhone"
              />
            </div>
          </div>
          <div class="form-group group-2">
            <div class="input-group">
              <label for="">Owner Email</label>
              <input
                type="text"
                placeholder="Owner Email"
                v-model="selecteddeal.ownerEmail"
              />
            </div>
            <div class="input-group">
              <label for="">Is New</label>
              <select
                name=""
                id=""
                v-model="selecteddeal.is_new"
                class="select"
              >
                <option value="">Select</option>
                <option :value="1">Yes</option>
                <option :value="0">No</option>
              </select>
            </div>
          </div>
          <div class="form-group group-2">
            <div class="input-group">
              <label for="">Deal Cover Image</label>
              <o-upload
                :multiple="false"
                :uploadUrl="'files/upload'"
                @imagesUploaded="
                  (data) => {
                    selecteddeal.product_coverImage = data;
                  }
                "
                @imagesUploadedFailed="
                  (data) => {
                    console.log('failed');
                  }
                "
                :value="selecteddeal.product_coverImage"
                @startLoader="() => $startLoader()"
                @stopLoader="() => $stopLoader()"
              />
            </div>
            <div class="input-group">
              <label for="">Deal Images</label>
              <o-upload
                :multiple="true"
                :uploadUrl="'files/upload'"
                @imagesUploaded="
                  (data) => {
                    selecteddeal.product_images = data;
                  }
                "
                @imagesUploadedFailed="
                  (data) => {
                    console.log('failed');
                  }
                "
                :value="selecteddeal.product_images"
                @startLoader="() => $startLoader()"
                @stopLoader="() => $stopLoader()"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="">Deal Description</label>
            <textarea
              name=""
              id=""
              cols="30"
              rows="6"
              placeholder="Deal Description"
              v-model="selecteddeal.product_description"
            ></textarea>
          </div>

          <div class="form-group">
            <button type="submit">Update</button>
          </div>
        </form>
      </model>
      <confirm-model
        :modelTitle="'Delete deal'"
        :smallModel="true"
        v-if="deletePopupOpen"
        @closeModel="deletePopupOpen = false"
        @confirmModel="confirmDelete()"
        :displayText="'Are you sure you want to delete this deal?'"
      ></confirm-model>
    </div>
  </div>
</template>

<script>
export default {
  name: "Deals",
  data() {
    return {
      allDeals: [],
      deals: [],
      search: "",
      popupOpen: false,
      editPopupOpen: false,
      newData: {
        product_name: "",
        product_price: "",
        product_location: "",
        ownerNames: "",
        ownerPhone: "",
        ownerEmail: "",
        is_new: 1,
        category_id: "",
        product_coverImage: "",
        all_product_images: [],
        product_description: "",
      },
      selecteddeal: {},
      deletePopupOpen: false,
      currentPage: 1,
      itemsPerPage: 5,
      categories: [],
    };
  },
  methods: {
    getdeals() {
      this.$startLoader();
      this.$store
        .dispatch("GET_SPECIALDEAL", { token: this.$getToken() })
        .then((res) => {
          // if res code is 200
          if (res.data.status == "success") {
            this.allDeals = res.data.data;

            // json parse the images
            this.allDeals.forEach((deal) => {
              deal.product_images = JSON.parse(deal.product_images);
            });
            this.deals = this.allDeals;
          }
          this.$stopLoader();
        })
        .catch((err) => {
          this.$stopLoader();
        });
    },
    getCategorys() {
      // this.$startLoader();
      this.$store
        .dispatch("GET_DEALCATEGORY", { token: this.$getToken() })
        .then((res) => {
          this.categories = res.data.data;
          // this.$stopLoader();
        })
        .catch((err) => {
          // this.$stopLoader();
        });
    },
    searchData() {
      this.deals = this.allDeals.filter((deal) => {
        return deal.dealName.toLowerCase().includes(this.search.toLowerCase());
      });
    },
    editdeal(deal) {
      this.selecteddeal = deal;
      this.editPopupOpen = true;
    },
    deletedeal(deal) {
      this.selecteddeal = deal;
      this.deletePopupOpen = true;
    },
    confirmDelete() {
      this.$startLoader();
      this.$store
        .dispatch("DELETE_SPECIALDEAL", {
          token: this.$getToken(),
          data: this.selecteddeal,
        })
        .then((res) => {
          if (res.data.status) {
            this.$notify({
              title: "Success",
              type: "success",
              text: "deal deleted successfully",
            });
          }
          this.deletePopupOpen = false;
          this.getdeals();
          this.$stopLoader();
        })
        .catch((err) => {
          console.log(err);
          this.$notify({
            title: "Error",
            type: "error",
            text: err.response.data.error,
          });
          this.$stopLoader();
        });
    },
    confirmAdd() {
      this.$startLoader();
      this.newData.product_images = JSON.stringify([
        ...this.newData.all_product_images,
      ]);
      this.$store
        .dispatch("ADD_SPECIALDEAL", {
          token: this.$getToken(),
          data: this.newData,
        })
        .then((res) => {
          if (res.data.status) {
            this.$notify({
              title: "Success",
              type: "success",
              text: "deal added successfully",
            });
          }
          this.popupOpen = false;
          this.getdeals();
          this.$stopLoader();
        })
        .catch((err) => {
          console.log(err);
          this.$notify({
            title: "Error",
            type: "error",
            text: err.response.data.error,
          });
          this.$stopLoader();
        });
    },
    confirmEdit() {
      this.$startLoader();
      this.selecteddeal.product_images = JSON.stringify([
        ...this.selecteddeal.product_images,
      ]);
      this.$store
        .dispatch("EDIT_SPECIALDEAL", {
          token: this.$getToken(),
          data: this.selecteddeal,
        })
        .then((res) => {
          if (res.data.status) {
            this.$notify({
              title: "Success",
              type: "success",
              text: "deal edited successfully",
            });
          }
          this.editPopupOpen = false;
          this.getdeals();
          this.$stopLoader();
        })
        .catch((err) => {
          console.log(err);
          this.$notify({
            title: "Error",
            type: "error",
            text: err.response.data.error,
          });
          this.$stopLoader();
        });
    },
    changePage(page) {
      this.currentPage = page;
      this.deals = this.$changePage(this.allDeals, page, this.itemsPerPage);
    },
  },
  mounted() {
    this.getCategorys();
    this.getdeals();
    this.changePage(1);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.Deals {
  .page-content {
    padding: 1rem;
  }
  .levels-table {
    .table-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
      .left {
        display: flex;
        align-items: center;
        .search {
          input {
            padding: 0.5rem;
            border: 1px solid #ccc;
            border-radius: 5px;
            outline: none;
            width: 300px;
          }
        }
      }
      .right {
        button {
          padding: 0.65rem 1rem;
          width: 150px;
          border: none;
          outline: none;
          border-radius: 5px;
          background: $blue;
          color: #fff;
          cursor: pointer;
          font-weight: 600;
        }
      }
    }
    table {
      width: 100%;
      border-collapse: collapse;
      thead {
        tr {
          th {
            padding: 10px;
            border-bottom: 1px solid #ccc;
            text-align: left;
          }
        }
      }
      tbody {
        tr {
          td {
            padding: 10px;
            border-bottom: 1px solid #ccc;
            button {
              background: transparent;
              border: none;
              outline: none;
              cursor: pointer;
              margin-right: 1rem;
              img {
                width: 30px;
              }
            }
          }
        }
      }
    }
  }
  .addLevel {
    .form-group {
      margin-bottom: 1rem;
      label {
        display: block;
        margin-bottom: 0.5rem;
      }
      input {
        padding: 0.5rem;
        border: 1px solid #ccc;
        border-radius: 5px;
        outline: none;
        width: 100%;
      }
      button {
        padding: 0.65rem 1rem;
        width: 150px;
        border: none;
        outline: none;
        border-radius: 5px;
        background: $blue;
        color: #fff;
        cursor: pointer;
        font-weight: 600;
      }
    }
  }
}
</style>