import axios from 'axios'
import { API_URL } from '../variables';
const GET_PROVINCES_URL = API_URL + '/locations/provinces';
const GET_DISTRICTS_URL = API_URL + '/locations/districts/';
const GET_SECTORS_URL = API_URL + '/locations/sectors/';
const GET_CELLS_URL = API_URL + '/locations/cells/';

const translation = {
    state: {
    },
    getters: {},
    mutations: {},
    actions: {
        ['GET_PROVINCES']: ({ commit }, payload) => {
            return axios({
                url: GET_PROVINCES_URL,
                method: "GET",
                data: payload,
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
        ['GET_DISTRICTS']: ({ commit }, payload) => {
            console.log(payload);
            return axios({
                url: GET_DISTRICTS_URL + payload.provinceId,
                method: "GET",
                data: payload,
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
        ['GET_SECTORS']: ({ commit }, payload) => {
            return axios({
                url: GET_SECTORS_URL + payload.districtId,
                method: "GET",
                data: payload,
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
        ['GET_CELLS']: ({ commit }, payload) => {
            return axios({
                url: GET_CELLS_URL + payload.sectorId,
                method: "GET",
                data: payload,
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
    },
    modules: {},
}
export default translation;