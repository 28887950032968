<template>
  <div class="Deals">
    <site-navbar />
    <div class="search-section">
      <form action="">
        <p>
          {{ $txt("Special") }} <span>Deals</span>
          {{ $txt("for you") }}
        </p>
      </form>
    </div>
    <div class="whole-content">
      <div class="body-widget">
        <div class="deals-widget">
          <div class="widget-header">
            <label for="">{{ $txt("Special deals") }}</label>
            <div class="nav-link">
              <input
                type="text"
                placeholder="Search"
                v-model="searchQuery"
                @keyup="searchNow()"
              />
            </div>
          </div>
          <div class="widget-content">
            <div class="carousel">
              <div v-for="ad in deals" :key="ad.id" @click="startView(ad)">
                <div to="/" class="ad-body">
                  <div class="img">
                    <img
                      :src="$file(ad.product_coverImage)"
                      alt=""
                      @error="$imageurlalt()"
                    />
                  </div>
                  <div class="d-flex">
                    <p class="desc"><span></span>{{ ad.product_name }}</p>
                    <p class="price">
                      <span></span>{{ $comma(ad.product_price) }} Rwf
                    </p>
                  </div>
                  <div class="d-flex">
                    <p class="desc"><span></span>{{ $txt("Owner") }}</p>
                    <p class="desc2"><span></span>{{ ad.ownerNames }}</p>
                  </div>
                  <div class="d-flex">
                    <p class="desc"><span></span>{{ $txt("Phone") }}</p>
                    <p class="desc2"><span></span>{{ ad.ownerPhone }}</p>
                  </div>
                  <!-- <p class="desc ad">
                    {{ ad.product_description }}
                  </p> -->
                </div>
              </div>
            </div>
          </div>
          <div
            class="widget-footer"
            v-if="allDeals.length != deals.length && searchQuery == ''"
          >
            <button @click="loadmore()">Load more</button>
          </div>
        </div>
      </div>
      <div class="header-widget">
        <div class="header-sections">
          <div
            class="header-section"
            :class="selectedSection == 1 ? 'active' : ''"
            @mouseover="selectedSection = 1"
          >
            <img src="/assets/images/tea.jpg" alt="" @error="$imageurlalt()" />
            <label for="" v-show="selectedSection == 1"
              ><span>
                {{
                  $txt(
                    "Find the best coach or location for yourself or your kids"
                  )
                }}</span
              >
              <div class="explore" v-show="selectedSection == 1">
                <button>{{ $txt("Explore") }}</button>
              </div>
            </label>
          </div>
          <div
            class="header-section"
            :class="selectedSection == 2 ? 'active' : ''"
            @mouseover="selectedSection = 2"
          >
            <img
              src="/assets/images/cover/tech.jpg"
              alt=""
              @error="$imageurlalt()"
            />
            <label for="" v-show="selectedSection == 2"
              ><span>{{ $txt("Hire a proffesional and find jobs.") }}"</span>
              <div class="explore" v-show="selectedSection == 2">
                <button>{{ $txt("Explore") }}</button>
              </div>
            </label>
          </div>
          <div
            class="header-section"
            :class="selectedSection == 3 ? 'active' : ''"
            @mouseover="selectedSection = 3"
          >
            <img
              src="/assets/images/cover/edu.jpg"
              alt=""
              @error="$imageurlalt()"
            />
            <label for="" v-show="selectedSection == 3">
              <span>{{
                $txt("Browse and know more about the best locations in Rwanda")
              }}</span>
              <div class="explore" v-show="selectedSection == 3">
                <button>{{ $txt("Explore") }}</button>
              </div>
            </label>
          </div>
          <div
            class="header-section"
            :class="selectedSection == 4 ? 'active' : ''"
            @mouseover="selectedSection = 4"
          >
            <img
              src="/assets/images/cover/rnit.jpg"
              alt=""
              @error="$imageurlalt()"
            />
            <label for="" v-show="selectedSection == 4">
              <span
                >{{ $txt("Boost your business and brand awareness") }}"</span
              >
              <div class="explore" v-show="selectedSection == 4">
                <button>{{ $txt("Explore") }}</button>
              </div>
            </label>
          </div>
          <div
            class="header-section"
            :class="selectedSection == 5 ? 'active' : ''"
            @mouseover="selectedSection = 5"
          >
            <img
              src="/assets/images/cover/trucks.jpg"
              alt=""
              @error="$imageurlalt()"
            />
            <label for="" v-show="selectedSection == 5"
              ><span>{{
                $txt(
                  "Transport your goods and materiels, Find transport clients for your vehicles"
                )
              }}</span>
              <div class="explore" v-show="selectedSection == 5">
                <button>{{ $txt("Explore") }}</button>
              </div>
            </label>
          </div>
          <div
            class="header-section"
            :class="selectedSection == 6 ? 'active' : ''"
            @mouseover="selectedSection = 6"
          >
            <img
              src="/assets/images/burger.jpg"
              alt=""
              @error="$imageurlalt()"
            />
            <label for="" v-show="selectedSection == 6"
              ><span>{{
                $txt(
                  "Sell or buy products and services to new customers and boost your sales"
                )
              }}</span>
              <div class="explore" v-show="selectedSection == 6">
                <button>{{ $txt("Explore") }}</button>
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>
    <view-product
      v-if="viewProduct"
      :item="selectItem"
      @closePopup="viewProduct = false"
    />
    <site-footer />
  </div>
</template>

<script>
export default {
  name: "Deals",
  components: {},
  data() {
    return {
      allDeals: [],
      deals: [],
      ismodules: [],
      modules: [],
      selectedSection: 1,
      searchQuery: "",
      viewProduct: false,
      selectItem: {},
      location: {
        province: "null",
        district: "null",
        sector: "null",
        cell_id: "null",
      },
      provinces: [],
      districts: [],
      sectors: [],
      cells: [],
      allDeals: [],
    };
  },
  methods: {
    loadmore() {
      this.deals = this.allDeals.slice(0, this.deals.length + 12);
    },
    searchNow() {
      if (this.searchQuery != "") {
        this.deals = this.allDeals.filter((ad) => {
          return (
            ad.product_name
              .toLowerCase()
              .includes(this.searchQuery.toLowerCase()) ||
            ad.ownerNames
              .toLowerCase()
              .includes(this.searchQuery.toLowerCase()) ||
            ad.product_description
              .toLowerCase()
              .includes(this.searchQuery.toLowerCase())
          );
        });
      } else {
        (this.deals = []), (this.deals = this.allDeals.slice(0, 12));
      }
    },
    startView(ad) {
      this.selectItem = ad;
      this.viewProduct = true;
    },
    getProvinces() {
      this.$startLoader();
      this.$store
        .dispatch("GET_PROVINCES", { token: this.$getToken() })
        .then((res) => {
          this.provinces = res.data.data.data;
          this.districts = [];
          this.sectors = [];
          this.cells = [];
          this.$stopLoader();
        });
    },
    getDistricts(provinceId) {
      if (provinceId == "" || provinceId == undefined || provinceId == null) {
        this.districts = [];
        this.sectors = [];
        this.cells = [];
        return;
      }
      this.$startLoader();
      this.$store
        .dispatch("GET_DISTRICTS", {
          token: this.$getToken(),
          provinceId: provinceId,
        })
        .then((res) => {
          this.districts = res.data.data.data;
          this.sectors = [];
          this.cells = [];
          this.$stopLoader();
        });
    },
    getSectors(districtId) {
      if (districtId == "" || districtId == undefined || districtId == null) {
        this.sectors = [];
        this.cells = [];
        return;
      }
      this.$startLoader();
      this.$store
        .dispatch("GET_SECTORS", {
          token: this.$getToken(),
          districtId: districtId,
        })
        .then((res) => {
          this.sectors = res.data.data.data;
          this.cells = [];
          this.$stopLoader();
        });
    },
    getCells(sectorId) {
      if (sectorId == "" || sectorId == undefined || sectorId == null) {
        this.cells = [];
        return;
      }
      this.$startLoader();
      this.$store
        .dispatch("GET_CELLS", { token: this.$getToken(), sectorId: sectorId })
        .then((res) => {
          this.cells = res.data.data.data;
          this.$stopLoader();
        });
    },

    getdeals() {
      this.$startLoader();
      this.$store
        .dispatch("GET_SPECIALDEAL", { token: this.$getToken() })
        .then((res) => {
          // if res code is 200
          if (res.data.status == "success") {
            this.allDeals = res.data.data;

            // json parse the images
            this.allDeals.forEach((deal) => {
              deal.product_images = JSON.parse(deal.product_images);
            });
            this.deals = [...this.allDeals].slice(0, 12);
          }
          this.$stopLoader();
        })
        .catch((err) => {
          this.$stopLoader();
        });
    },
    getdealsByCategory() {
      this.$startLoader();
      this.$store
        .dispatch("GET_SPECIALDEALBYCATEGORY", {
          token: this.$getToken(),
          data: { category_id: this.selectedCategory },
        })
        .then((res) => {
          // if res code is 200
          if (res.data.status == "success") {
            this.allDeals = res.data.data;

            // json parse the images
            this.allDeals.forEach((deal) => {
              deal.product_images = JSON.parse(deal.product_images);
            });
          }
          this.deals = [...this.allDeals].slice(0, 12);
          this.$stopLoader();
        })
        .catch((err) => {
          this.$stopLoader();
        });
    },
  },
  mounted() {
    this.deals = this.allDeals.slice(0, 8);
    this.getProvinces();

    if (this.$route.params.id) {
      this.selectedCategory = this.$route.params.id;
      this.getdealsByCategory();
    } else {
      this.getdeals();
    }
  },
  watch: {
    $route(to, from) {
      if (this.$route.params.id) {
        this.selectedCategory = this.$route.params.id;
        this.getdealsByCategory();
      } else {
        this.getdeals();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.whole-content {
  padding: 2rem 5rem;
  background: #f1f3f3;
  .header-widget {
    margin-top: 3rem;
    .header-sections {
      display: flex;
      column-gap: 1rem;
      row-gap: 1rem;
      .header-section {
        position: relative;
        border-radius: 20px;
        overflow: hidden;
        border: 1px solid #ccc;
        width: 200px;
        height: 350px;
        cursor: pointer;
        transition: 0.5s ease-in-out;
        &.active {
          width: 100%;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          background: #f2f2f2;
          // inner-shadow
          box-shadow: inset 0 0 1000px rgba(0, 0, 0, 0.2);
          cursor: pointer;
        }
        label {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 100%;
          padding: 1rem;
          color: #fff;
          font-size: 0.8rem;
          font-weight: 600;
          transition: 1s ease-in-out;
          text-align: right;
          text-shadow: 0 0 10px rgba(0, 0, 0, 0.438);
          display: flex;
          justify-content: flex-end;
          cursor: pointer;
          flex-direction: column;
          background: linear-gradient(
            to top,
            rgba(0, 0, 0, 0.8) 0%,
            rgba(0, 0, 0, 0.5) 30%,
            rgba(0, 0, 0, 0) 50%,
            rgba(0, 0, 0, 0) 100%
          );
          span {
            font-size: 1rem;
            font-weight: 400;
            height: 100%;
            display: flex;
            align-items: flex-end;
            width: 100%;
          }
          .explore {
            display: flex;
            align-items: flex-end;
            width: 100%;
            button {
              background: white;
              color: black;
              padding: 10px 20px;
              border-radius: 3px;
              font-size: 12px;
              font-weight: 600;
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
  .body-widget {
    .deals-widget {
      .widget-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;
        label {
          font-size: 1.2rem;
          font-weight: 600;
          color: black;
        }
        .nav-link {
          input {
            padding: 10px 20px;
            border-radius: 5px;
            border: 1px solid #ccc;
            font-size: 15px;
            font-weight: 500;
            width: 300px;
            outline: none;
          }
        }
      }
      .carousel {
        display: flex;
        column-gap: 1rem;
        row-gap: 1rem;
        overflow-x: auto;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 269.5px));
        cursor: pointer;
        .ad-body {
          padding: 10px 1.5rem;
          background: white;
          border-radius: 5px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          .img {
            width: 100%;
            height: 130px;
            border-radius: 5px;
            background: #eee;
          }
          img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
            object-fit: contain;
            background: #fff;
          }
          p {
            font-size: 0.8rem;
            font-weight: 600;
            width: 100%;
            text-align: center;
            @media (max-width: 1500px) {
              font-size: 0.6rem;
            }
            &.desc {
              font-size: 0.7rem;
              font-weight: 400;
              color: #494949;
              text-align: left;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              @media (max-width: 1500px) {
                font-size: 0.5rem;
              }
              span {
                font-weight: 600;
              }
            }
            &.desc2 {
              font-size: 0.7rem;
              font-weight: 500;
              color: #494949;
              text-align: right;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;

              @media (max-width: 1500px) {
                font-size: 0.5rem;
              }
              span {
                font-weight: 600;
              }
            }
            &.ad {
              color: black;
            }
          }
          .d-flex {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 10px;
            .price {
              font-size: 0.8rem;
              font-weight: 600;
              color: #16a562;
              text-align: right;
              @media (max-width: 1500px) {
                font-size: 0.6rem;
              }
            }
            .owner {
              font-size: 0.9rem;
              font-weight: 400;
              color: #494949;
            }
          }
        }
      }
    }
  }
  .widget-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;
    button {
      background: $blue;
      color: white;
      padding: 15px 20px;
      border-radius: 3px;
      font-size: 13px;
      font-weight: 600;
    }
  }
}
</style>