<template>
  <div class="SiteNavbar">
    <div class="top-bar">
      <div class="left">
        <p>Infomaster</p>
        <p>
          <a href="mailto:support@infomaster.rw">support@infomaster.rw</a>
        </p>
        <p>
          <a href="tel:+250782539657">+250 782 539 657</a>
        </p>
      </div>
      <div class="right">
        <div class="a" v-click-out-side="customMethod" @click="showDrop = true">
          <change-lang :showDrop="showDrop" />
        </div>
      </div>
    </div>
    <div class="navbar">
      <div class="nav-left">
        <div class="nav-toogler">
          <button @click="$toggleSmallNav()">
            <i class="fa fa-bars"></i>
          </button>
        </div>
        <div class="nav-links">
          <router-link :to="{ name: 'home' }" class="nav-link">
            <label for="">{{ $txt("Home") }}</label>
          </router-link>

          <router-link
            :to="{
              name: 'ViewAgriculture',
            }"
            class="nav-link"
          >
            <label for="">{{ $txt("Agriculture") }}</label>
            <div class="drop-items">
              <div
                class="sub-nav-link"
                v-for="AgriSector in AgriSectors"
                :key="AgriSector.product_sector_id"
              >
                <label for="">{{ AgriSector.product_sector_name }}</label>
                <div class="megaDropdown">
                  <router-link
                    :to="{
                      name: 'ViewSingleAgricultureByCategoryId',
                      params: {
                        category: AgriCategory.category_id,
                        categoryname: $urlCompatible(
                          AgriCategory.category_name
                        ),
                      },
                    }"
                    class="sub-nav-link"
                    v-for="AgriCategory in AgriSector.uniquecategories"
                    :key="AgriCategory.category_id"
                  >
                    <label for="">{{ AgriCategory.category_name }}</label>
                  </router-link>
                </div>
              </div>
              <!-- <router-link
                :to="{
                  name: 'ViewAgriculture',
                }"
                class="sub-nav-link"
              >
                <label for="">{{ $txt("Others") }}</label>
              </router-link> -->
            </div>
          </router-link>
          <router-link
            :to="{
              name: 'Transport',
            }"
            class="nav-link"
          >
            <label for="">{{ $txt("Transport") }}</label>
            <div class="drop-items">
              <router-link
                :to="{
                  name: 'ViewSingleTransport',
                  params: { name: 'For Rent' },
                }"
                class="sub-nav-link"
              >
                <label for="">{{ $txt("Vehicles Availiable for Rent") }}</label>
              </router-link>
              <router-link
                :to="{
                  name: 'ViewSingleTransport',
                  params: { name: 'Moving' },
                }"
                class="sub-nav-link"
              >
                <label for="">{{ $txt("Vehicles in Movement") }}</label>
              </router-link>
            </div>
          </router-link>
          <router-link
            :to="{
              name: 'ListBusinesses',
            }"
            class="nav-link"
          >
            <label for="">{{ $txt("Business directory") }}</label>
          </router-link>
          <router-link
            :to="{
              name: 'Workers',
            }"
            class="nav-link"
          >
            <label for="">Workers</label>
          </router-link>
          <router-link
            :to="{
              name: 'Deals',
            }"
            class="nav-link"
          >
            <label for="">Special deals</label>
            <div class="drop-items">
              <router-link
                v-for="category in dealcategories"
                :key="category.category_id"
                :to="{
                  name: 'SingleDeals',
                  params: {
                    name: $urlCompatible(category.name),
                    id: category.category_id,
                  },
                }"
                class="sub-nav-link"
              >
                <label for="">{{category.name}}</label>
              </router-link>
            </div>
          </router-link>

          <router-link
            :to="{
              name: 'ContactUs',
              params: { name: 'Contact US' },
            }"
            class="nav-link"
          >
            <label for="">{{ $txt("Contact Us") }}</label>
          </router-link>

          <!-- <router-link
            :to="{
              name: 'AboutUs',
              params: { name: 'About US' },
            }"
            class="nav-link"
          >
            <label for="">{{ $txt("About Us") }}</label>
          </router-link> -->
          <router-link
            :to="{
              name: 'Profile',
            }"
            class="nav-link"
            v-if="$getUser()"
          >
            <label for="">{{ $txt("My Dashboard") }}</label>
          </router-link>
        </div>
      </div>
      <div class="nav-right">
        <div class="nav-links">
          <router-link :to="{ name: 'Search' }" class="nav-link search">
            <label for=""><i class="fa fa-search"></i></label>
          </router-link>
          <div
            class="a"
            v-if="$getUser()"
            v-click-out-side="customMethod2"
            @click="showAccountDrop = true"
          >
            <logged-user :showDrop="showAccountDrop" />
          </div>
          <div v-else>
            <router-link :to="{ name: 'Login' }" class="nav-link">
              <label for="">{{ $txt("Login") }}</label>
            </router-link>
            <router-link :to="{ name: 'Register' }" class="nav-link">
              <label for="">{{ $txt("Register") }}</label>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <nav-small v-if="$store.state.showSmallNav" />
  </div>
</template>

<script>
import clickOutSide from "@mahdikhashan/vue3-click-outside";
export default {
  name: "SiteNavbar",
  directives: {
    clickOutSide,
  },
  data() {
    return {
      translatedText: "",
      showDrop: false,
      showAccountDrop: false,
      AgriSectors: [],
      AgriCategories: [],
      dealcategories: [],
    };
  },
  methods: {
    customMethod() {
      this.showDrop = false;
    },
    customMethod2() {
      this.showAccountDrop = false;
    },
    getSectors() {
      this.$store
        .dispatch("GET_AGRISECTORS", { token: this.$getToken() })
        .then((res) => {
          this.AgriSectors = res.data.data;
          this.allAgriSectors = res.data.data;
        })
        .catch((err) => {
          this.$stopLoader();
        });
    },
    getCategories() {
      this.$store
        .dispatch("GET_AGRICATEGORIES", { token: this.$getToken() })
        .then((res) => {
          this.AgriCategories = res.data.data;
          this.allAgriCategories = res.data.data;
        })
        .catch((err) => {});
    },

    getCategoriesBySector(product_sector_id) {
      return this.AgriCategories.filter(
        (item) => item.product_sector_id == product_sector_id
      );
    },

    getDealCategories() {
      // this.$startLoader();
      this.$store
        .dispatch("GET_DEALCATEGORY", { token: this.$getToken() })
        .then((res) => {
          this.dealcategories = res.data.data;
          // this.$stopLoader();
        })
        .catch((err) => {
          // this.$stopLoader();
        });
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    this.$store.state.showSmallNav = false;
    this.getSectors();
    this.getCategories();
    this.getDealCategories();
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.SiteNavbar {
  height: 80px;
  .top-bar {
    width: 100%;
    height: 35px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding: 0 5rem;
    background-color: $darkblue;
    border-bottom: 1px solid #ffffff25;
    position: fixed;
    z-index: 1;
    @media screen and (max-width: 1500px) {
      padding: 0 3rem;
    }
    @media screen and (max-width: 768px) {
      padding: 0 1rem;
    }
    .left {
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        font-size: 12px;
        font-weight: 600;
        color: #fff;
        margin-right: 20px;
        @media screen and (max-width: 1500px) {
          font-size: 0.5rem;
        }
      }
      a {
        @media screen and (max-width: 768px) {
          display: none;
        }
      }
    }

    .right {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .a {
        position: relative;
        cursor: pointer;

        .drop {
          position: absolute;
          top: 30px;
          right: 0;
          width: 100px;
          height: 100px;
          background-color: #fff;
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.507);
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          z-index: 100;
          .item {
            width: 100%;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &:hover {
              background-color: #f5f5f5;
            }
          }
        }
      }
    }
  }

  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $blue;
    padding: 0px 5rem;
    position: fixed;
    width: 100%;
    top: 35px;
    z-index: 999;
    border-bottom: 1px solid #ffffff25;
    @media screen and (max-width: 1500px) {
      padding: 0px 3rem;
    }
    @media screen and (max-width: 768px) {
      padding: 0px 2rem;
    }
    .nav-left {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .nav-toogler {
        border: 1px solid #fff;
        border-radius: 5px;
        padding: 5px;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (min-width: 768px) {
          display: none;
        }
        button {
          background-color: transparent;
          border: none;
          font-size: 30px;
          cursor: pointer;
          color: #fff;
        }
      }
      .nav-links {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media screen and (max-width: 768px) {
          display: none;
        }
        .nav-link {
          margin-right: 20px;
          position: relative;
          cursor: pointer;
          padding: 20px 0;
          @media screen and (max-width: 1500px) {
            padding: 10px 0;
          }
          label {
            font-size: 14px;
            font-weight: 600;
            color: #fff;
            cursor: pointer;
            @media screen and (max-width: 1500px) {
              font-size: 0.6rem;
            }
            &:hover,
            .active {
              color: #f0cd35;
            }
          }
          .drop-items {
            display: none;
            position: absolute;
            top: 63px;
            left: -20px;
            min-width: 120px;
            width: fit-content;
            height: fit-content;
            background-color: $lightblue;
            // box-shadow: 0 0 5px rgba(0, 0, 0, 0.507);
            justify-content: center;
            align-items: center;
            flex-direction: column;
            z-index: 999;
            border-radius: 0px 0px 5px 5px;
            @media screen and (max-width: 1500px) {
              top: 45px;
            }
            // &::before {
            //   content: "";
            //   position: absolute;
            //   top: -10px;
            //   left: 50%;
            //   transform: translateX(-50%);
            //   border-left: 10px solid transparent;
            //   border-right: 10px solid transparent;
            //   border-bottom: 10px solid $lightblue;
            // }
            .sub-nav-link {
              width: 100%;
              display: block;
              cursor: pointer;
              color: #fff;
              padding: 10px 10px;
              position: relative;
              white-space: nowrap;
              @media screen and (max-width: 1500px) {
                padding: 5px 10px;
              }
              .megaDropdown {
                display: none;
                position: absolute;
                top: 0;
                left: 100%;
                width: fit-content;
                height: fit-content;
                min-width: 400px;
                background-color: darken($lightblue, 20%);
                grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
                z-index: 999;
                border-radius: 0px;
                @media screen and (max-width: 1500px) {
                  min-width: 300px;
                  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
                }
                .sub-nav-link {
                  width: 100%;
                  display: block;
                  cursor: pointer;
                  color: #fff;
                  padding: 10px 10px;
                  border-bottom: #f0ce3531 1px dashed;
                  border-right: #f0ce3531 1px dashed;
                  @media screen and (max-width: 1500px) {
                    padding: 5px 10px;
                  }
                  &:last-child {
                    border-bottom: none;
                  }
                  &:hover {
                    background-color: $darkblue;
                    label {
                      color: #f0cd35;
                    }
                  }
                }
                &:hover {
                  display: flex;
                }
              }

              &:hover {
                background-color: $darkblue;
                .megaDropdown {
                  display: grid;
                  a {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                }
              }
            }
          }
          &:hover {
            .drop-items {
              display: flex;
            }
          }
        }
      }
    }
    .nav-right {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .nav-links {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .nav-link {
          margin-right: 20px;
          cursor: pointer;
          label {
            font-size: 14px;
            font-weight: 600;
            color: #fff;
            cursor: pointer;
            &:hover,
            .active {
              color: #f0cd35;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
}
@media screen and (max-width: 576px) {
  .top-bar {
    .left,
    .right {
      p {
        font-size: 10px;
      }
    }
  }
}
</style>
