<template>
  <div class="Categories">
    <super-admin-topbar :pageTitle="'Agricultural Products'" />
    <div class="page-content">
      <div class="levels-table">
        <div class="table-header">
          <div class="left">
            <div class="search">
              <input
                type="text"
                placeholder="Search"
                @keyup="searchData()"
                v-model="search"
              />
            </div>
          </div>
          <div class="right">
            <button @click="popupOpen = true">Add Product</button>
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Type</th>
              <th>Category</th>
              <th>Sector</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in AgriProducts" :key="item.product_id">
              <td>{{ item.product_name }}</td>
              <td>{{ item.is_organic }}</td>
              <td>{{ item.category.category_name }}</td>
              <td>{{ item.product_sector.product_sector_name }}</td>
              <td>
                <button @click="editItem(item)">
                  <img src="/assets/images/edit.svg" alt="" />
                </button>
                <button @click="deleteItem(item)">
                  <img src="/assets/images/delete.svg" alt="" />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <model
        :modelTitle="'Add Product'"
        :largeModel="false"
        :smallModel="true"
        v-show="popupOpen"
        @closeModel="popupOpen = false"
      >
        <form action="" class="addLevel" @submit.prevent="confirmAdd()">
          <div class="form-group">
            <label for="">Product Name</label>
            <input
              type="text"
              placeholder="Product Name"
              v-model="newData.product_name"
            />
          </div>
          <div class="form-group">
            <label for="">Type</label>
            <select name="" id="" v-model="newData.is_organic">
              <option value="">Select</option>
              <option>Organic</option>
              <option>Non-organic</option>
            </select>
          </div>
          <div class="form-group">
            <label for="">Sector</label>
            <select
              name=""
              id=""
              v-model="newData.product_sector_id"
              @change="getCategories(newData.product_sector_id)"
            >
              <option value="">Select</option>
              <option
                :value="sector.product_sector_id"
                v-for="sector in AgriSectors"
                :key="sector.product_sector_id"
              >
                {{ sector.product_sector_name }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label for="">Category</label>
            <select name="" id="" v-model="newData.category_id">
              <option value="">Select</option>
              <option
                :value="category.category_id"
                v-for="category in AgriCategories"
                :key="category.category_id"
              >
                {{ category.category_name }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <button type="submit">Save</button>
          </div>
        </form>
      </model>
      <model
        :modelTitle="'Edit Product'"
        :largeModel="false"
        :smallModel="true"
        v-show="editPopupOpen"
        @closeModel="editPopupOpen = false"
      >
        <form action="" class="addLevel" @submit.prevent="confirmEdit()">
          <div class="form-group">
            <label for="">Product Name</label>
            <input
              type="text"
              placeholder="Product Name"
              v-model="selectedItem.product_name"
            />
          </div>
          <div class="form-group">
            <label for="">Type</label>
            <select name="" id="" v-model="selectedItem.is_organic">
              <option value="">Select</option>
              <option>Organic</option>
              <option>Non-organic</option>
            </select>
          </div>
          <div class="form-group">
            <label for="">Sector</label>
            <select
              name=""
              id=""
              v-model="selectedItem.product_sector_id"
              @change="getCategories(selectedItem.product_sector_id)"
            >
              <option value="">Select</option>
              <option
                :value="sector.product_sector_id"
                v-for="sector in AgriSectors"
                :key="sector.product_sector_id"
              >
                {{ sector.product_sector_name }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label for="">Category</label>
            <select name="" id="" v-model="selectedItem.category_id">
              <option value="">Select</option>
              <option
                :value="category.category_id"
                v-for="category in AgriCategories"
                :key="category.category_id"
              >
                {{ category.category_name }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <button type="submit">Save</button>
          </div>
        </form>
      </model>
      <confirm-model
        :modelTitle="'Delete product'"
        :smallModel="true"
        v-if="deletePopupOpen"
        @closeModel="deletePopupOpen = false"
        @confirmModel="confirmDelete()"
        :displayText="'Are you sure you want to delete this product?'"
      ></confirm-model>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "Categories",
  data() {
    return {
      AgriSectors: [],
      allAgriSectors: [],
      AgriCategories: [],
      allAgriCategories: [],
      allAgriProducts: [],
      AgriProducts: [],
      search: "",
      popupOpen: false,
      editPopupOpen: false,
      newData: {
        product_name: "",
        product_sector_id: "",
        category_id: "",
        is_organic: "",
      },
      selectedItem: {},
      deletePopupOpen: false,
    };
  },
  methods: {
    getSectors() {
      this.$startLoader();
      this.$store
        .dispatch("GET_AGRISECTORS", { token: this.$getToken() })
        .then((res) => {
          this.AgriSectors = res.data.data;
          this.allAgriSectors = res.data.data;
          this.$stopLoader();
        })
        .catch((err) => {
          this.$stopLoader();
        });
    },
    getCategories(product_sector_id) {
      this.$startLoader();
      this.$store
        .dispatch("GET_AGRICATEGORIES_BYSECTOR", {
          token: this.$getToken(),
          data: { product_sector_id: product_sector_id },
        })
        .then((res) => {
          this.AgriCategories = res.data.data;
          this.allAgriCategories = res.data.data;
          this.$stopLoader();
        })
        .catch((err) => {
          this.$stopLoader();
        });
    },
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_AGRIPRODUCTS", { token: this.$getToken() })
        .then((res) => {
          this.allAgriProducts = res.data.data;
          this.AgriProducts = res.data.data;
          this.$stopLoader();
        })
        .catch((err) => {
          this.$stopLoader();
        });
    },
    searchData() {
      this.AgriProducts = this.allAgriProducts.filter((agrisector) => {
        return agrisector.product_name
          .toLowerCase()
          .includes(this.search.toLowerCase());
      });
    },
    editItem(item) {
      this.getCategories(item.product_sector.product_sector_id);
      this.selectedItem = item;
      this.editPopupOpen = true;
    },
    deleteItem(item) {
      this.selectedItem = item;
      this.deletePopupOpen = true;
    },
    confirmAdd() {
      this.$startLoader();
      this.$store
        .dispatch("ADD_AGRIPRODUCT", {
          token: this.$getToken(),
          data: this.newData,
        })
        .then((res) => {
          if (res.data.status == "success") {
            this.$notify({
              title: "Success",
              type: "success",
              text: res.data.message,
            });
            this.popupOpen = false;
            this.getItems();
          } else {
            this.$stopLoader();
            this.$notify({
              title: "Error",
              type: "warn",
              text: res.data.message,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.$notify({
            title: "Error",
            type: "error",
            text: err.response.data.error,
          });
          this.$stopLoader();
        });
    },
    confirmEdit() {
      this.$startLoader();
      this.$store
        .dispatch("EDIT_AGRIPRODUCT", {
          token: this.$getToken(),
          data: this.selectedItem,
        })
        .then((res) => {
          if (res.data.status) {
            this.$notify({
              title: "Success",
              type: "success",
              text: res.data.message,
            });
          }
          this.editPopupOpen = false;
          this.getItems();
        })
        .catch((err) => {
          console.log(err);
          this.$notify({
            title: "Error",
            type: "error",
            text: err.response.data.error,
          });
          this.$stopLoader();
        });
    },
    confirmDelete() {
      this.$startLoader();
      this.$store
        .dispatch("DELETE_AGRIPRODUCT", {
          token: this.$getToken(),
          data: {
            product_id: this.selectedItem.product_id,
          },
        })
        .then((res) => {
          if (res.data.status) {
            this.$notify({
              title: "Success",
              type: "success",
              text: res.data.message,
            });
          }
          this.deletePopupOpen = false;
          this.getItems();
        })
        .catch((err) => {
          console.log(err);
          this.$notify({
            title: "Error",
            type: "error",
            text: err.response.data.error,
          });
          this.$stopLoader();
        });
    },
  },
  mounted() {
    this.getSectors();
    this.getItems();
  },
};
</script>
  
  <style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.page-content {
  padding: 1rem;
}
.Categories {
  .levels-table {
    .table-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
      .left {
        display: flex;
        align-items: center;
        .search {
          input {
            padding: 0.5rem;
            border: 1px solid #ccc;
            border-radius: 5px;
            outline: none;
            width: 300px;
          }
        }
      }
      .right {
        button {
          padding: 0.65rem 1rem;
          width: 150px;
          border: none;
          outline: none;
          border-radius: 5px;
          background: $blue;
          color: #fff;
          cursor: pointer;
          font-weight: 600;
        }
      }
    }
    table {
      width: 100%;
      border-collapse: collapse;
      thead {
        tr {
          th {
            padding: 10px;
            border-bottom: 1px solid #ccc;
            text-align: left;
          }
        }
      }
      tbody {
        tr {
          td {
            padding: 10px;
            border-bottom: 1px solid #ccc;
            button {
              background: transparent;
              border: none;
              outline: none;
              cursor: pointer;
              margin-right: 1rem;
              img {
                width: 30px;
              }
            }
          }
        }
      }
    }
  }
  .addLevel {
    .form-group {
      margin-bottom: 1rem;
      label {
        display: block;
        margin-bottom: 0.5rem;
      }
      input {
        padding: 0.5rem;
        border: 1px solid #ccc;
        border-radius: 5px;
        outline: none;
        width: 100%;
      }
      button {
        padding: 0.65rem 1rem;
        width: 150px;
        border: none;
        outline: none;
        border-radius: 5px;
        background: $blue;
        color: #fff;
        cursor: pointer;
        font-weight: 600;
      }
    }
  }
}
</style>