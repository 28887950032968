<template>
  <div class="container mx-auto p-4">
    <div class="form-group group-3">
      <div class="input-group">
        <label for="">Name</label>
        <input
          type="text"
          name=""
          id=""
          placeholder="cooperative name"
          v-model="newData.cooperative_name"
        />
      </div>
      <div class="input-group">
        <label for="">Contact Phone</label>
        <input
          type="phone"
          name=""
          id=""
          placeholder="cooperative phone"
          v-model="newData.cooperative_phone"
        />
      </div>
      <div class="input-group">
        <label for="">Contact Email</label>
        <input
          type="email"
          name=""
          id=""
          placeholder="cooperative email"
          v-model="newData.cooperative_email"
        />
      </div>
    </div>
    <div class="form-group group-3">
      <div class="input-group">
        <label for="">President Name</label>
        <input
          type="text"
          name=""
          id=""
          placeholder="President name"
          v-model="newData.president_name"
        />
      </div>
      <div class="input-group">
        <label for="">President Phone</label>
        <input
          type="phone"
          name=""
          id=""
          placeholder="President phone"
          v-model="newData.president_phone"
        />
      </div>
      <div class="input-group">
        <label for="">President Email</label>
        <input
          type="email"
          name=""
          id=""
          placeholder="President email"
          v-model="newData.president_email"
        />
      </div>
    </div>
    <div class="form-group group-4">
      <div class="input-group">
        <label class="block mb-2">{{ $txt("Province") }}</label>
        <select
          name="province"
          id=""
          v-model="newData.provinceId"
          @change="getDistricts(newData.provinceId)"
          class="w-full md:w-1/4 p-2"
        >
          <option value="">{{ $txt("Province") }}</option>
          <option
            v-for="province in provinces"
            :key="province.provinceId"
            :value="province.provinceId"
          >
            {{ province.provinceName }}
          </option>
        </select>
      </div>
      <div class="input-group">
        <label class="block mb-2">{{ $txt("District") }}</label>
        <select
          name="district"
          id=""
          v-model="newData.districtId"
          @change="getSectors(newData.districtId)"
          class="w-full md:w-1/4 p-2"
        >
          <option value="">{{ $txt("District") }}</option>
          <option
            v-for="district in districts"
            :key="district.districtId"
            :value="district.districtId"
          >
            {{ district.districtName }}
          </option>
        </select>
      </div>
      <div class="input-group">
        <label class="block mb-2">{{ $txt("Sector") }}</label>
        <select
          name="sector"
          id=""
          v-model="newData.sectorId"
          @change="getCells(newData.sectorId)"
          class="w-full md:w-1/4 p-2"
        >
          <option value="">{{ $txt("Sector") }}</option>
          <option
            v-for="sector in sectors"
            :key="sector.sectorId"
            :value="sector.sectorId"
          >
            {{ sector.sectorName }}
          </option>
        </select>
      </div>
      <div class="input-group">
        <label class="block mb-2">{{ $txt("Cell") }}</label>
        <select
          name="cell"
          id=""
          v-model="newData.cellId"
          class="w-full md:w-1/4 p-2"
        >
          <option value="">{{ $txt("Cell") }}</option>
          <option v-for="cell in cells" :key="cell.cellId" :value="cell.cellId">
            {{ cell.cellName }}
          </option>
        </select>
      </div>
    </div>
    <div class="form-group group-2">
      <div class="input-group">
        <label class="block mb-2">{{ $txt("Main Product") }}</label>
        <select name="" id="" v-model="newData.mainProduct">
          <option value="">{{ $txt("Select product") }}</option>
          <option
            :value="product.product_id"
            :key="product.id"
            v-for="product in products"
          >
            {{ product.product_name }}
          </option>
        </select>
      </div>
      <div class="input-group">
        <label class="block mb-2">{{ $txt("Production") }}</label>
        <input
          type="text"
          name=""
          id=""
          placeholder="Average production/Year"
          v-model="newData.production"
        />
      </div>
    </div>
  </div>
  <button @click.prevent="saveItem()">{{ $txt("Create Cooperative") }}</button>
</template>
  
  <script>
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
export default {
  data() {
    return {
      newData: {
        cooperative_name: "",
        cooperative_phone: "",
        cooperative_email: "",
        president_name: "",
        president_phone: "",
        president_email: "",
        mainProduct: "",
        production: "",
        price: "",
        sectorId: "",
        cellId: "",
        districtId: "",
        provinceId: "",
      },
      units: [],
      products: [],
      categories: [],
      provinces: [],
      districts: [],
      sectors: [],
      cells: [],
      AgriSectors: [],
      AgriCategories: [],
    };
  },
  methods: {
    closeModel() {
      document.body.style.overflowY = "auto";
      this.$emit("closePopup");
    },
    getProducts() {
      this.$startLoader();
      this.$store
        .dispatch("GET_AGRIPRODUCTS", {
          token: this.$getToken(),
          data: {},
        })
        .then((res) => {
          this.products = res.data.data;
          this.$stopLoader();
        })
        .catch((err) => {
          this.$stopLoader();
        });
    },
    getProvinces() {
      this.$startLoader();
      this.$store
        .dispatch("GET_PROVINCES", { token: this.$getToken() })
        .then((res) => {
          this.$stopLoader();
          this.provinces = res.data.data.data;
          this.newData.districtId = "";
          this.newData.sectorId = "";
          this.newData.cellId = "";
        });
    },
    getDistricts(provinceId) {
      this.$startLoader();
      this.$store
        .dispatch("GET_DISTRICTS", {
          token: this.$getToken(),
          provinceId: provinceId,
        })
        .then((res) => {
          this.$stopLoader();
          (this.districts = res.data.data.data), (this.newData.sectorId = "");
          this.newData.cellId = "";
        });
    },
    getSectors(districtId) {
      this.$startLoader();
      this.$store
        .dispatch("GET_SECTORS", {
          token: this.$getToken(),
          districtId: districtId,
        })
        .then((res) => {
          this.$stopLoader();
          this.sectors = res.data.data.data;
          this.newData.cellId = "";
        });
    },
    getCells(sectorId) {
      this.$startLoader();
      this.$store
        .dispatch("GET_CELLS", {
          token: this.$getToken(),
          sectorId: sectorId,
        })
        .then((res) => {
          this.$stopLoader();
          this.cells = res.data.data.data;
        });
    },

    saveItem() {
      this.$startLoader();
      this.$store
        .dispatch("ADD_COOPERATIVE", {
          token: this.$getToken(),
          data: this.newData,
        })
        .then((res) => {
          if (res.data.status) {
            this.$notify({
              title: "Success",
              type: "success",
              text: res.data.message,
            });
          }
          this.$stopLoader();
          this.closeModel();
        })
        .catch((err) => {
          console.log(err);
          this.$notify({
            title: "Error",
            type: "error",
            text: "Something went wrong",
          });
          this.$stopLoader();
        });
    },
  },
  mounted() {
    this.getProvinces();
    this.getProducts();
    this.newData.president_name = this.$getUser().names;
    this.newData.president_phone = this.$getUser().telephone;
    this.newData.president_email = this.$getUser().emailAddress;
  },
};
</script>
  
  <style lang="scss" scoped>
button {
  border: none;
  background-color: #1d3557;
  margin-left: 1rem;
  border-radius: 5px;
  padding: 15px 10px;
  color: #fff;
  display: block;
  width: 300px;
}
</style>
  