<script>
export default {
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    $txt(txt) {
      const lang = localStorage.getItem("lang");
      if (!lang) {
        localStorage.setItem("lang", "en");
        const lang = "en";
      }
      if (lang === "en") {
        let newText = this.$store.state.translations.find(
          (translation) => translation.en == txt
        );
        if (!newText) {
          return txt;
        } else {
          return newText.en;
        }
      }
      if (lang === "kny") {
        let newText = this.$store.state.translations.find(
          (translation) => translation.en == txt
        );
        if (!newText) {
          return txt;
        } else {
          return newText.kny;
        }
      }
    },
    $changeLang(lang) {
      localStorage.setItem("lang", lang);
      window.location.reload();
    },
    $getCountries() {
      this.$store.dispatch("GET_COUNTRIES").then((res) => {
        this.$store.state.countries = res.data.data.data;
      });
    },
    $userLogin(user, token) {
      localStorage.setItem("i_user", JSON.stringify(user));
      localStorage.setItem("i_token", token);
      if (this.$isAdmin()) {
        this.$router.push({ name: "SuperAdminSchools" });
      } else {
        this.$router.push({ name: "Profile" });
      }
    },
    $userModelLogin(user, token) {
      localStorage.setItem("i_user", JSON.stringify(user));
      localStorage.setItem("i_token", token);
      window.location.reload();
    },
    $urlContains(id) {
      return this.$route.path.includes(id);
    },
    $userLogout() {
      this.$startLoader();
      let userToken = this.$getToken();
      if (localStorage.getItem("i_user")) {
        localStorage.removeItem("i_user");
        localStorage.removeItem("i_token");
        this.$store
          .dispatch("LOGOUT_USER", { token: userToken })
          .then((res) => {
            if (res.data.status) {
              this.$notify({
                title: "Success",
                type: "success",
                text: res.data.data.message,
              });
            } else {
              this.$notify({
                title: "Error",
                type: "error",
                text: res.data.error,
              });
            }
          })
          .catch((err) => {
            // this.$notify({
            //   title: "Error",
            //   type: "error",
            //   text: err.response.data.error,
            // });
          });

        this.$stopLoader();
        this.$router.push({ name: "Login" });
        // window.location.reload();
      } else {
        this.$stopLoader();
        this.$router.push({ name: "Login" });
        return;
      }
    },
    $getUser() {
      return JSON.parse(localStorage.getItem("i_user"));
    },
    $getToken() {
      return localStorage.getItem("i_token");
    },
    $isSuperAdmin() {
      let user = this.$getUser();
      if (!user) {
        return false;
      }
      if (user.userRole.roleName == "Super Administrator") {
        return true;
      } else {
        return false;
      }
    },
    $isAdmin() {
      let user = this.$getUser();
      if (user.userRole.roleName == "Administrator") {
        return true;
      } else {
        return false;
      }
    },
    onCancel() {
      let loader = useLoading();
      loader.hide();
    },
    $startLoader() {
      console.trace();
      this.$store.state.isLoading = true;
    },
    $stopLoader() {
      this.$store.state.isLoading = false;
    },
    $urlCompatible(url) {
      if (!url) {
        return;
      }
      return url.replace(/\s/g, "-");
    },
    $getDomains() {
      this.$store
        .dispatch("GET_DOMAINS", { token: this.$getToken() })
        .then((res) => {
          return res.data.data.data;
        });
    },
    $getLevels() {
      this.$store
        .dispatch("GET_LEVELS", { token: this.$getToken() })
        .then((res) => {
          return res.data.data.data;
        });
    },
    $getDegrees() {
      this.$store
        .dispatch("GET_DEGREES", { token: this.$getToken() })
        .then((res) => {
          return res.data.data.data;
        });
    },
    $toggleSANav() {
      this.$store.state.showSANav = !this.$store.state.showSANav;
      localStorage.setItem("showSANav", this.$store.state.showSANav);
    },
    $toggleSmallNav() {
      this.$store.state.showSmallNav = !this.$store.state.showSmallNav;
      localStorage.setItem("showSmallNav", this.$store.state.showSmallNav);
    },
    $changePage(allItems, page, itemsPerPage) {
      const startIndex = (page - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      return allItems.slice(startIndex, endIndex);
    },
    $comma(amount) {
      if (!amount) {
        return;
      }
      return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    $dateToDay(date) {
      if (!date) {
        return;
      }
      // convert date like this 2021-10-12  to this format Saturday, 07,01,2023
      let newDate = new Date(date);
      let day = newDate.getDay();
      let month = newDate.getMonth();
      let year = newDate.getFullYear();
      let dayName = newDate.toLocaleString("default", { weekday: "long" });
      let monthName = newDate.toLocaleString("default", { month: "long" });
      return `${day} ${monthName}, ${year}`;
    },
    $imageurlalt() {
      event.target.src = "/assets/images/place.jpg";
    },
    $to12(time) {
      if (!time) {
        return;
      }
      let timeArray = time.split(":");
      let hours = timeArray[0];
      let minutes = timeArray[1];
      let ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? minutes : minutes;
      let strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    },
    $getProvinces() {
      this.$startLoader();
      this.$store
        .dispatch("GET_PROVINCES", { token: this.$getToken() })
        .then((res) => {
          this.$stopLoader();
          console.log(res.data.data.data);
          return res.data.data.data;
        });
    },
    $getDistricts(provinceId) {
      this.$startLoader();
      this.$store
        .dispatch("GET_DISTRICTS", {
          token: this.$getToken(),
          provinceId: provinceId,
        })
        .then((res) => {
          this.$stopLoader();
          return res.data.data.data;
        });
    },
    $getSectors(districtId) {
      this.$startLoader();
      this.$store
        .dispatch("GET_SECTORS", {
          token: this.$getToken(),
          districtId: districtId,
        })
        .then((res) => {
          this.$stopLoader();
          return res.data.data.data;
        });
    },
    $getCells(sectorId) {
      this.$startLoader();
      this.$store
        .dispatch("GET_CELLS", {
          token: this.$getToken(),
          sectorId: sectorId,
        })
        .then((res) => {
          this.$stopLoader();
          return res.data.data.data;
        });
    },
    $businessLogin(user) {
      // store user details and jwt token in session storage
      console.log(user);
      localStorage.setItem("i_user_buss", JSON.stringify(user.user));
      localStorage.setItem("i_token_buss", user.token);
    },
    $getBussUser() {
      return JSON.parse(localStorage.getItem("i_user_buss"));
    },
    $getBussToken() {
      return localStorage.getItem("i_token_buss");
    },
    $today() {
      const today = new Date();
      return today;
    },
    $ascending(list) {},
    $file(image) {
      return this.$store.state.apiUploadUrl + image;
    },
  },
  mounted() {
    let showSANav = localStorage.getItem("showSANav");
    if (showSANav != undefined && showSANav != null) {
      // convert to boolean
      if (showSANav == "true") {
        this.$store.state.showSANav = true;
      } else {
        this.$store.state.showSANav = false;
      }
    }
  },
};
</script>