<template>
  <div class="Modules">
    <super-admin-topbar :pageTitle="'System Modules'" />
    <div class="page-content">
      <div class="Modules-table">
        <div class="table-header">
          <div class="left">
            <div class="search">
              <input
                type="text"
                placeholder="Search"
                @keyup="searchData()"
                v-model="search"
              />
            </div>
          </div>
          <div class="right">
            <!-- <button @click="popupOpen = true">Add Module</button> -->
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Module</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="isModule in Modules" :key="isModule.moduleId">
              <td>
                <img
                  :src="'/assets/images/' + isModule.moduleIcon"
                  alt=""
                  v-if="isModule.moduleIcon"
                />
                <img :src="'/assets/images/dash.svg'" alt="" v-else />
              </td>
              <td>{{ isModule.moduleName }}</td>
              <td>
                <button @click="editModule(isModule.moduleId)">
                  <img src="/assets/images/edit.svg" alt="" />
                </button>
                <!-- <button @click="deleteModule(isModule.moduleId)">
                  <img src="/assets/images/delete.svg" alt="" />
                </button> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <model
        :modelTitle="'Edit Module'"
        :largeModel="false"
        :smallModel="true"
        v-show="editPopupOpen"
        @closeModel="editPopupOpen = false"
      >
        <form action="" class="addModule" @submit.prevent="confirmEdit()">
          <div class="form-group">
            <label for="">Module Name</label>
            <input
              type="text"
              placeholder="Module Name"
              v-model="selectedModule.moduleName"
            />
          </div>
          <!-- description -->
          <div class="form-group">
            <label for="">Description</label>
            <textarea
              name=""
              id=""
              cols="30"
              rows="5"
              v-model="selectedModule.description"
            ></textarea>
          </div>
          <div class="form-group">
            <button type="submit">Update</button>
          </div>
        </form>
      </model>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modules",
  data() {
    return {
      Modules: [],
      allModules: [],
      popupOpen: false,
      editPopupOpen: false,
      editAction: "add",
      search: "",
      newModule: {
        moduleName: "",
      },
      selectedModule: {},
    };
  },
  methods: {
    getModules() {
      this.$startLoader();
      this.$store
        .dispatch("GET_ALL_MODULES", { token: this.$getToken() })
        .then((res) => {
          this.Modules = res.data.data.data;
          this.allModules = res.data.data.data;
          this.$stopLoader();
        });
    },
    searchData() {
      if (this.search.length > 0) {
        this.filterData();
      } else {
        this.modules = this.allModules;
      }
    },
    filterData() {
      this.Modules = this.allModules.filter((isModule) => {
        return isModule.moduleName
          .toLowerCase()
          .includes(this.search.toLowerCase());
      });
    },
    editModule(moduleId) {
      this.editAction = "edit";
      this.editPopupOpen = true;
      this.selectedModule = this.Modules.find((isModule) => {
        return isModule.moduleId == moduleId;
      });
    },
    deleteModule(moduleId) {
      this.$store
        .dispatch("DELETE_MODULE", {
          token: this.$getToken(),
          data: {
            moduleId: moduleId,
          },
        })
        .then((res) => {
          if (res.data.status) {
            this.$notify({
              title: "Success",
              type: "success",
              text: "Module deleted successfully",
            });
            this.getModules();
          }
        })
        .catch((err) => {
          console.log(err);
          this.$notify({
            title: "Error",
            type: "error",
            text: err.response.data.error,
          });
        });
    },
    confirmEdit() {
      let newData = {};
      newData.module_id = this.selectedModule.moduleId;
      newData.module_name = this.selectedModule.moduleName;
      newData.icon = this.selectedModule.moduleIcon;
      newData.photo = this.selectedModule.modulePicture;
      newData.module_description = this.selectedModule.description;
      this.$startLoader();
      this.$store
        .dispatch("EDIT_MODULE", {
          token: this.$getToken(),
          data: newData,
        })
        .then((res) => {
          if (res.data.status) {
            this.$notify({
              title: "Success",
              type: "success",
              text: "Module updated successfully",
            });
            this.editPopupOpen = false;
            this.getModules();
            this.$stopLoader();
          }
        })
        .catch((err) => {
          console.log(err);
          this.$notify({
            title: "Error",
            type: "error",
            text: err.response.data.error,
          });
          this.$stopLoader();
        });
    },
  },
  mounted() {
    this.getModules();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.Modules {
  .Modules-table {
    padding: 2rem 1rem;
    .table-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
      .left {
        display: flex;
        align-items: center;
        .search {
          input {
            padding: 0.5rem;
            border: 1px solid #ccc;
            border-radius: 5px;
            outline: none;
            width: 300px;
          }
        }
      }
      .right {
        button {
          padding: 0.65rem 1rem;
          width: 150px;
          border: none;
          outline: none;
          border-radius: 5px;
          background: $blue;
          color: #fff;
          cursor: pointer;
          font-weight: 600;
        }
      }
    }
    table {
      width: 100%;
      border-collapse: collapse;
      thead {
        tr {
          th {
            padding: 10px;
            border-bottom: 1px solid #ccc;
            text-align: left;
          }
        }
      }
      tbody {
        tr {
          td {
            padding: 10px;
            border-bottom: 1px solid #ccc;
            img {
              width: 30px;
            }
            button {
              background: transparent;
              border: none;
              outline: none;
              cursor: pointer;
              margin-right: 1rem;
              img {
                width: 30px;
              }
            }
          }
        }
      }
    }
  }
  .addModule {
    .form-group {
      margin-bottom: 1rem;
      label {
        display: block;
        margin-bottom: 0.5rem;
      }
      input, textarea {
        padding: 0.5rem;
        border: 1px solid #ccc;
        border-radius: 5px;
        outline: none;
        width: 100%;
      }
      button {
        padding: 0.65rem 1rem;
        width: 150px;
        border: none;
        outline: none;
        border-radius: 5px;
        background: $blue;
        color: #fff;
        cursor: pointer;
        font-weight: 600;
      }
    }
  }
}
</style>