<template>
  <div class="ChangeLang">
    <div class="langauges" @click="toggle = true">
      <div class="lang" v-if="lang == 'kny'">
        <img src="/assets/images/rw.png" alt="" />
        <span>{{ $txt("Kinyarwanda") }}</span>
      </div>
      <div class="lang" v-if="lang == 'en'">
        <img src="/assets/images/uk.png" alt="" />
        <span>{{ $txt("English") }}</span>
      </div>
    </div>
    <div class="language-toggle" v-if="toggle">
      <div class="lang" @click="choose('kny')" v-if="lang == 'en'">
        <img src="/assets/images/rw.png" alt="" />
        <span>{{ $txt("Kinyarwanda") }}</span>
      </div>
      <div class="lang" @click="choose('en')" v-if="lang == 'kny'">
        <img src="/assets/images/uk.png" alt="" />
        <span>{{ $txt("English") }}</span>
      </div>
    </div>
    <!-- fa carret-down -->
    <i class="fas fa-caret-down"></i>
  </div>
</template>

<script>
export default {
  name: "ChangeLang",
  data() {
    return {
      lang: "en",
      toggle: false,
    };
  },
  props: {
    showDrop: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    choose(lang) {
      this.lang = lang;
      this.toggle = false;
      this.$changeLang(lang);
    },
  },
  mounted() {
    this.lang = localStorage.getItem("lang");
  },
  computed: {},
  // watch showDrop
  watch: {
    showDrop: function (val) {
      if (this.showDrop == false) {
        this.toggle = false;
      } else {
        this.toggle = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.ChangeLang {
  display: flex;
  align-items: center;
  position: relative;
}

.langauges {
  span {
    color: #fff;
    font-size: 12px;
  }
}

.lang {
  display: flex;
  align-items: center;
  margin: 0 10px;
  cursor: pointer;

  img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
}

.language-toggle {
  position: absolute;
  top: 30px;
  right: 0;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  padding: 10px;
  width: max-content;
  z-index: 999;

  .lang {
    margin: 0;
  }
}

i {
  color: #fff;
}

</style>

