import axios from 'axios';
import { API_URL, DEALS_API_URL } from '../../variables';
const GET_SPECIALDEAL_URL = DEALS_API_URL + '/products/getAllProducts';
const ADD_SPECIALDEAL_URL = DEALS_API_URL + '/products/createProduct';
const GET_SPECIALDEALBYID_URL = DEALS_API_URL + '/products/getProductById';
const GET_SPECIALDEALBYCATEGORY_URL = DEALS_API_URL + '/products/getProductsByCategory';
const EDIT_SPECIALDEAL_URL = DEALS_API_URL + '/products/updateProduct';
const DELETE_SPECIALDEAL_URL = DEALS_API_URL + '/products/deleteProduct';

const dealCategories = {
    state: {
    },
    getters: {},
    mutations: {},
    actions: {
        ['GET_SPECIALDEAL']: ({ commit }, payload) => {
            return axios({
                url: GET_SPECIALDEAL_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
        ['GET_SPECIALDEALBYID']: ({ commit }, payload) => {
            return axios({
                url: GET_SPECIALDEALBYID_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
        ['GET_SPECIALDEALBYCATEGORY']: ({ commit }, payload) => {
            return axios({
                url: GET_SPECIALDEALBYCATEGORY_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
        ['DELETE_SPECIALDEAL']: ({ commit }, payload) => {
            return axios({
                url: DELETE_SPECIALDEAL_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
        ['ADD_SPECIALDEAL']: ({ commit }, payload) => {
            return axios({
                url: ADD_SPECIALDEAL_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
        ['EDIT_SPECIALDEAL']: ({ commit }, payload) => {
            return axios({
                url: EDIT_SPECIALDEAL_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
    },
    modules: {},
}
export default dealCategories;