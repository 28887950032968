<template>
    <site-navbar/>
    <div class="mailContainer mx-auto mt-10 text-center">
      <h2 class="text-2xl font-semibold mb-4">Email Verification</h2>
      <p class="mb-4">
        Thank you for registering. To verify your email address, please click the button below or check your email inbox.
      </p>
      <button
        class=" mailButton text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        @click="openEmailApp"
      >
        Open Email App
      </button>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        verificationEmail: 'hortanceirakoze@gmail.com', 
      };
    },
    methods: {
      openEmailApp() {
        const mailtoLink = `mailto:${this.verificationEmail}`;
        window.location.href = mailtoLink;
      },
    },
  };
  </script>
  
  <style scoped lang="scss">
    @import "@/assets/scss/main.scss";

  .mailContainer{
  width: 850px;
  height:200px;
  background: white;
  border: 1px solid grey;
  border-radius: 5px;
  margin-top: 180px;
  }

.mailButton{
  width: 40%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  outline: none;
  background: $blue;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    background: darken($blue, 10%);
  }
  }  
  </style>
  