import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/site/Home.vue'
import Register from '../views/site/Register.vue'
import Login from '../views/site/Login.vue'
import ViewEducation from '../views/site/ViewEducation.vue'
import EmailVerification from '../views/site/EmailVerification.vue'
import VerifyEmail from '../views/site/VerifyEmail.vue'
import UserProfile from '../views/site/users/UserProfile.vue'
import ViewModule from '../views/site/ViewModule.vue'
import ViewAgriculture from '../views/site/ViewAgriculture.vue'
import EditProfile from '../views/site/users/EditProfile.vue'
import Search from '../views/site/Search.vue'
import Deals from '../views/site/Deals.vue'
import Workers from '../views/site/Workers.vue'
import Dashboard from '../views/dashboard/Dashboard.vue'
import ContactUs from '../views/site/ContactUs.vue'
import AboutUs from '../views/site/AboutUs.vue'
import ChangePassword from '../views/components/ChangePassword.vue'
// superAdmin
import SuperAdmin from '../views/superAdmin/Home.vue'
import SuperAdminDashboard from '../views/superAdmin/Dashboard.vue'
import SuperAdminLevels from '../views/superAdmin/Levels.vue'
import SuperAdminDomains from '../views/superAdmin/Domains.vue'
import SuperAdminSchools from '../views/superAdmin/Schools.vue'
import SuperAdminDegrees from '../views/superAdmin/Degrees.vue'
import SuperAdminAcademicYears from '../views/superAdmin/AcademicYears.vue'
import SuperAdminReports from '../views/superAdmin/Reports.vue'
import SuperAdminUsers from '../views/superAdmin/Users.vue'
import SuperAdminModuleAdmins from '../views/superAdmin/ModuleAdmins.vue'
import SuperAdminGiReports from '../views/superAdmin/GiReports.vue'
import SuperAdminModules from '../views/superAdmin/Modules.vue'

import ForgotPassword from '../views/site/Forgot.vue'
import ResetPassword from '../views/site/ResetPassword.vue'
import adminAgricultureCooperatives from '../views/superAdmin/AgricultureCooperatives.vue'
import AdminCooperativeMembers from '../views/superAdmin/CooperativeMembers.vue'
import AdminFarmers from '../views/superAdmin/Farmers.vue'
import ViewFarmer from '../views/superAdmin/ViewFarmer.vue'
import AgricultureMarket from '../views/superAdmin/AgricultureMarket.vue'
import AgricultureCooperativesMarket from '../views/superAdmin/AgricultureCooperativesMarket.vue'
import ViewCooperative from '../views/superAdmin/ViewCooperative.vue'

// sa agri
import adminAgricultureSectors from '../views/superAdmin/Agriculture/Sectors'
import adminAgricultureCategories from '../views/superAdmin/Agriculture/Categories.vue'
import adminAgricultureUnits from '../views/superAdmin/Agriculture/Units.vue'
import adminAgricultureProducts from '../views/superAdmin/Agriculture/Products.vue'
import adminAgricultureOrders from '../views/superAdmin/Agriculture/Orders.vue'


import ProfileNav from "../views/components/ProfileNav.vue"
//business directory
import ListBusinesses from '../views/site/business directory/ListBusinesses.vue'
import MyBusinesses from '../views/site/business directory/MyBusinesses.vue'
import AddBusiness from '../views/site/business directory/AddBusiness.vue'
import ViewSingleBusiness from '../views/site/business directory/ViewSingleBusiness.vue'
import AddNewWorker from '../views/site/workers/AddNewWorker'
import Reviews from "../views/site/workers/Reviews.vue"
// import WorkerProfile from "../views/site/workers/Profile.vue"
import Applications from "../views/site/workers/Applications.vue"
import adminAllBusiness from '../views/superAdmin/Business/AllBusiness.vue'
import AdminBusinessSectors from '../views/superAdmin/Business/Sectors.vue'

// deals
import SuperAdminDeals from '../views/superAdmin/Deals/Deals.vue'
import SuperAdminDealCategories from '../views/superAdmin/Deals/DealCategories.vue'
import Profile from "../views/site/users/Profile.vue"

// workers
import adminWorkerSkills from '../views/superAdmin/Workers/Skills.vue'
import adminWorkers from '../views/superAdmin/Workers/AllWorkers.vue'

//transport
import Transport from '../views/site/transport/Transport.vue'
import Vehicles from '../views/site/transport/Vehicles.vue'

// agriculture
import MyProduction from '../views/site/agriculture/Production.vue'
import MyCooperatives from '../views/site/agriculture/Cooperatives.vue'
import CooperativeProduction from '../views/site/agriculture/CooperativeProduction.vue'
import MyOrders from '../views/site/agriculture/Orders.vue'
const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },


  {
    path: '/profile-nav',
    name: 'ProfileNav',
    component: ProfileNav,
    children: [
      {
        path: '/my-businesses',
        name: 'MyBusinesses',
        component: MyBusinesses
      },

      {
        path: '/vehicles',
        name: 'Vehicles',
        component: Vehicles,
      },
      {
        path: '/reviews',
        name: 'Reviews',
        component: Reviews
      },
      {
        path: '/change-password',
        name: 'ChangePassword',
        component: ChangePassword
      },
      {
        path: '/profile',
        name: 'Profile',
        component: Profile
      },
      {
        path: '/dashboard/agriculture/my-production',
        name: 'MyProduction',
        component: MyProduction
      },
      {
        path: '/dashboard/agriculture/cooperative/my-cooperatives',
        name: 'MyCooperatives',
        component: MyCooperatives
      },
      {
        path: '/dashboard/agriculture/cooperative/:id/cooperative-production',
        name: 'CooperativeProduction',
        component: CooperativeProduction
      },
      {
        path: '/dashboard/agriculture/my-orders',
        name: 'MyOrders',
        component: MyOrders
      },

    ]
  },

  {
    path: '/contact-us',
    name: 'ContactUs',
    component: ContactUs
  },
  {
    path: '/about-us',
    name: 'AboutUs',
    component: AboutUs
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/user/email-verification',
    name: 'EmailVerification',
    component: EmailVerification
  },
  {
    path: '/user/verifyAccount/:token',
    name: 'VerifyEmail',
    component: VerifyEmail
  },

  {
    path: '/user/edit-profile',
    name: "EditProfile",
    component: EditProfile
  },
  {
    path: '/add-business',
    name: "AddBusiness",
    component: AddBusiness
  },
  {
    path: '/view-business',
    name: "ViewSingleBusiness",
    component: ViewSingleBusiness
  },
  {
    path: '/view-education',
    name: 'ViewEducation',
    component: ViewEducation
  },
  {
    path: '/view-module/:moduleCode/:name',
    name: 'ViewModule',
    component: ViewModule
  },
  {
    path: '/agriculture',
    name: 'ViewAgriculture',
    component: ViewAgriculture
  },
  {
    path: '/agriculture/:id/:name',
    name: 'ViewSingleAgriculture',
    component: ViewAgriculture
  },
  {
    path: '/agriculture/:category',
    name: 'ViewSingleAgricultureByCategory',
    component: ViewAgriculture
  },
  {
    path: '/agriculture/:category/:categoryname',
    name: 'ViewSingleAgricultureByCategoryId',
    component: ViewAgriculture
  },
  {
    path: '/list-businesses',
    name: 'ListBusinesses',
    component: ListBusinesses
  },
  {
    path: '/add-new-worker',
    name: 'AddNewWorker',
    component: AddNewWorker
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword
  },
  {
    path: '/user/resetPassword/:token',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/search',
    name: 'Search',
    component: Search
  },
  {
    path: '/special-deals',
    name: 'Deals',
    component: Deals
  },
  {
    path: '/special-deals/:id/:name',
    name: 'SingleDeals',
    component: Deals
  },
  {
    path: '/workers',
    name: 'Workers',
    component: Workers
  },

  {
    path: '/transport',
    name: 'Transport',
    component: Transport
  },
  {
    path: '/transport/:name',
    name: 'ViewSingleTransport',
    component: Transport
  },

  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    children: [
      /*     {
            path: '/reviews',
            name: 'Reviews',
            component: Reviews
          }, */
      // {
      //   path: '/view-worker',
      //   name: 'WorkerProfile',
      //   component: WorkerProfile
      // },
      {
        path: '/applications',
        name: 'Applications',
        component: Applications
      }
    ]
  },
  {
    path: '/admin',
    name: 'SuperAdmin',
    component: SuperAdmin,
    children: [
      {
        path: '/admin/dashboard',
        name: 'SuperAdminDashboard',
        component: SuperAdminDashboard
      },
      {
        path: '/admin/education/levels',
        name: 'SuperAdminLevels',
        component: SuperAdminLevels
      },
      {
        path: '/admin/education/domains',
        name: 'SuperAdminDomains',
        component: SuperAdminDomains
      },
      {
        path: '/admin/education/schools',
        name: 'SuperAdminSchools',
        component: SuperAdminSchools
      },
      {
        path: '/admin/education/degrees',
        name: 'SuperAdminDegrees',
        component: SuperAdminDegrees
      },
      {
        path: '/admin/education/academic-years',
        name: 'SuperAdminAcademicYears',
        component: SuperAdminAcademicYears
      },
      {
        path: '/admin/education/reports',
        name: 'SuperAdminReports',
        component: SuperAdminReports
      },
      {
        path: '/admin/users',
        name: 'SuperAdminUsers',
        component: SuperAdminUsers
      },
      {
        path: '/admin/education/users/moduleAdmins',
        name: 'SuperAdminModuleAdmins',
        component: SuperAdminModuleAdmins
      },
      {
        path: '/admin/education/users/giReports',
        name: 'SuperAdminGiReports',
        component: SuperAdminGiReports
      },
      {
        path: '/admin/modules',
        name: 'SuperAdminModules',
        component: SuperAdminModules
      },
      {
        path: '/admin/agriculture/cooperatives',
        name: 'adminAgricultureCooperatives',
        component: adminAgricultureCooperatives
      },
      {
        path: '/admin/agriculture/cooperatives/members/:id',
        name: 'AdminCooperativeMembers',
        component: AdminCooperativeMembers
      },
      {
        path: '/admin/agriculture/farmers',
        name: 'AdminFarmers',
        component: AdminFarmers
      },
      {
        path: '/admin/agriculture/farmers/:id',
        name: 'ViewFarmer',
        component: ViewFarmer
      },
      {
        path: '/admin/agriculture/market/farmers',
        name: 'AgricultureMarket',
        component: AgricultureMarket
      },
      {
        path: '/admin/agriculture/market/cooperatives',
        name: 'AgricultureCooperativesMarket',
        component: AgricultureCooperativesMarket
      },
      {
        path: '/admin/agriculture/cooperatives/:id',
        name: 'ViewCooperative',
        component: ViewCooperative
      },

      // Sa agri
      {
        path: '/admin/agriculture/sectors',
        name: 'adminAgricultureSectors',
        component: adminAgricultureSectors
      },
      {
        path: '/admin/agriculture/categories',
        name: 'adminAgricultureCategories',
        component: adminAgricultureCategories
      },
      {
        path: '/admin/agriculture/units',
        name: 'adminAgricultureUnits',
        component: adminAgricultureUnits
      },
      {
        path: '/admin/agriculture/products',
        name: 'adminAgricultureProducts',
        component: adminAgricultureProducts
      },
      {
        path: '/admin/agriculture/orders',
        name: 'adminAgricultureOrders',
        component: adminAgricultureOrders
      },


// Deals

      {
        path: '/admin/deals',
        name: 'SuperAdminDeals',
        component: SuperAdminDeals
      },
      {
        path: '/admin/deals/categories',
        name: 'SuperAdminDealCategories',
        component: SuperAdminDealCategories
      },



      // business
      {
        path: '/admin/business/all-business',
        name: 'adminAllBusiness',
        component: adminAllBusiness
      },
      {
        path: '/admin/business/sectors',
        name: 'AdminBusinessSectors',
        component: AdminBusinessSectors
      },

      // workers
      {
        path: '/admin/workers/skills',
        name: 'adminWorkerSkills',
        component: adminWorkerSkills
      },
      {
        path: '/admin/workers/all-workers',
        name: 'adminWorkers',
        component: adminWorkers
      },
    ]
  },



  {
    path: '/:catchAll(.*)',
    redirect: '/'
  }
  // {
  //   path: '/:catchAll(.*)',
  //   redirect: '/'
  // }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
