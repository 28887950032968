<template>
  <div class="Dashboard">
    <h1>Dashboard</h1>
  </div>
</template>

<script>
export default {
  name: "Dashboard",
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.Dashboard{

}
</style>