<template>
  <div class="UsersNavigation">
    <div class="tabbing">
      <button
        :class="activePage == 'all' ? 'active' : ''"
        @click="changePage('all')"
      >
        All Users
      </button>
      <button
        :class="activePage == 'mod' ? 'active' : ''"
        @click="changePage('mod')"
      >
        Module Admins
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "UsersNavigation",
  props: {
    activePage: {
      type: String,
      default: "all",
    },
  },
  methods: {
    changePage(page) {
      if (page == "all") {
        this.$router.push({ name: "SuperAdminUsers" });
      } else if (page == "mod") {
        this.$router.push({ name: "SuperAdminModuleAdmins" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.UsersNavigation {
  .tabbing {
    display: flex;
    column-gap: 20px;
    margin-top: 2rem;
    margin-left: 1rem;
    button {
      outline: none;
      margin: 0 0 15px 0;
      display: flex;
      align-items: center;
      padding: 7px 20px;
      cursor: pointer;
      border-radius: 4px;
      font-size: 0.8rem;
      column-gap: 15px;
      border: 1px solid $blue;
      justify-content: center;
      white-space: nowrap;
      background: #fff;
      color: $blue;
      position: relative;
      &::before {
        position: absolute;
        content: "";
        width: 10px;
        height: 10px;
        bottom: -5px;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
        background: inherit;
        display: none;
      }
      &.active {
        color: #fff;
        background: $blue;
        &::before {
          display: flex;
        }
      }
    }
  }
}
</style>