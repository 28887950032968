<template>
  <div class="Farmers">
    <super-admin-topbar :pageTitle="'Farmers (' + allUsers.length + ')'" />
    <div class="page-content">
      <div class="levels-table">
        <div class="table-header">
          <div class="left">
            <div class="search">
              <input
                type="text"
                placeholder="Search"
                @keyup="searchData()"
                v-model="search"
              />
            </div>
          </div>
          <div class="right"></div>
        </div>
        <div class="filter-form">
          <label for="">Filter: </label>
          <div class="form">
            <div class="form-group group-4">
              <!-- Province, Disctict, sector, cell -->
              <select
                name="province"
                id=""
                v-model="school.province"
                @change="getDistricts(school.province)"
              >
                <option value="null">{{ $txt("All Provinces") }}</option>
                <option
                  v-for="province in provinces"
                  :key="province.provinceId"
                  :value="province.provinceId"
                >
                  {{ province.provinceName }}
                </option>
              </select>
              <select
                name="district"
                id=""
                v-model="school.district"
                @change="getSectors(school.district)"
              >
                <option value="null">{{ $txt("All Districts") }}</option>
                <option
                  v-for="district in districts"
                  :key="district.districtId"
                  :value="district.districtId"
                >
                  {{ district.districtName }}
                </option>
              </select>
              <select
                name="sector"
                id=""
                v-model="school.sector"
                @change="getCells(school.sector)"
              >
                <option value="null">{{ $txt("All Sectors") }}</option>
                <option
                  v-for="sector in sectors"
                  :key="sector.sectorId"
                  :value="sector.sectorId"
                >
                  {{ sector.sectorName }}
                </option>
              </select>
              <select name="cell" id="" v-model="school.cell_id">
                <option value="null">{{ $txt("All Cells") }}</option>
                <option
                  v-for="cell in cells"
                  :key="cell.cellId"
                  :value="cell.cellId"
                >
                  {{ cell.cellName }}
                </option>
              </select>
            </div>
            <button class="submit" @click="getFilteredData()">Filter</button>
          </div>
        </div>
        <div class="item-per-page">
          <label for="">Items per page: </label>
          <select name="" id="" v-model="itemsPerPage" @change="changePage(1)">
            <option>5</option>
            <option>10</option>
            <option>25</option>
            <option>50</option>
            <option>100</option>
            <option>500</option>
          </select>
        </div>
        <table>
          <thead>
            <tr>
              <th>N<sup>o</sup></th>
              <th>Names</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Farms</th>
              <th>Main product</th>
              <th>Amount</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(user, index) in users" :key="user.id">
              <td>{{ index + 1 }}</td>
              <td>{{ user.name }}</td>
              <td>{{ user.phone }}</td>
              <td>{{ user.email }}</td>
              <td>{{ user.farms }}</td>
              <td>{{ user.mainProduct }}</td>
              <td>{{ user.amount }}</td>
              <td style="width: 200px">
                <router-link
                  :to="{ name: 'ViewFarmer', params: { id: user.id } }"
                  class="View"
                >
                  <label for="">View</label>
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
        <pagination
          :totalItems="allUsers.length"
          :itemsPerPage="itemsPerPage"
          @pageChanged="
            (page) => {
              changePage(page);
            }
          "
        />
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "Farmers",
  data() {
    return {
      domains: [],
      allDomains: [],
      search: "",
      popupOpen: false,
      editPopupOpen: false,
      newDomain: {
        domainName: "",
      },
      selectedDomain: {},
      deletePopupOpen: false,
      school: {
        province: "null",
        district: "null",
        sector: "null",
        cell_id: "null",
      },
      provinces: [],
      districts: [],
      sectors: [],
      cells: [],
      itemsPerPage: 5,
      allUsers: [
        {
          id: 1,
          name: "Umulisa Eric1",
          farms: 3,
          phone: "0789178645",
          email: "eric@kinazi.co",
          mainProduct: "Cassava",
          amount: "30000 kg",
        },
        {
          id: 2,
          name: "Umulisa Eric2",
          farms: 2,
          phone: "0789178645",
          email: "eric@kinazi.co",
          mainProduct: "Beans",
          amount: "50000 kg",
        },
        {
          id: 3,
          name: "Umulisa Eric3",
          farms: 1,
          phone: "0789178645",
          email: "eric@kinazi.co",
          mainProduct: "Maize",
          amount: "20000 kg",
        },
        {
          id: 4,
          name: "Umulisa Eric4",
          farms: 4,
          phone: "0789178645",
          email: "eric@kinazi.co",
          mainProduct: "Pigs",
          amount: "1000",
        },
        {
          id: 5,
          name: "Umulisa Eric5",
          farms: 1,
          phone: "0789178645",
          email: "eric@kinazi.co",
          mainProduct: "Poultry-eggs",
          amount: "100000",
        },
        {
          id: 6,
          name: "Umulisa Eric6",
          farms: 5,
          phone: "0789178645",
          email: "eric@kinazi.co",
          mainProduct: "Poultry-meat",
          amount: "10000 kg",
        },
        {
          id: 7,
          name: "Umulisa Eric7",
          farms: 3,
          phone: "0789178645",
          email: "eric@kinazi.co",
          mainProduct: "Poultry-eggs",
          amount: "100000",
        },
        {
          id: 8,
          name: "Umulisa Eric8",
          farms: 3,
          phone: "0789178645",
          email: "eric@kinazi.co",
          mainProduct: "Cattle-Milk",
          amount: "100000 litters",
        },
        {
          id: 9,
          name: "Umulisa Eric9",
          farms: 3,
          phone: "0789178645",
          email: "eric@kinazi.co",
          mainProduct: "Cattle-Meat",
          amount: "10000 kg",
        },
        {
          id: 10,
          name: "Umulisa Eric",
          farms: 3,
          phone: "0789178645",
          email: "eric@kinazi.co",
          mainProduct: "Cattle-Milk",
          amount: "100000 litters",
        },
        {
          id: 11,
          name: "Umulisa Eric",
          farms: 3,
          phone: "0789178645",
          email: "eric@kinazi.co",
          mainProduct: "Cattle-Meat",
          amount: "10000 kg",
        },
        {
          id: 12,
          name: "Umulisa Eric",
          farms: 3,
          phone: "0789178645",
          email: "eric@kinazi.co",
          mainProduct: "Cattle-Milk",
          amount: "100000 litters",
        },
        {
          id: 13,
          name: "Umulisa Eric",
          farms: 3,
          phone: "0789178645",
          email: "eric@kinazi.co",
          mainProduct: "Cassava",
          amount: "30000 kg",
        },
        {
          id: 14,
          name: "Umulisa Eric",
          farms: 3,
          phone: "0789178645",
          email: "eric@kinazi.co",
          mainProduct: "Beans",
          amount: "50000 kg",
        },
        {
          id: 15,
          name: "Umulisa Eric",
          farms: 3,
          phone: "0789178645",
          email: "eric@kinazi.co",
          mainProduct: "Maize",
          amount: "20000 kg",
        },
      ],
      users: [],
    };
  },
  methods: {
    searchData() {
      this.users = this.allUsers.filter((user) => {
        return (
          user.name.toLowerCase().includes(this.search.toLowerCase()) ||
          user.mainProduct.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
    changePage(page) {
      this.currentPage = page;
      this.users = this.$changePage(this.allUsers, page, this.itemsPerPage);
    },
    getProvinces() {
      this.$startLoader();
      this.$store
        .dispatch("GET_PROVINCES", { token: this.$getToken() })
        .then((res) => {
          this.provinces = res.data.data.data;
          this.districts = [];
          this.sectors = [];
          this.cells = [];
          this.$stopLoader();
        });
    },
    getDistricts(provinceId) {
      if (provinceId == "" || provinceId == undefined || provinceId == null) {
        this.districts = [];
        this.sectors = [];
        this.cells = [];
        return;
      }
      this.$startLoader();
      this.$store
        .dispatch("GET_DISTRICTS", {
          token: this.$getToken(),
          provinceId: provinceId,
        })
        .then((res) => {
          this.districts = res.data.data.data;
          this.sectors = [];
          this.cells = [];
          this.$stopLoader();
        });
    },
    getSectors(districtId) {
      if (districtId == "" || districtId == undefined || districtId == null) {
        this.sectors = [];
        this.cells = [];
        return;
      }
      this.$startLoader();
      this.$store
        .dispatch("GET_SECTORS", {
          token: this.$getToken(),
          districtId: districtId,
        })
        .then((res) => {
          this.sectors = res.data.data.data;
          this.cells = [];
          this.$stopLoader();
        });
    },
    getCells(sectorId) {
      if (sectorId == "" || sectorId == undefined || sectorId == null) {
        this.cells = [];
        return;
      }
      this.$startLoader();
      this.$store
        .dispatch("GET_CELLS", { token: this.$getToken(), sectorId: sectorId })
        .then((res) => {
          this.cells = res.data.data.data;
          this.$stopLoader();
        });
    },
    getFilteredData() {
      if (
        this.school.province == "null" &&
        this.school.district == "null" &&
        this.school.sector == "null" &&
        this.school.cell_id == "null"
      ) {
        this.getSchools();
      } else {
        this.$startLoader();
        this.$store
          .dispatch("GET_FILTERED_SCHOOLS_BY_ADDRESS", {
            token: this.$getToken(),
            data: {
              provinceId: this.school.province,
              districtId: this.school.district,
              sectorId: this.school.sector,
              cellId: this.school.cell_id,
            },
          })
          .then((res) => {
            this.schools = res.data.data.data;
            this.allSchools = res.data.data.data;
            this.$stopLoader();
          })
          .catch((err) => {
            console.log(err);
            this.$notify({
              title: "Error",
              type: "error",
              text: err.response.data.error,
            });
            this.$stopLoader();
          });
      }
    },
  },
  mounted() {
    this.getProvinces();
    this.changePage(1);
  },
};
</script>
  
  <style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.Farmers {
  .levels-table {
    padding: 2rem 1rem;
    .table-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
      .left {
        display: flex;
        align-items: center;
        .search {
          input {
            padding: 0.5rem;
            border: 1px solid #ccc;
            border-radius: 5px;
            outline: none;
            width: 300px;
          }
        }
      }
      .right {
        button {
          padding: 0.65rem 1rem;
          width: 150px;
          border: none;
          outline: none;
          border-radius: 5px;
          background: $blue;
          color: #fff;
          cursor: pointer;
          font-weight: 600;
        }
      }
    }
    table {
      width: 100%;
      border-collapse: collapse;
      thead {
        tr {
          th {
            padding: 10px;
            border-bottom: 1px solid #ccc;
            text-align: left;
          }
        }
      }
    }
  }
  .addLevel {
    .form-group {
      margin-bottom: 1rem;
      label {
        display: block;
        margin-bottom: 0.5rem;
      }
      input {
        padding: 0.5rem;
        border: 1px solid #ccc;
        border-radius: 5px;
        outline: none;
        width: 100%;
      }
      button {
        padding: 0.65rem 1rem;
        width: 150px;
        border: none;
        outline: none;
        border-radius: 5px;
        background: $blue;
        color: #fff;
        cursor: pointer;
        font-weight: 600;
      }
    }
  }
}
</style>