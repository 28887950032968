<template>
  <div class="container mx-auto p-4">
    <div class="form-group group-3">
      <div class="input-group">
        <label class="block mb-2">Company Name</label>
        <input
          class="w-full p-2 border rounded"
          type="text"
          v-model="newData.business_name"
        />
      </div>
      <div class="input-group">
        <label class="block mb-2">Ownership</label>
        <select
          class="w-full p-2 border rounded"
          v-model="newData.business_ownership_id"
        >
          <option value="">Select</option>
          <option
            v-for="ownership in ownerships"
            :key="ownership.ownership_id"
            :value="ownership.ownership_id"
          >
            {{ ownership.ownership_name }}
          </option>
        </select>
      </div>
      <div class="input-group">
        <label class="block mb-2">Business Sector</label>
        <select
          class="w-full p-2 border rounded"
          v-model="newData.business_sector_id"
        >
          <option value="">Select sector</option>
          <option
            :value="businessSector.sector_id"
            v-for="businessSector in businessSectors"
            :key="businessSector.sector_id"
          >
            {{ businessSector.sector_name }}
          </option>
        </select>
      </div>
    </div>
    <div class="form-group group-3">
      <div class="input-group">
        <label class="block mb-2">Email</label>
        <input
          class="w-full p-2 border rounded"
          type="email"
          v-model="newData.business_email"
        />
      </div>
      <div class="input-group">
        <label class="block mb-2">Telephone</label>
        <input
          class="w-full p-2 border rounded"
          type="phone"
          v-model="newData.business_phone"
        />
      </div>
      <div class="input-group">
        <label class="block mb-2">Website</label>
        <input
          class="w-full p-2 border rounded"
          type="url"
          v-model="newData.business_website"
        />
      </div>
    </div>

    <div class="form-group group-3">
      <div class="input-group">
        <label class="block mb-2">Representative</label>
        <input
          class="w-full p-2 border rounded"
          type="text"
          v-model="newData.business_representative"
        />
      </div>
      <div class="input-group">
        <label class="block mb-2">Representative Phone</label>
        <input
          class="w-full p-2 border rounded"
          type="phone"
          v-model="newData.business_representative_phone"
        />
      </div>
      <div class="input-group">
        <label class="block mb-2">Representative Email</label>
        <input
          class="w-full p-2 border rounded"
          type="email"
          v-model="newData.business_representative_email"
        />
      </div>
    </div>
    <div class="form-group group-4">
      <div class="input-group">
        <label class="block mb-2">{{ $txt("Province") }}</label>
        <select
          name="province"
          id=""
          v-model="newData.business_province"
          @change="getDistricts(newData.business_province)"
          class="w-full md:w-1/4 p-2"
        >
          <option value="">{{ $txt("Province") }}</option>
          <option
            v-for="province in provinces"
            :key="province.provinceId"
            :value="province.provinceId"
          >
            {{ province.provinceName }}
          </option>
        </select>
      </div>
      <div class="input-group">
        <label class="block mb-2">{{ $txt("District") }}</label>
        <select
          name="district"
          id=""
          v-model="newData.business_district"
          @change="getSectors(newData.business_district)"
          class="w-full md:w-1/4 p-2"
        >
          <option value="">{{ $txt("District") }}</option>
          <option
            v-for="district in districts"
            :key="district.districtId"
            :value="district.districtId"
          >
            {{ district.districtName }}
          </option>
        </select>
      </div>
      <div class="input-group">
        <label class="block mb-2">{{ $txt("Sector") }}</label>
        <select
          name="sector"
          id=""
          v-model="newData.business_sector"
          @change="getCells(newData.business_sector)"
          class="w-full md:w-1/4 p-2"
        >
          <option value="">{{ $txt("Sector") }}</option>
          <option
            v-for="sector in sectors"
            :key="sector.sectorId"
            :value="sector.sectorId"
          >
            {{ sector.sectorName }}
          </option>
        </select>
      </div>
      <div class="input-group">
        <label class="block mb-2">{{ $txt("Cell") }}</label>
        <select
          name="cell"
          id=""
          v-model="newData.business_cell"
          class="w-full md:w-1/4 p-2"
        >
          <option value="">{{ $txt("Cell") }}</option>
          <option v-for="cell in cells" :key="cell.cellId" :value="cell.cellId">
            {{ cell.cellName }}
          </option>
        </select>
      </div>
    </div>
    <div class="form-group group-3">
      <div class="input-group">
        <label>RDB Certificate</label>
        <o-upload
          :multiple="false"
          :uploadUrl="'files/upload'"
          @imagesUploaded="
            (data) => {
              newData.business_rdb_certificate = data;
            }
          "
          @imagesUploadedFailed="
            (data) => {
              console.log('failed');
            }
          "
          :value="newData.business_rdb_certificate"
          @startLoader="() => $startLoader()"
          @stopLoader="() => $stopLoader()"
        />
      </div>
      <div class="input-group">
        <label>Logo</label>
        <o-upload
          :multiple="false"
          :uploadUrl="'files/upload'"
          @imagesUploaded="
            (data) => {
              newData.business_logo = data;
            }
          "
          @imagesUploadedFailed="
            (data) => {
              console.log('failed');
            }
          "
          :value="newData.business_logo"
          @startLoader="() => $startLoader()"
          @stopLoader="() => $stopLoader()"
        />
      </div>
      <div class="input-group">
        <label>Image</label>
        <o-upload
          :multiple="false"
          :uploadUrl="'files/upload'"
          @imagesUploaded="
            (data) => {
              newData.business_image = data;
            }
          "
          @imagesUploadedFailed="
            (data) => {
              console.log('failed');
            }
          "
          :value="newData.business_image"
          @startLoader="() => $startLoader()"
          @stopLoader="() => $stopLoader()"
        />
      </div>
    </div>
    <div>
      <div class="form-group">
        <div class="input-group">
          <label>Description</label>
          <quill-editor
            theme="snow"
            v-model:content="newData.business_description"
            contentType="html"
            style="height: 200px"
          ></quill-editor>
        </div>
      </div>
    </div>
  </div>
  <button @click.prevent="saveItem()">Save Business</button>
</template>
  
  <script>
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
export default {
  data() {
    return {
      newData: {
        business_name: "",
        business_province: "",
        business_district: "",
        business_sector: "",
        business_cell: "",
        business_email: "",
        business_phone: "",
        business_website: "",
        business_representative: "",
        business_representative_phone: "",
        business_representative_email: "",
        business_rdb_certificate: "",
        business_logo: "",
        business_image: "",
        business_description: "",
        business_ownership_id: "",
        business_sector_id: "",
      },
      ownerships: [],
      businessSectors: [],
      provinces: [],
      districts: [],
      sectors: [],
      cells: [],
      errors: [],
    };
  },
  methods: {
    closeModel() {
      document.body.style.overflowY = "auto";
      this.$emit("closePopup");
    },
    getOwnerships() {
      this.$startLoader();
      this.$store
        .dispatch("GET_BUSS_OWNERSHIPS", { token: this.$getBussToken() })
        .then((response) => {
          this.ownerships = response.data.data;
        });
    },
    getBusinessSectors() {
      this.$startLoader();
      this.$store
        .dispatch("GET_BUSS_SECTORS", { token: this.$getBussToken() })
        .then((response) => {
          this.businessSectors = response.data.data;
        });
    },
    getProvinces() {
      this.$startLoader();
      this.$store
        .dispatch("GET_PROVINCES", { token: this.$getToken() })
        .then((res) => {
          this.$stopLoader();
          this.provinces = res.data.data.data;
          this.newData.business_district = "";
          this.newData.business_sector = "";
          this.newData.business_cell = "";
        });
    },
    getDistricts(provinceId) {
      this.$startLoader();
      this.$store
        .dispatch("GET_DISTRICTS", {
          token: this.$getToken(),
          provinceId: provinceId,
        })
        .then((res) => {
          this.$stopLoader();
          (this.districts = res.data.data.data),
            (this.newData.business_sector = "");
          this.newData.business_cell = "";
        });
    },
    getSectors(districtId) {
      this.$startLoader();
      this.$store
        .dispatch("GET_SECTORS", {
          token: this.$getToken(),
          districtId: districtId,
        })
        .then((res) => {
          this.$stopLoader();
          this.sectors = res.data.data.data;
          this.newData.business_cell = "";
        });
    },
    getCells(sectorId) {
      this.$startLoader();
      this.$store
        .dispatch("GET_CELLS", {
          token: this.$getToken(),
          sectorId: sectorId,
        })
        .then((res) => {
          this.$stopLoader();
          this.cells = res.data.data.data;
        });
    },
    saveItem() {
      this.errors = [];
      // check if all fields are filled

      //   this.newData.business_district == "" ||
      //   this.newData.business_sector == "" ||
      //   this.newData.business_cell == "" ||
      //   this.newData.business_email == "" ||
      //   this.newData.business_phone == "" ||
      //   this.newData.business_website == "" ||
      //   this.newData.business_representative == "" ||
      //   this.newData.business_representative_phone == "" ||
      //   this.newData.business_representative_email == "" ||
      //   this.newData.business_description == "" ||
      //   this.newData.business_ownership_id == "" ||
      //   this.newData.business_sector_id == ""
      if (this.newData.business_name == "") {
        this.errors.push("Business name is required");
      }
      if (this.newData.business_province == "") {
        this.errors.push("Business name is required");
      }
      if (this.newData.business_district == "") {
        this.errors.push("Business district is required");
      }
      if (this.newData.business_sector == "") {
        this.errors.push("Business sector is required");
      }
      if (this.newData.business_cell == "") {
        this.errors.push("Business cell is required");
      }
      if (this.newData.business_email == "") {
        this.errors.push("Business email is required");
      }
      if (this.newData.business_phone == "") {
        this.errors.push("Business phone is required");
      }
      if (this.newData.business_website == "") {
        this.errors.push("Business website is required");
      }
      if (this.newData.business_representative == "") {
        this.errors.push("Business representative is required");
      }
      if (this.newData.business_representative_phone == "") {
        this.errors.push("Business representative_phone is required");
      }
      if (this.newData.business_representative_email == "") {
        this.errors.push("Business representative_email is required");
      }
      if (this.newData.business_description == "") {
        this.errors.push("Business description is required");
      }
      if (this.newData.business_ownership_id == "") {
        this.errors.push("Business ownership is required");
      }
      if (this.newData.business_sector_id == "") {
        this.errors.push("Business sector is required");
      }
      if (this.errors.length > 0) {
        this.errors.forEach((error) => {
          this.$notify({
            title: "Error",
            text: error,
            type: "error",
          });
        });
        return;
      } else {
        this.$startLoader();
        this.$store
          .dispatch("ADD_BUSINESS", {
            token: this.$getToken(),
            data: this.newData,
          })
          .then((res) => {
            this.$stopLoader();
            this.$notify({
              title: "Success",
              text: "Business added successfully, we will review and verify your business soon.",
              type: "success",
            });
            this.closeModel();
          })
          .catch((err) => {
            this.$stopLoader();
            this.$notify({
              title: "Error",
              text: err.response.data.message,
              type: "error",
            });
          });
      }
    },
  },
  mounted() {
    this.getOwnerships();
    this.getBusinessSectors();
    this.getProvinces();
  },
};
</script>
  
  <style lang="scss" scoped>
button {
  border: none;
  background-color: #1d3557;
  margin-left: 1rem;
  border-radius: 5px;
  padding: 15px 10px;
  color: #fff;
  display: block;
  width: 300px;
}
</style>
  