<template>
  <div class="AcademicYears">
    <super-admin-topbar :pageTitle="'Academic Years'" />
    <div class="page-content">
      <div class="levels-table">
        <div class="table-header">
          <div class="left">
            <div class="search">
              <input
                type="text"
                placeholder="Search"
                @keyup="searchData()"
                v-model="search"
              />
            </div>
          </div>
          <div class="right">
            <button @click="popupOpen = true">Add Year</button>
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Start</th>
              <th>End</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="academicYear in academicYears"
              :key="academicYear.academicYearId"
            >
              <td>{{ academicYear.acName }}</td>
              <td>{{ academicYear.acStartDate }}</td>
              <td>{{ academicYear.acEndDate }}</td>
              <td>
                <button @click="editacademicYear(academicYear)">
                  <img src="/assets/images/edit.svg" alt="" />
                </button>
                <button @click="editacademicYear(academicYear)">
                  <img src="/assets/images/delete.svg" alt="" />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <model
        :modelTitle="'Add academic year'"
        :largeModel="false"
        :smallModel="true"
        v-show="popupOpen"
        @closeModel="popupOpen = false"
      >
        <form action="" class="addLevel" @submit.prevent="confirmAdd()">
          <div class="form-group">
            <label for="">Start Date</label>
            <input
              type="date"
              placeholder="Start date"
              v-model="newacademicYear.start_date"
            />
          </div>
          <div class="form-group">
            <label for="">End Date</label>
            <input
              type="date"
              placeholder="End date"
              v-model="newacademicYear.end_date"
            />
          </div>
          <div class="form-group">
            <label for="">Name</label>
            <input
              type="text"
              placeholder="academic Year Name"
              v-model="newacademicYear.academic_name"
            />
          </div>

          <div class="form-group">
            <button type="submit">Save</button>
          </div>
        </form>
      </model>
      <model
        :modelTitle="'Edit academic year'"
        :largeModel="false"
        :smallModel="true"
        v-show="editPopupOpen"
        @closeModel="editPopupOpen = false"
      >
        <form action="" class="addLevel" @submit.prevent="confirmEdit()">
          <div class="form-group">
            <label for="">Start Date</label>
            <input
              type="text"
              placeholder="start date"
              v-model="selectedAcademicYear.start_date"
            />
          </div>
          <div class="form-group">
            <label for="">End Date</label>
            <input
              type="date"
              placeholder="End date"
              v-model="selectedAcademicYear.end_date"
            />
          </div>
          <div class="form-group">
            <label for="">Name</label>
            <input
              type="text"
              placeholder="academicYear Name"
              v-model="selectedAcademicYear.academic_name"
            />
          </div>
          <div class="form-group">
            <button type="submit">Save</button>
          </div>
        </form>
      </model>
      <confirm-model
        :modelTitle="'Delete Year'"
        :smallModel="true"
        v-if="deletePopupOpen"
        @closeModel="deletePopupOpen = false"
        @confirmModel="confirmDelete()"
        :displayText="'Are you sure you want to delete this academicYear?'"
      ></confirm-model>
    </div>
  </div>
</template>

<script>
export default {
  name: "AcademicYears",
  data() {
    return {
      academicYears: [],
      allacademicYears: [],
      search: "",
      popupOpen: false,
      editPopupOpen: false,
      newacademicYear: {
        start_date: "",
        end_date: "",
        academic_name: "",
      },
      selectedAcademicYear: {},
      deletePopupOpen: false,
    };
  },
  methods: {
    getAcademicYears() {
      this.$store
        .dispatch("GET_ACADEMIC_YEARS", { token: this.$getToken() })
        .then((res) => {
          this.academicYears = res.data.data.data;
          this.allacademicYears = res.data.data.data;
        });
    },
    searchData() {
      this.academicYears = this.allacademicYears.filter((academicYear) => {
        return academicYear.acName
          .toLowerCase()
          .includes(this.search.toLowerCase());
      });
    },
    editacademicYear(academicYear) {
      this.selectedAcademicYear = academicYear;
      this.editPopupOpen = true;
    },
    confirmEdit() {
      this.$startLoader();
      this.$store
        .dispatch("EDIT_ACADEMIC_YEAR", {
          token: this.$getToken(),
          data: this.selectedAcademicYear,
        })
        .then((res) => {
          if (res.data.status) {
            this.$notify({
              title: "Success",
              type: "success",
              text: "academicYear edited successfully",
            });
          }
          this.editPopupOpen = false;
          this.getAcademicYears();
        })
        .catch((err) => {
          console.log(err);
          this.$notify({
            title: "Error",
            type: "error",
            text: err.response.data.error,
          });
        });
    },
    confirmAdd() {
      this.$startLoader();
      this.$store
        .dispatch("ADD_ACADEMIC_YEAR", {
          token: this.$getToken(),
          data: this.newacademicYear,
        })
        .then((res) => {
          this.$stopLoader();
          if (res.data.status) {
            this.$notify({
              title: "Success",
              type: "success",
              text: "academic year added successfully",
            });
          }
          this.popupOpen = false;
          this.getAcademicYears();
        })
        .catch((err) => {
          this.$stopLoader();
          console.log(err);
          this.$notify({
            title: "Error",
            type: "error",
            text: err.response.data.error,
          });
        });
    },
  },
  mounted() {
    this.getAcademicYears();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.AcademicYears {
  .levels-table {
    padding: 2rem 1rem;
    .table-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
      .left {
        display: flex;
        align-items: center;
        .search {
          input {
            padding: 0.5rem;
            border: 1px solid #ccc;
            border-radius: 5px;
            outline: none;
            width: 300px;
          }
        }
      }
      .right {
        button {
          padding: 0.65rem 1rem;
          width: 150px;
          border: none;
          outline: none;
          border-radius: 5px;
          background: $blue;
          color: #fff;
          cursor: pointer;
          font-weight: 600;
        }
      }
    }
    table {
      width: 100%;
      border-collapse: collapse;
      thead {
        tr {
          th {
            padding: 10px;
            border-bottom: 1px solid #ccc;
            text-align: left;
          }
        }
      }
      tbody {
        tr {
          td {
            padding: 10px;
            border-bottom: 1px solid #ccc;
            button {
              background: transparent;
              border: none;
              outline: none;
              cursor: pointer;
              margin-right: 1rem;
              img {
                width: 30px;
              }
            }
          }
        }
      }
    }
  }
  .addLevel {
    .form-group {
      margin-bottom: 1rem;
      label {
        display: block;
        margin-bottom: 0.5rem;
      }
      input {
        padding: 0.5rem;
        border: 1px solid #ccc;
        border-radius: 5px;
        outline: none;
        width: 100%;
      }
      button {
        padding: 0.65rem 1rem;
        width: 150px;
        border: none;
        outline: none;
        border-radius: 5px;
        background: $blue;
        color: #fff;
        cursor: pointer;
        font-weight: 600;
      }
    }
  }
}
</style>