import { API_URL } from '../variables';
import axios from 'axios'
const GET_LEVELS_URL = API_URL + '/schools/levels';
const ADD_LEVEL_URL = API_URL + '/levels/add-level';
const EDIT_LEVEL_URL = API_URL + '/levels/edit-level';
const DELETE_LEVEL_URL = API_URL + '/levels/delete-level';


const levels = {
    state: {
    },
    getters: {},
    mutations: {},
    actions: {
        ['GET_LEVELS']: ({ commit }, payload) => {
            return axios({
                url: GET_LEVELS_URL,
                method: "GET",
                data: payload,
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
        ['ADD_LEVEL']: ({ commit }, payload) => {
            return axios({
                url: ADD_LEVEL_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
        ['EDIT_LEVEL']: ({ commit }, payload) => {
            return axios({
                url: EDIT_LEVEL_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
        ['DELETE_LEVEL']: ({ commit }, payload) => {
            return axios({
                url: DELETE_LEVEL_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        }

    },
    modules: {},
}
export default levels;