// import { createStore } from 'vuex'
import { BUSS_API_URL } from '../variables'
const UPLOADIMAGES = '/files/upload'
const SEARCH_URL = '/files/search'
import axios from 'axios'

const files = {
    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {
        // UPLOADIMAGES
        ['UPLOADIMAGES']: ({ state, getters, rootState }, payload) => {
            return axios({
                url: BUSS_API_URL + UPLOADIMAGES,
                method: "POST",
                data: payload,
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                },
            });
        },
        // SEARCH
        ['SEARCH']: ({ state, getters, rootState }, payload) => {
            return axios({
                url: BUSS_API_URL + SEARCH_URL,
                method: "POST",
                data: payload,
            });
        }
    },
}
export default files;
