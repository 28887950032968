<template>
  <div class="Business">
    <site-navbar />
    <div class="search-section">
      <form action="">
        <label>
          {{ $txt("Business Listing") }}
        </label>
        <p for="">
          {{
            $txt("Browse business partners, and boost your brand awareness.")
          }}
        </p>
      </form>
    </div>
    <div class="whole-content" v-if="allbusinesses.length > 0">
      <div class="Business-section">
        <model
          v-if="showModelFilters"
          @closeModel="showModelFilters = false"
          :modelTitle="'Filters'"
        >
          <div class="model-left left">
            <div class="filters">
              <div class="title">
                <h4>Filter By</h4>
              </div>
              <div class="filter-item">
                <div class="header" @click="showType = !showType">
                  <label for="">Category</label>
                  <i class="fa fa-angle-down"></i>
                </div>
                <div class="content" v-if="showType">
                  <span class="help" @click="viewCarsBodies = true">{{
                    $txt(
                      "Click here if you want to get help choosing car bodies"
                    )
                  }}</span>
                  <ul>
                    <li><input type="checkbox" name="" id="" />Micro</li>
                    <li><input type="checkbox" name="" id="" />Sedan</li>
                    <li><input type="checkbox" name="" id="" />Hatchback</li>
                    <li><input type="checkbox" name="" id="" />Universal</li>
                    <li><input type="checkbox" name="" id="" />Coupe</li>
                    <li><input type="checkbox" name="" id="" />Cabriolet</li>
                    <li><input type="checkbox" name="" id="" />Roadster</li>
                    <li><input type="checkbox" name="" id="" />Targa</li>
                    <li><input type="checkbox" name="" id="" />Limousine</li>
                    <li><input type="checkbox" name="" id="" />Muscle car</li>
                    <li><input type="checkbox" name="" id="" />Sport car</li>
                    <li><input type="checkbox" name="" id="" />Super car</li>
                    <li><input type="checkbox" name="" id="" />SUV</li>
                    <li><input type="checkbox" name="" id="" />Crossover</li>
                    <li><input type="checkbox" name="" id="" />Pickup</li>
                    <li><input type="checkbox" name="" id="" />Van</li>
                    <li><input type="checkbox" name="" id="" />Minivan</li>
                    <li><input type="checkbox" name="" id="" />Minibus</li>
                    <li><input type="checkbox" name="" id="" />Campervan</li>
                    <li><input type="checkbox" name="" id="" />Bus</li>
                    <li><input type="checkbox" name="" id="" />Tow truck</li>
                    <li><input type="checkbox" name="" id="" />Mini truck</li>
                    <li><input type="checkbox" name="" id="" />Big truck</li>
                    <li><input type="checkbox" name="" id="" />Dump truck</li>
                  </ul>
                </div>
              </div>
              <div class="filter-item">
                <div class="header" @click="showLocation = !showLocation">
                  <label for="">Location</label>
                  <i class="fa fa-angle-down"></i>
                </div>
                <div class="content" v-if="showLocation">
                  <form action="">
                    <div class="form-group group-2">
                      <select
                        name="province"
                        id=""
                        v-model="filterData.province"
                        @change="getDistricts(filterData.province)"
                      >
                        <option value="null">
                          {{ $txt("All Provinces") }}
                        </option>
                        <option
                          v-for="province in provinces"
                          :key="province.provinceId"
                          :value="province.provinceId"
                        >
                          {{ province.provinceName }}
                        </option>
                      </select>
                      <select
                        name="district"
                        id=""
                        v-model="filterData.district"
                      >
                        <option value="null">
                          {{ $txt("All Districts") }}
                        </option>
                        <option
                          v-for="district in districts"
                          :key="district.districtId"
                          :value="district.districtId"
                        >
                          {{ district.districtName }}
                        </option>
                      </select>
                    </div>
                  </form>
                </div>
              </div>
              <div class="filter-item">
                <div class="header" @click="showDestination = !showDestination">
                  <label for="">Destination</label>
                  <i class="fa fa-angle-down"></i>
                </div>
                <div class="content" v-if="showDestination">
                  <form action="">
                    <div class="form-group group-2">
                      <select
                        name="province"
                        id=""
                        v-model="filterData.destination_province"
                        @change="
                          getDestinationDistricts(
                            filterData.destination_province
                          )
                        "
                      >
                        <option value="null">
                          {{ $txt("All Provinces") }}
                        </option>
                        <option
                          v-for="province in destination_provinces"
                          :key="province.provinceId"
                          :value="province.provinceId"
                        >
                          {{ province.provinceName }}
                        </option>
                      </select>
                      <select
                        name="district"
                        id=""
                        v-model="filterData.destination_district"
                      >
                        <option value="null">
                          {{ $txt("All Districts") }}
                        </option>
                        <option
                          v-for="district in destination_districts"
                          :key="district.districtId"
                          :value="district.districtId"
                        >
                          {{ district.districtName }}
                        </option>
                      </select>
                    </div>
                  </form>
                </div>
              </div>
              <div class="filter-item">
                <div class="header" @click="showCategory = !showCategory">
                  <label for="">Date - Time</label>
                  <i class="fa fa-angle-down"></i>
                </div>
                <div class="content" v-if="showCategory">
                  <VueDatePicker
                    v-model="filterData.date"
                    range
                    multi-calendars
                  ></VueDatePicker>
                </div>
              </div>
              <div class="apply-filter">
                <button type="button" @click="startSearch()" class="clear">
                  {{ $txt("Clear filter") }}
                </button>
                <button type="button" @click="startSearch()">
                  {{ $txt("Apply filter") }}
                </button>
              </div>
            </div>
          </div>
        </model>
        <div class="left">
          <div class="filters">
            <div class="title">
              <h4>Filter By</h4>
            </div>
            <div class="filter-item">
              <div class="header" @click="showType = !showType">
                <label for="">Category</label>
                <i class="fa fa-angle-down"></i>
              </div>
              <div class="content" v-if="showType">
                <select v-model="activeCategory" @change="startSearch()">
                  <option value="All">All categories</option>
                  <option
                    v-for="category in uniqueCategories"
                    :key="category"
                    :value="category"
                  >
                    {{ category }}
                  </option>
                </select>
              </div>
            </div>

            <div class="filter-form">
              <label for="">Filter By location: </label>
              <div class="form">
                <div class="form-group group-4">
                  <!-- Province, Disctict, sector, cell -->
                  <select
                    name="province"
                    id=""
                    v-model="filterData.province"
                    @change="
                      getDistricts(filterData.province);
                      startSearch();
                    "
                  >
                    <option value="">{{ $txt("All Provinces") }}</option>
                    <option
                      v-for="province in provinces"
                      :key="province.provinceId"
                      :value="province.provinceId"
                    >
                      {{ province.provinceName }}
                    </option>
                  </select>
                  <select
                    name="district"
                    id=""
                    v-model="filterData.district"
                    @change="
                      getSectors(filterData.district);
                      startSearch();
                    "
                  >
                    <option value="">{{ $txt("All Districts") }}</option>
                    <option
                      v-for="district in districts"
                      :key="district.districtId"
                      :value="district.districtId"
                    >
                      {{ district.districtName }}
                    </option>
                  </select>
                  <select
                    name="sector"
                    id=""
                    v-model="filterData.sector"
                    @change="
                      getCells(filterData.sector);
                      startSearch();
                    "
                  >
                    <option value="">{{ $txt("All Sectors") }}</option>
                    <option
                      v-for="sector in sectors"
                      :key="sector.sectorId"
                      :value="sector.sectorId"
                    >
                      {{ sector.sectorName }}
                    </option>
                  </select>
                  <select
                    name="cell"
                    id=""
                    v-model="filterData.cell"
                    @change="startSearch()"
                  >
                    <option value="">{{ $txt("All Cells") }}</option>
                    <option
                      v-for="cell in cells"
                      :key="cell.cellId"
                      :value="cell.cellId"
                    >
                      {{ cell.cellName }}
                    </option>
                  </select>
                </div>
                <!-- <button class="submit" @click="getFilteredData()">Filter</button> -->
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="results-section">
            <div class="results-header">
              <div class="add-business">
                <label for=""
                  >Showing {{ allbusinesses.length }}+ business</label
                >
                <button @click="openAddModel()">Add business</button>
              </div>
              <form action="" class="search" @submit.prevent="startSearch()">
                <input
                  type="text"
                  name=""
                  id=""
                  :placeholder="$txt('Search')"
                  v-model="searchQuery"
                  @keyup="startSearch()"
                />
                <!-- <button type="submit" @click="startSearch()">
                  <i class="fa fa-search"></i>
                </button> -->
                <!-- <i class="fa fa-times clear"></i> -->
                <div class="filter-btn">
                  <button type="button" @click="showModelFilters = true">
                    <i class="fa fa-filter"></i>
                    <span>{{ $txt("Filter") }}</span>
                  </button>
                </div>
              </form>
              <!-- <div class="category-listings">
                <div
                  class="slide-item"
                  v-for="category in businessFields.sort()"
                  :key="category"
                  @click="activeCategory = category"
                >
                  <label for="">{{ category }}</label>
                </div>
              </div> -->
              <h3 class="showing">
                <p v-if="activeCategory != 'All'">
                  Showing Businesses from
                  <span>{{ activeCategory }}</span> field
                </p>
                <p v-else>
                  Showing Businesses from
                  <span>{{ activeCategory }}</span> fields
                </p>
              </h3>
            </div>
            <div class="listing-items">
              <div
                class="listing-item"
                v-for="business in businesses"
                :key="business.id"
                @click="
                  selectedItem = business;
                  viewItem = true;
                "
              >
                <div class="image">
                  <img
                    :src="$file(business.business_image)"
                    alt=""
                    @error="$imageurlalt()"
                  />
                  <div class="reviews">
                    <button>
                      <i class="fa fa-eye"></i>
                      <label for=""
                        >{{ business.reviews.length }} Reviews</label
                      >
                    </button>
                  </div>
                </div>
                <div class="details">
                  <div class="logo absolute">
                    <img
                      :src="$file(business.business_logo)"
                      alt=""
                      @error="$imageurlalt()"
                    />
                  </div>
                  <div class="title">
                    <h4>{{ business.business_name }}</h4>
                  </div>
                  <div>
                    <div class="category">
                      <p>
                        <i class="fa fa-map-marker"></i>
                        {{ business.district_name }},
                        {{ business.sector_name }}
                      </p>
                    </div>
                    <div class="category">
                      <p>
                        <i class="fa fa-tag"></i>
                        {{ business.businesssector.sector_name }}
                      </p>
                    </div>
                  </div>
                  <div>
                    <div class="category">
                      <p>
                        <i class="fa fa-phone"></i>
                        {{ business.business_phone }}
                      </p>
                    </div>
                    <!-- <div class="category">
                      <p v-if="business.verified">
                        <img
                          src="/assets/images/verify.png"
                          alt=""
                          class="verify"
                        />Verified
                      </p>
                      <p v-else>
                        <img
                          src="/assets/images/unverify.png"
                          alt=""
                          class="verify"
                        />
                        Not Verified
                      </p>
                    </div> -->
                  </div>
                  <div class="description">
                    <p
                      v-html="
                        business.business_description.length > 100
                          ? business.business_description.substring(0, 100) +
                            '...'
                          : business.business_description
                      "
                    ></p>
                  </div>
                  <div class="actions">
                    <!-- button(directions, email, phone) -->
                    <button type="button" class="green">
                      <i class="fa-solid fa-diamond-turn-right"></i>
                    </button>
                    <a
                      :href="'mailto:' + business.business_email"
                      type="button"
                      class="blue"
                    >
                      <i class="fa fa-envelope"></i>
                    </a>
                    <a
                      :href="'tel:' + business.business_phone"
                      type="button"
                      class="red"
                    >
                      <i class="fa fa-phone"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <pagination
              class="pagination"
              :totalItems="allbusinesses.length"
              :itemsPerPage="itemsPerPage"
              @pageChanged="
                (page) => {
                  changePage(page);
                }
              "
            />
          </div>
        </div>
      </div>
      <div class="header-widget">
        <div class="header-sections">
          <div
            class="header-section"
            :class="selectedSection == 1 ? 'active' : ''"
            @mouseover="selectedSection = 1"
          >
            <img src="/assets/images/tea.jpg" alt="" @error="$imageurlalt()" />
            <label for="" v-show="selectedSection == 1"
              ><span>
                {{
                  $txt(
                    "Find the best coach or school for yourself or your kids"
                  )
                }}</span
              >
              <div class="explore" v-show="selectedSection == 1">
                <button>{{ $txt("Explore") }}</button>
              </div>
            </label>
          </div>
          <div
            class="header-section"
            :class="selectedSection == 2 ? 'active' : ''"
            @mouseover="selectedSection = 2"
          >
            <img
              src="/assets/images/cover/tech.jpg"
              alt=""
              @error="$imageurlalt()"
            />
            <label for="" v-show="selectedSection == 2"
              ><span>{{ $txt("Hire a proffesional and find jobs.") }}"</span>
              <div class="explore" v-show="selectedSection == 2">
                <button>{{ $txt("Explore") }}</button>
              </div>
            </label>
          </div>
          <div
            class="header-section"
            :class="selectedSection == 3 ? 'active' : ''"
            @mouseover="selectedSection = 3"
          >
            <img
              src="/assets/images/cover/edu.jpg"
              alt=""
              @error="$imageurlalt()"
            />
            <label for="" v-show="selectedSection == 3">
              <span>{{
                $txt("Browse and know more about the best schools in Rwanda")
              }}</span>
              <div class="explore" v-show="selectedSection == 3">
                <button>{{ $txt("Explore") }}</button>
              </div>
            </label>
          </div>
          <div
            class="header-section"
            :class="selectedSection == 4 ? 'active' : ''"
            @mouseover="selectedSection = 4"
          >
            <img
              src="/assets/images/cover/rnit.jpg"
              alt=""
              @error="$imageurlalt()"
            />
            <label for="" v-show="selectedSection == 4">
              <span
                >{{ $txt("Boost your business and brand awareness") }}"</span
              >
              <div class="explore" v-show="selectedSection == 4">
                <button>{{ $txt("Explore") }}</button>
              </div>
            </label>
          </div>
          <div
            class="header-section"
            :class="selectedSection == 5 ? 'active' : ''"
            @mouseover="selectedSection = 5"
          >
            <img
              src="/assets/images/cover/trucks.jpg"
              alt=""
              @error="$imageurlalt()"
            />
            <label for="" v-show="selectedSection == 5"
              ><span>{{
                $txt(
                  "Business your goods and materiels, Find Business clients for your vehicles"
                )
              }}</span>
              <div class="explore" v-show="selectedSection == 5">
                <button>{{ $txt("Explore") }}</button>
              </div>
            </label>
          </div>
          <div
            class="header-section"
            :class="selectedSection == 6 ? 'active' : ''"
            @mouseover="selectedSection = 6"
          >
            <img
              src="/assets/images/burger.jpg"
              alt=""
              @error="$imageurlalt()"
            />
            <label for="" v-show="selectedSection == 6"
              ><span>{{
                $txt(
                  "Sell or buy products and services to new customers and boost your sales"
                )
              }}</span>
              <div class="explore" v-show="selectedSection == 6">
                <button>{{ $txt("Explore") }}</button>
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>
    <left-view
      v-if="viewItem"
      @closePopup="viewItem = false"
      :modelTitle="'View Business'"
    >
      <div class="dialog-body">
        <div class="dialog-body-top">
          <div class="image">
            <img
              :src="$file(selectedItem.business_image)"
              alt=""
              @error="$imageurlalt()"
            />
          </div>
        </div>
        <div class="dialog-body-bottom">
          <div class="title">
            <h4>{{ selectedItem.business_name }}</h4>
          </div>
          <div class="location">
            <p>
              <i class="fa fa-map-marker"></i>
              {{ selectedItem.province_name }},
              {{ selectedItem.district_name }},
              {{ selectedItem.sector_name }}
            </p>
          </div>
          <div class="category">
            <p>
              <i class="fa fa-phone"></i>
              {{ selectedItem.business_phone }}
            </p>
          </div>
          <div class="category">
            <p>
              <i class="fa fa-envelope"></i>
              {{ selectedItem.business_email }}
            </p>
          </div>
          <div class="category">
            <p>
              <i class="fa fa-tag"></i>
              {{ selectedItem.businesssector.sector_name }}
            </p>
          </div>
          <div class="category">
            <p v-if="selectedItem.verified">
              <img
                src="/assets/images/verify.png"
                alt=""
                class="verify"
              />Verified
            </p>
            <p v-else>
              <img src="/assets/images/unverify.png" alt="" class="verify" />
              Not Verified
            </p>
          </div>
          <div class="description">
            <p v-html="selectedItem.business_description"></p>
          </div>
          <div class="actions">
            <button type="button" class="green">
              <i class="fa-solid fa-diamond-turn-right"></i>
            </button>
            <button type="button" class="blue">
              <i class="fa fa-envelope"></i>
            </button>
            <button type="button" class="red">
              <i class="fa fa-phone"></i>
            </button>
          </div>
          <div class="reviews">
            <label for="" class="title">
              {{ $txt("Reviews") }}
            </label>
            <div class="post-a-review">
              <button class="post" @click="startPost = true">
                <i class="fa fa-plus"></i>
                <div v-if="$getUser()">
                  <p>
                    {{ $txt("Post a review") }}
                  </p>
                </div>

                <div v-else>
                  <button @click="$store.state.showModelLogin = true">
                    {{ $txt("Login to post a review") }}
                  </button>
                </div>
              </button>
              <div class="post-form" v-show="startPost">
                <h4 class="title">
                  {{ $txt("Post a review") }}
                </h4>
                <form action="" @submit.prevent="sendReview()">
                  <div class="body" v-if="!doneSubmit">
                    <textarea
                      name=""
                      id=""
                      cols="30"
                      rows="10"
                      placeholder="review"
                      v-model="review.message"
                    ></textarea>
                    <button>Send Review</button>
                  </div>
                  <p v-if="doneSubmit">
                    {{
                      $txt(
                        "Your review has been submitted successfully. It will appear on the profile soon."
                      )
                    }}
                  </p>
                </form>
              </div>
            </div>

            <div
              class="review-item"
              v-for="review in selectedItem.reviews"
              :key="review.id"
            >
              <!-- <div class="image">
                <img :src="'sdfsd'" alt="" @error="$imageurlalt()" />
              </div> -->
              <div class="details">
                <div class="name">
                  <h4>{{ review.sender.full_names }}</h4>
                </div>
                <div class="review">
                  <p>{{ review.message }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </left-view>
    <model
      :modelTitle="'Add Business'"
      :largeModel="true"
      v-if="popupOpen"
      @closeModel="
        popupOpen = false;
        getitems();
      "
    >
      <add-business />
    </model>
    <site-footer />
  </div>
</template>

<script>
import Login from "../Login.vue";
export default {
  name: "Business",
  components: { Login },
  data() {
    return {
      selectedSection: 1,
      searchQuery: "",
      viewProduct: false,
      selectedItem: {},
      showType: true,
      showLocation: false,
      showDestination: false,
      showCategory: false,
      showAvailability: false,
      currentPage: 1,
      itemsPerPage: 9,
      viewItem: false,
      viewCarsBodies: false,
      filterData: {
        province: "",
        district: "",
        sector: "",
        cell: "",
        destination_province: "null",
        destination_district: "null",
        date: [],
      },
      provinces: [],
      districts: [],
      sectors: [],
      cells: [],
      destination_provinces: [],
      destination_districts: [],
      showModelFilters: false,
      allbusinesses: [],
      businesses: [],
      startPost: false,
      doneSubmit: false,
      popupOpen: false,
      modalOpen: false,
      businessFields: [],
      activeCategory: "All",
      review: {
        message: "",
        business_id: "",
      },
    };
  },
  methods: {
    changePage(page) {
      this.currentPage = page;
      this.businesses = this.$changePage(
        this.allbusinesses,
        page,
        this.itemsPerPage
      );
      window.scrollTo(0, 0);
    },
    startSearch() {
      let allData = [...this.allbusinesses];
      let result1 = [];
      if (this.searchQuery != "") {
        result1 = allData.filter((business) => {
          return business.business_name
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase());
        });
      } else {
        result1 = allData;
      }

      let result2 = [];

      if (this.activeCategory != "All") {
        result2 = result1.filter((business) => {
          return business.businesssector.sector_name == this.activeCategory;
        });
      } else {
        result2 = result1;
      }

      let result3 = this.searchByLocation(result2);

      this.businesses = result3;
    },
    getProvinces() {
      this.$startLoader();
      this.$store
        .dispatch("GET_PROVINCES", { token: this.$getToken() })
        .then((res) => {
          this.provinces = res.data.data.data;
          this.destination_provinces = res.data.data.data;
          this.districts = [];
          this.sectors = [];
          this.cells = [];
          this.$stopLoader();
        });
    },
    getDistricts(provinceId) {
      if (provinceId == "" || provinceId == undefined || provinceId == null) {
        this.districts = [];
        this.sectors = [];
        this.cells = [];
        return;
      }
      this.$startLoader();
      this.$store
        .dispatch("GET_DISTRICTS", {
          token: this.$getToken(),
          provinceId: provinceId,
        })
        .then((res) => {
          this.districts = res.data.data.data;
          this.sectors = [];
          this.cells = [];
          this.$stopLoader();
        });
    },
    getSectors(districtId) {
      if (districtId == "" || districtId == undefined || districtId == null) {
        this.sectors = [];
        this.cells = [];
        return;
      }
      this.$startLoader();
      this.$store
        .dispatch("GET_SECTORS", {
          token: this.$getToken(),
          districtId: districtId,
        })
        .then((res) => {
          this.sectors = res.data.data.data;
          this.cells = [];
          this.$stopLoader();
        });
    },
    getCells(sectorId) {
      if (sectorId == "" || sectorId == undefined || sectorId == null) {
        this.cells = [];
        return;
      }
      this.$startLoader();
      this.$store
        .dispatch("GET_CELLS", { token: this.$getToken(), sectorId: sectorId })
        .then((res) => {
          this.cells = res.data.data.data;
          this.$stopLoader();
        });
    },
    getDestinationDistricts(provinceId) {
      if (provinceId == "" || provinceId == undefined || provinceId == null) {
        this.destination_districts = [];
        return;
      }
      this.$startLoader();
      this.$store
        .dispatch("GET_DISTRICTS", {
          token: this.$getToken(),
          provinceId: provinceId,
        })
        .then((res) => {
          this.destination_districts = res.data.data.data;
          this.$stopLoader();
        });
    },
    searchByLocation(searchResults1) {
      let searchResults2 = [];
      if (this.filterData.province == "") {
        this.filterData.district = "";
        this.filterData.sector = "";
        this.filterData.cell = "";
      }
      if (this.filterData.district == "") {
        this.filterData.sector = "";
        this.filterData.cell = "";
      }
      if (this.filterData.sector == "") {
        this.filterData.cell = "";
      }
      if (this.filterData.cell && this.filterData.cell != "") {
        searchResults2 = searchResults1.filter((product) => {
          return parseInt(product.business_cell) == this.filterData.cell;
        });
      } else {
        if (this.filterData.sector && this.filterData.sector != "") {
          searchResults2 = searchResults1.filter((product) => {
            return parseInt(product.business_sector) == this.filterData.sector;
          });
        } else {
          if (this.filterData.district && this.filterData.district != "") {
            searchResults2 = searchResults1.filter((product) => {
              return (
                parseInt(product.business_district) == this.filterData.district
              );
            });
          } else {
            if (this.filterData.province && this.filterData.province != "") {
              console.log(this.filterData.province);
              searchResults2 = searchResults1.filter((product) => {
                return (
                  parseInt(product.business_province) ==
                  this.filterData.province
                );
              });
            } else {
              searchResults2 = searchResults1;
            }
          }
        }
      }
      return searchResults2;
    },
    openAddModel() {
      if (this.$getUser()) {
        this.popupOpen = true;
      } else {
        this.$notify({
          title: "Error",
          type: "error",
          text: "Please login first",
        });
        this.$store.state.showModelLogin = true;
      }
    },
    openModal() {
      this.modalOpen = true;
    },
    closeModal() {
      this.modalOpen = false;
    },

    getitems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_ALL_BUSINESSES", {
          token: this.$getToken(),
        })
        .then((res) => {
          this.allbusinesses = res.data.data;
          this.businesses = this.allbusinesses;
          this.$stopLoader();
        })
        .catch((err) => {
          console.log(err);
          this.$stopLoader();
        });
    },
    sendReview() {
      this.review.business_id = this.selectedItem.business_id;
      this.$startLoader();
      this.$store
        .dispatch("ADD_BUSINESS_REVIEW", {
          token: this.$getToken(),
          data: this.review,
        })
        .then((res) => {
          this.$stopLoader();
          this.$notify({
            title: "Success",
            text: "Review Added. It will be posted soon",
            type: "success",
          });
          this.doneSubmit = true;
        })
        .catch((err) => {
          this.$stopLoader();
          this.$notify({
            title: "Error",
            text: err.response.data.message,
            type: "error",
          });
        });
    },
  },
  mounted() {
    this.getProvinces();
    this.changePage(1);
    this.getitems();
  },
  computed: {
    uniqueCategories() {
      return [
        ...new Set(
          this.allbusinesses.map(
            (business) => business.businesssector.sector_name
          )
        ),
      ].sort();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.search-section {
    form {
      @media screen and (max-width: 1500px) {
        margin-top: 1rem !important;
      }
      label {
        @media screen and (max-width: 1500px) {
          font-size: 1rem !important;
        }
      }
      p {
        font-size: 0.8rem !important;
      }
    }
  }
.whole-content {
  padding: 2rem 3rem;
  background: #fff;
  @media screen and (max-width: 1500px) {
    padding: 2rem 1rem;
  }
  .Business-section {
    display: flex;
    column-gap: 1rem;
    background: #fff;
    padding: 1rem;
    .left {
      width: 300px;
      @media screen and (max-width: 1500px) {
        width: 200px;
      }
      .filters {
        .title {
          h4 {
            font-size: 1rem;
            font-weight: 600;
            color: black;
            @media screen and (max-width: 1500px) {
              font-size: 0.7rem !important;
            }
          }
        }
        .filter-item {
          margin-top: 1rem;
          .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            label {
              font-size: 0.8rem;
              font-weight: 400;
              color: #1f1f1f;
              cursor: pointer;
              @media (max-width: 1500px) {
                font-size: 0.5rem;
              }
              @media (max-width: 1200px) {
              }
              @media (max-width: 800px) {
              }
            }
            i {
              font-size: 0.8rem;
              color: #1f1f1f;
              cursor: pointer;
              @media (max-width: 1500px) {
                font-size: 0.6rem;
              }
              @media (max-width: 1200px) {
              }
              @media (max-width: 800px) {
              }
            }
          }
          .content {
            margin-top: 1rem;
            padding-left: 1rem;
            .help {
              font-size: 1rem;
              font-weight: 400;
              color: #0439e7;
              margin-top: 0.5rem;
              text-decoration: underline;
              cursor: pointer;
              @media (max-width: 1500px) {
                font-size: 0.7rem;
              }
              @media (max-width: 1200px) {
              }
              @media (max-width: 800px) {
              }
            }
            ul {
              li {
                font-size: 1rem;
                font-weight: 400;
                color: black;
                margin-top: 0.5rem;
                input {
                  margin-right: 0.5rem;
                }
              }
            }
            input,
            select {
              width: 100% !important;
              border-radius: 0px;
              border: none;
              border-bottom: 1px solid #eee;
              padding: 5px 10px;
              outline: none;
              font-size: 0.75rem;
              font-weight: 400;
              color: #1f1f1f;
              cursor: pointer;
              @media screen and (max-width: 1500px) {
                font-size: 0.6rem;
                padding: 5px 5px;
                height: fit-content !important;
              }
              @media (max-width: 1200px) {
              }
              @media (max-width: 800px) {
              }
            }
          }
        }
        .apply-filter {
          margin-top: 1rem;
          display: flex;
          justify-content: space-between;
          button {
            background: $blue;
            color: white;
            padding: 10px 20px;
            border-radius: 3px;
            font-size: 15px;
            font-weight: 500;
            outline: none;
            border: none;
            cursor: pointer;
            width: 48%;
            &.clear {
              background: white;
              color: $blue;
              border: 1px solid $blue;
              &:hover {
                background: $blue;
                color: white;
                border: 1px solid $blue;
              }
            }
          }
        }
      }
    }
    .right {
      width: calc(100% - 300px);
      background: #fff;
      overflow: hidden;
      @media screen and (max-width: 1500px) {
        width: calc(100% - 200px);
      }
      .filter-btn {
        display: none;
        position: relative;
        margin: 0 10px;
        width: 130px;
        button {
          border-radius: 100px !important;
          background: #fff !important;
          color: $blue !important;
          border: 2px solid $blue !important;
        }

        i {
          margin-right: 5px;
        }
      }
      .results-section {
        border: 1px solid #ccc;
        border-radius: 20px;
        overflow: hidden;
        .results-header {
          padding: 1rem;
          padding-bottom: 2rem;
          display: flex;
          flex-direction: column;
          margin-bottom: 1rem;
          border-bottom: 1px solid #ccc;
          overflow: hidden;
          .search {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            position: relative;
            input {
              padding: 10px 20px;
              border-radius: 5px 0px 0px 5px;
              border: 1px solid #ccc;
              font-size: 15px;
              font-weight: 500;
              width: 100%;
              outline: none;
            }
            button {
              background: $blue;
              color: white;
              padding: 10px 20px;
              border-radius: 0px 5px 5px 0px;
              font-size: 15px;
              font-weight: 500;
              outline: none;
              border: none;
              cursor: pointer;
            }
            .clear {
              font-size: 1.2rem;
              color: #949494;
              margin-left: 1rem;
              cursor: pointer;
              position: absolute;
              right: 4rem;
            }
          }
          p {
            font-size: 1rem;
            font-weight: 400;
            color: #5c5c5c;
            margin-top: 1rem;
            span {
              font-weight: 600;
              color: #000;
            }
          }
        }
        .listing-items {
          background: #7091911f;
          padding: 1rem;
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(250px, 278px));
          grid-gap: 1rem;
          @media (max-width: 1500px) {
            grid-template-columns: repeat(auto-fit, minmax(140px, 200px));
            padding: 1rem 1rem;
          }
          @media (max-width: 1200px) {
            grid-template-columns: repeat(auto-fit, minmax(100px, 200px));
          }
          @media (max-width: 800px) {
            grid-template-columns: repeat(auto-fit, minmax(100px, 200px));
          }
          .listing-item {
            background: #fff;
            border-radius: 20px;
            overflow: hidden;
            border: 1px solid #ccc;
            cursor: pointer;
            transition: 0.5s ease-in-out;
            &:hover {
              box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            }
            .image {
              width: 100%;
              height: 120px;
              overflow: hidden;
              position: relative;
              @media (max-width: 1500px) {
                height: 100px;
              }
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                background: #f2f2f2;
                // inner-shadow
                box-shadow: inset 0 0 1000px rgba(0, 0, 0, 0.2);
              }
              .reviews {
                position: absolute;
                top: 10px;
                left: 10px;
                padding: 0.5rem;
                border-radius: 5px;
                background: rgba(0, 0, 0, 0.123);
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                font-size: 0.7rem;
                font-weight: 600;
                border: 1px solid #dddddd;
                color: #dddddd;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                label {
                  cursor: pointer;
                }
                i {
                  margin-right: 0.5rem;
                }
                &:hover {
                  background: rgba(0, 0, 0, 0.39);
                  color: #fff;
                  border: 1px solid #fff;
                }
              }
            }
            .details {
              padding: 1rem;
              position: relative;
              box-shadow: 0 0 10px rgba(0, 0, 0, 0.438);
              .logo {
                position: absolute;
                top: -1.3rem;
                left: 1.7rem;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                overflow: hidden;
                background: #eee;
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }
              .title {
                margin-top: 1rem;
                h4 {
                  font-size: 0.8rem;
                  font-weight: 600;
                  color: black;
                  @media (max-width: 1500px) {
                    font-size: 0.6rem;
                  }
                }
              }
              .location,
              .category,
              .contact {
                margin-top: 0.5rem;
                p {
                  font-size: 0.7rem;
                  font-weight: 400;
                  color: #5c5c5c;
                  @media (max-width: 1500px) {
                    font-size: 0.5rem;
                  }
                  i {
                    font-size: 0.7rem;
                    margin-right: 0.5rem;
                    @media (max-width: 1500px) {
                      font-size: 0.6rem;
                    }
                  }
                }
              }
              .description {
                margin-top: 1rem;
                p {
                  font-size: 0.7rem;
                  font-weight: 400;
                  color: #5c5c5c;
                  margin-top: 0.5rem;
                  display: none;
                  i {
                    margin-right: 0.5rem;
                  }
                }
              }
              .actions {
                margin-top: 2rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                button,
                a {
                  background: $blue;
                  color: white;
                  padding: 10px 20px;
                  border-radius: 50%;
                  font-size: 17px;
                  font-weight: 500;
                  outline: none;
                  border: none;
                  cursor: pointer;
                  height: 50px;
                  width: 50px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  @media (max-width: 1500px) {
                    height: 30px;
                    width: 30px;
                    font-size: .8rem;
                    padding: 0px;
                  }
                  &.green {
                    background: #fff;
                    color: #6db16d;
                    border: 1px solid #6db16d;
                    &:hover {
                      background: #6db16d;
                      color: #fff;
                    }
                  }
                  &.blue {
                    background: #fff;
                    color: #63a5c4;
                    border: 1px solid #63a5c4;
                    &:hover {
                      background: #63a5c4;
                      color: #fff;
                    }
                  }
                  &.red {
                    background: #fff;
                    color: #b66c91;
                    border: 1px solid #b66c91;
                    &:hover {
                      background: #b66c91;
                      color: #fff;
                    }
                  }
                }
              }
            }
          }
        }
        .no-results {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 300px;
          p {
            font-size: 1.2rem;
            font-weight: 600;
            color: #000;
          }
        }
        .pagination {
          margin: 1rem 0;
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
}
.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #ccc;
  .title {
    font-size: 1.2rem;
    font-weight: 600;
    color: black;
  }
  button {
    background: none;
    color: #363636;
    cursor: pointer;
    margin-right: 15px;
    font-size: 2rem;
  }
}
.dialog-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  .dialog-body-top {
    background: white;
    max-height: 350px;
    height: 350px;
    img {
      object-fit: cover;
      width: 100%;
      max-height: 350px;
    }
  }
  .dialog-body-bottom {
    padding: 1rem 3rem;
    .logo {
      position: absolute;
      top: -2rem;
      left: 1.7rem;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      overflow: hidden;
      background: #eee;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .title {
      margin-top: 1rem;
      h4 {
        font-size: 1.2rem;
        font-weight: 600;
        color: black;
      }
    }
    .location {
      margin-top: 1rem;
      p {
        font-size: 1rem;
        font-weight: 400;
        color: #5c5c5c;
        margin-top: 0.5rem;
        i {
          margin-right: 0.5rem;
        }
      }
    }

    .category {
      margin-top: 1rem;
      p {
        font-size: 1rem;
        font-weight: 400;
        color: #5c5c5c;
        margin-top: 0.5rem;
        display: flex;
        i {
          margin-right: 0.5rem;
        }
        .verify {
          width: 20px;
          height: 20px;
          margin-right: 5px;
        }
      }
    }
    .contact {
      margin-top: 1rem;
      p {
        font-size: 1rem;
        font-weight: 400;
        color: #5c5c5c;
        margin-top: 0.5rem;
        i {
          margin-right: 0.5rem;
        }
      }
    }
    .description {
      margin-top: 1rem;
      p {
        font-size: 0.8rem;
        font-weight: 400;
        color: #5c5c5c;
        margin-top: 0.5rem;
        i {
          margin-right: 0.5rem;
        }
      }
    }
    .actions {
      margin-top: 2rem;
      display: flex;
      justify-content: space-around;
      align-items: center;
      button {
        background: $blue;
        color: white;
        padding: 10px 20px;
        border-radius: 50%;
        font-size: 17px;
        font-weight: 500;
        outline: none;
        border: none;
        cursor: pointer;
        height: 50px;
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        &.green {
          background: #fff;
          color: #6db16d;
          border: 1px solid #6db16d;
          &:hover {
            background: #6db16d;
            color: #fff;
          }
        }
        &.blue {
          background: #fff;
          color: #63a5c4;
          border: 1px solid #63a5c4;
          &:hover {
            background: #63a5c4;
            color: #fff;
          }
        }
        &.red {
          background: #fff;
          color: #b66c91;
          border: 1px solid #b66c91;
          &:hover {
            background: #b66c91;
            color: #fff;
          }
        }
      }
    }
    .reviews {
      margin-top: 1rem;
      .title {
        font-size: 1.2rem;
        font-weight: 600;
        color: black;
      }
      .review-item {
        margin-top: 1rem;
        display: flex;
        align-items: center;
        column-gap: 1rem;
        .image {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          overflow: hidden;
          background: #eee;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .details {
          margin-left: 1rem;
          p {
            font-size: 0.9rem;
            font-weight: 400;
            color: #5c5c5c;
            margin-top: 0.5rem;
            i {
              margin-right: 0.5rem;
            }
          }
          h4 {
            font-size: 1rem;
            font-weight: 600;
            color: black;
          }
        }
      }
    }
    .post-a-review {
      margin-top: 1rem;
      .post {
        background: $blue;
        color: white;
        padding: 10px 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        column-gap: 5px;
      }
      .post-form {
        .title {
          font-size: 1.2rem;
          font-weight: 600;
          color: black;
        }
        form {
          margin-top: 1rem;
          input,
          textarea {
            padding: 10px 20px;
            border-radius: 5px;
            border: 1px solid #ccc;
            font-size: 15px;
            font-weight: 500;
            width: 100%;
            outline: none;
            margin-top: 0.5rem;
          }
          textarea {
            height: 100px;
          }
          button {
            background: $blue;
            color: white;
            padding: 10px 20px;
            border-radius: 3px;
            font-size: 15px;
            font-weight: 500;
            outline: none;
            border: none;
            cursor: pointer;
            margin-top: 1rem;
          }
        }
      }
    }
  }
}

.category-listings {
  display: flex;
  column-gap: 10px;
  margin-top: 2rem;
  width: 90%;
  overflow-x: auto;
  .slide-item {
    // min-width: 150px;
    width: fit-content;
    background: #eee;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
    &:hover {
      background: #d1d1d1;
    }
    label {
      padding: 5px 20px;
      font-size: 1.2rem;
      cursor: pointer;
      white-space: nowrap;
    }
  }
}
.showing {
  p {
    font-size: 1.2rem !important;
    span {
      color: $blue !important;
      font-size: 1.6rem !important;
    }
  }
}
</style>