<template>
  <div class="Production">
    <agriProfileMenu />
    <div class="table-header">
      <div class="left">
        <div class="search">
          <input
            type="text"
            placeholder="Search"
            @keyup="searchData()"
            v-model="search"
          />
        </div>
      </div>
      <div class="right">
        <button @click="addPopup = true" class="btn-lg unverify">
          Add Production
        </button>
      </div>
    </div>
    <table class="table bus-table">
      <thead>
        <tr>
          <th>#</th>
          <th>Product</th>
          <th>Quantity</th>
          <th>Price</th>
          <th>Location</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in items" :key="item.id">
          <td class="img">
            <img :src="$file(item.images[0])" alt="" @error="$imageurlalt" />
          </td>
          <td>{{ item.product.product_name }}</td>
          <td>{{ item.quantity }} {{ item.unit.unit_name }}</td>
          <td>{{ item.price }}</td>
          <td>{{ item.district_name }}, {{ item.sector_name }}</td>
          <td>
            <button
              class="unverify"
              @click="
                selectedItem = item;
                editPopup = true;
              "
            >
              <label for="">{{ $txt("Edit") }}</label>
            </button>
            <button
              class="delete"
              @click="
                selectedItem = item;
                deletePopup = true;
              "
            >
              <label for="">{{ $txt("Delete") }}</label>
            </button>
            <button
              class="verify"
              @click="
                selectedItem = item;
                sellOutPopup = true;
              "
            >
              <label for="">{{ $txt("Sold out") }}</label>
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <model
      :modelTitle="'Add Production'"
      :largeModel="true"
      v-if="addPopup"
      @closeModel="addPopup = false"
    >
      <add-production
        @closePopup="
          addPopup = false;
          getItems();
        "
      />
    </model>
    <model
      :modelTitle="'Edit Production'"
      :largeModel="true"
      v-if="editPopup"
      @closeModel="editPopup = false"
    >
      <edit-production
        @closePopup="
          editPopup = false;
          getItems();
        "
        :newData="selectedItem"
      />
    </model>
    <confirm-model
      :modelTitle="'Delete Production'"
      :displayText="'Are you sure you want to delete this production?'"
      :smallModel="'small'"
      v-if="deletePopup"
      @closeModel="deletePopup = false"
      @confirmModel="
        deletePopup = false;
        deleteItem();
      "
    >
    </confirm-model>
    <confirm-model
      :modelTitle="'Mark as sold out'"
      :displayText="'Are you sure you want to mark this production as sold out?'"
      :smallModel="'small'"
      v-if="sellOutPopup"
      @closeModel="sellOutPopup = false"
      @confirmModel="
        sellOutPopup = false;
        sellOut();
      "
    >
    </confirm-model>
  </div>
</template>

<script>
import AddProduction from "./AddProduction.vue";
import EditProduction from "./EditProduction.vue";
export default {
  name: "Production",
  components: { AddProduction, EditProduction },
  data() {
    return {
      allItems: [
        {
          id: 1,
          images: ["maize.jpg"],
          price: "3000Rwf/kg",
          quantity: 10,
          unit: "Tons",
          district_name: "KICUKIRO",
          sector_name: "Kanombe",
          product: {
            product_name: "Ibigori",
          },
        },
      ],
      items: [],
      search: "",
      addPopup: false,
      editPopup: false,
      selectedItem: null,
      deletePopup: false,
      sellOutPopup: false,
    };
  },
  methods: {
    searchData() {
      this.items = this.allItems.filter((item) => {
        return (
          item.product.product_name
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          item.sector_name.toLowerCase().includes(this.search.toLowerCase()) ||
          item.district_name.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_MY_AGRIPRODUCTION", { token: this.$getToken() })
        .then((res) => {
          this.allItems = res.data.data;
          this.allItems.forEach((item) => {
            console.log(item.images);
            item.images = JSON.parse(item.images);
            console.log(item.images);
          });
          this.items = this.allItems;
          this.$stopLoader();
        })
        .catch((err) => {
          this.$stopLoader();
        });
    },
    deleteItem() {
      this.$startLoader();
      this.$store
        .dispatch("DELETE_AGRIPRODUCTION", {
          token: this.$getToken(),
          data: {
            production_id: this.selectedItem.production_id,
          },
        })
        .then((res) => {
          if (res.data.status == "success") {
            this.$notify({
              title: "Success",
              type: "success",
              text: res.data.message,
            });
            this.getItems();
          } else {
            this.$notify({
              title: "Error",
              type: "warn",
              text: res.data.message,
            });
            this.$stopLoader();
          }
          this.deletePopupOpen = false;
        })
        .catch((err) => {
          console.log(err);
          this.$notify({
            title: "Error",
            type: "error",
            text: err.response.data.error,
          });
          this.$stopLoader();
        });
    },
    sellOut() {
      this.$startLoader();
      this.$store
        .dispatch("DELETE_AGRIPRODUCTION", {
          token: this.$getToken(),
          data: {
            production_id: this.selectedItem.production_id,
          },
        })
        .then((res) => {
          if (res.data.status == "success") {
            this.$notify({
              title: "Success",
              type: "success",
              text: this.$txt("Production marked as sold out"),
            });
            this.getItems();
          } else {
            this.$notify({
              title: "Error",
              type: "warn",
              text: res.data.message,
            });
            this.$stopLoader();
          }
          this.deletePopupOpen = false;
        })
        .catch((err) => {
          console.log(err);
          this.$notify({
            title: "Error",
            type: "error",
            text: "Something went wrong",
          });
          this.$stopLoader();
        });
    },
  },
  mounted() {
    this.getItems();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.Production {
  padding: 1rem;
}
</style>