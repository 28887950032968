<template>
  <div class="ModuleAdmins">
    <super-admin-topbar :pageTitle="'Users'" />
    <users-navigation :activePage="'mod'" />
    <div class="page-content">
      <div class="levels-table">
        <div class="table-header">
          <div class="left">
            <div class="search">
              <input
                type="text"
                placeholder="Search"
                @keyup="searchData()"
                v-model="search"
              />
            </div>
          </div>
          <div class="right">
            <!-- <button @click="popupOpen = true">Add user</button> -->
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th>Names</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Module</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in users" :key="user.userId">
              <td>{{ user.fullNames }}</td>
              <td>{{ user.emailAddress }}</td>
              <td>{{ user.telephoneNumber }}</td>
              <td>{{ user.moduleName }}</td>
              <td>
                <button @click="removeUser(user)" class="remove-role">
                  Remove role
                </button>
                <!-- <button @click="deleteuser(user)">
                  <img src="/assets/images/delete.svg" alt="" />
                </button> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <model
        :modelTitle="'Edit User'"
        :largeModel="false"
        :smallModel="true"
        v-if="editPopupOpen"
        @closeModel="editPopupOpen = false"
      >
        <form action="" class="addLevel" @submit.prevent="confirmEdit()">
          <div class="form-group">
            <label for="">Change role</label>
            <select name="" id="" v-model="selecteduser.userRole.id">
              <option value="">Select role</option>
              <option v-for="role in userRoles" :key="role.id" :value="role.id">
                {{ role.roleName }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <button type="submit">Save</button>
          </div>
        </form>
      </model>
      <confirm-model
        :modelTitle="'Remove module admin role'"
        :smallModel="true"
        v-if="deletePopupOpen"
        @closeModel="deletePopupOpen = false"
        @confirmModel="confirmDelete()"
        :displayText="
          'Are you sure you want to ' + selecteduser.fullNames + ' from this role?'
        "
      ></confirm-model>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModuleAdmins",
  data() {
    return {
      users: [],
      allusers: [],
      search: "",
      popupOpen: false,
      editPopupOpen: false,
      newuser: {
        userName: "",
      },
      selecteduser: {},
      deletePopupOpen: false,
      userRoles: [],
      moduleAssign: {
        user_id: "",
        module_id: "",
      },
      modulesByAdmin: [],
    };
  },
  methods: {
    getusers() {
      this.allusers = [];
      this.users = [];
      this.$startLoader();
      this.$store
        .dispatch("GET_USERS_BY_ADMINS", { token: this.$getToken() })
        .then((res) => {
          this.modulesByAdmin = res.data.data.data;
          this.modulesByAdmin.forEach((ismodule) => {
            ismodule.admins.forEach((user) => {
              user.moduleName = ismodule.moduleName;
              user.moduleCode = ismodule.moduleCode;
              user.moduleId = ismodule.moduleId;
              this.allusers.push(user);
              this.users.push(user);
            });
          });
          (this.selecteduser = {}),
            (this.moduleAssign = {
              user_id: "",
              module_id: "",
            });
          this.$stopLoader();
        });
    },
    searchData() {
      this.users = this.allusers.filter((user) => {
        return user.names.toLowerCase().includes(this.search.toLowerCase());
      });
    },
    removeUser(user) {
      this.selecteduser = user;
      this.moduleAssign.user_id = user.adminId;
      this.moduleAssign.module_id = user.moduleId;
      this.deletePopupOpen = true;
    },
    confirmDelete() {
      this.$startLoader();
      this.$store
        .dispatch("REMOVE_MODULE_ADMIN", {
          token: this.$getToken(),
          data: this.moduleAssign,
        })
        .then((res) => {
          if (res.data.status) {
            this.$notify({
              title: "Success",
              type: "success",
              text: "User removed successfully",
            });
          }
          this.deletePopupOpen = false;
          this.getusers();
        })
        .catch((err) => {
          console.log(err);
          this.$notify({
            title: "Error",
            type: "error",
            text: err.response.data.error,
          });
        });
    },
  },
  mounted() {
    this.getusers();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.ModuleAdmins {
  .levels-table {
    padding: 0 1rem;
    .table-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
      .left {
        display: flex;
        align-items: center;
        .search {
          input {
            padding: 0.5rem;
            border: 1px solid #ccc;
            border-radius: 5px;
            outline: none;
            width: 300px;
          }
        }
      }
      .right {
        button {
          padding: 0.65rem 1rem;
          width: 150px;
          border: none;
          outline: none;
          border-radius: 5px;
          background: $blue;
          color: #fff;
          cursor: pointer;
          font-weight: 600;
        }
      }
    }
    table {
      width: 100%;
      border-collapse: collapse;
      thead {
        tr {
          th {
            padding: 10px;
            border-bottom: 1px solid #ccc;
            text-align: left;
          }
        }
      }
      tbody {
        tr {
          td {
            padding: 10px;
            border-bottom: 1px solid #ccc;
            button {
              background: transparent;
              border: none;
              outline: none;
              cursor: pointer;
              margin-right: 1rem;
              img {
                width: 30px;
              }
            }
          }
        }
      }
    }
  }
  .addLevel {
    .form-group {
      margin-bottom: 1rem;
      label {
        display: block;
        margin-bottom: 0.5rem;
      }
      input,
      select {
        padding: 0.5rem;
        border: 1px solid #ccc;
        border-radius: 5px;
        outline: none;
        width: 100%;
      }
      button {
        padding: 0.65rem 1rem;
        width: 150px;
        border: none;
        outline: none;
        border-radius: 5px;
        background: $blue;
        color: #fff;
        cursor: pointer;
        font-weight: 600;
      }
    }
  }
  .remove-role {
    color: #fff;
    padding: 0.35rem 1rem;
    border: none;
    outline: none;
    border-radius: 5px;
    background: #7d2d2d !important;
  }
}
</style>