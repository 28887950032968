<template>
    <div class="Topbar">
      <div class="toggler">
        <!-- <div class="nav-lines" @click="$toggleSANav()" v-if="!$store.state.showSANav">
          <div></div>
          <div></div>
          <div></div>
        </div> -->
        <label for="">{{ pageTitle }}</label>
      </div>
      <div
        class="a"
        v-if="$getUser()"
        v-click-out-side="customMethod2"
        @click="showAccountDrop = true"
      >
        <logged-user :showDrop="showAccountDrop" />
      </div>
    </div>
  </template>
  
  <script>
  import clickOutSide from "@mahdikhashan/vue3-click-outside";
  export default {
    name: "Topbar",
    props: {
      pageTitle: {
        type: String,
        default: "Dashboard",
      },
    },
    directives: {
      clickOutSide,
    },
    data() {
      return {
        showAccountDrop: false,
      };
    },
    methods: {
      customMethod2() {
        this.showAccountDrop = false;
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  @import "@/assets/scss/main.scss";
  .Topbar {
    width: 100%;
    height: 60px;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    label {
      font-size: 20px;
      font-weight: 600;
      margin-left: 3rem;
    }
    .toggler {
      display: flex;
      .nav-lines {
        display: flex;
        flex-direction: column;
        border: 1px #6a6a6a solid;
        justify-content: center;
        align-items: center;
        padding: 5px;
        border-radius: 5px;
        row-gap: 2px;
        margin-right: 10px;
        cursor: pointer;
        &:hover{
          background-color: #e5e5e5;
        }
        div {
          width: 25px;
          border-top: 3px #191919 solid;
          margin: 1px 0;
        }
      }
    }
    .a {
      position: relative;
      .drop {
        position: absolute;
        top: 40px;
        right: 0;
        width: 200px;
        background-color: #fff;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        padding: 10px;
        z-index: 100;
        .item {
          padding: 10px;
          cursor: pointer;
          &:hover {
            background-color: #f5f5f5;
          }
        }
      }
    }
  }
  </style>