<template>
  <div class="AgricultureCooperatives">
    <super-admin-topbar :pageTitle="'Cooperatives'" />
    <div class="page-content">
      <div class="levels-table">
        <div class="table-header">
          <div class="left">
            <div class="search">
              <input
                type="text"
                placeholder="Search"
                @keyup="searchData()"
                v-model="search"
              />
            </div>
          </div>
          <div class="right"></div>
        </div>
        <div class="filter-form">
          <label for="">Filter: </label>
          <div class="form">
            <div class="form-group group-4">
              <!-- Province, Disctict, sector, cell -->
              <select
                name="province"
                id=""
                v-model="school.province"
                @change="getDistricts(school.province)"
              >
                <option value="null">{{ $txt("All Provinces") }}</option>
                <option
                  v-for="province in provinces"
                  :key="province.provinceId"
                  :value="province.provinceId"
                >
                  {{ province.provinceName }}
                </option>
              </select>
              <select
                name="district"
                id=""
                v-model="school.district"
                @change="getSectors(school.district)"
              >
                <option value="null">{{ $txt("All Districts") }}</option>
                <option
                  v-for="district in districts"
                  :key="district.districtId"
                  :value="district.districtId"
                >
                  {{ district.districtName }}
                </option>
              </select>
              <select
                name="sector"
                id=""
                v-model="school.sector"
                @change="getCells(school.sector)"
              >
                <option value="null">{{ $txt("All Sectors") }}</option>
                <option
                  v-for="sector in sectors"
                  :key="sector.sectorId"
                  :value="sector.sectorId"
                >
                  {{ sector.sectorName }}
                </option>
              </select>
              <select name="cell" id="" v-model="school.cell_id">
                <option value="null">{{ $txt("All Cells") }}</option>
                <option
                  v-for="cell in cells"
                  :key="cell.cellId"
                  :value="cell.cellId"
                >
                  {{ cell.cellName }}
                </option>
              </select>
            </div>
            <button class="submit" @click="getFilteredData()">Filter</button>
          </div>
        </div>
        <div class="item-per-page">
          <label for="">Items per page: </label>
          <select name="" id="" v-model="itemsPerPage" @change="changePage(1)">
            <option>5</option>
            <option>10</option>
            <option>25</option>
            <option>50</option>
            <option>100</option>
            <option>500</option>
          </select>
        </div>
        <table>
          <thead>
            <tr>
              <th>N<sup>o</sup></th>
              <th>Cooperative</th>
              <th>President</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Main Product</th>
              <th>Amount/year</th>
              <th>Members</th>
              <th>Location</th>
              <th>Verified</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(cooperative, index) in cooperatives"
              :key="cooperative.id"
            >
              <td>{{ index + 1 }}</td>
              <td>
                <span
                  class="View"
                  @click="ViewCooperative(cooperative, 'cooperative')"
                  >{{ cooperative.cooperative_names }}</span
                >
              </td>
              <td v-if="cooperative.president">{{ cooperative.president }}</td>
              <td v-else>Owner {{ cooperative.addedBy.names }}</td>
              <td>{{ cooperative.telephone }}</td>
              <td>{{ cooperative.email_address }}</td>
              <td>{{ cooperative.mainProduct.name }}</td>
              <td>{{ cooperative.production_size }}</td>
              <td>
                <span
                  class="View"
                  @click="ViewCooperative(cooperative, 'members')"
                  >{{ cooperative.members }}</span
                >
              </td>
              <td>{{ cooperative.district }}</td>
              <td>
                <span v-if="cooperative.status == 'Verified'" class="verified">
                  <i class="fa fa-check" aria-hidden="true"></i
                ></span>
                <span v-else class="unverified">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </span>
              </td>
              <td style="width: 200px">
                <button
                  class="verify"
                  v-if="cooperative.status == 'Not Verified'"
                  @click="verifyCooperative(cooperative, 'verify')"
                >
                  <label for="">Verify</label>
                </button>
                <button
                  class="unverify"
                  @click="verifyCooperative(cooperative, 'unverify')"
                  v-else
                >
                  <label for="">Unverify</label>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <pagination
          :totalItems="allCooperatives.length"
          :itemsPerPage="itemsPerPage"
          @pageChanged="
            (page) => {
              changePage(page);
            }
          "
        />
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "AgricultureCooperatives",
  data() {
    return {
      domains: [],
      allDomains: [],
      search: "",
      popupOpen: false,
      editPopupOpen: false,
      newDomain: {
        domainName: "",
      },
      selectedDomain: {},
      deletePopupOpen: false,
      school: {
        province: "null",
        district: "null",
        sector: "null",
        cell_id: "null",
      },
      provinces: [],
      districts: [],
      sectors: [],
      cells: [],
      itemsPerPage: 5,
      allCooperatives: [],
      cooperatives: [],
    };
  },
  methods: {
    searchData() {
      this.cooperatives = this.allCooperatives.filter((cooperative) => {
        return (
          cooperative.name.toLowerCase().includes(this.search.toLowerCase()) ||
          cooperative.president
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          cooperative.district
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          cooperative.phone.toLowerCase().includes(this.search.toLowerCase()) ||
          cooperative.mainProduct
            .toLowerCase()
            .includes(this.search.toLowerCase())
        );
      });
    },
    deleteDomain(domain) {
      this.selectedDomain = domain;
      this.deletePopupOpen = true;
    },
    confirmDelete() {
      this.$startLoader();
      this.$store
        .dispatch("DELETE_DOMAIN", {
          token: this.$getToken(),
          data: {
            domainId: this.selectedDomain.domainId,
          },
        })
        .then((res) => {
          if (res.data.status) {
            this.$notify({
              title: "Success",
              type: "success",
              text: "Domain deleted successfully",
            });
          }
          this.deletePopupOpen = false;
          this.getDomains();
        })
        .catch((err) => {
          console.log(err);
          this.$notify({
            title: "Error",
            type: "error",
            text: err.response.data.error,
          });
        });
    },
    confirmAdd() {
      this.$startLoader();
      this.$store
        .dispatch("ADD_DOMAIN", {
          token: this.$getToken(),
          data: this.newDomain,
        })
        .then((res) => {
          if (res.data.status) {
            this.$notify({
              title: "Success",
              type: "success",
              text: "Domain added successfully",
            });
          }
          this.popupOpen = false;
          this.getDomains();
        })
        .catch((err) => {
          console.log(err);
          this.$notify({
            title: "Error",
            type: "error",
            text: err.response.data.error,
          });
        });
    },
    confirmEdit() {
      this.$startLoader();
      this.$store
        .dispatch("EDIT_DOMAIN", {
          token: this.$getToken(),
          data: this.selectedDomain,
        })
        .then((res) => {
          if (res.data.status) {
            this.$notify({
              title: "Success",
              type: "success",
              text: "Domain edited successfully",
            });
          }
          this.editPopupOpen = false;
          this.getDomains();
        })
        .catch((err) => {
          console.log(err);
          this.$notify({
            title: "Error",
            type: "error",
            text: err.response.data.error,
          });
        });
    },
    getProvinces() {
      this.$startLoader();
      this.$store
        .dispatch("GET_PROVINCES", { token: this.$getToken() })
        .then((res) => {
          this.provinces = res.data.data.data;
          this.districts = [];
          this.sectors = [];
          this.cells = [];
          this.$stopLoader();
        });
    },
    getDistricts(provinceId) {
      if (provinceId == "" || provinceId == undefined || provinceId == null) {
        this.districts = [];
        this.sectors = [];
        this.cells = [];
        return;
      }
      this.$startLoader();
      this.$store
        .dispatch("GET_DISTRICTS", {
          token: this.$getToken(),
          provinceId: provinceId,
        })
        .then((res) => {
          this.districts = res.data.data.data;
          this.sectors = [];
          this.cells = [];
          this.$stopLoader();
        });
    },
    getSectors(districtId) {
      if (districtId == "" || districtId == undefined || districtId == null) {
        this.sectors = [];
        this.cells = [];
        return;
      }
      this.$startLoader();
      this.$store
        .dispatch("GET_SECTORS", {
          token: this.$getToken(),
          districtId: districtId,
        })
        .then((res) => {
          this.sectors = res.data.data.data;
          this.cells = [];
          this.$stopLoader();
        });
    },
    getCells(sectorId) {
      if (sectorId == "" || sectorId == undefined || sectorId == null) {
        this.cells = [];
        return;
      }
      this.$startLoader();
      this.$store
        .dispatch("GET_CELLS", { token: this.$getToken(), sectorId: sectorId })
        .then((res) => {
          this.cells = res.data.data.data;
          this.$stopLoader();
        });
    },
    getFilteredData() {
      if (
        this.school.province == "null" &&
        this.school.district == "null" &&
        this.school.sector == "null" &&
        this.school.cell_id == "null"
      ) {
        this.getSchools();
      } else {
        this.$startLoader();
        this.$store
          .dispatch("GET_FILTERED_SCHOOLS_BY_ADDRESS", {
            token: this.$getToken(),
            data: {
              provinceId: this.school.province,
              districtId: this.school.district,
              sectorId: this.school.sector,
              cellId: this.school.cell_id,
            },
          })
          .then((res) => {
            this.schools = res.data.data.data;
            this.allSchools = res.data.data.data;
            this.$stopLoader();
          })
          .catch((err) => {
            console.log(err);
            this.$notify({
              title: "Error",
              type: "error",
              text: err.response.data.error,
            });
            this.$stopLoader();
          });
      }
    },
    changePage(page) {
      this.currentPage = page;
      this.cooperatives = this.$changePage(
        this.allCooperatives,
        page,
        this.itemsPerPage
      );
    },
    getCooperatives() {
      this.$startLoader();
      this.$store
        .dispatch("GET_COOPERATIVES", { token: this.$getToken() })
        .then((res) => {
          this.allCooperatives = res.data.data;
          this.cooperatives = this.$changePage(
            this.allCooperatives,
            this.currentPage,
            this.itemsPerPage
          );
          this.$stopLoader();
        })
        .catch((err) => {
          this.$stopLoader();
          if (err.response.status == 401) {
            this.$notify({
              title: "Error",
              type: "error",
              text: err.response.data.message,
            });
            this.$router.push({ name: "Login" });
          } else {
            this.$notify({
              title: "Error",
              type: "error",
              text: err.response.data.message,
            });
          }
        });
    },
    ViewCooperative(cooperative, type) {
      if (type == "cooperative") {
        this.$router.push({
          name: "ViewCooperative",
          params: { id: cooperative.id },
        });
      } else {
        this.$router.push({
          name: "AdminCooperativeMembers",
          params: { id: cooperative.id },
        });
      }
    },
    verifyCooperative(cooperative, type) {
      this.$startLoader();
      this.$store
        .dispatch("VERIFY_COOPERATIVE", {
          token: this.$getToken(),
          data: {
            cooperative_id: cooperative.id,
          },
        })
        .then((res) => {
          this.$notify({
            title: "Success",
            type: "success",
            text:
              type == "verify"
                ? "Cooperative verified"
                : "Cooperative unverified",
          });
          this.getCooperatives();
        })
        .catch((err) => {
          console.log(err);
          this.$notify({
            title: "Error",
            type: "error",
            text: err.response.data.error,
          });
        });
    },
  },
  mounted() {
    this.getProvinces();
    this.changePage(1);
    this.getCooperatives();
  },
};
</script>
  
  <style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.AgricultureCooperatives {
  .levels-table {
    padding: 2rem 1rem;
    .table-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
      .left {
        display: flex;
        align-items: center;
        .search {
          input {
            padding: 0.5rem;
            border: 1px solid #ccc;
            border-radius: 5px;
            outline: none;
            width: 300px;
          }
        }
      }
      .right {
        button {
          padding: 0.65rem 1rem;
          width: 150px;
          border: none;
          outline: none;
          border-radius: 5px;
          background: $blue;
          color: #fff;
          cursor: pointer;
          font-weight: 600;
        }
      }
    }
    table {
      width: 100%;
      border-collapse: collapse;
      thead {
        tr {
          th {
            padding: 10px;
            border-bottom: 1px solid #ccc;
            text-align: left;
          }
        }
      }
    }
  }
  .addLevel {
    .form-group {
      margin-bottom: 1rem;
      label {
        display: block;
        margin-bottom: 0.5rem;
      }
      input {
        padding: 0.5rem;
        border: 1px solid #ccc;
        border-radius: 5px;
        outline: none;
        width: 100%;
      }
      button {
        padding: 0.65rem 1rem;
        width: 150px;
        border: none;
        outline: none;
        border-radius: 5px;
        background: $blue;
        color: #fff;
        cursor: pointer;
        font-weight: 600;
      }
    }
  }
}
</style>