<template>
  <div class="container mx-auto p-4">
    <div class="form-group group-3">
      <div class="input-group">
        <label for="">Sector</label>
        <select
          name=""
          id=""
          v-model="newData.product_sector_id"
          @change="getCategories(newData.product_sector_id)"
        >
          <option value="">Select</option>
          <option
            :value="sector.product_sector_id"
            v-for="sector in AgriSectors"
            :key="sector.product_sector_id"
          >
            {{ sector.product_sector_name }}
          </option>
        </select>
      </div>
      <div class="input-group">
        <label class="block mb-2">{{ $txt("Product category") }}</label>
        <select
          name=""
          id=""
          v-model="newData.category_id"
          @change="getProducts()"
        >
          <option value="">{{ $txt("Select category") }}</option>
          <option
            :value="category.category_id"
            :key="category.id"
            v-for="category in AgriCategories"
          >
            {{ category.category_name }}
          </option>
        </select>
      </div>
      <div class="input-group">
        <label class="block mb-2">{{ $txt("Product") }}</label>
        <select name="" id="" v-model="newData.product_id">
          <option value="">{{ $txt("Select product") }}</option>
          <option
            :value="product.product_id"
            :key="product.id"
            v-for="product in products"
          >
            {{ product.product_name }}
          </option>
        </select>
      </div>
    </div>
    <div class="form-group group-3">
      <div class="input-group">
        <label class="block mb-2"
          >{{ $txt("Quantity")
          }}<span>{{ $txt(" (Numbers only)") }}</span></label
        >
        <input
          type="number"
          name=""
          id=""
          placeholder="Quantity"
          v-model="newData.quantity"
        />
      </div>
      <div class="input-group">
        <label class="block mb-2">{{ $txt("Unit") }}</label>
        <select name="" id="" v-model="newData.unit_id">
          <option value="">{{ $txt("Select Unit") }}</option>
          <option
            :value="unit.unit_id"
            :key="unit.unit_id"
            v-for="unit in units"
          >
            {{ unit.unit_name }}
          </option>
        </select>
      </div>
      <div class="input-group">
        <label class="block mb-2">{{ $txt("Price") }}</label>
        <input
          type="text"
          name=""
          id=""
          placeholder="2000 Rwf/Kg"
          v-model="newData.price"
        />
      </div>
    </div>
    <div class="form-group group-4">
      <div class="input-group">
        <label class="block mb-2">{{ $txt("Province") }}</label>
        <select
          name="province"
          id=""
          v-model="newData.provinceId"
          @change="getDistricts(newData.provinceId)"
          class="w-full md:w-1/4 p-2"
        >
          <option value="">{{ $txt("Province") }}</option>
          <option
            v-for="province in provinces"
            :key="province.provinceId"
            :value="province.provinceId"
          >
            {{ province.provinceName }}
          </option>
        </select>
      </div>
      <div class="input-group">
        <label class="block mb-2">{{ $txt("District") }}</label>
        <select
          name="district"
          id=""
          v-model="newData.districtId"
          @change="getSectors(newData.districtId)"
          class="w-full md:w-1/4 p-2"
        >
          <option value="">{{ $txt("District") }}</option>
          <option
            v-for="district in districts"
            :key="district.districtId"
            :value="district.districtId"
          >
            {{ district.districtName }}
          </option>
        </select>
      </div>
      <div class="input-group">
        <label class="block mb-2">{{ $txt("Sector") }}</label>
        <select
          name="sector"
          id=""
          v-model="newData.sectorId"
          @change="getCells(newData.sectorId)"
          class="w-full md:w-1/4 p-2"
        >
          <option value="">{{ $txt("Sector") }}</option>
          <option
            v-for="sector in sectors"
            :key="sector.sectorId"
            :value="sector.sectorId"
          >
            {{ sector.sectorName }}
          </option>
        </select>
      </div>
      <div class="input-group">
        <label class="block mb-2">{{ $txt("Cell") }}</label>
        <select
          name="cell"
          id=""
          v-model="newData.cellId"
          class="w-full md:w-1/4 p-2"
        >
          <option value="">{{ $txt("Cell") }}</option>
          <option v-for="cell in cells" :key="cell.cellId" :value="cell.cellId">
            {{ cell.cellName }}
          </option>
        </select>
      </div>
    </div>
    <div class="form-group group-3">
      <div class="input-group">
        <label class="block mb-2">{{ $txt("Contact Name") }}</label>
        <input
          type="text"
          name=""
          id=""
          placeholder="Contact Name"
          v-model="newData.contact_name"
        />
      </div>
      <div class="input-group">
        <label class="block mb-2">{{ $txt("Contact Phone") }}</label>
        <input
          type="text"
          name=""
          id=""
          placeholder="Contact Phone"
          v-model="newData.contact_phone"
        />
      </div>
      <div class="input-group">
        <label class="block mb-2">{{ $txt("Contact Email") }}</label>
        <input
          type="text"
          name=""
          id=""
          placeholder="Contact Email"
          v-model="newData.contact_email"
        />
      </div>
    </div>
    <div class="form-group group-1">
      <div class="input-group">
        <label>Images</label>
        <o-upload
          :multiple="true"
          :uploadUrl="'files/upload'"
          @imagesUploaded="
            (data) => {
              newData.images = data;
            }
          "
          @imagesUploadedFailed="
            (data) => {
              console.log('failed');
            }
          "
          :value="newData.images"
          @startLoader="() => $startLoader()"
          @stopLoader="() => $stopLoader()"
        />
      </div>
    </div>
    <div class="form-group group-1">
      <div class="input-group">
        <label>{{ $txt("Description") }}</label>
        <textarea
          name=""
          id=""
          rows="5"
          v-model="newData.description"
          :placeholder="$txt(`Any message for your clients`)"
        ></textarea>
      </div>
    </div>
  </div>
  <button @click.prevent="saveItem()">{{ $txt("Save Production") }}</button>
</template>
  
  <script>
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
export default {
  props: {
    newData: {
      type: Object,
    },
  },
  data() {
    return {
      units: [],
      products: [],
      categories: [],
      provinces: [],
      districts: [],
      sectors: [],
      cells: [],
      AgriSectors: [],
      AgriCategories: [],
    };
  },
  methods: {
    closeModel() {
      document.body.style.overflowY = "auto";
      this.$emit("closePopup");
    },
    getUnits() {
      this.$startLoader();
      this.$store
        .dispatch("GET_AGRIUNITS", { token: this.$getToken() })
        .then((res) => {
          this.units = res.data.data;
          this.$stopLoader();
        })
        .catch((err) => {
          this.$stopLoader();
        });
    },
    getProducts() {
      this.$startLoader();
      this.$store
        .dispatch("GET_AGRIPRODUCTSBYCATEGORY", {
          token: this.$getToken(),
          data: { category_id: this.newData.category_id },
        })
        .then((res) => {
          this.products = res.data.data;
          this.$stopLoader();
        })
        .catch((err) => {
          this.$stopLoader();
        });
    },
    getProvinces() {
      this.$startLoader();
      this.$store
        .dispatch("GET_PROVINCES", { token: this.$getToken() })
        .then((res) => {
          this.$stopLoader();
          this.provinces = res.data.data.data;
        });
    },
    getDistricts(provinceId) {
      this.$startLoader();
      this.$store
        .dispatch("GET_DISTRICTS", {
          token: this.$getToken(),
          provinceId: provinceId,
        })
        .then((res) => {
          this.$stopLoader();
          (this.districts = res.data.data.data);
        });
    },
    getSectors(districtId) {
      this.$startLoader();
      this.$store
        .dispatch("GET_SECTORS", {
          token: this.$getToken(),
          districtId: districtId,
        })
        .then((res) => {
          this.$stopLoader();
          this.sectors = res.data.data.data;
        });
    },
    getCells(sectorId) {
      this.$startLoader();
      this.$store
        .dispatch("GET_CELLS", {
          token: this.$getToken(),
          sectorId: sectorId,
        })
        .then((res) => {
          this.$stopLoader();
          this.cells = res.data.data.data;
        });
    },

    getAgriSectors() {
      this.$startLoader();
      this.$store
        .dispatch("GET_AGRISECTORS", { token: this.$getToken() })
        .then((res) => {
          this.AgriSectors = res.data.data;
          this.$stopLoader();
        })
        .catch((err) => {
          this.$stopLoader();
        });
    },
    getCategories(product_sector_id) {
      this.$startLoader();
      this.$store
        .dispatch("GET_AGRICATEGORIES_BYSECTOR", {
          token: this.$getToken(),
          data: { product_sector_id: product_sector_id },
        })
        .then((res) => {
          this.AgriCategories = res.data.data;
          this.$stopLoader();
        })
        .catch((err) => {
          this.$stopLoader();
        });
    },
    saveItem() {
      this.$startLoader();
      this.newData.images = JSON.stringify(this.newData.images);
      this.$store
        .dispatch("EDIT_AGRIPRODUCTION", {
          token: this.$getToken(),
          data: this.newData,
        })
        .then((res) => {
          if (res.data.status) {
            this.$notify({
              title: "Success",
              type: "success",
              text: res.data.message,
            });
          }
          this.$stopLoader();
          this.closeModel();
        })
        .catch((err) => {
          console.log(err);
          this.$notify({
            title: "Error",
            type: "error",
            text: "Something went wrong",
          });
          this.$stopLoader();
        });
    },
  },
  mounted() {
    this.getProvinces();
    this.getDistricts(this.newData.provinceId);
    this.getSectors(this.newData.districtId);
    this.getCells(this.newData.sectorId);
    this.getUnits();
    this.getAgriSectors();
    this.getCategories(this.newData.product_sector_id);
    this.getProducts();
  },
};
</script>
  
  <style lang="scss" scoped>
button {
  border: none;
  background-color: #1d3557;
  margin-left: 1rem;
  border-radius: 5px;
  padding: 15px 10px;
  color: #fff;
  display: block;
  width: 300px;
}
</style>
  