<template>
  <site-navbar/>
  <section class="relative py-20 bg-white">
    <div
      class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
      style="height: 80px; "
    >
      <svg
        class="absolute bottom-0 overflow-hidden"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        version="1.1"
        viewBox="0 0 2560 100"
        x="0"
        y="0"
      >
        <polygon class="text-white fill-current" points="2560 0 2560 100 0 100"></polygon>
      </svg>
    </div>
    <div class="container mx-auto px-4">
      <div class="items-center flex flex-wrap">
        <div class="w-full md:w-4/12 ml-auto mr-auto px-4">
          <img
            alt="..."
            class="max-w-full rounded-lg shadow-lg"
            src="https://images.unsplash.com/photo-1555212697-194d092e3b8f?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=634&amp;q=80"
          >
        </div>
        <div class="w-full md:w-5/12 ml-auto mr-auto px-4">
          <div class="md:pr-12 mt-6 md:mt-0">
            <div
              class="text-blue-900 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white-300"
            >
              <i class="fas fa-rocket text-xl"></i>
            </div>
            <h3 class="text-3xl font-semibold">All Information page</h3>
            <p
              class="mt-4 text-lg leading-relaxed text-gray-600"
            >Lorem ipsum dolor sit, amet consectetur adipisicing elit. Molestias ex, corrupti porro tempore quidem voluptates 
            ut placeat rem, eum ipsam aliquid nulla pariatur earum labore similique. Rerum exercitationem ducimus voluptatibus..</p>
            <ul class="list-none mt-6">
              <li class="py-2">
                <div class="flex items-center">
                  <div>
                    <span
                      class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-900 bg-white-200 mr-3"
                    >
                      <i class="fas fa-star"></i>
                    </span>
                  </div>
                  <div>
                    <h4 class="text-gray-600">Mission statement</h4>
                  </div>
                </div>
              </li>
              <li class="py-2">
                <div class="flex items-center">
                  <div>
                    <span
                      class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-900 bg-white-200 mr-3"
                    >
                      <i class="fa fa-plus"></i>
                    </span>
                  </div>
                  <div>
                    <h4 class="text-gray-600">Meet Over 500 cooperatives</h4>
                  </div>
                </div>
              </li>
              <li class="py-2">
                <div class="flex items-center">
                  <div>
                    <span
                      class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-900 bg-white-200 mr-3"
                    >
                      <i class="fa fa-cow"></i>
                    </span>
                  </div>
                  <div>
                    <h4 class="text-gray-600">Famu za kijyambere</h4>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {};
</script>

