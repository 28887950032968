<template>
  <div class="Transport">
    <site-navbar />
    <div class="search-section">
      <form action="">
        <label>
          {{ $txt("Transport") }}
        </label>
        <p for="">{{ $txt("Transport your goods and materials.") }}</p>
      </form>
    </div>
    <div class="whole-content">
      <div class="Transport-section">
        <model
          v-if="showModelFilters"
          @closeModel="showModelFilters = false"
          :modelTitle="'Filters'"
        >
          <div class="model-left left">
            <div class="filters">
              <div class="title">
                <h4>Filter By</h4>
              </div>
              <div class="filter-item">
                <div class="header" @click="showType = !showType">
                  <label for="">Car body</label>
                  <i class="fa fa-angle-down"></i>
                </div>
                <div class="content" v-if="showType">
                  <span class="help" @click="viewCarsBodies = true">{{
                    $txt(
                      "Click here if you want to get help choosing car bodies"
                    )
                  }}</span>
                  <ul>
                    <li><input type="checkbox" name="" id="" />Micro</li>
                    <li><input type="checkbox" name="" id="" />Sedan</li>
                    <li><input type="checkbox" name="" id="" />Hatchback</li>
                    <li><input type="checkbox" name="" id="" />Universal</li>
                    <li><input type="checkbox" name="" id="" />Coupe</li>
                    <li><input type="checkbox" name="" id="" />Cabriolet</li>
                    <li><input type="checkbox" name="" id="" />Roadster</li>
                    <li><input type="checkbox" name="" id="" />Targa</li>
                    <li><input type="checkbox" name="" id="" />Limousine</li>
                    <li><input type="checkbox" name="" id="" />Muscle car</li>
                    <li><input type="checkbox" name="" id="" />Sport car</li>
                    <li><input type="checkbox" name="" id="" />Super car</li>
                    <li><input type="checkbox" name="" id="" />SUV</li>
                    <li><input type="checkbox" name="" id="" />Crossover</li>
                    <li><input type="checkbox" name="" id="" />Pickup</li>
                    <li><input type="checkbox" name="" id="" />Van</li>
                    <li><input type="checkbox" name="" id="" />Minivan</li>
                    <li><input type="checkbox" name="" id="" />Minibus</li>
                    <li><input type="checkbox" name="" id="" />Campervan</li>
                    <li><input type="checkbox" name="" id="" />Bus</li>
                    <li><input type="checkbox" name="" id="" />Tow truck</li>
                    <li><input type="checkbox" name="" id="" />Mini truck</li>
                    <li><input type="checkbox" name="" id="" />Big truck</li>
                    <li><input type="checkbox" name="" id="" />Dump truck</li>
                  </ul>
                </div>
              </div>
              <div class="filter-item">
                <div class="header" @click="showLocation = !showLocation">
                  <label for="">From</label>
                  <i class="fa fa-angle-down"></i>
                </div>
                <div class="content" v-if="showLocation">
                  <form action="">
                    <div class="form-group group-2">
                      <select
                        name="province"
                        id=""
                        v-model="filterData.province"
                        @change="getDistricts(filterData.province)"
                      >
                        <option value="All">
                          {{ $txt("All Provinces") }}
                        </option>
                        <option
                          v-for="province in provinces"
                          :key="province.provinceId"
                          :value="province.provinceId"
                        >
                          {{ province.provinceName }}
                        </option>
                      </select>
                      <select
                        name="district"
                        id=""
                        v-model="filterData.district"
                      >
                        <option value="All">
                          {{ $txt("All Districts") }}
                        </option>
                        <option
                          v-for="district in districts"
                          :key="district.districtId"
                          :value="district.districtId"
                        >
                          {{ district.districtName }}
                        </option>
                      </select>
                    </div>
                  </form>
                </div>
              </div>
              <div class="filter-item">
                <div class="header" @click="showDestination = !showDestination">
                  <label for="">To</label>
                  <i class="fa fa-angle-down"></i>
                </div>
                <div class="content" v-if="showDestination">
                  <form action="">
                    <div class="form-group group-2">
                      <select
                        name="province"
                        id=""
                        v-model="filterData.destination_province"
                        @change="
                          getDestinationDistricts(
                            filterData.destination_province
                          )
                        "
                      >
                        <option value="All">
                          {{ $txt("All Provinces") }}
                        </option>
                        <option
                          v-for="province in destination_provinces"
                          :key="province.provinceId"
                          :value="province.provinceId"
                        >
                          {{ province.provinceName }}
                        </option>
                      </select>
                      <select
                        name="district"
                        id=""
                        v-model="filterData.destination_district"
                      >
                        <option value="All">
                          {{ $txt("All Districts") }}
                        </option>
                        <option
                          v-for="district in destination_districts"
                          :key="district.districtId"
                          :value="district.districtId"
                        >
                          {{ district.districtName }}
                        </option>
                      </select>
                    </div>
                  </form>
                </div>
              </div>
              <div class="filter-item">
                <div class="header" @click="showCategory = !showCategory">
                  <label for="">Date - Time</label>
                  <i class="fa fa-angle-down"></i>
                </div>
                <div class="content" v-if="showCategory">
                  <VueDatePicker
                    v-model="filterData.date"
                    range
                    multi-calendars
                  ></VueDatePicker>
                </div>
              </div>
              <div class="apply-filter">
                <button type="button" @click="startSearch()" class="clear">
                  {{ $txt("Clear filter") }}
                </button>
                <button type="button" @click="startSearch()">
                  {{ $txt("Apply filter") }}
                </button>
              </div>
            </div>
          </div>
        </model>
        <div class="left">
          <div class="filters">
            <div class="title">
              <h4>Filter By</h4>
            </div>
            <div class="filter-item">
              <div class="header" @click="showType = !showType">
                <label for="">Car body</label>
                <i class="fa fa-angle-down"></i>
              </div>
              <div class="content" v-if="showType">
                <span class="help" @click="viewCarsBodies = true">{{
                  $txt("Click here if you want to get help choosing car bodies")
                }}</span>
                <select
                  v-model="filterData.car_body"
                  @change="filterNow('body')"
                >
                  <option value="" selected>All</option>
                  <option v-for="body in carBodies" :key="body.id">
                    {{ body.car_body_name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="filter-form" v-if="filterData.car_type == 'For Rent'">
              <label for="">Filter By location: </label>
              <div class="form">
                <div class="form-group group-4">
                  <!-- Province, Disctict, sector, cell -->
                  <select
                    name="province"
                    id=""
                    v-model="locations.province"
                    @change="
                      getLocationDistricts(locations.province);
                      filterNow();
                    "
                  >
                    <option value="">{{ $txt("All Provinces") }}</option>
                    <option
                      v-for="province in locationprovinces"
                      :key="province.provinceId"
                      :value="province.provinceId"
                    >
                      {{ province.provinceName }}
                    </option>
                  </select>
                  <select
                    name="district"
                    id=""
                    v-model="locations.district"
                    @change="
                      getLocationSectors(locations.district);
                      filterNow();
                    "
                  >
                    <option value="">{{ $txt("All Districts") }}</option>
                    <option
                      v-for="district in locationdistricts"
                      :key="district.districtId"
                      :value="district.districtId"
                    >
                      {{ district.districtName }}
                    </option>
                  </select>
                  <select
                    name="sector"
                    id=""
                    v-model="locations.sector"
                    @change="
                      getLocationCells(locations.sector);
                      filterNow();
                    "
                  >
                    <option value="">{{ $txt("All Sectors") }}</option>
                    <option
                      v-for="sector in locationsectors"
                      :key="sector.sectorId"
                      :value="sector.sectorId"
                    >
                      {{ sector.sectorName }}
                    </option>
                  </select>
                  <select
                    name="cell"
                    id=""
                    v-model="locations.cell"
                    @change="filterNow()"
                  >
                    <option value="">{{ $txt("All Cells") }}</option>
                    <option
                      v-for="cell in locationcells"
                      :key="cell.cellId"
                      :value="cell.cellId"
                    >
                      {{ cell.cellName }}
                    </option>
                  </select>
                </div>
                <!-- <button class="submit" @click="getFilteredData()">Filter</button> -->
              </div>
            </div>
            <!-- <div class="filter-item">
              <div class="header" @click="showCarType = !showCarType">
                <label for="">Movement status</label>
                <i class="fa fa-angle-down"></i>
              </div>
              <div class="content" v-if="showCarType">
                <select
                  v-model="filterData.car_type"
                  @change="filterNow('type')"
                >
                  <option value="" selected>
                    {{ $txt("All") }}
                  </option>
                  <option value="Moving">{{ $txt("Moving") }}</option>
                  <option value="For Rent">{{ $txt("For Rent") }}</option>
                </select>
              </div>
            </div> -->
            <div class="filter-item" v-if="filterData.car_type != 'For Rent'">
              <div class="header" @click="showLocation = !showLocation">
                <label for="">From</label>
                <i class="fa fa-angle-down"></i>
              </div>
              <div class="content" v-if="showLocation">
                <form action="">
                  <div class="form-group group-2">
                    <select
                      name="province"
                      id=""
                      v-model="filterData.province"
                      @change="getDistricts(filterData.province)"
                    >
                      <option value="All">{{ $txt("All Provinces") }}</option>
                      <option
                        v-for="province in provinces"
                        :key="province.provinceId"
                        :value="province.provinceId"
                      >
                        {{ province.provinceName }}
                      </option>
                    </select>
                    <select name="district" id="" v-model="filterData.district">
                      <option value="All">{{ $txt("All Districts") }}</option>
                      <option
                        v-for="district in districts"
                        :key="district.districtId"
                        :value="district.districtId"
                      >
                        {{ district.districtName }}
                      </option>
                    </select>
                  </div>
                </form>
              </div>
            </div>
            <div class="filter-item">
              <div
                class="header"
                @click="showDestination = !showDestination"
                v-if="filterData.car_type != 'For Rent'"
              >
                <label for="">To</label>
                <i class="fa fa-angle-down"></i>
              </div>
              <div class="content" v-if="showDestination">
                <form action="">
                  <div class="form-group group-2">
                    <select
                      name="province"
                      id=""
                      v-model="filterData.destination_province"
                      @change="
                        getDestinationDistricts(filterData.destination_province)
                      "
                    >
                      <option value="All">{{ $txt("All Provinces") }}</option>
                      <option
                        v-for="province in destination_provinces"
                        :key="province.provinceId"
                        :value="province.provinceId"
                      >
                        {{ province.provinceName }}
                      </option>
                    </select>
                    <select
                      name="district"
                      id=""
                      v-model="filterData.destination_district"
                    >
                      <option value="All">{{ $txt("All Districts") }}</option>
                      <option
                        v-for="district in destination_districts"
                        :key="district.districtId"
                        :value="district.districtId"
                      >
                        {{ district.districtName }}
                      </option>
                    </select>
                  </div>
                </form>
              </div>
            </div>
            <!-- <div class="filter-item" v-if="filterData.car_type != 'For Rent'">
              <div class="header" @click="showCategory = !showCategory">
                <label for="">Date - Time</label>
                <i class="fa fa-angle-down"></i>
              </div>
              <div class="content" v-if="showCategory">
                <VueDatePicker
                  v-model="filterData.date"
                  range
                  multi-calendars
                ></VueDatePicker>
              </div>
            </div> -->
          </div>
        </div>
        <div class="right">
          <div class="results-section">
            <div class="results-header">
              <form action="" class="search" @submit.prevent="filterNow()">
                <input
                  type="text"
                  name=""
                  id=""
                  :placeholder="$txt('Search')"
                  v-model="searchQuery"
                  @keyup="filterNow()"
                />
                <!-- <button type="submit">
                  <i class="fa fa-search"></i>
                </button> -->
                <!-- <i class="fa fa-times clear"></i> -->
                <div class="filter-btn">
                  <button type="button" @click="showModelFilters = true">
                    <i class="fa fa-filter"></i>
                    <span>{{ $txt("Filter") }}</span>
                  </button>
                </div>
              </form>
              <p>
                Showing <span>{{ trucks.length }}</span> results from
                {{ allTrucks.length }} users
              </p>
            </div>
            <div class="truck-items">
              <div class="truck-item" v-for="truck in trucks" :key="truck.id">
                <div
                  class="item-top"
                  @click="
                    viewTruck = true;
                    selectedItem = truck;
                  "
                >
                  <div class="item-top-top">
                    <div class="top-top-right">
                      <div class="image">
                        <img
                          :src="$file(truck.vehicle_images[0])"
                          alt=""
                          @error="$imageurlalt()"
                        />
                        <p
                          class="location trans"
                          v-if="truck.is_for_transport == '1'"
                        >
                          {{ $txt("Moving") }}
                        </p>
                        <p class="location trans per" v-else>
                          {{ $txt("For Rent") }}
                        </p>
                      </div>
                    </div>
                    <div class="top-top-left">
                      <label for=""
                        >{{ truck.vehicle_brand }}
                        {{ truck.car_body.car_body_name }}</label
                      >
                      <p class="location">
                        <i class="fa fa-phone"></i>
                        <a :href="'tel:' + truck.vehicle_driver_phone"></a>
                        {{ truck.vehicle_driver_phone }}
                      </p>
                      <p class="location">
                        <i class="fa fa-map-marker"></i>
                        {{ truck.district_name }}, {{ truck.sector_name }}
                      </p>
                      <p class="location" v-if="truck.departure_date">
                        <i class="fa fa-calendar"></i>
                        {{ truck.departure_date }},
                        {{ $to12(truck.departure_time) }}
                      </p>
                      <p class="location" v-else>
                        <i class="fa fa-calendar"></i>
                        Anytime
                      </p>
                    </div>
                  </div>
                  <div class="item-top-bottom">
                    <p>"{{ truck.Message }}"</p>
                  </div>
                </div>
                <div class="item-bottom">
                  <a
                    :href="'mailto:' + truck.user.email_address"
                    target="_blank"
                  >
                    <i class="fa fa-envelope"></i>
                    <p>Email</p>
                  </a>
                  <a :href="'tel:' + truck.user.telephone" target="_blank">
                    <i class="fa fa-phone"></i>
                    <p>Call</p>
                  </a>
                </div>
              </div>
            </div>
            <pagination
              class="pagination"
              :totalItems="allTrucks.length"
              :itemsPerPage="itemsPerPage"
              @pageChanged="
                (page) => {
                  changePage(page);
                }
              "
            />
          </div>
        </div>
      </div>
      <div class="header-widget">
        <div class="header-sections">
          <div
            class="header-section"
            :class="selectedSection == 1 ? 'active' : ''"
            @mouseover="selectedSection = 1"
          >
            <img src="/assets/images/tea.jpg" alt="" @error="$imageurlalt()" />
            <label for="" v-show="selectedSection == 1"
              ><span>
                {{
                  $txt(
                    "Find the best coach or school for yourself or your kids"
                  )
                }}</span
              >
              <div class="explore" v-show="selectedSection == 1">
                <button>{{ $txt("Explore") }}</button>
              </div>
            </label>
          </div>
          <div
            class="header-section"
            :class="selectedSection == 2 ? 'active' : ''"
            @mouseover="selectedSection = 2"
          >
            <img
              src="/assets/images/cover/tech.jpg"
              alt=""
              @error="$imageurlalt()"
            />
            <label for="" v-show="selectedSection == 2"
              ><span>{{ $txt("Hire a proffesional and find jobs.") }}"</span>
              <div class="explore" v-show="selectedSection == 2">
                <button>{{ $txt("Explore") }}</button>
              </div>
            </label>
          </div>
          <div
            class="header-section"
            :class="selectedSection == 3 ? 'active' : ''"
            @mouseover="selectedSection = 3"
          >
            <img
              src="/assets/images/cover/edu.jpg"
              alt=""
              @error="$imageurlalt()"
            />
            <label for="" v-show="selectedSection == 3">
              <span>{{
                $txt("Browse and know more about the best schools in Rwanda")
              }}</span>
              <div class="explore" v-show="selectedSection == 3">
                <button>{{ $txt("Explore") }}</button>
              </div>
            </label>
          </div>
          <div
            class="header-section"
            :class="selectedSection == 4 ? 'active' : ''"
            @mouseover="selectedSection = 4"
          >
            <img
              src="/assets/images/cover/rnit.jpg"
              alt=""
              @error="$imageurlalt()"
            />
            <label for="" v-show="selectedSection == 4">
              <span
                >{{ $txt("Boost your business and brand awareness") }}"</span
              >
              <div class="explore" v-show="selectedSection == 4">
                <button>{{ $txt("Explore") }}</button>
              </div>
            </label>
          </div>
          <div
            class="header-section"
            :class="selectedSection == 5 ? 'active' : ''"
            @mouseover="selectedSection = 5"
          >
            <img
              src="/assets/images/cover/trucks.jpg"
              alt=""
              @error="$imageurlalt()"
            />
            <label for="" v-show="selectedSection == 5"
              ><span>{{
                $txt(
                  "Transport your goods and materiels, Find transport clients for your vehicles"
                )
              }}</span>
              <div class="explore" v-show="selectedSection == 5">
                <button>{{ $txt("Explore") }}</button>
              </div>
            </label>
          </div>
          <div
            class="header-section"
            :class="selectedSection == 6 ? 'active' : ''"
            @mouseover="selectedSection = 6"
          >
            <img
              src="/assets/images/burger.jpg"
              alt=""
              @error="$imageurlalt()"
            />
            <label for="" v-show="selectedSection == 6"
              ><span>{{
                $txt(
                  "Sell or buy products and services to new customers and boost your sales"
                )
              }}</span>
              <div class="explore" v-show="selectedSection == 6">
                <button>{{ $txt("Explore") }}</button>
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>
    <left-view
      v-if="viewTruck"
      @closePopup="viewTruck = false"
      :modelTitle="'View Vehicle'"
    >
      <div class="dialog-body">
        <div class="dialog-body-top">
          <div class="image">
            <carousel :items-to-show="1" :wrapAround="true" :autoplay="3000">
              <slide v-for="image in selectedItem.vehicle_images" :key="image">
                <img :src="$file(image)" alt="" @error="$imageurlalt()" />
              </slide>
              <template #addons>
                <navigation />
              </template>
            </carousel>
          </div>
        </div>
        <div class="dialog-body-bottom">
          <div class="item-top">
            <div class="item-top-top">
              <label for=""
                >{{ selectedItem.vehicle_brand }}
                {{ selectedItem.car_body.car_body_name }}</label
              >
              <p class="location">
                <i class="fa fa-map-marker"></i>
                {{ selectedItem.district_name }},
                {{ selectedItem.sector_name }}
              </p>
              <p class="location" v-if="selectedItem.departure_date">
                <i class="fa fa-calendar"></i>
                {{ selectedItem.departure_date }},
                {{ $to12(selectedItem.departure_time) }}
              </p>
              <p class="location" v-else>
                <i class="fa fa-calendar"></i>
                Available anytime
              </p>
              <p
                class="location trans"
                v-if="selectedItem.is_for_transport == '1'"
              >
                {{ $txt("Moving") }}
              </p>
              <p class="location trans" v-else>
                {{ $txt("For Rent") }}
              </p>
            </div>
            <div class="item-top-bottom">
              <p>"{{ selectedItem.Message }}"</p>
            </div>
          </div>
          <div class="owner">
            <div class="owner-details">
              <div class="title">
                <h4>{{ $txt("Driver") }}</h4>
              </div>
              <div class="name">
                <label for="">{{ selectedItem.vehicle_driver }}</label>
              </div>
              <div class="email">
                <p>
                  <a :href="'tel:' + selectedItem.vehicle_driver_phone"></a
                  >{{ selectedItem.vehicle_driver_phone }}
                </p>
              </div>
            </div>
          </div>
          <div class="owner">
            <div class="owner-details">
              <div class="title">
                <h4>{{ $txt("Owner") }}</h4>
              </div>
              <div class="name">
                <label for="">{{ selectedItem.user.full_names }}</label>
              </div>
              <div class="email">
                <p>
                  <a :href="'tel:' + selectedItem.user.telephone"></a
                  >{{ selectedItem.user.telephone }}
                </p>
                <a
                  :href="'mailto:' + selectedItem.user.email_address"
                  target="_blank"
                  >{{ selectedItem.user.email_address }}</a
                >
              </div>
            </div>
          </div>
          <div class="item-bottom">
            <a
              :href="'mailto:' + selectedItem.user.email_address"
              target="_blank"
            >
              <i class="fa fa-envelope"></i>
              <p>Email</p>
            </a>
            <a :href="'tel:' + selectedItem.user.telephone" target="_blank">
              <i class="fa fa-phone"></i>
              <p>Call</p>
            </a>
          </div>
        </div>
      </div>
    </left-view>
    <model
      v-if="viewCarsBodies"
      @closeModel="viewCarsBodies = false"
      :modelTitle="'Car bodies'"
    >
      <img src="/assets/images/trucks.png" alt="" />
    </model>
    <site-footer />
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
export default {
  name: "Transport",
  components: {},
  data() {
    return {
      allTrucks: [],
      trucks: [],
      ismodules: [],
      modules: [],
      selectedSection: 1,
      searchQuery: "",
      viewProduct: false,
      selectedItem: {},
      showType: true,
      showCarType: false,
      showLocation: false,
      showDestination: false,
      showCategory: false,
      showAvailability: false,
      currentPage: 1,
      itemsPerPage: 15,
      viewTruck: false,
      viewCarsBodies: false,
      filterData: {
        province: "All",
        district: "All",
        sector: "All",
        cell_id: "All",
        destination_province: "All",
        destination_district: "All",
        date: [],
        car_body: "",
        car_type: "",
      },
      provinces: [],
      districts: [],
      sectors: [],
      cells: [],
      destination_provinces: [],
      destination_districts: [],
      showModelFilters: false,
      carBodies: [],

      locations: {
        province: "",
        district: "",
        sector: "",
        cell: "",
      },
      locationprovinces: [],
      locationdistricts: [],
      locationsectors: [],
      locationcells: [],
    };
  },
  methods: {
    changePage(page) {
      this.currentPage = page;
      this.trucks = this.$changePage(this.allTrucks, page, this.itemsPerPage);
      window.scrollTo(0, 0);
    },
    filterNow(type) {
      let filter_results = [];

      if (this.filterData.car_body != "") {
        filter_results = this.allTrucks.filter((truck) => {
          return truck.car_body.car_body_name
            .toLowerCase()
            .includes(this.filterData.car_body.toLowerCase());
        });
      } else {
        filter_results = this.allTrucks;
      }

      let filter_results2 = filter_results;
      if (this.filterData.car_type == "Moving") {
        filter_results2 = filter_results.filter((truck) => {
          return truck.is_for_transport == "1";
        });
      } else if (this.filterData.car_type == "For Rent") {
        filter_results2 = filter_results.filter((truck) => {
          return truck.is_for_transport != "1";
        });
      } else {
        filter_results2 = filter_results;
        this.changePage(1);
      }

      let filter_results3 = [];
      console.log(filter_results2);
      console.log(this.searchQuery);

      if (this.searchQuery && this.searchQuery != "") {
        filter_results3 = filter_results2.filter((truck) => {
          return (
            truck.vehicle_brand
              .toLowerCase()
              .includes(this.searchQuery.toLowerCase()) ||
            truck.car_body.car_body_name
              .toLowerCase()
              .includes(this.searchQuery.toLowerCase()) ||
            truck.district_name
              .toLowerCase()
              .includes(this.searchQuery.toLowerCase()) ||
            truck.sector_name
              .toLowerCase()
              .includes(this.searchQuery.toLowerCase()) ||
            truck.Message.toLowerCase().includes(this.searchQuery.toLowerCase())
          );
        });
      } else {
        filter_results3 = filter_results2;
      }

      let filter_results4 = this.searchByLocation(filter_results3);

      this.trucks = filter_results4;
    },

    searchByLocation(searchResults1) {
      let searchResults2 = [];
      if (this.locations.province == "") {
        this.locations.district = "";
        this.locations.sector = "";
        this.locations.cell = "";
      }
      if (this.locations.district == "") {
        this.locations.sector = "";
        this.locations.cell = "";
      }
      if (this.locations.sector == "") {
        this.locations.cell = "";
      }
      if (this.locations.cell && this.locations.cell != "") {
        searchResults2 = searchResults1.filter((product) => {
          return parseInt(product.cell) == this.locations.cell;
        });
      } else {
        if (this.locations.sector && this.locations.sector != "") {
          searchResults2 = searchResults1.filter((product) => {
            return parseInt(product.sector) == this.locations.sector;
          });
        } else {
          if (this.locations.district && this.locations.district != "") {
            searchResults2 = searchResults1.filter((product) => {
              return parseInt(product.district) == this.locations.district;
            });
          } else {
            if (this.locations.province && this.locations.province != "") {
              console.log(this.locations.province);
              searchResults2 = searchResults1.filter((product) => {
                return parseInt(product.province) == this.locations.province;
              });
            } else {
              searchResults2 = searchResults1;
            }
          }
        }
      }
      return searchResults2;
    },
    getProvinces() {
      this.$startLoader();
      this.$store
        .dispatch("GET_PROVINCES", { token: this.$getToken() })
        .then((res) => {
          this.provinces = res.data.data.data;
          this.destination_provinces = res.data.data.data;
          this.districts = [];
          this.sectors = [];
          this.cells = [];
          this.$stopLoader();
        });
    },
    getDistricts(provinceId) {
      if (provinceId == "" || provinceId == undefined || provinceId == null) {
        this.districts = [];
        this.sectors = [];
        this.cells = [];
        return;
      }
      this.$startLoader();
      this.$store
        .dispatch("GET_DISTRICTS", {
          token: this.$getToken(),
          provinceId: provinceId,
        })
        .then((res) => {
          this.districts = res.data.data.data;
          this.sectors = [];
          this.cells = [];
          this.$stopLoader();
        });
    },
    getDestinationDistricts(provinceId) {
      if (provinceId == "" || provinceId == undefined || provinceId == null) {
        this.destination_districts = [];
        return;
      }
      this.$startLoader();
      this.$store
        .dispatch("GET_DISTRICTS", {
          token: this.$getToken(),
          provinceId: provinceId,
        })
        .then((res) => {
          this.destination_districts = res.data.data.data;
          this.$stopLoader();
        });
    },
    getVehicles() {
      // GET_ALL_VEHICLES
      this.$startLoader();
      this.$store
        .dispatch("GET_ALL_VEHICLES", {
          token: this.$getToken(),
        })
        .then((res) => {
          this.allTrucks = res.data.data;
          this.allTrucks.forEach((item) => {
            console.log(item.vehicle_images);
            item.vehicle_images = JSON.parse(item.vehicle_images);
            console.log(item.vehicle_images);
          });
          this.changePage(1);

          if (this.$route.params.name) {
            this.filterData.car_type = this.$route.params.name;
            this.filterNow();
          }

          this.$stopLoader();
        })
        .catch((err) => {
          console.log(err);
          this.$stopLoader();
        });
    },
    getCarBodies() {
      this.$startLoader();
      this.$store
        .dispatch("GET_CAR_BODIES", {
          token: this.$getToken(),
        })
        .then((res) => {
          this.carBodies = res.data.data;
          this.$stopLoader();
        })
        .catch((err) => {
          console.log(err);
          this.$stopLoader();
        });
    },

    getLocationProvinces() {
      this.$store
        .dispatch("GET_PROVINCES", { token: this.$getToken() })
        .then((res) => {
          this.locationprovinces = res.data.data.data;
          this.locationdistricts = [];
          this.locationsectors = [];
          this.locationcells = [];
          this.locations = {
            province: "",
            district: "",
            sector: "",
            cell: "",
          };
        });
    },
    getLocationDistricts(provinceId) {
      if (provinceId == "" || provinceId == undefined || provinceId == null) {
        this.locationdistricts = [];
        this.locationsectors = [];
        this.locationcells = [];
        return;
      }
      this.$startLoader();
      this.$store
        .dispatch("GET_DISTRICTS", {
          token: this.$getToken(),
          provinceId: provinceId,
        })
        .then((res) => {
          this.locationdistricts = res.data.data.data;
          this.locationsectors = [];
          this.locationcells = [];
          this.locations = {
            province: provinceId,
            district: "",
            sector: "",
            cell: "",
          };
          this.$stopLoader();
        });
    },
    getLocationSectors(districtId) {
      if (districtId == "" || districtId == undefined || districtId == null) {
        this.locationsectors = [];
        this.locationcells = [];
        return;
      }
      this.$startLoader();
      this.$store
        .dispatch("GET_SECTORS", {
          token: this.$getToken(),
          districtId: districtId,
        })
        .then((res) => {
          this.locationsectors = res.data.data.data;
          this.locationcells = [];
          this.locations = {
            province: this.locations.province,
            district: districtId,
            sector: "",
            cell: "",
          };
          this.$stopLoader();
        });
    },
    getLocationCells(sectorId) {
      if (sectorId == "" || sectorId == undefined || sectorId == null) {
        this.locationcells = [];
        return;
      }
      this.$startLoader();
      this.$store
        .dispatch("GET_CELLS", { token: this.$getToken(), sectorId: sectorId })
        .then((res) => {
          this.locationcells = res.data.data.data;
          this.locations = {
            province: this.locations.province,
            district: this.locations.district,
            sector: sectorId,
            cell: "",
          };
          this.$stopLoader();
        });
    },
    searchData() {},
  },
  mounted() {
    this.getLocationProvinces();
    this.getProvinces();
    this.getVehicles();
    this.getCarBodies();
  },
  onMounted() {
    const startDate = new Date();
    const endDate = new Date(new Date().setDate(startDate.getDate() + 7));
    this.filterData.date.value = [startDate, endDate];
  },
  watch: {
    "$route.params.name": function (val) {
      this.filterData.car_type = val;
      this.filterNow();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
  .search-section {
    form {
      @media screen and (max-width: 1500px) {
        margin-top: 1rem !important;
      }
      label {
        @media screen and (max-width: 1500px) {
          font-size: 1rem !important;
        }
      }
      p {
        font-size: 0.8rem !important;
      }
    }
  }
.whole-content {
  padding: 2rem 5rem;
  background: #fff;
  @media screen and (max-width: 1500px) {
    padding: 2rem 1rem;
  }
  .Transport-section {
    display: flex;
    column-gap: 1rem;
    background: #fff;
    padding: 1rem;
    .left {
      width: 300px;
      @media screen and (max-width: 1500px) {
        width: 200px;
      }
      .filters {
        .title {
          h4 {
            font-size: 1rem;
            font-weight: 600;
            color: black;
            @media screen and (max-width: 1500px) {
              font-size: 0.7rem !important;
            }
          }
        }
        .filter-item {
          margin-top: 1rem;
          .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            label {
              font-size: 0.8rem;
              font-weight: 400;
              color: #1f1f1f;
              cursor: pointer;
              @media (max-width: 1500px) {
                font-size: 0.5rem;
              }
              @media (max-width: 1200px) {
              }
              @media (max-width: 800px) {
              }
            }
            i {
              font-size: 0.8rem;
              color: #1f1f1f;
              cursor: pointer;
              @media (max-width: 1500px) {
                font-size: 0.6rem;
              }
              @media (max-width: 1200px) {
              }
              @media (max-width: 800px) {
              }
            }
          }
          .content {
            margin-top: 1rem;
            padding-left: 1rem;
            .help {
              font-size: 1rem;
              font-weight: 400;
              color: #0439e7;
              margin-top: 0.5rem;
              text-decoration: underline;
              cursor: pointer;
              @media (max-width: 1500px) {
                font-size: 0.7rem;
              }
              @media (max-width: 1200px) {
              }
              @media (max-width: 800px) {
              }
            }
            ul {
              li {
                font-size: 1rem;
                font-weight: 400;
                color: black;
                margin-top: 0.5rem;
                input {
                  margin-right: 0.5rem;
                }
              }
            }
            input,
            select {
              width: 100% !important;
              border-radius: 0px;
              border: none;
              border-bottom: 1px solid #eee;
              padding: 5px 10px;
              outline: none;
              font-size: 0.75rem;
              font-weight: 400;
              color: #1f1f1f;
              cursor: pointer;
              @media screen and (max-width: 1500px) {
                font-size: 0.6rem;
                padding: 5px 5px;
                height: fit-content !important;
              }
              @media (max-width: 1200px) {
              }
              @media (max-width: 800px) {
              }
            }
          }
        }
        .apply-filter {
          margin-top: 1rem;
          display: flex;
          justify-content: space-between;
          button {
            background: $blue;
            color: white;
            padding: 10px 20px;
            border-radius: 3px;
            font-size: 15px;
            font-weight: 500;
            outline: none;
            border: none;
            cursor: pointer;
            width: 48%;
            &.clear {
              background: white;
              color: $blue;
              border: 1px solid $blue;
              &:hover {
                background: $blue;
                color: white;
                border: 1px solid $blue;
              }
            }
          }
        }
      }
    }
    .right {
      width: calc(100% - 300px);
      background: #fff;
      overflow: hidden;
      @media screen and (max-width: 1500px) {
        width: calc(100% - 200px);
      }
      .filter-btn {
        display: none;
        position: relative;
        margin: 0 10px;
        width: 130px;
        button {
          border-radius: 100px !important;
          background: #fff !important;
          color: $blue !important;
          border: 2px solid $blue !important;
        }

        i {
          margin-right: 5px;
        }
      }
      .results-section {
        border: 1px solid #ccc;
        border-radius: 20px;
        overflow: hidden;
        .results-header {
          padding: 1rem;
          padding-bottom: 2rem;
          display: flex;
          flex-direction: column;
          margin-bottom: 1rem;
          border-bottom: 1px solid #ccc;
          overflow: hidden;
          .search {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            position: relative;
            input {
              padding: 10px 20px;
              border-radius: 5px 0px 0px 5px;
              border: 1px solid #ccc;
              font-size: 15px;
              font-weight: 500;
              width: 100%;
              outline: none;
            }
            button {
              background: $blue;
              color: white;
              padding: 10px 20px;
              border-radius: 0px 5px 5px 0px;
              font-size: 15px;
              font-weight: 500;
              outline: none;
              border: none;
              cursor: pointer;
            }
            .clear {
              font-size: 1.2rem;
              color: #949494;
              margin-left: 1rem;
              cursor: pointer;
              position: absolute;
              right: 4rem;
            }
          }
          p {
            font-size: 1rem;
            font-weight: 400;
            color: #5c5c5c;
            margin-top: 1rem;
            span {
              font-weight: 600;
              color: #000;
            }
          }
        }
        .truck-items {
          background: #7091911f;
          padding: 1rem;
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(250px, 278px));
          grid-gap: 1rem;
          @media (max-width: 1500px) {
            grid-template-columns: repeat(auto-fit, minmax(140px, 200px));
            padding: 1rem 1rem;
          }
          @media (max-width: 1200px) {
            grid-template-columns: repeat(auto-fit, minmax(100px, 200px));
          }
          @media (max-width: 800px) {
            grid-template-columns: repeat(auto-fit, minmax(100px, 200px));
          }
          .truck-item {
            display: flex;
            flex-direction: column;
            background: #fff;
            border-radius: 10px;
            overflow: hidden;
            cursor: pointer;
            transition: 0.5s ease-in-out;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            &:hover {
              box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
            }
            .item-top {
              display: flex;
              flex-direction: column;
              align-items: center;
              border-bottom: 1px solid #ccc;
              .item-top-top {
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                width: 100%;
                .top-top-left {
                  width: 100%;
                  padding-top: 10px;
                  padding: 0 1rem;
                  padding-top: 1rem;
                  label {
                    font-size: 0.9rem;
                    font-weight: 600;
                    color: black;
                    @media (max-width: 1500px) {
                      font-size: 0.6rem;
                    }
                  }
                  .location,
                  .destination,
                  .date,
                  .time {
                    font-size: 0.75rem;
                    font-weight: 400;
                    color: #5c5c5c;
                    margin-top: 0.5rem;
                    @media (max-width: 1500px) {
                      font-size: 0.5rem;
                    }
                  }
                }
                .top-top-right {
                  width: 100%;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-end;
                  .image {
                    width: 100%;
                    height: 120px;
                    // border-radius: 50%;
                    overflow: hidden;
                    background: #eee;
                    position: relative;
                    .trans {
                      background: green;
                      padding: 5px 10px;
                      border-radius: 600px;
                      color: white;
                      position: absolute;
                      right: 5px;
                      top: 5px;
                      font-size: 0.8rem;
                      @media (max-width: 1500px) {
                        font-size: 0.5rem;
                      }
                      &.per {
                        color: white;
                        background: rgb(1, 74, 143);
                      }
                    }
                    img {
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                    }
                  }
                }
              }
              .item-top-bottom {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                padding: 0 1rem;
                padding-bottom: 1rem;
                p {
                  font-size: 0.7rem;
                  font-weight: 400;
                  color: #5c5c5c;
                  margin-top: 0.5rem;
                  font-style: italic;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  display: none;
                  @media (max-width: 1500px) {
                    font-size: 0.5rem;
                  }
                }
              }
            }
            .item-bottom {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              a {
                padding: 0.5rem 1rem;
                text-decoration: none;
                color: black;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 0.8rem;
                @media (max-width: 1500px) {
                  font-size: 0.6rem;
                  padding: 0.5rem 1rem;
                }
                i {
                  color: #acacac;
                  margin-right: 1rem;
                  font-size: 0.8rem;
                }
                &:nth-child(1) {
                  border-right: 1px solid #ccc;
                }
                &:hover {
                  background: #eee;
                }
              }
            }
          }
        }
        .pagination {
          margin: 1rem 0;
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
}
.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #ccc;
  .title {
    font-size: 1.2rem;
    font-weight: 600;
    color: black;
  }
  button {
    background: none;
    color: #363636;
    cursor: pointer;
    margin-right: 15px;
    font-size: 2rem;
  }
}
.dialog-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  .dialog-body-top {
    background: white;
    max-height: 300px;
    height: 300px;
    img {
      object-fit: cover;
      width: 100%;
      max-height: 350px;
    }
  }
  .dialog-body-bottom {
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    margin: 3rem 0;
    .item-top {
      display: flex;
      flex-direction: column;
      row-gap: 2rem;
      .item-top-top {
        padding: 0 4rem;
        label {
          font-size: 2rem;
          text-align: center;
          color: #000;
          display: block;
        }
        p {
          font-size: 1rem;
          display: flex;
          column-gap: 10px;
          color: #5c5c5c;
        }
      }
      .item-top-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 4rem;
        p {
          font-size: 1rem;
          font-weight: 400;
          color: #5c5c5c;
          margin-top: 0.5rem;
          font-style: italic;
        }
      }
    }
    .item-bottom {
      display: flex;
      align-items: center;
      padding: 0 4rem;
      margin-top: 2rem;
      column-gap: 1rem;
      a {
        padding: 1rem;
        text-decoration: none;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $blue;
        width: 200px;
        border: 1px solid $blue;
        transition: 0.5s ease-in-out;
        i {
          color: #fff;
          margin-right: 0.5rem;
        }
        &:nth-child(1) {
        }
        &:hover {
          background: #fff;
          color: $blue;
          i {
            color: $blue;
          }
        }
      }
    }
    .owner {
      align-items: center;
      display: flex;
      column-gap: 1rem;
      margin: 2rem 4rem;
      background: #a9b6bf42;
      padding: 10px;
      border-radius: 10px;
      margin-bottom: 0;
      .image {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        overflow: hidden;
        background: #eee;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .owner-details {
        .title {
          font-size: 1rem;
          font-weight: 400;
          color: #737373;
        }
        display: flex;
        flex-direction: column;
        .name {
          font-size: 1.3rem;
          font-weight: 600;
          color: #000;
        }
        .email {
          font-size: 1rem;
          font-weight: 400;
          color: #5c5c5c;
          font-size: 0.85rem;
        }
      }
    }
  }
}

// @media (max-width: 1700px) {
//   .whole-content {
//     padding: 1.5rem 1rem;
//     .Transport-section {
//       .left {
//         width: 300px;
//       }
//       .right {
//         width: calc(100% - 300px);
//         .results-section {
//           .truck-items {
//             padding: 2rem 1rem;
//           }
//         }
//       }
//     }
//   }
// }
// @media (max-width: 1600px) {
//   .whole-content {
//     padding: 1.5rem 1rem;
//     .Transport-section {
//       .left {
//         width: 300px;
//       }
//       .right {
//         width: calc(100% - 300px);
//         .results-section {
//           .truck-items {
//             grid-template-columns: repeat(2, 1fr);
//             padding: 2rem 2rem;
//           }
//         }
//       }
//     }
//   }
// }
// @media (max-width: 1200px) {
//   .whole-content {
//     padding: 1.5rem 1rem;
//     .Transport-section {
//       .left {
//         width: 300px;
//         display: none;
//       }
//       .model-left {
//         width: 100%;
//         display: block;
//       }
//       .right {
//         width: 100%;
//         .filter-btn {
//           display: block;
//         }
//         .results-section {
//           .truck-items {
//             grid-template-columns: repeat(2, 1fr);
//             padding: 2rem 1rem;
//           }
//         }
//       }
//     }
//   }
// }
// @media (max-width: 700px) {
//   .whole-content {
//     padding: 1.5rem 1rem;
//     .Transport-section {
//       .left {
//         width: 300px;
//         display: none;
//       }
//       .model-left {
//         width: 100%;
//         display: block;
//       }
//       .right {
//         width: 100%;
//         .results-section {
//           .truck-items {
//             grid-template-columns: repeat(1, 1fr);
//             padding: 2rem 1rem;
//           }
//         }
//       }
//     }
//   }
// }

.filter-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 2rem;
  row-gap: 1rem;
  label {
    font-size: 1rem;
    font-weight: bolder;
    @media screen and (max-width: 1500px) {
      font-size: 0.7rem;
    }
  }
  .form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    column-gap: 20px;
    width: 100%;
    .form-group {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      column-gap: 20px;
      padding: 0 1rem;
      width: 100%;
      select {
        width: 100% !important;
        border-radius: 0px;
        border: none;
        border-bottom: 1px solid #eee;
        padding: 5px 10px;
        outline: none;
        font-size: 0.75rem;
        font-weight: 400;
        color: #1f1f1f;
        cursor: pointer;
        @media screen and (max-width: 1500px) {
          font-size: 0.6rem;
          padding: 5px 5px;
          height: fit-content !important;
        }
        @media (max-width: 1200px) {
        }
        @media (max-width: 800px) {
        }
      }
    }
    .group-4 {
      select {
        width: 150px;
      }
    }
    .submit {
      width: 100px;
      height: 40px;
      border-radius: 4px;
      border: none;
      outline: none;
      background-color: $blue;
      color: #fff;
      font-size: 1rem;
      font-weight: 600;
      cursor: pointer;
    }
  }

  .no-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    label {
      font-size: 1rem;
      font-weight: 500;
      color: #474747;
    }
    p {
      font-size: 0.8rem;
      color: #757575;
      font-weight: 400;
    }
  }
}
</style>