<template>
  <div class="navbar-small">
    <div
      class="content"
      v-click-out-side="customMethod2"
      :class="startShow ? 'active' : ''"
    >
      <div class="nav-top">
        <div class="left">
          <p>Infomaster</p>
        </div>
        <div class="right">
          <button @click="closePopup">&times;</button>
        </div>
      </div>
      <div class="nav-left">
        <div class="nav-links">
          <router-link :to="{ name: 'home' }" class="nav-link">
            <label for="">{{ $txt("Home") }}</label>
          </router-link>
          <router-link
            :to="{
              name: 'ViewModule',
              params: { moduleCode: 'IEDU', name: 'Education' },
            }"
            class="nav-link"
          >
            <label for="">{{ $txt("Education") }}</label>
          </router-link>
          <router-link
            :to="{
              name: 'ViewModule',
              params: { moduleCode: 'IRAB', name: 'Education' },
            }"
            class="nav-link"
          >
            <label for="">{{ $txt("Agriculture") }}</label>
          </router-link>
          <router-link
            :to="{
              name: 'Transport',
            }"
            class="nav-link"
          >
            <label for="">{{ $txt("Transport") }}</label>
          </router-link>
          <router-link
            :to="{
              name: 'ListBusinesses',
              params: { moduleCode: 'ITR', name: 'Business directory' },
            }"
            class="nav-link"
          >
            <label for="">{{ $txt("Business directory") }}</label>
          </router-link>


          <router-link
            :to="{
              name: 'Workers',
            }"
            class="nav-link"
          >
            <label for="">Workers</label>
          </router-link>
          <router-link
            :to="{
              name: 'Deals',
            }"
            class="nav-link"
          >
            <label for="">Special deals</label>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import clickOutSide from "@mahdikhashan/vue3-click-outside";
export default {
  name: "navbar-small",
  data() {
    return {
      dialogActive: false,
      clickCount: 0,
      modelOpened: true,
      startShow: false,
    };
  },
  directives: {
    clickOutSide,
  },
  methods: {
    closePopup() {
      this.startShow = false;
      setTimeout(() => {
        this.$toggleSmallNav();
        document.body.style.overflowY = "auto";
      }, 500);
    },
    customMethod2() {
      this.clickCount++;
      if (this.clickCount > 1) {
        this.closePopup();
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.startShow = true;
    }, 10);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.navbar-small {
  width: 100vw;
  background: rgba(0, 0, 0, 0.541);
  position: fixed;
  z-index: 100;
  height: 100vh;
  top: 0;
  left: 0;
  .content {
    display: flex;
    flex-direction: column;
    background-color: $blue;
    margin-left: -300px;
    overflow: hidden;
    height: 100vh;
    width: 300px;
    transition: all 0.5s ease-in-out;
    &.active {
      margin-left: 0;
    }
    .nav-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 20px;
      border-bottom: 1px solid #ffffff18;
      .left {
        p {
          font-size: 20px;
          font-weight: 600;
          color: #fff;
        }
      }
      .right {
        border: 1px solid #fff;
        border-radius: 5px;
        padding: 5px;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        button {
          background-color: transparent;
          border: none;
          font-size: 30px;
          font-weight: 400;
          cursor: pointer;
          color: #fff;
        }
      }
    }
    .nav-left {
      display: flex;
      flex-direction: column;
      .nav-links {
        display: flex;
        flex-direction: column;
        .nav-link {
          cursor: pointer;
          padding: 10px;
          border-bottom: 1px solid #ffffff18;
          label {
            font-size: 14px;
            font-weight: 600;
            color: #fff;
            cursor: pointer;
            &:hover,
            .active {
              color: #f0cd35;
            }
          }
        }
      }
    }
    .nav-right {
      display: flex;
      justify-content: space-between;
      align-items: center;
      display: none;
      .nav-links {
        display: flex;
        align-items: center;
        .nav-link {
          cursor: pointer;
          padding: 10px;
          label {
            font-size: 14px;
            font-weight: 600;
            color: #fff;
            cursor: pointer;
            &:hover,
            .active {
              color: #f0cd35;
            }
          }
        }
      }
    }
  }
}
</style>