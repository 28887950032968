<template>
  <div class="items">
    <super-admin-topbar :pageTitle="'All businesses'" />
    <div class="page-content">
      <div class="levels-table">
        <div class="table-header">
          <div class="left">
            <div class="search">
              <input
                type="text"
                placeholder="Search"
                @keyup="searchData()"
                v-model="search"
              />
            </div>
          </div>
          <div class="right">
            <!-- <button @click="popupOpen = true">Add item</button> -->
          </div>
        </div>
        <!-- <div class="item-per-page">
          <label for="">Items per page: </label>
          <select name="" id="" v-model="itemsPerPage" @change="changePage(1)">
            <option>5</option>
            <option>10</option>
            <option>25</option>
            <option>50</option>
            <option>100</option>
            <option>500</option>
          </select>
        </div> -->
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>phone</th>
              <th>email</th>
              <th>Owner</th>
              <th>Owner's phone</th>
              <th>Location</th>
              <th>Business sector</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items" :key="item.id">
              <td class="img">
                <img
                  :src="$file(item.business_logo)"
                  alt=""
                  @error="$imageurlalt"
                />
              </td>
              <td>{{ item.business_name }}</td>
              <td>{{ item.business_phone }}</td>
              <td>{{ item.business_email }}</td>
              <td>{{ item.business_representative }}</td>
              <td>{{ "-" }}</td>
              <td>{{ item.district_name }}, {{ item.sector_name }}</td>
              <td>{{ item.businesssector.sector_name }}</td>
              <td>
                <button
                  class="verify"
                  v-if="!item.verified"
                  @click="verifyItem(item, 'verify')"
                >
                  <label for="">Verify</label>
                </button>
                <button
                  class="unverify"
                  @click="verifyItem(item, 'unverify')"
                  v-else
                >
                  <label for="">Unverify</label>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <pagination
          :totalItems="allItems.length"
          :itemsPerPage="itemsPerPage"
          @pageChanged="
            (page) => {
              changePage(page);
            }
          "
        />
      </div>
      <model
        :modelTitle="'Add item'"
        :largeModel="false"
        :smallModel="true"
        v-show="popupOpen"
        @closeModel="popupOpen = false"
      >
        <form action="" class="addLevel" @submit.prevent="confirmAdd()">
          <div class="form-group">
            <label for="">item Name</label>
            <input
              type="text"
              placeholder="item Name"
              v-model="newitem.itemName"
            />
          </div>
          <div class="form-group">
            <button type="submit">Save</button>
          </div>
        </form>
      </model>
      <model
        :modelTitle="'Edit item'"
        :largeModel="false"
        :smallModel="true"
        v-show="editPopupOpen"
        @closeModel="editPopupOpen = false"
      >
        <form action="" class="addLevel" @submit.prevent="confirmEdit()">
          <div class="form-group">
            <label for="">item Name</label>
            <input
              type="text"
              placeholder="item Name"
              v-model="selecteditem.itemName"
            />
          </div>
          <div class="form-group">
            <button type="submit">Save</button>
          </div>
        </form>
      </model>
      <confirm-model
        :modelTitle="'Delete item'"
        :smallModel="true"
        v-if="deletePopupOpen"
        @closeModel="deletePopupOpen = false"
        @confirmModel="confirmDelete()"
        :displayText="'Are you sure you want to delete this item?'"
      ></confirm-model>
    </div>
  </div>
</template>

<script>
export default {
  name: "items",
  data() {
    return {
      allItems: [],
      items: [],
      search: "",
      popupOpen: false,
      editPopupOpen: false,
      newitem: {
        itemName: "",
      },
      selecteditem: {},
      deletePopupOpen: false,
      currentPage: 1,
      itemsPerPage: 10,
    };
  },
  methods: {
    getitems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_ALL_BUSINESSES", {
          token: this.$getToken(),
        })
        .then((res) => {
          this.allItems = res.data.data;
          this.items = this.allItems;
          this.$stopLoader();
        })
        .catch((err) => {
          console.log(err);
          this.$stopLoader();
        });
    },
    searchData() {
      this.items = this.allItems.filter((item) => {
        return (
          item.business_name
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          item.business_phone
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          item.business_email
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          item.business_representative
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          item.district_name
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          item.sector_name.toLowerCase().includes(this.search.toLowerCase()) ||
          item.businesssector.sector_name
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          item.province_name.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
    edititem(item) {
      this.selecteditem = item;
      this.editPopupOpen = true;
    },
    deleteitem(item) {
      this.selecteditem = item;
      this.deletePopupOpen = true;
    },
    confirmDelete() {
      this.$startLoader();
      this.$store
        .dispatch("DELETE_item", {
          token: this.$getToken(),
          data: {
            business_id: this.selecteditem.business_id,
          },
        })
        .then((res) => {
          if (res.data.status) {
            this.$notify({
              title: "Success",
              type: "success",
              text: "item deleted successfully",
            });
          }
          this.deletePopupOpen = false;
          this.getitems();
        })
        .catch((err) => {
          console.log(err);
          this.$notify({
            title: "Error",
            type: "error",
            text: err.response.data.error,
          });
        });
    },
    confirmAdd() {
      this.$startLoader();
      this.$store
        .dispatch("ADD_item", {
          token: this.$getToken(),
          data: this.newitem,
        })
        .then((res) => {
          if (res.data.status) {
            this.$notify({
              title: "Success",
              type: "success",
              text: "item added successfully",
            });
          }
          this.popupOpen = false;
          this.getitems();
        })
        .catch((err) => {
          console.log(err);
          this.$notify({
            title: "Error",
            type: "error",
            text: err.response.data.error,
          });
        });
    },
    confirmEdit() {
      this.$startLoader();
      this.$store
        .dispatch("EDIT_item", {
          token: this.$getToken(),
          data: this.selecteditem,
        })
        .then((res) => {
          if (res.data.status) {
            this.$notify({
              title: "Success",
              type: "success",
              text: "item edited successfully",
            });
          }
          this.editPopupOpen = false;
          this.getitems();
        })
        .catch((err) => {
          console.log(err);
          this.$notify({
            title: "Error",
            type: "error",
            text: err.response.data.error,
          });
        });
    },
    changePage(page) {
      this.currentPage = page;
      this.items = this.$changePage(this.allItems, page, this.itemsPerPage);
    },
    verifyItem(item, actionType) {
      this.$startLoader();
      this.$store
        .dispatch("VERIFY_BUSINESS", {
          token: this.$getToken(),
          data: {
            business_id: item.business_id,
          },
        })
        .then((res) => {
          if (res.data.status) {
            this.$notify({
              title: "Success",
              type: actionType == "verify" ? "success" : "warn",
              text:
                actionType == "verify"
                  ? "Business verified successfully"
                  : "Business unverified successfully",
            });
          }
          this.getitems();
        })
        .catch((err) => {
          console.log(err);
          this.$notify({
            title: "Error",
            type: "error",
            text: "Something went wrong",
          });
          this.$stopLoader();
        });
    },
  },
  mounted() {
    this.getitems();
    this.changePage(1);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.items {
  .page-content {
    padding: 1rem;
  }
  .levels-table {
    table {
      width: 100%;
      border-collapse: collapse;
      thead {
        tr {
          th {
            padding: 10px;
            border-bottom: 1px solid #ccc;
            text-align: left;
          }
        }
      }
      tbody {
        tr {
          td {
            padding: 10px;
            border-bottom: 1px solid #ccc;
            button {
              background: transparent;
              border: none;
              outline: none;
              cursor: pointer;
              margin-right: 1rem;
              img {
                width: 30px;
              }
            }
          }
        }
      }
    }
  }
  .addLevel {
    .form-group {
      margin-bottom: 1rem;
      label {
        display: block;
        margin-bottom: 0.5rem;
      }
      input {
        padding: 0.5rem;
        border: 1px solid #ccc;
        border-radius: 5px;
        outline: none;
        width: 100%;
      }
      button {
        padding: 0.65rem 1rem;
        width: 150px;
        border: none;
        outline: none;
        border-radius: 5px;
        background: $blue;
        color: #fff;
        cursor: pointer;
        font-weight: 600;
      }
    }
  }
}
</style>