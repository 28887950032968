<template>
  <div class="OUpload">
    <div class="img-uploader">
      <!-- image preview -->
      <div class="preview">
        <div class="img-item" v-for="image in images" :key="image">
          <!-- <previewImage :image="image" class="prev" /> -->
          <img
            :src="$store.state.apiUploadUrl + checkFileExtension(image)"
            alt="Preview Image"
          />
          <span class="delete" @click="removeImage(image)">
            <i class="fas fa-trash"></i>
          </span>
          <span class="uploaded" v-if="image.uploadStatus == 'uploaded'"
            >Uploaded</span
          >
          <span class="failed" v-if="image.uploadStatus == 'failed'"
            >Failed</span
          >
        </div>
        <div class="dropzone">
          <span v-if="multiple || images.length < 1">+</span>
          <span class="change" v-else>Change</span>
          <input
            type="file"
            @change="upload"
            :multiple="false"
            accept="image/*,.pdf,.doc,.docx,.xml,application/msword,.xlsx,.xlsm,"
            ref="file"
          />
        </div>
      </div>
      <!-- single image uploader dropzone -->
    </div>
    <!-- upload btn -->
    <!-- <div class="upload-btn">
      <button @click="uploadImages()">Upload</button>
    </div> -->
  </div>
</template>
<script>
import previewImage from "./previewImage.vue";
export default {
  name: "OUpload",
  components: {
    previewImage,
  },
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    uploadUrl: {
      type: String,
      default: "files/upload",
    },
    imagesBaseUrl: {
      type: String,
      default: "http://localhost:3000/",
    },
    value: {
      type: [String, Array],
      default: "",
    },
  },
  data() {
    return {
      images: [],
      imagesToUpload: [],
      previewUrl: "",
    };
  },
  methods: {
    upload() {
      if (this.$refs.file.files.length == 0) return;
      this.imagesToUpload.push(this.$refs.file.files[0]);
      this.uploadImages(this.$refs.file.files[0]);
    },
    removeImage(image) {
      this.images = this.images.filter((img) => img != image);
      if (this.multiple) {
        this.$emit("imagesUploaded", this.images);
      } else {
        this.$emit("imagesUploaded", this.images[0]);
      }
    },
    async uploadImages($image) {
      // emit startLoader event
      this.$emit("startLoader");
      const res = await this.$store
        .dispatch("UPLOADIMAGES", {
          file: $image,
          url: this.uploadUrl,
        })
        .then((res) => {
          console.log(res);
          if (res.data.status == "ok") {
            if (this.multiple) {
              this.images.push(res.data.file_name);
            } else {
              this.images = [res.data.file_name];
            }
            if (this.multiple) {
              this.$emit("imagesUploaded", this.images);
            } else {
              this.$emit("imagesUploaded", this.images[0]);
            }
            this.$emit("stopLoader");
          } else {
            this.imagesToUpload.forEach((img) => {
              if (img.name == $image.name) {
                img.uploadStatus = "failed";
                this.$emit("imagesUploadedFailed", $image);
              }
            });
          }
        })
        .catch((res) => {
          alert("Unsupported image");
          this.$emit("stopLoader");
         });
    },
    checkFileExtension(file) {
      let fileExtension = file.split(".").pop();
      if (
        fileExtension == "png" ||
        fileExtension == "jpg" ||
        fileExtension == "jpeg" ||
        fileExtension == "gif"
      ) {
        return file;
      } else if (
        fileExtension == "mp4" ||
        fileExtension == "mkv" ||
        fileExtension == "avi" ||
        fileExtension == "flv" ||
        fileExtension == "wmv"
      ) {
        return "videoThumb.jpg";
      } else if (fileExtension == "pdf") {
        return "pdfThumb.png";
      } else if (
        fileExtension == "doc" ||
        fileExtension == "docx" ||
        fileExtension == "application/msword"
      ) {
        return "docThumb.png";
      } else {
        return "fileThumb.svg";
      }
    },
  },
  mounted() {
    if (this.value) {
      if (this.multiple) {
        this.images = this.value;
      } else {
        this.images = [this.value];
      }
    }
  },
  // watch value prop
  watch: {
    value: {
      handler: function (val) {
        if (this.multiple) {
          this.images = val;
        } else {
          this.images = [val];
        }
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.OUpload {
  .img-uploader {
    display: flex;
    flex-wrap: wrap;
  }
  .preview {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 10px;
    .img-item {
      width: 100px;
      height: 100px;
      position: relative;
      .delete {
        position: absolute;
        top: -5px;
        right: -5px;
        background: #c40505;
        color: #fff;
        padding: 5px;
        border-radius: 50%;
        font-size: 10px;
        width: 25px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &:hover {
          background: #780202;
        }
      }
      .uploaded {
        position: absolute;
        bottom: 0;
        left: 0;
        background: #4f934c;
        color: #fff;
        padding: 3px 5px;
        border-radius: 0 0 5px 5px;
        font-size: 10px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .failed {
        position: absolute;
        bottom: 0;
        left: 0;
        background: #c40505;
        color: #fff;
        padding: 3px 5px;
        border-radius: 0 0 5px 5px;
        font-size: 10px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .prev {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .dropzone {
    width: 100px;
    height: 100px;
    border: 1px dashed #ccc;
    position: relative;
    cursor: pointer;
    &:hover {
      border: 1px dashed #000;
    }
    input {
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }
    span {
      font-size: 2rem;
      color: #ccc;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
      &.change {
        font-size: 1rem;
      }
    }
  }
  .upload-btn {
    button {
      padding: 10px 20px;
      border: none;
      border-radius: 5px;
      background: #000;
      color: #fff;
      margin-top: 10px;
      cursor: pointer;
      &:hover {
        background: #333;
      }
    }
  }
}
</style>