import axios from 'axios'
import { API_URL } from '../variables';
const GET_COUNTRIES_URL = '/countries';

const translation = {
    state: {
    },
    getters: {},
    mutations: {},
    actions: {
        ['GET_COUNTRIES']: ({ commit }, payload) => {
            return axios({
                url: API_URL + GET_COUNTRIES_URL,
                method: "GET"
            })
        }
    },
    modules: {},
}
export default translation;