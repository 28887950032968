<template>
  <div class="viewAgricultureModule">
    <div
      v-if="ismodule"
      class="jumbotron"
      :style="
        `background-image: url('/assets/images/cover/` +
        ismodule.modulePicture +
        `')`
      "
    >
      <div class="left">
        <div class="module-info">
          <h1>{{ $txt(ismodule.moduleName) }}</h1>
          <p>
            {{ $txt(ismodule.description) }}
          </p>
        </div>
        <div class="module-actions">
          <router-link :to="{ name: `MyProduction` }" class="btn btn-lg">
            {{ $txt("Manage My Products") }}
          </router-link>
        </div>
      </div>
    </div>
    <label
      for=""
      class="page-title"
      v-if="$route.params.categoryname && products"
      >{{ products[0].Category.category_name }} Products</label
    >
    <label for="" class="page-title" v-else>Available Products</label>
    <div class="shop-widget">
      <div class="left">
        <div class="categories">
          <div class="header" v-if="!forCategory">
            <b>Categories</b>
          </div>
          <div class="category-list" v-if="!forCategory">
            <div class="filtercategories">
              <input
                type="text"
                placeholder="search category"
                v-model="categorysearch"
                @keyup="filterCategories()"
              />
            </div>
            <div class="category-item">
              <div class="category-list">
                <div
                  class="category"
                  @click="getAllProducts()"
                  :class="{ active: activeCategory == 'all' }"
                >
                  <label for="">All categories</label>
                  <span class="badge">({{ allProductsCount }})</span>
                </div>
                <div
                  class="category"
                  v-for="category in categories"
                  :key="category.id"
                  @click="getProductsById(category.category_id)"
                  :class="{ active: activeCategory == category.category_id }"
                >
                  <label for="">{{ category.category_name }}</label>
                  <span class="badge">({{ category.Production.length }})</span>
                </div>
              </div>
            </div>
          </div>
          <div class="filter-form">
            <label for="">Filter By location: </label>
            <div class="form">
              <div class="form-group group-4">
                <!-- Province, Disctict, sector, cell -->
                <select
                  name="province"
                  id=""
                  v-model="locations.province"
                  @change="
                    getDistricts(locations.province);
                    searchData();
                  "
                >
                  <option value="">{{ $txt("All Provinces") }}</option>
                  <option
                    v-for="province in provinces"
                    :key="province.provinceId"
                    :value="province.provinceId"
                  >
                    {{ province.provinceName }}
                  </option>
                </select>
                <select
                  name="district"
                  id=""
                  v-model="locations.district"
                  @change="
                    getSectors(locations.district);
                    searchData();
                  "
                >
                  <option value="">{{ $txt("All Districts") }}</option>
                  <option
                    v-for="district in districts"
                    :key="district.districtId"
                    :value="district.districtId"
                  >
                    {{ district.districtName }}
                  </option>
                </select>
                <select
                  name="sector"
                  id=""
                  v-model="locations.sector"
                  @change="
                    getCells(locations.sector);
                    searchData();
                  "
                >
                  <option value="">{{ $txt("All Sectors") }}</option>
                  <option
                    v-for="sector in sectors"
                    :key="sector.sectorId"
                    :value="sector.sectorId"
                  >
                    {{ sector.sectorName }}
                  </option>
                </select>
                <select
                  name="cell"
                  id=""
                  v-model="locations.cell"
                  @change="searchData()"
                >
                  <option value="">{{ $txt("All Cells") }}</option>
                  <option
                    v-for="cell in cells"
                    :key="cell.cellId"
                    :value="cell.cellId"
                  >
                    {{ cell.cellName }}
                  </option>
                </select>
              </div>
              <!-- <button class="submit" @click="getFilteredData()">Filter</button> -->
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="products">
          <div class="products-header">
            <div>
              <label for="">Products</label>
            </div>
            <div class="search">
              <input
                type="text"
                v-model="searchQuery"
                placeholder="Search products"
                @keyup="searchData()"
              />
            </div>
          </div>
          <div class="products-list" v-if="products">
            <div
              class="product-item"
              v-for="product in products"
              :key="product.id"
              @click="viewOrder(product)"
            >
              <div class="product-image">
                <img
                  :src="$file(JSON.parse(product.images)[0])"
                  alt=""
                  @error="$imageurlalt()"
                />
                <div class="view-btn">
                  <button class="view-order" @click="viewOrder(product)">
                    View Details
                  </button>
                </div>
              </div>
              <div class="product-info">
                <h3 v-if="product.product">
                  {{ product.product.product_name }}
                </h3>
                <p class="price"><span>Price</span>{{ product.price }} Rwf</p>
                <p class="quantity">
                  {{ product.quantity }}
                  {{ product.unit.unit_name }}
                </p>
                <p class="cooperative">
                  <span><i class="fas fa-map-marker-alt"></i></span>
                  {{ product.district_name }} - {{ product.sector_name }}
                </p>
                <div class="cooperative" v-if="product.is_cooperative">
                  <span><i class="fa fa-user"></i></span>
                  {{ product.cooperative.cooperative_name }}
                </div>
                <div class="cooperative" v-else>
                  <span><i class="fa fa-user"></i></span>
                  {{ product.contact_name }}
                </div>
                <div class="cooperative">
                  <span><i class="fa fa-phone"></i></span>
                  <div>{{ product.contact_phone }}</div>
                </div>
                <p class="content">{{ product.description }}</p>
              </div>
            </div>
          </div>
          <pagination
            :totalItems="allProducts.length"
            :itemsPerPage="itemsPerPage"
            @pageChanged="
              (page) => {
                changePage(page);
              }
            "
          />
          <br /><br />
          <left-view
            class="ViewProduct"
            v-if="showOrderModal"
            @closePopup="showOrderModal = false"
            :modelTitle="
              selectedProduct.is_cooperative
                ? 'View ' +
                  selectedProduct.product.product_name +
                  ' from ' +
                  selectedProduct.cooperative.cooperative_name
                : 'View ' +
                  selectedProduct.product.product_name +
                  ' from ' +
                  selectedProduct.contact_name
            "
          >
            <div class="dialog">
              <div class="dialog-body">
                <div class="product">
                  <div class="dialog-body-top">
                    <div class="image">
                      <carousel
                        :items-to-show="1"
                        :wrapAround="true"
                        :autoplay="3000"
                      >
                        <slide
                          v-for="image in JSON.parse(selectedProduct.images)"
                          :key="image"
                        >
                          <img
                            :src="$file(image)"
                            alt=""
                            @error="$imageurlalt()"
                          />
                        </slide>
                        <template #addons>
                          <navigation />
                        </template>
                      </carousel>
                    </div>
                  </div>
                  <div class="product-details">
                    <h1>{{ selectedProduct.product.product_name }}</h1>
                    <p>{{ selectedProduct.description }}</p>
                    <div class="product-price">
                      <h1 v-if="selectedProduct.is_cooperative">
                        <span>{{ $txt("Cooperative:") }} </span>
                        <span>{{
                          $comma(selectedProduct.cooperative.cooperative_name)
                        }}</span>
                      </h1>
                      <h1 v-else>
                        <span>{{ $txt("Farmer:") }} </span>
                        <span>{{ $comma(selectedProduct.contact_name) }}</span>
                      </h1>
                      <h1>
                        <span>{{ $txt("Location:") }} </span>
                        <span>
                          {{ selectedProduct.district_name }} -
                          {{ selectedProduct.sector_name }}</span
                        >
                      </h1>
                      <h1>
                        <span>{{ $txt("Phone:") }} </span>
                        <span>
                          {{ selectedProduct.contact_phone }}
                        </span>
                      </h1>
                      <h1>
                        <span>{{ $txt("Email:") }} </span>
                        <span>
                          {{ selectedProduct.contact_email }}
                        </span>
                      </h1>
                      <h1>
                        <span>{{ $txt("Available Quantity:") }} </span>
                        <label>{{ $comma(selectedProduct.quantity) }} </label>
                      </h1>
                      <h1>
                        <span>{{ $txt("Price:") }} </span>
                        <label>{{ $comma(selectedProduct.price) }}</label>
                      </h1>
                    </div>
                  </div>
                  <div class="contact-form" v-show="startContact">
                    <form action="" @submit.prevent="sendOrder()">
                      <!-- Names, Email, Message -->
                      <div class="input-group">
                        <input
                          type="text"
                          name="names"
                          id="names"
                          v-model="orderData.order_quantity"
                          :placeholder="$txt('Quantity')"
                        />
                      </div>
                      <div class="input-group">
                        <textarea
                          name="message"
                          id="message"
                          cols="30"
                          rows="10"
                          v-model="orderData.order_description"
                          :placeholder="$txt('Message to supplier')"
                        ></textarea>
                      </div>
                    </form>
                  </div>
                  <div class="buttons">
                    <button v-if="startContact" @click.prevent="sendOrder()">
                      {{ $txt("Send") }}
                    </button>
                    <button v-if="!startContact" @click="openForm">
                      {{ $txt("Send Order") }}
                    </button>
                    <button @click="closeViewModel()" class="cancel">
                      {{ $txt("Close") }}
                    </button>
                  </div>
                  <div class="for-more-info">
                    <p>
                      {{
                        $txt("For more info, contact the support@infomaster.rw")
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </left-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "viewAgricultureModule",
  data() {
    return {
      allClasses: [],
      categories: [],
      products: null,
      allProducts: [],
      currentPage: 1,
      itemsPerPage: 8,
      searchQuery: "",
      activeCategory: "all",
      allProductsCount: 0,
      quantity: 0,
      message: "",
      locations: {
        province: "",
        district: "",
        sector: "",
        cell: "",
      },
      provinces: [],
      districts: [],
      sectors: [],
      cells: [],
      showOrderModal: false,
      selectedProduct: null,
      ismodule: null,
      startContact: false,
      categorysearch: "",
      forCategory: false,
      activeCategoryName: "",
      orderData: {
        order_quantity: 0,
        order_description: "",
      },
    };
  },
  methods: {
    filterCategories() {
      let search = this.categorysearch;
      if (search) {
        this.categories = this.allClasses.filter((category) => {
          return category.category_name
            .toLowerCase()
            .includes(search.toLowerCase());
        });
      } else {
        this.categories = this.allClasses;
      }
    },
    changePage(page) {
      this.currentPage = page;
      this.products = this.$changePage(
        this.allProducts,
        page,
        this.itemsPerPage
      );
    },
    searchData() {
      this.$startLoader();
      let search = this.searchQuery;
      let searchResults1 = this.searchBar();
      let searchResults2 = this.searchByLocation(searchResults1);
      this.products = searchResults2;
      this.$changePage(this.products, 1, this.itemsPerPage);
      this.$stopLoader();
    },
    searchBar() {
      let search = this.searchQuery;
      let allResults = this.allProducts;
      let searchResults1 = [];
      if (search && search != "") {
        searchResults1 = allResults.filter((product) => {
          return (
            product.product.product_name
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            product.description.toLowerCase().includes(search.toLowerCase()) ||
            product.contact_name.toLowerCase().includes(search.toLowerCase()) ||
            product.province_name
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            product.district_name
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            product.sector_name.toLowerCase().includes(search.toLowerCase()) ||
            product.cell_name.toLowerCase().includes(search.toLowerCase())
          );
        });
      } else {
        searchResults1 = allResults;
      }
      return searchResults1;
    },
    searchByLocation(searchResults1) {
      let searchResults2 = [];
      if (this.locations.province == "") {
        this.locations.district = "";
        this.locations.sector = "";
        this.locations.cell = "";
      }
      if (this.locations.district == "") {
        this.locations.sector = "";
        this.locations.cell = "";
      }
      if (this.locations.sector == "") {
        this.locations.cell = "";
      }
      if (this.locations.cell && this.locations.cell != "") {
        searchResults2 = searchResults1.filter((product) => {
          return parseInt(product.cellId) == this.locations.cell;
        });
      } else {
        if (this.locations.sector && this.locations.sector != "") {
          searchResults2 = searchResults1.filter((product) => {
            return parseInt(product.sectorId) == this.locations.sector;
          });
        } else {
          if (this.locations.district && this.locations.district != "") {
            searchResults2 = searchResults1.filter((product) => {
              return parseInt(product.districtId) == this.locations.district;
            });
          } else {
            if (this.locations.province && this.locations.province != "") {
              console.log(this.locations.province);
              searchResults2 = searchResults1.filter((product) => {
                return parseInt(product.provinceId) == this.locations.province;
              });
            } else {
              searchResults2 = searchResults1;
            }
          }
        }
      }
      return searchResults2;
    },
    getAllProducts() {
      this.$startLoader();
      this.searchQuery = "";
      this.activeCategory = "all";
      let ACTION_NAME = "GET_AGRIPRODUCTIONS";
      if (this.$route.params.category) {
        ACTION_NAME = "GET_AGRIPRODUCTIONSBYCATEGORY";
      }
      this.$store
        .dispatch(ACTION_NAME, {
          token: this.$getToken(),
          data: { category_id: this.activeCategoryName },
        })
        .then((res) => {
          this.allProducts = res.data.data;
          this.products = this.$changePage(
            this.allProducts,
            this.currentPage,
            this.itemsPerPage
          );

          if (this.$route.params.id) {
            this.selectedProduct = this.allProducts.find(
              (item) => item.product_id == this.$route.params.id
            );
            if (
              this.selectedProduct == undefined ||
              this.selectedProduct == null ||
              this.selectedProduct == ""
            ) {
              this.$router.push({ name: "ViewAgriculture" });
            } else {
              this.showOrderModal = true;
            }
          }
          this.$stopLoader();
        })
        .catch((res) => {
          this.$stopLoader();
          this.$notify({
            title: "Error",
            type: "error",
            text: "Something went wrong",
          });
        });
    },
    getAllProductCategories() {
      this.$startLoader();
      this.$store
        .dispatch("GET_AGRICATEGORIES", {
          token: this.$getToken(),
        })
        .then((res) => {
          this.allClasses = res.data.data;
          this.categories = res.data.data;
          this.allClasses.forEach((category) => {
            this.allProductsCount += category.totalProducts;
          });
          this.$stopLoader();
          this.getAllProducts();
        })
        .catch((res) => {
          this.$stopLoader();
          this.$notify({
            title: "Error",
            type: "error",
            text: "Something went wrong",
          });
        });
    },
    getProductsById(id) {
      this.$startLoader();
      this.searchQuery = "";
      this.activeCategory = id;
      this.$store
        .dispatch("GET_AGRIPRODUCTIONSBYCATEGORY", {
          data: { category_id: id },
          token: this.$getToken(),
        })
        .then((res) => {
          this.allProducts = res.data.data;
          this.products = this.$changePage(
            this.allProducts,
            this.currentPage,
            this.itemsPerPage
          );
          this.$stopLoader();
        })
        .catch((res) => {
          this.$stopLoader();
          this.$notify({
            title: "Error",
            type: "error",
            text: "Something went wrong",
          });
        });
    },
    viewOrder(product) {
      this.selectedProduct = product;
      this.showOrderModal = true;
      if (this.forCategory) {
        this.$router.push({
          name: "ViewSingleAgricultureByCategoryId",
          params: {
            id: product.product_id,
            name: this.$urlCompatible(product.product.product_name),
            category: this.$route.params.category,
          },
        });
      } else {
        this.$router.push({
          name: "ViewSingleAgriculture",
          params: {
            id: product.product_id,
            name: this.$urlCompatible(product.product.product_name),
          },
        });
      }
    },
    getProvinces() {
      this.$store
        .dispatch("GET_PROVINCES", { token: this.$getToken() })
        .then((res) => {
          this.provinces = res.data.data.data;
          this.districts = [];
          this.sectors = [];
          this.cells = [];
        });
    },
    getDistricts(provinceId) {
      if (provinceId == "" || provinceId == undefined || provinceId == null) {
        this.districts = [];
        this.sectors = [];
        this.cells = [];
        return;
      }
      this.$startLoader();
      this.$store
        .dispatch("GET_DISTRICTS", {
          token: this.$getToken(),
          provinceId: provinceId,
        })
        .then((res) => {
          this.districts = res.data.data.data;
          this.sectors = [];
          this.cells = [];
          this.$stopLoader();
        });
    },
    getSectors(districtId) {
      if (districtId == "" || districtId == undefined || districtId == null) {
        this.sectors = [];
        this.cells = [];
        return;
      }
      this.$startLoader();
      this.$store
        .dispatch("GET_SECTORS", {
          token: this.$getToken(),
          districtId: districtId,
        })
        .then((res) => {
          this.sectors = res.data.data.data;
          this.cells = [];
          this.$stopLoader();
        });
    },
    getCells(sectorId) {
      if (sectorId == "" || sectorId == undefined || sectorId == null) {
        this.cells = [];
        return;
      }
      this.$startLoader();
      this.$store
        .dispatch("GET_CELLS", { token: this.$getToken(), sectorId: sectorId })
        .then((res) => {
          this.cells = res.data.data.data;
          this.$stopLoader();
        });
    },
    getModule() {
      let moduleCode = "IRAB";
      this.$store.dispatch("GET_MODULE_BY_CODE", moduleCode).then((res) => {
        this.ismodule = res.data.data.data[0];
      });
    },
    viewDashboard(moduleCode) {
      window.open(process.env.VUE_APP_AGRICULTURE_URL, "_blank");
    },
    openForm() {
      if (this.$getUser()) {
        this.showOrderModal = true;
        this.startContact = true;
      } else {
        this.$store.state.showModelLogin = true;
      }
    },

    sendOrder() {
      this.selectedProduct.order = this.orderData;
      this.$startLoader();
      this.$store
        .dispatch("ADD_AGRIORDER", {
          data: this.selectedProduct,
          token: this.$getToken(),
        })
        .then((res) => {
          this.$stopLoader();
          this.$notify({
            title: "Success",
            type: "success",
            text: "Order sent successfully",
          });
          this.closeViewModel();
        })
        .catch((err) => {
          this.$stopLoader();
          this.$notify({
            title: "Error",
            type: "error",
            text: "Something went wrong",
          });
        });
    },
    closeViewModel() {
      this.showOrderModal = false;
      document.body.style.overflowY = "auto";
    },
  },
  mounted() {
    if (this.$route.params.category) {
      this.forCategory = true;
      this.activeCategoryName = this.$route.params.category;
    }
    this.getAllProductCategories();
    this.getProvinces();
    this.getModule();
  },
  // watch route params category change
  watch: {
    "$route.params.categoryname": function (val) {
      this.forCategory = true;
      this.activeCategoryName = this.$route.params.category;
      // window.location.reload();
      this.getAllProductCategories();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.viewAgricultureModule {
  display: flex;
  flex-direction: column;
  // margin: 2rem 1rem;
  .page-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: $darkblue;
    margin-bottom: 1rem;
    margin: 2rem 1rem;
    margin-bottom: 0rem;
    @media (max-width: 1500px) {
      font-size: 1.2rem;
    }
  }
  .shop-widget {
    display: grid;
    grid-template-columns: 300px calc(100vw - 300px);
    box-shadow: 0 0 10px #0000003d;
    margin: 2rem 1rem;
    @media (max-width: 1500px) {
      grid-template-columns: 200px calc(100vw - 200px);
    }
    @media screen and (max-width: 1000px) {
      grid-template-columns: 1fr;
    }

    .left {
      background: white;
      padding: 1rem;
      border-right: 1px #eee solid;
      .header {
        b {
          @media screen and (max-width: 1500px) {
            font-size: 0.7rem !important;
          }
        }
      }
      .categories {
        @media screen and (max-width: 1000px) {
          display: none;
        }

        .filtercategories {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          margin: 1rem 0;
          padding: 0 1rem;
          input {
            width: 100%;
            border-radius: 5px;
            border: none;
            border-bottom: 1px solid #eee;
            padding: 5px 10px;
            outline: none;
            font-size: 0.75rem;
            font-weight: 400;
            color: #1f1f1f;
            @media (max-width: 1500px) {
              font-size: 0.6rem;
            }
            @media (max-width: 1200px) {
            }
            @media (max-width: 800px) {
            }
          }
        }
        .category-item {
          margin-bottom: 1rem;
          height: fit-content;
          max-height: 100vh;
          overflow-y: auto;
          .category-list {
            padding: 1rem 1rem;
            max-height: 200px;
            overflow-y: auto;
            @media (max-width: 1500px) {
              max-height: 150px;
              padding: 0.5rem 0.5rem;
            }
            .category {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 0.25rem;
              border-radius: 5px;
              cursor: pointer;
              &:hover,
              &.active {
                background: #eee;
              }
              label {
                font-size: 0.75rem;
                font-weight: 400;
                color: #1f1f1f;
                cursor: pointer;
                @media (max-width: 1500px) {
                  font-size: 0.5rem;
                }
                @media (max-width: 1200px) {
                }
                @media (max-width: 800px) {
                }
              }
              .badge {
                font-size: 0.7rem;
                font-weight: 400;
                color: #4a4a4a;
                cursor: pointer;
                @media (max-width: 1500px) {
                  font-size: 0.5rem;
                }
              }
            }
          }
        }
      }
    }
    .right {
      width: calc(100% - 50px);
      .products {
        display: flex;
        flex-direction: column;
        .products-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 1rem;
          @media (max-width: 1500px) {
            padding: 10px 0.5rem;
          }
          label {
            font-size: 1.2rem;
            font-weight: 700;
            color: black;
            @media (max-width: 1500px) {
              font-size: 0.9rem;
            }
          }
        }
        .products-list {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(250px, 278px));
          grid-gap: 1rem;
          padding: 1rem 2rem;
          max-height: 850px;
          overflow-y: auto;
          @media (max-width: 1500px) {
            grid-template-columns: repeat(auto-fit, minmax(140px, 200px));
          }
          @media (max-width: 1200px) {
            grid-template-columns: repeat(auto-fit, minmax(100px, 200px));
          }
          @media (max-width: 800px) {
            grid-template-columns: repeat(auto-fit, minmax(100px, 200px));
          }
          .product-item {
            background: white;
            box-shadow: 0 0 10px #0000003d;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .product-image {
              width: 100%;
              height: 100px;
              position: relative;
              @media (max-width: 1500px) {
                height: 80px;
              }
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
              .view-btn {
                position: absolute;
                padding: 0.5rem 1rem;
                background: #27272796;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: none;
                button {
                  font-size: 0.95rem;
                  font-weight: 400;
                  background: $blue;
                  color: white;
                  padding: 0.75rem 1.5rem;
                  border-radius: 5px;
                  cursor: pointer;
                  border: none;
                  @media (max-width: 1500px) {
                    font-size: 0.7rem;
                    padding: 0.5rem 1rem;
                  }
                  &:hover {
                    background: $darkblue;
                    color: white;
                  }
                }
              }
              &:hover {
                .view-btn {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
              }
            }
            .product-info {
              display: flex;
              flex-direction: column;
              padding: 1rem;
              padding-top: 10px;
              h3 {
                font-size: 0.8rem;
                font-weight: 700;
                color: black;
                margin: 0.5rem 0;
                margin-top: 0;
                @media (max-width: 1500px) {
                  font-size: 0.6rem;
                }
              }
              p,
              .cooperative {
                font-size: 0.8rem;
                font-weight: 400;
                color: #1f1f1f;
                margin-bottom: 0.5rem;
                display: flex;
                // justify-content: space-between;
                column-gap: 5px;
                @media (max-width: 1500px) {
                  font-size: 0.5rem;
                }
                span {
                  font-weight: 700;
                }
                &.price {
                  font-weight: 700;
                }
              }
            }
          }
        }
      }
    }
  }
}
.content {
  color: #636363 !important;
  font-style: italic;
  font-size: 0.8rem !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: none !important;
}

.filter-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 2rem;
  row-gap: 1rem;
  label {
    font-size: 1rem;
    font-weight: bolder;
    @media screen and (max-width: 1500px) {
      font-size: 0.7rem;
    }
  }
  .form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    column-gap: 20px;
    width: 100%;
    .form-group {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      column-gap: 20px;
      padding: 0 1rem;
      width: 100%;
      select {
        width: 100% !important;
        border-radius: 0px;
        border: none;
        border-bottom: 1px solid #eee;
        padding: 5px 10px;
        outline: none;
        font-size: 0.75rem;
        font-weight: 400;
        color: #1f1f1f;
        cursor: pointer;
        @media screen and (max-width: 1500px) {
          font-size: 0.6rem;
          padding: 5px 5px;
          height: fit-content !important;
        }
        @media (max-width: 1200px) {
        }
        @media (max-width: 800px) {
        }
      }
    }
    .group-4 {
      select {
        width: 150px;
      }
    }
    .submit {
      width: 100px;
      height: 40px;
      border-radius: 4px;
      border: none;
      outline: none;
      background-color: $blue;
      color: #fff;
      font-size: 1rem;
      font-weight: 600;
      cursor: pointer;
    }
  }

  .no-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    label {
      font-size: 1rem;
      font-weight: 500;
      color: #474747;
    }
    p {
      font-size: 0.8rem;
      color: #757575;
      font-weight: 400;
    }
  }
}

.jumbotron {
  width: 100%;
  height: fit-content;
  background-position: bottom right;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  .left {
    width: 80%;
    max-width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
    background: #eee;
    padding: 4rem 2rem;
    @media screen and (max-width: 1500px) {
      padding: 1rem 2rem;
    }
    a {
      display: inline-block !important;
    }
    // make it a trapazoid
    clip-path: polygon(0 0, 100% 0, 90% 100%, 0% 100%);
    @media screen and (max-width: 768px) {
      clip-path: none;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 3rem 2rem;
    }

    .module-info {
      width: 50%;
      h1 {
        font-size: 2rem;
        font-weight: 700;
        margin-bottom: 10px;
        @media screen and (max-width: 1500px) {
          font-size: 1.2rem;
        }
      }
      p {
        font-size: 0.9rem;
        line-height: 1.5;
        color: #535353;
        @media screen and (max-width: 1500px) {
          font-size: 0.8rem;
        }
      }
    }
    .module-actions {
      width: 40%;
      display: flex;
      justify-content: flex-end;
      button,
      a {
        padding: 1rem 2rem;
        border-radius: 5px;
        background: $blue;
        border: 1px solid $blue;
        font-size: 1.2rem;
        font-weight: 700;
        cursor: pointer;
        transition: all 0.3s ease;
        color: #fff;
        margin-right: 5rem;
        text-align: center;
        @media screen and (max-width: 1500px) {
          font-size: 0.7rem !important;
          padding: 0.5rem 1rem;
          margin-right: 2rem;
        }
        &:hover {
          background: darken($blue, 10%);
        }
      }
    }
  }
}

.ViewProduct {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  overflow-x: hidden;
  .dialog {
    width: 600px;
    height: 100%;
    background-color: #eee;
    position: relative;
    top: 0;
    transition: all 0.5s ease-in-out;
    border-radius: 20px 0px 0px 20px;
    overflow-y: auto;
    overflow-x: hidden;
    right: 0;
    .dialog-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 20px;
      border-bottom: 1px #e5e5e5 solid;
      h1 {
        font-size: 20px;
        font-weight: 600;
      }
      button {
        background-color: transparent;
        border: none;
        font-size: 30px;
        font-weight: 400;
        cursor: pointer;
      }
    }
    .dialog-body {
      width: 100%;
      height: calc(100% - 60px);
      padding: 1rem 20px;
      overflow-y: auto;
      .product {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;
        .product-image {
          width: 100%;
          height: 230px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .product-details {
          width: 100%;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          h1 {
            font-size: 20px;
            font-weight: 600;
            margin-top: 1rem;
          }
          p {
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 10px;
          }
          .product-price {
            width: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            padding-right: 10px;
            h1 {
              font-size: 20px;
              font-weight: 600;
              display: flex;
              justify-content: space-between;
              width: 100%;
              span {
                font-size: 14px;
                font-weight: 400;
              }
              label {
                font-size: 20px;
                font-weight: 600;
                color: #16a562;
              }
            }
          }
        }
        .buttons {
          width: 100%;
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 10px;
          margin-top: 10px;
          button {
            height: 50px;
            border: none;
            background-color: $blue;
            color: #fff;
            font-size: 14px;
            font-weight: 600;
            cursor: pointer;
            &:hover {
              background-color: $darkblue;
            }
            &.cancel {
              background-color: #2b2b2b;
              &:hover {
                background-color: #000;
              }
            }
          }
        }
        .for-more-info {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 20px;
          p {
            font-size: 14px;
            font-weight: 400;
            color: #2b2b2b;
          }
        }
        .contact-form {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 20px;
          form {
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            .input-group {
              width: 100%;
              display: flex;
              justify-content: space-between;
              margin-bottom: 10px;
              input,
              textarea {
                width: 100%;
                height: 50px;
                border: 1px #e5e5e5 solid;
                padding: 0 10px;
                font-size: 14px;
                font-weight: 400;
                &:focus {
                  outline: none;
                }
              }
              textarea {
                height: 100px;
              }
            }
          }
        }
      }
    }
  }
}
.dialog-body-top {
  background: white;
  max-height: 300px;
  height: 300px;
  margin-bottom: 1.5rem;
  width: 100%;
  img {
    object-fit: cover;
    width: 100%;
    height: 300px;
    max-height: 300px;
  }
}
</style>