<template>
  <div class="Home">
    <site-navbar />
    <div class="whole-content">
      <div class="header-widget">
        <div class="header-sections">
          <!-- <div
            class="header-section"
            :class="selectedSection == 1 ? 'active' : ''"
            @mouseover="selectedSection = 1"
          >
            <img src="/assets/images/tea.jpg" alt="" @error="$imageurlalt()" />
            <label for="" v-show="selectedSection == 1"
              ><span>
                {{
                  $txt(
                    "Find the best coach or school for yourself or your kids"
                  )
                }}</span
              >
              <div class="explore" v-show="selectedSection == 1">
                <button>{{ $txt("Explore") }}</button>
              </div>
            </label>
          </div> -->
          <div
            class="header-section"
            :class="selectedSection == 2 ? 'active' : ''"
            @mouseover="selectedSection = 2"
          >
            <img
              src="/assets/images/cover/tech.jpg"
              alt=""
              @error="$imageurlalt()"
            />
            <label for="" v-show="selectedSection == 2"
              ><span>{{ $txt("Hire a proffesional and find jobs.") }}"</span>
              <div class="explore" v-show="selectedSection == 2">
                <button>{{ $txt("Explore") }}</button>
              </div>
            </label>
          </div>
          <div
            class="header-section"
            :class="selectedSection == 3 ? 'active' : ''"
            @mouseover="selectedSection = 3"
          >
            <img
              src="/assets/images/cover/edu.jpg"
              alt=""
              @error="$imageurlalt()"
            />
            <label for="" v-show="selectedSection == 3">
              <span>{{
                $txt("Browse and know more about the best schools in Rwanda")
              }}</span>
              <div class="explore" v-show="selectedSection == 3">
                <button>{{ $txt("Explore") }}</button>
              </div>
            </label>
          </div>
          <div
            class="header-section"
            :class="selectedSection == 4 ? 'active' : ''"
            @mouseover="selectedSection = 4"
          >
            <img
              src="/assets/images/cover/rnit.jpg"
              alt=""
              @error="$imageurlalt()"
            />
            <label for="" v-show="selectedSection == 4">
              <span
                >{{ $txt("Boost your business and brand awareness") }}"</span
              >
              <div class="explore" v-show="selectedSection == 4">
                <button>{{ $txt("Explore") }}</button>
              </div>
            </label>
          </div>
          <div
            class="header-section"
            :class="selectedSection == 5 ? 'active' : ''"
            @mouseover="selectedSection = 5"
          >
            <img
              src="/assets/images/cover/trucks.jpg"
              alt=""
              @error="$imageurlalt()"
            />
            <label for="" v-show="selectedSection == 5"
              ><span>{{
                $txt(
                  "Transport your goods and materiels, Find transport clients for your vehicles"
                )
              }}</span>
              <div class="explore" v-show="selectedSection == 5">
                <button>{{ $txt("Explore") }}</button>
              </div>
            </label>
          </div>
          <div
            class="header-section"
            :class="selectedSection == 6 ? 'active' : ''"
            @mouseover="selectedSection = 6"
          >
            <img
              src="/assets/images/burger.jpg"
              alt=""
              @error="$imageurlalt()"
            />
            <label for="" v-show="selectedSection == 6"
              ><span>{{
                $txt(
                  "Sell or buy products and services to new customers and boost your sales"
                )
              }}</span>
              <div class="explore" v-show="selectedSection == 6">
                <button>{{ $txt("Explore") }}</button>
              </div>
            </label>
          </div>
        </div>
        <carousel
          :items-to-show="1"
          :wrapAround="true"
          :autoplay="3000"
          class="header-sections small"
        >
          <!-- <slide
            class="header-section"
            :class="selectedSection == 1 ? 'active' : ''"
            @mouseover="selectedSection = 1"
          >
            <img src="/assets/images/tea.jpg" alt="" @error="$imageurlalt()" />
            <label for=""
              ><span>
                {{
                  $txt(
                    "Find the best coach or school for yourself or your kids"
                  )
                }}</span
              >
              <div class="explore">
                <button>{{ $txt("Explore") }}</button>
              </div>
            </label>
          </slide> -->
          <slide
            class="header-section"
            :class="selectedSection == 2 ? 'active' : ''"
            @mouseover="selectedSection = 2"
          >
            <img
              src="/assets/images/cover/tech.jpg"
              alt=""
              @error="$imageurlalt()"
            />
            <label for=""
              ><span>{{ $txt("Hire a proffesional and find jobs.") }}"</span>
              <div class="explore">
                <button>{{ $txt("Explore") }}</button>
              </div>
            </label>
          </slide>
          <slide
            class="header-section"
            :class="selectedSection == 3 ? 'active' : ''"
            @mouseover="selectedSection = 3"
          >
            <img
              src="/assets/images/cover/edu.jpg"
              alt=""
              @error="$imageurlalt()"
            />
            <label for="">
              <span>{{
                $txt("Browse and know more about the best schools in Rwanda")
              }}</span>
              <div class="explore">
                <button>{{ $txt("Explore") }}</button>
              </div>
            </label>
          </slide>
          <slide
            class="header-section"
            :class="selectedSection == 4 ? 'active' : ''"
            @mouseover="selectedSection = 4"
          >
            <img
              src="/assets/images/cover/rnit.jpg"
              alt=""
              @error="$imageurlalt()"
            />
            <label for="">
              <span
                >{{ $txt("Boost your business and brand awareness") }}"</span
              >
              <div class="explore">
                <button>{{ $txt("Explore") }}</button>
              </div>
            </label>
          </slide>
          <slide
            class="header-section"
            :class="selectedSection == 5 ? 'active' : ''"
            @mouseover="selectedSection = 5"
          >
            <img
              src="/assets/images/cover/trucks.jpg"
              alt=""
              @error="$imageurlalt()"
            />
            <label for=""
              ><span>{{
                $txt(
                  "Transport your goods and materiels, Find transport clients for your vehicles"
                )
              }}</span>
              <div class="explore">
                <button>{{ $txt("Explore") }}</button>
              </div>
            </label>
          </slide>
          <slide
            class="header-section"
            :class="selectedSection == 6 ? 'active' : ''"
            @mouseover="selectedSection = 6"
          >
            <img
              src="/assets/images/burger.jpg"
              alt=""
              @error="$imageurlalt()"
            />
            <label for=""
              ><span>{{
                $txt(
                  "Sell or buy products and services to new customers and boost your sales"
                )
              }}</span>
              <div class="explore">
                <button>{{ $txt("Explore") }}</button>
              </div>
            </label>
          </slide>
        </carousel>
      </div>
      <div class="body-widget">
        <div class="widget-header">{{ $txt("Infomaster") }} Modules</div>
        <div class="categories all">
          <div class="right">
            <div class="category-item">
              <router-link
                :to="{
                  name: 'ViewModule',
                  params: {
                    moduleCode: 'IRAB',
                    name: $urlCompatible('Agriculture'),
                  },
                }"
                class="img"
              >
                <img
                  :src="'/assets/images/agriculture.svg'"
                  alt=""
                  @error="$imageurlalt()"
                />
              </router-link>
              <p>{{ $txt("Agriculture") }}</p>
            </div>
            <!-- Transsport, Business directory, Workers -->
            <div class="category-item">
              <router-link
                :to="{
                  name: 'Transport',
                }"
                class="img"
              >
                <img
                  :src="'/assets/images/trucks.svg'"
                  alt=""
                  @error="$imageurlalt()"
                />
              </router-link>
              <p>{{ $txt("Transport") }}</p>
            </div>
            <div class="category-item">
              <router-link
                :to="{
                  name: 'ListBusinesses',
                  params: { moduleCode: 'ITR', name: 'Business directory' },
                }"
                class="img"
              >
                <img
                  :src="'/assets/images/psf.svg'"
                  alt=""
                  @error="$imageurlalt()"
                />
              </router-link>
              <p>{{ $txt("Business directory") }}</p>
            </div>
            <div class="category-item">
              <router-link
                :to="{
                  name: 'Workers',
                }"
                class="img"
              >
                <img
                  :src="'/assets/images/tec.svg'"
                  alt=""
                  @error="$imageurlalt()"
                />
              </router-link>
              <p>{{ $txt("Workers") }}</p>
            </div>
            <div class="category-item">
              <router-link
                :to="{
                  name: 'Deals',
                }"
                class="img"
              >
                <img
                  :src="'/assets/images/deal.png'"
                  alt=""
                  @error="$imageurlalt()"
                />
              </router-link>
              <p>{{ $txt("Special deals") }}</p>
            </div>
          </div>
        </div>
        <div class="info-widget">
          <!-- <div class="widget-item">
            <div class="widget-header">
              <label for="">{{ $txt("Schools") }}</label>
              <router-link
                :to="{
                  name: 'ViewModule',
                  params: {
                    moduleCode: 'IEDU',
                    name: $urlCompatible('Education'),
                  },
                }"
                class="nav-link"
              >
                <label for="">{{ $txt("View all") }}</label>
              </router-link>
            </div>
            <div class="widget-content schools">
              <div
                class="widget-body"
                v-for="school in schools"
                :key="school.id"
              >
                <router-link
                  :to="{
                    name: 'ViewModule',
                    params: {
                      moduleCode: 'IEDU',
                      name: $urlCompatible('Education'),
                    },
                  }"
                >
                  <img
                    :src="'/assets/images/' + school.image"
                    alt=""
                    @error="$imageurlalt()"
                  />
                  <p>{{ school.name }}</p>
                  <p class="desc">{{ school.description }}</p>
                </router-link>
              </div>
            </div>
          </div> -->
          <div class="widget-item">
            <div class="widget-header">
              <label for="">{{ $txt("Transport") }}</label>
              <router-link
                :to="{
                  name: 'Transport',
                }"
                class="nav-link"
              >
                <label for="">{{ $txt("View all") }}</label>
              </router-link>
            </div>
            <div class="widget-content">
              <div
                class="widget-body"
                v-for="truck in allTrucks.slice(0, 3)"
                :key="truck.id"
              >
                <router-link
                  :to="{
                    name: 'ViewModule',
                    params: {
                      moduleCode: 'IRAB',
                      name: $urlCompatible('Agriculture'),
                    },
                  }"
                >
                  <img
                    :src="$file(truck.vehicle_images[0])"
                    alt=""
                    @error="$imageurlalt()"
                  />
                  <p>{{ truck.name }}</p>
                  <p class="desc">
                    <span></span>{{ truck.vehicle_brand }}
                    {{ truck.car_body.car_body_name }}
                  </p>
                  <p class="desc">
                    <span>Location: </span>{{ truck.district_name }} -
                    {{ truck.sector_name }}
                  </p>
                </router-link>
              </div>
            </div>
          </div>
          <div class="widget-item">
            <div class="widget-header">
              <label for="">{{ $txt("Workers") }}</label>
              <router-link
                :to="{
                  name: 'Workers',
                }"
                class="nav-link"
              >
                <label for="">{{ $txt("View all") }}</label>
              </router-link>
            </div>
            <div class="widget-content">
              <div
                class="widget-body"
                v-for="worker in allworkers.slice(0, 3)"
                :key="worker.worker_id"
              >
                <router-link
                  :to="{
                    name: 'Workers',
                  }"
                >
                  <img
                    :src="$file(worker.worker_image)"
                    alt=""
                    @error="$imageurlalt()"
                  />
                  <p>{{ worker.worker_name }}</p>
                  <p class="desc skills">
                    <span>Skills: </span>
                    <i
                      v-for="skill in JSON.parse(worker.worker_skills)"
                      :key="skill.skill_id"
                      >{{ skill.skill_name }},
                    </i>
                  </p>
                  <p class="desc">
                    <span>Location: </span>{{ worker.district_name }} -
                    {{ worker.sector_name }}
                  </p>
                </router-link>
              </div>
            </div>
          </div>
          <div class="widget-item full-row">
            <div class="widget-header">
              <label for="">{{ $txt("Business directory") }}</label>
              <router-link
                :to="{
                  name: 'ListBusinesses',
                }"
                class="nav-link"
              >
                <label for="">{{ $txt("View all") }}</label>
              </router-link>
            </div>
            <div class="widget-content business">
              <div
                class="widget-body"
                v-for="business in allbusinesses.slice(0, 6)"
                :key="business.business_id"
              >
                <router-link
                  :to="{
                    name: 'ViewModule',
                    params: {
                      moduleCode: 'IBD',
                      name: $urlCompatible('Business directory'),
                    },
                  }"
                >
                  <img
                    :src="$file(business.business_image)"
                    alt=""
                    @error="$imageurlalt()"
                  />
                  <p>{{ business.business_name }}</p>
                  <p class="desc">
                    <span>Category: </span
                    >{{ business.businesssector.sector_name }}
                  </p>
                  <p class="desc">
                    <span>Ownership: </span
                    >{{ business.ownership.ownership_name }}
                  </p>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="ads-widget">
          <div class="widget-header">
            <label for="">{{ $txt("Special deals") }}</label>
            <router-link
              :to="{
                name: 'Deals',
              }"
              class="nav-link"
            >
              <label for="">{{ $txt("View all") }}</label>
            </router-link>
          </div>
          <div class="widget-content">
            <carousel
              :settings="{
                itemsToShow: 1.2,
                snapAlign: 'center',
                autoplay: 3000,
                wrapAround: true,
              }"
              :breakpoints="breakpoints"
            >
              <slide v-for="ad in ads" :key="ad.id" @click="startView(ad)">
                <div class="ad-body">
                  <img :src="ad.image" alt="" @error="$imageurlalt()" />
                  <p class="desc"><span></span>{{ ad.owner }}</p>
                  <p class="desc ad">
                    {{ ad.description }}
                  </p>
                </div>
              </slide>
              <template #addons>
                <navigation />
              </template>
            </carousel>
          </div>
        </div>
      </div>
    </div>
    <view-product
      v-if="viewProduct"
      :item="selectItem"
      @closePopup="viewProduct = false"
    />
    <site-footer />
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
  data() {
    return {
      schools: [
        {
          id: 1,
          name: "King David Academy",
          description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatum! Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatum! Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatum!",
          image: "king.jpg",
        },
        {
          id: 2,
          name: "Rwanda Coding Academy",
          description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatum! Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatum! Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatum!",
          image: "rca.png",
        },
      ],
      farms: [
        {
          id: 1,
          name: "Kanombe ICC Farm",
          image: "pou1.jpg",
          mainProduct: "Poultry Eggs",
          district: "Kicukiro",
          sector: "Kanombe",
        },
        {
          id: 2,
          name: "Munenega farm",
          image: "pou2.jpg",
          mainProduct: "Cattle Meat",
          district: "Gasabo",
          sector: "Kimironko",
        },
        {
          id: 3,
          name: "Mugisha farm",
          image: "pou3.jpg",
          mainProduct: "Poultry Meat",
          district: "Burera",
          sector: "Butaro",
        },
      ],
      workers: [
        {
          id: 1,
          name: "Mugisha Faustin",
          image: "pro1.jpg",
          skills: ["Carpenter", "Plumber", "Electrician"],
          distric: "Kicukiro",
          sector: "Kanombe",
        },
        {
          id: 2,
          name: "Kalisa Emmanuel",
          image: "pro2.jpg",
          skills: ["Lawyer", "Accountant", "Engineer"],
          distric: "Gasabo",
          sector: "Kimironko",
        },
        {
          id: 3,
          name: "Eric Kanamugire",
          image: "pro3.jpg",
          skills: ["Domestic worker", "Driver", "Security guard"],
          distric: "Burera",
          sector: "Butaro",
        },
      ],
      techs: [
        {
          id: 1,
          name: "Eng. Mugabo Faustin",
          image: "pro1.jpg",
        },
        {
          id: 2,
          name: "Eng. Kalisa Emmanuel",
          image: "pro1.jpg",
        },
        {
          id: 3,
          name: "Eng. Eric Kanamugire",
          image: "pro1.jpg",
        },
      ],
      businesses: [
        {
          id: 1,
          name: "Ireme Technologies",
          image: "ireme.png",
          category: "Information Technology",
          ownership: "Individual",
        },
        {
          id: 2,
          name: "Infomaster",
          image: "infomaster.png",
          category: "Engineering",
          ownership: "Company",
        },
        {
          id: 3,
          name: "Ireme Technologies",
          image: "ireme.png",
          category: "Transport",
          ownership: "Cooperative",
        },
        {
          id: 4,
          name: "Ireme Technologies",
          image: "ireme.png",
          category: "Information Technology",
          ownership: "Individual",
        },
        {
          id: 5,
          name: "Infomaster",
          image: "infomaster.png",
          category: "Engineering",
          ownership: "Company",
        },
        {
          id: 6,
          name: "Ireme Technologies",
          image: "ireme.png",
          category: "Transport",
          ownership: "Cooperative",
        },
      ],
      ads: [
        {
          id: 1,
          name: "Burgers",
          image: "/assets/images/burger.jpg",
          owner: "Burger Planet",
          description:
            "Get 20% off on all burgers before 10:00 AM every day with Burger Planet.",
        },
        {
          id: 2,
          name: "Jordan shoes",
          image: "/assets/images/shoe.png",
          owner: "Bryan store",
          description:
            "Get the best Jordan shoes at the best price with Bryan. Shop the latest selection of Jordan Shoes. Find the hottest sneakers.",
        },
        {
          id: 3,
          name: "Sofa set",
          image: "/assets/images/home.png",
          owner: "Mika furniture",
          description:
            "Customizable living room furniture sofa set fabric sofa. Customized High quality living room furniture living room sofa set fabric sofa",
        },
        {
          id: 4,
          name: "Accessories",
          image: "/assets/images/dish.png",
          owner: "MIKA Group",
          description:
            "Dish washing machine. When we enjoy cooking, we always have to face the problem of washing dishes. Dishwashers are not only a convenient way to clean up after a meal, but also save a considerable amount of water compared to washing dishes by hand.Dishwashers can clean tableware, fruits and vegetables, and also use the drying function after cleaning to keep tableware dry and tasteless. Hot temperature and hot wind are suitable for baby products and oily tableware to give glassware a sparkling clean finish.",
        },
      ],
      ismodules: [],
      modules: [],
      selectedSection: 2,
      viewProduct: false,
      selectItem: {},
      breakpoints: {
        // 700px and up
        768: {
          itemsToShow: 1.2,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 3.5,
          snapAlign: "start",
        },
      },
      allTrucks: [],
      allworkers: [],
      allbusinesses: [],
    };
  },
  methods: {
    startView(ad) {
      this.selectItem = ad;
      this.viewProduct = true;
    },
    getVehicles() {
      this.$startLoader();
      this.$store
        .dispatch("GET_ALL_VEHICLES", {
          token: this.$getToken(),
        })
        .then((res) => {
          this.allTrucks = res.data.data;
          this.allTrucks.forEach((item) => {
            console.log(item.vehicle_images);
            item.vehicle_images = JSON.parse(item.vehicle_images);
            console.log(item.vehicle_images);
          });
          this.changePage(1);
          this.$stopLoader();
        })
        .catch((err) => {
          console.log(err);
          this.$stopLoader();
        });
    },
    getWorkers() {
      this.$startLoader();
      this.$store
        .dispatch("GET_ALL_WORKERS", {
          token: this.$getToken(),
        })
        .then((res) => {
          this.allworkers = res.data.data;
          this.changePage(1);
          this.$stopLoader();
        })
        .catch((err) => {
          console.log(err);
          this.$stopLoader();
        });
    },
    getBusinesses() {
      this.$startLoader();
      this.$store
        .dispatch("GET_ALL_BUSINESSES", {
          token: this.$getToken(),
        })
        .then((res) => {
          this.allbusinesses = res.data.data;
          this.$stopLoader();
        })
        .catch((err) => {
          console.log(err);
          this.$stopLoader();
        });
    },
  },
  mounted() {
    this.$getUser();
    this.getVehicles();
    this.getWorkers();
    this.getBusinesses();
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.whole-content {
  padding: 2rem 5rem;
  background: #f1f3f3;
  @media screen and (max-width: 768px) {
    padding: 2rem 1rem;
  }
  .header-widget {
    .header-sections {
      display: flex;
      column-gap: 1rem;
      row-gap: 1rem;
      @media screen and (max-width: 768px) {
        display: none;
      }
      &.small {
        display: none;

        @media screen and (max-width: 768px) {
          display: flex;
        }
      }
      .header-section {
        position: relative;
        border-radius: 20px;
        overflow: hidden;
        border: 1px solid #ccc;
        width: 200px;
        height: 350px;
        cursor: pointer;
        transition: 0.5s ease-in-out;
        &.active {
          width: 100%;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          background: #f2f2f2;
          // inner-shadow
          box-shadow: inset 0 0 1000px rgba(0, 0, 0, 0.2);
          cursor: pointer;
        }
        label {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 100%;
          padding: 1rem;
          color: #fff;
          font-size: 0.8rem;
          font-weight: 600;
          transition: 1s ease-in-out;
          text-align: right;
          text-shadow: 0 0 10px rgba(0, 0, 0, 0.438);
          display: flex;
          justify-content: flex-end;
          cursor: pointer;
          flex-direction: column;
          background: linear-gradient(
            to top,
            rgba(0, 0, 0, 0.8) 0%,
            rgba(0, 0, 0, 0.5) 30%,
            rgba(0, 0, 0, 0) 50%,
            rgba(0, 0, 0, 0) 100%
          );
          span {
            font-size: 1rem;
            font-weight: 400;
            height: 100%;
            display: flex;
            align-items: flex-end;
            width: 100%;
          }
          .explore {
            display: flex;
            align-items: flex-end;
            width: 100%;
            button {
              background: white;
              color: black;
              padding: 10px 20px;
              border-radius: 3px;
              font-size: 12px;
              font-weight: 600;
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
  .body-widget {
    .widget-header {
      padding: 1rem 2rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      label {
        font-size: 1rem;
        font-weight: 400;
        cursor: pointer;
      }
      a {
        font-size: 10px;
        font-weight: 500;
        color: $blue;
        padding: 10px 20px;
        border-radius: 3px;
        cursor: pointer;
      }
    }
    .categories {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 2rem;
      border: 1px solid #ccc;
      @media screen and (max-width: 768px) {
        flex-direction: column;
        padding: 1rem;
      }
      &.all {
        border-radius: 10px 10px 0 0;
      }
      &.yours {
        border-radius: 0 0 10px 10px;
      }
      .left {
        width: 20%;
        h3 {
          font-size: 1.5rem;
          font-weight: 600;
          margin-bottom: 5px;
          color: $blue;
        }
        p {
          font-size: 14px;
          font-weight: 4 00;
          margin-bottom: 10px;
          color: #494949;
        }
        a {
          font-size: 15px;
          font-weight: 600;
          background: #fff;
          color: $lightblue;
          border-radius: 3px;
          margin-top: 0.5rem;
          display: inline-block;
        }
      }
      .right {
        width: 80%;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        column-gap: 1rem;
        row-gap: 1rem;
        @media screen and (max-width: 768px) {
          grid-template-columns: repeat(2, 1fr);
        }
        .category-item {
          padding: 0 1.5rem;
          display: flex;
          align-items: center;
          flex-direction: column;
          .img {
            background: #fff;
            border: 1px solid #ccc;
            width: 75px;
            height: 75px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;
            border-radius: 5px;
            &:hover {
              box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
              transition: all 0.5s ease-in-out;
            }
            img {
              width: 100%;
              height: 100%;
              border-radius: 5px;
              object-fit: contain;
            }
          }
          p {
            font-size: 14px;
            font-weight: 600;
            width: 100%;
            text-align: center;
            margin-top: 10px;
          }
        }
      }
    }
    .filter-schools {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .filter-left {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: fit-content;
        select {
          width: 150px;
          height: 40px;
          border: none;
          border-bottom: 1px solid #ccc;
          padding: 0 10px;
          font-size: 12px;
          margin-left: 10px;
        }
      }
      .filter-right {
        width: 300px;
        .search {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          position: relative;
          input {
            width: 100%;
            height: 40px;
            border-radius: 5px;
            border: 1px solid #ccc;
            padding: 0 10px;
            padding-right: 40px;
          }
          i {
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            position: absolute !important;
            right: 10px;
          }
        }
      }
    }
    .info-widget {
      align-items: center;
      padding: 2rem 0;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 1rem;
      row-gap: 1rem;
      @media screen and (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr);
      }
      .widget-item {
        border-radius: 5px;
        overflow: hidden;
        border: 1px solid #ccc;
        &.full-row {
          grid-column: 1 / 3;
        }
        .widget-content {
          width: 100%;
          padding: 2rem;
          display: grid;
          row-gap: 1.5rem;
          grid-template-columns: repeat(3, 1fr);
          @media screen and (max-width: 768px) {
            grid-template-columns: repeat(1, 1fr);
          }
          &.business {
            grid-template-columns: repeat(6, 1fr);
            @media screen and (max-width: 768px) {
              grid-template-columns: repeat(1, 1fr);
            }
          }
          .widget-body {
            padding: 0 1.5rem;
            img {
              width: 100%;
              height: 150px;
              border-radius: 5px;
              object-fit: cover;
              background: #f2f2f2;
            }
            p {
              font-size: 15px;
              font-weight: 600;
              width: 100%;
              text-align: left;
              margin-top: 10px;
              &.desc {
                font-size: 12px;
                font-weight: 400;
                margin-top: 5px;
                text-align: left;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                span {
                  font-weight: 600;
                }
              }
              &.skills {
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
              }
            }
          }
        }
      }
    }
    .ads-widget {
      .ad-body {
        padding: 0 1.5rem;
        img {
          width: 100%;
          height: 300px;
          border-radius: 5px;
          object-fit: cover;
          background: #f2f2f2;
        }
        p {
          font-size: 0.9rem;
          font-weight: 600;
          width: 100%;
          text-align: center;
          margin-top: 10px;
          @media screen and (max-width: 768px) {
            font-size: 0.7rem;
          }
          &.desc {
            font-size: 14px;
            font-weight: 400;
            margin-top: 5px;
            color: #494949;
            text-align: left;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;

            @media screen and (max-width: 768px) {
              font-size: 0.7rem;
            }
            span {
              font-weight: 600;
            }
          }
          &.ad {
            font-size: 1.1rem;
            color: black;
            @media screen and (max-width: 768px) {
              font-size: 0.8rem;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .page-content {
    .body-widget {
      .categories {
        flex-direction: column;
        padding: 1rem;
        border: none;

        .left,
        .right {
          width: 100%;
        }

        .right {
          grid-template-columns: repeat(4, 1fr);
          column-gap: 0.5rem;
          row-gap: 0.5rem;
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .page-content {
    .body-widget {
      .info-widget {
        grid-template-columns: 1fr;
        row-gap: 1rem;

        .widget-item {
          .widget-content {
            grid-template-columns: repeat(1, 1fr);
          }
        }
      }

      .categories {
        .left,
        .right {
          width: 100%;
        }

        .right {
          grid-template-columns: repeat(2, 1fr);
          column-gap: 0.5rem;
          row-gap: 0.5rem;
        }
      }
    }
  }
}
</style>