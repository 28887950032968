<template>
  <div class="users">
    <super-admin-topbar :pageTitle="'Users'" />
    <users-navigation :activePage="'all'" />
    <div class="page-content">
      <div class="levels-table">
        <div class="table-header">
          <div class="left">
            <div class="search">
              <input
                type="text"
                placeholder="Search"
                @keyup="searchData()"
                v-model="search"
              />
            </div>
          </div>
          <div class="right">
            <!-- <button @click="popupOpen = true">Add user</button> -->
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th>Names</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Nationality</th>
              <th>Role</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in users" :key="user.userId">
              <td>{{ user.names }}</td>
              <td>{{ user.emailAddress }}</td>
              <td>{{ user.telephone }}</td>
              <td>{{ user.nationalityName.countryName }}</td>
              <td>{{ user.userRole.roleName }}</td>
              <td>
                <button @click="edituser(user)">
                  <img src="/assets/images/edit.svg" alt="" />
                </button>
                <!-- <button @click="deleteuser(user)">
                  <img src="/assets/images/delete.svg" alt="" />
                </button> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <model
        :modelTitle="'Edit ' + selecteduser.names"
        :largeModel="false"
        :smallModel="true"
        v-if="editPopupOpen"
        @closeModel="editPopupOpen = false"
      >
        <form action="" class="addLevel" @submit.prevent="confirmEdit()">
          <div class="form-group">
            <label for="">Change role</label>
            <select name="" id="" v-model="selecteduser.userRole.id">
              <option value="">Select role</option>
              <option v-for="role in userRoles" :key="role.id" :value="role.id">
                {{ role.roleName }}
              </option>
            </select>
          </div>
          <button class="assign" @click.prevent="startAssign = true">
            Assign a module
          </button>
          <div v-if="startAssign" class="form-group">
            <label for="">Assign a module</label>
            <select name="" id="" v-model="moduleAssign.module_id">
              <option value="">Select module</option>
              <option
                v-for="ismodule in modules"
                :key="ismodule.moduleId"
                :value="ismodule.moduleId"
              >
                {{ ismodule.moduleName }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <button type="submit">Save</button>
          </div>
        </form>
      </model>
      <confirm-model
        :modelTitle="'Delete User'"
        :smallModel="true"
        v-if="deletePopupOpen"
        @closeModel="deletePopupOpen = false"
        @confirmModel="confirmDelete()"
        :displayText="'Are you sure you want to delete this user?'"
      ></confirm-model>
    </div>
  </div>
</template>

<script>
export default {
  name: "users",
  data() {
    return {
      users: [],
      allusers: [],
      search: "",
      popupOpen: false,
      editPopupOpen: false,
      newuser: {
        userName: "",
      },
      selecteduser: {},
      deletePopupOpen: false,
      userRoles: [],
      modules: [],
      startAssign: false,
      moduleAssign: {
        user_id: "",
        module_id: "",
      },
    };
  },
  methods: {
    getusers() {
      this.$startLoader();
      this.$store
        .dispatch("GET_ALL_USERS", { token: this.$getToken() })
        .then((res) => {
          this.users = res.data.data.data;
          this.allusers = res.data.data.data;
          this.selecteduser = {};
          this.moduleAssign = {
            user_id: "",
            module_id: "",
          };
          this.$stopLoader();
        });
    },
    getUserRoles() {
      this.$store
        .dispatch("GET_USER_ROLES", { token: this.$getToken() })
        .then((res) => {
          this.userRoles = res.data.data.data;
        });
    },
    searchData() {
      this.users = this.allusers.filter((user) => {
        return user.names.toLowerCase().includes(this.search.toLowerCase());
      });
    },
    deleteuser(user) {
      this.selecteduser = user;
      this.deletePopupOpen = true;
    },
    confirmDelete() {
      this.$startLoader();
      this.$store
        .dispatch("DELETE_USER", {
          token: this.$getToken(),
          data: {
            userId: this.selecteduser.userId,
          },
        })
        .then((res) => {
          if (res.data.status) {
            this.$notify({
              title: "Success",
              type: "success",
              text: "user deleted successfully",
            });
          }
          this.deletePopupOpen = false;
          this.getusers();
        })
        .catch((err) => {
          console.log(err);
          this.$notify({
            title: "Error",
            type: "error",
            text: err.response.data.error,
          });
        });
    },
    edituser(user) {
      this.selecteduser = user;
      this.moduleAssign.user_id = user.userId;
      this.editPopupOpen = true;
    },
    confirmEdit() {
      this.$startLoader();
      this.$store
        .dispatch("CHANGE_USER_ROLE", {
          token: this.$getToken(),
          data: {
            user_id: this.selecteduser.userId,
            role_id: this.selecteduser.userRole.id,
          },
        })
        .then((res) => {
          if (res.data.status) {
            if (this.moduleAssign.module_id != "") {
              this.$store
                .dispatch("ASSIGN_MODULE_ADMIN", {
                  token: this.$getToken(),
                  data: this.moduleAssign,
                })
                .then((res) => {
                  if (res.data.status) {
                    this.$notify({
                      title: "Success",
                      type: "success",
                      text: "module assigned successfully",
                    });
                  }
                  this.editPopupOpen = false;
                  this.getusers();
                })
                .catch((err) => {
                  console.log(err);
                  this.$notify({
                    title: "Error",
                    type: "error",
                    text: err.response.data.error,
                  });
                  this.$stopLoader();
                });
              this.$notify({
                title: "Success",
                type: "success",
                text: "user edited successfully",
              });
            } else {
              this.$notify({
                title: "Success",
                type: "success",
                text: "user edited successfully",
              });
            }
          }
          this.editPopupOpen = false;
          this.getusers();
        })
        .catch((err) => {
          console.log(err);
          this.$notify({
            title: "Error",
            type: "error",
            text: err.response.data.error,
          });
          this.$stopLoader();
        });
    },
  },
  mounted() {
    this.getusers();
    this.getUserRoles();
    this.modules = this.$store.state.ismodules;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.users {
  .levels-table {
    padding: 0 1rem;
    .table-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
      .left {
        display: flex;
        align-items: center;
        .search {
          input {
            padding: 0.5rem;
            border: 1px solid #ccc;
            border-radius: 5px;
            outline: none;
            width: 300px;
          }
        }
      }
      .right {
        button {
          padding: 0.65rem 1rem;
          width: 150px;
          border: none;
          outline: none;
          border-radius: 5px;
          background: $blue;
          color: #fff;
          cursor: pointer;
          font-weight: 600;
        }
      }
    }
    table {
      width: 100%;
      border-collapse: collapse;
      thead {
        tr {
          th {
            padding: 10px;
            border-bottom: 1px solid #ccc;
            text-align: left;
          }
        }
      }
      tbody {
        tr {
          td {
            padding: 10px;
            border-bottom: 1px solid #ccc;
            button {
              background: transparent;
              border: none;
              outline: none;
              cursor: pointer;
              margin-right: 1rem;
              img {
                width: 30px;
              }
            }
          }
        }
      }
    }
  }
  .addLevel {
    .form-group {
      margin-bottom: 1rem;
      label {
        display: block;
        margin-bottom: 0.5rem;
      }
      input,
      select {
        padding: 0.5rem;
        border: 1px solid #ccc;
        border-radius: 5px;
        outline: none;
        width: 100%;
      }
      button {
        padding: 0.65rem 1rem;
        width: 150px;
        border: none;
        outline: none;
        border-radius: 5px;
        background: $blue;
        color: #fff;
        cursor: pointer;
        font-weight: 600;
      }
    }
  }
}
.assign {
  padding: 0.65rem 1rem;
  width: fit-content;
  border: none;
  outline: none;
  border-radius: 5px;
  background: $lightblue;
  color: #fff;
  cursor: pointer;
  font-weight: 600;
  margin-bottom: 1rem;
}
</style>