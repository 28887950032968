import axios from 'axios'
import { API_URL, TRANSPORT_API_URL } from '../../variables';
const GET_ALL_VEHICLES_URL = '/transport/getAllVehicles';
const GET_ALL_VEHICLES_BY_LOCATION_URL = '/transport/getVehiclesByLocation';
const GET_MY_VEHICLES_URL = '/transport/getMyVehicles';
const ADD_VEHICLE_URL = '/transport/createVehicle';
const EDIT_VEHICLE_URL = '/transport/updateVehicle';
const DELETE_VEHICLE_URL = '/transport/deleteVehicle';

const GET_CAR_BODIES_URL = '/transport/getCarBodies';
const transport = {
    state: {
    },
    getters: {},
    mutations: {},
    actions: {
        ['GET_ALL_VEHICLES']: ({ commit }, payload) => {
            return axios({
                url: TRANSPORT_API_URL + GET_ALL_VEHICLES_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                }
            })
        },
        ['GET_ALL_VEHICLES_BY_LOCATION']: ({ commit }, payload) => {
            return axios({
                url: TRANSPORT_API_URL + GET_ALL_VEHICLES_BY_LOCATION_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                }
            })
        },
        ['ADD_VEHICLE']: ({ commit }, payload) => {
            return axios({
                url: TRANSPORT_API_URL + ADD_VEHICLE_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                }
            })
        },
        ['EDIT_VEHICLE']: ({ commit }, payload) => {
            return axios({
                url: TRANSPORT_API_URL + EDIT_VEHICLE_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                }
            })
        },
        ['DELETE_VEHICLE']: ({ commit }, payload) => {
            return axios({
                url: TRANSPORT_API_URL + DELETE_VEHICLE_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                }
            })
        },
        ['GET_MY_VEHICLES']: ({ commit }, payload) => {
            return axios({
                url: TRANSPORT_API_URL + GET_MY_VEHICLES_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                }
            })
        },
        ['GET_CAR_BODIES']: ({ commit }, payload) => {
            return axios({
                url: TRANSPORT_API_URL + GET_CAR_BODIES_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                }
            })
        },

},
modules: {},

}
export default transport;