<template>
    <topbar/>
    <div class="job-applications-page">
      <div class="card" v-for="application in applications" :key="application.id">
        <div class="card-header">
          <h3>{{ application.jobTitle }}</h3>
          <span>Status: {{ application.status }}</span>
        </div>
        <div class="card-body">
          <p>Job Poster: {{ application.jobPoster }}</p>
          <p>Applicant: {{ application.applicant }}</p>
          <p>Application Date: {{ application.applicationDate }}</p>
          <button @click="viewDetails(application.id)">View Details</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "JobApplicationsPage",
    data() {
      return {
        applications: [
          {
            id: 1,
            jobTitle: "Web Developer",
            jobPoster:"Emma ",
            applicant: "Faustin Niyigena",
            applicationDate: "2023-10-15",
            status: "Pending",
          },
          {
            id: 2,
            jobTitle: "Graphic Designer",
            jobPoster: "leo",
            applicant: "Faustin Niyigena",
            applicationDate: "2023-10-16",
            status: "Accepted",
          },
        ],
      };
    },
    methods: {

    },
  };
  </script>
  
  <style scoped>
  .job-applications-page {
    display: flex;
    flex-wrap: wrap;
  }
  
  .card {
    border: 1px solid #ccc;
    border-radius: 8px;
    margin: 16px;
    width: 300px;
  }
  
  .card-header {
    background-color: #f0f0f0;
    padding: 8px;
    border-bottom: 1px solid #ccc;
  }
  
  .card-header h3 {
    margin: 0;
    font-size: 18px;
  }
  
  .card-header span {
    font-size: 14px;
    color: #777;
  }
  
  .card-body {
    padding: 16px;
  }
  
  button {
    background-color: #1d3557;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 8px;
  }
  </style>
  