<template>
  <notifications position="bottom right" :style="'z-index: 99999999999;'" />
  <iloader v-if="$store.state.isLoading" />
  <router-view />
  <model
    v-if="$store.state.showModelLogin"
    :modelTitle="'Login'"
    @closeModel="$store.state.showModelLogin = false"
    :modelSize="'sm'"
  >
    <Login-Model />
    <div class="buttons">
      <button @click="$store.state.showModelLogin = false" class="delete md">
        {{ $txt("Close") }}
      </button>
    </div>
  </model>
</template>
<script>
import iloader from "./views/components/iloader.vue";
export default {
  name: "App",
  data() {},
  components: {
    iloader,
  },
  methods: {
    getModules() {
      this.$store
        .dispatch("GET_ALL_MODULES")
        .then((res) => {
          this.$store.state.ismodules = res.data.data.data;
        })
        .catch((res) => {
          this.$stopLoader();
          this.$notify({
            title: "Error",
            type: "error",
            text: "Something went wrong",
          });
        });
    },

    getCountries() {
      if (this.$store.state.countries.length == 0) {
        this.$store.dispatch("GET_COUNTRIES").then((res) => {
          this.$store.state.countries = res.data.data.data;
        });
      }
    },
  },
  mounted() {
    this.$getCountries();
    this.getModules();
    this.getCountries();
    this.$store.state.apiUploadUrl =
      process.env.VUE_APP_BUSINESS_BACKEND + "/files/images/";
  },
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");
@import "@/assets/scss/main.scss";
* {
  font-family: "Roboto Condensed", sans-serif;
  font-family: "Fira Sans", sans-serif;
  font-family: "Poppins", sans-serif;
}
.page-content {
  // margin-top: 10px;
}
button,
a {
  cursor: pointer !important;
  label {
    cursor: pointer !important;
  }
}

input,
select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  outline: none;
}
input[type="checkbox"] {
  width: auto;
}
.form-body {
  .form-group {
    margin-bottom: 20px;
    position: relative;
    label {
      display: block;
      margin-bottom: 5px;
      position: absolute;
      top: 5px;
      left: 10px;
      font-size: 13px;
      color: #5e5e5e;
    }
    input,
    select {
      width: 100%;
      padding: 10px;
      border: 1px solid #ddd;
      border-radius: 5px;
      outline: none;
    }
    .submit,
    .prev {
      width: 100%;
      padding: 10px;
      border: 1px solid #ddd;
      border-radius: 5px;
      outline: none;
      background: $blue;
      color: #fff;
      font-weight: 600;
      cursor: pointer;
      &:hover {
        background: darken($blue, 10%);
      }
    }
    .prev {
      background: #000;
      &:hover {
        background: #000;
      }
    }
  }
}
.page-content {
  height: calc(100vh - 60px);
  overflow-y: auto;
}
.filter-form {
  display: flex;
  align-items: center;
  margin: 2rem 0rem;
  row-gap: 1rem;
  label {
    font-size: 1rem;
    font-weight: 600;
    margin-right: 1rem;
  }
  .form {
    display: flex;
    align-items: center;
    column-gap: 20px;

    .form-group {
      display: flex;
      align-items: center;
      column-gap: 20px;
      @media screen and (max-width: 1000px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
      @media screen and (max-width: 768px) {
        display: grid;
        grid-template-columns: 1fr;
      }
      select {
        width: 200px;
        height: 40px;
        border-radius: 4px;
        border: 1px solid $blue;
        padding: 0 10px;
        outline: none;
      }
    }
    .group-4 {
      select {
        width: 150px;
      }
    }
    .submit {
      width: 100px;
      height: 40px;
      border-radius: 4px;
      border: none;
      outline: none;
      background-color: $blue;
      color: #fff;
      font-size: 1rem;
      font-weight: 600;
      cursor: pointer;
    }
  }

  .no-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    label {
      font-size: 1rem;
      font-weight: 500;
      color: #474747;
    }
    p {
      font-size: 0.8rem;
      color: #757575;
      font-weight: 400;
    }
  }
}
th {
  @media screen and (max-width: 1500px) {
    font-size: 10px;
  }
}
td {
  padding: 10px;
  border-bottom: 1px solid #ccc;
  @media screen and (max-width: 1500px) {
    font-size: 10px;
  }
  button {
    border: none;
    outline: none;
    cursor: pointer;
    margin-right: 5px;
    font-size: 0.8rem;
    width: fit-content !important;
    @media screen and (max-width: 1500px) {
      font-size: 10px !important;
    }
    img {
      width: 30px;
      @media screen and (max-width: 1500px) {
        width: 20px !important;
      }
    }
    label {
      cursor: pointer;
    }
  }
  a {
    color: $lightblue;
    font-weight: 400;
    &.View {
      color: #fff;
      background: $blue;
      padding: 0.15rem 0.8rem;
      border-radius: 5px;
      cursor: pointer;
      label {
        cursor: pointer;
      }
    }
  }
  .verify {
    padding: 0.15rem 0.8rem !important;
    border-radius: 5px !important;
    background: #4da24d !important;
    color: #fff !important;
    display: inline-block !important;
    @media screen and (max-width: 1500px) {
      font-size: 10px;
    }
  }
  .unverify {
    padding: 0.15rem 0.8rem !important;
    border-radius: 5px !important;
    background: #2e2e2e !important;
    color: #fff !important;
    display: inline-block !important;
    @media screen and (max-width: 1500px) {
      font-size: 10px;
    }
  }
  .normal {
    padding: 0.15rem 0.8rem;
    border-radius: 5px;
    background: $blue;
    color: #fff;
    display: inline-block;
    @media screen and (max-width: 1500px) {
      font-size: 10px;
    }
  }
  span {
    font-size: 10px;
    &.View {
      color: $lightblue;
      border-radius: 5px;
      font-size: 1rem;
      cursor: pointer;
      label {
        cursor: pointer;
      }
    }
  }
  .verified {
    padding: 0.1rem 0.8rem;
    border-radius: 50px;
    background: #4da24d;
    color: #fff;
    display: inline-block;
  }
  .unverified {
    padding: 0.1rem 0.8rem;
    border-radius: 50px;
    background: #a44d4d;
    color: #fff;
    display: inline-block;
  }
}
.item-per-page {
  display: flex;
  select {
    width: 70px;
    border-radius: 4px;
    border: 1px solid $blue;
    padding: 0 10px;
    outline: none;
    margin-left: 10px;
  }
}
.search {
  input {
    width: 300px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    outline: none;
    @media (max-width: 1500px) {
      padding: 5px;
      font-size: 10px;
    }
  }
}
.search-section {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $blue;
  position: relative;
  padding-bottom: 3rem;
  form {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 5rem;
    @media (max-width: 768px) {
      margin-top: 2rem;
      width: 90%;
    }
    p {
      font-size: 2rem;
      font-weight: 400;
      color: white;
      text-align: center;
      span {
        color: $yellow;
      }
      @media (max-width: 768px) {
        font-size: 1.5rem;
      }
      @media (max-width: 576px) {
        font-size: 1.2rem;
      }
    }
    label {
      font-size: 1.2rem;
      font-weight: 400;
      color: $yellow;
      margin-top: 2rem;
      @media (max-width: 768px) {
        font-size: 1rem;
      }
      @media (max-width: 576px) {
        font-size: 0.8rem;
      }
    }
    input {
      width: 50%;
      border-radius: 500px;
      border: none;
      outline: none;
      padding: 1rem;
      margin-top: 20px;
      font-size: 1.2rem;
      font-weight: 600;
      color: #242424;
      border: 1px solid $blue;
      position: absolute;
      bottom: -1.75rem;

      &::placeholder {
        color: #646464;
        font-weight: 300;
      }
    }
  }
}
.form-group {
  input,
  select,
  textarea {
    margin-top: 0;
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    outline: none;
  }
  label {
    font-size: 0.8rem;
    color: #2f2f2f;
    margin-bottom: 0;
    span {
      font-size: 0.6rem;
      color: #821010;
    }
  }
  .input-group {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin-bottom: 1rem;
    width: 100%;
  }
  &.group-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }
  &.group-3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1rem;
    @media screen and (max-width: 1000px) {
      grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }
  &.group-4 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 1rem;
    @media screen and (max-width: 1000px) {
      grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }
  // submit button
  input[type="submit"],
  button[type="submit"] {
    width: 200px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    outline: none;
    background: $blue;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
    &:hover {
      background: darken($blue, 10%);
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }
}
.add-business {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  label {
    font-size: 1rem;
    color: #6d6d6d;
    font-weight: 400;
  }
  button {
    background: $blue;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 500;
    outline: none;
    border: none;
    cursor: pointer;
  }
}
td {
  &.img {
    @media screen and (max-width: 1500px) {
    }
    img {
      height: 60px;
      width: 60px;
      object-fit: cover;
      @media screen and (max-width: 1500px) {
        height: 50px;
        width: 50px;
      }
    }
  }
}

.delete {
  padding: 0.15rem 0.8rem !important;
  border-radius: 5px !important;
  background: #a44d4d !important;
  color: #fff !important;
  display: inline-block !important;
  &.md {
    padding: 0.5rem 1.5rem !important;
  }
}
.d-flex {
  display: flex;
  column-gap: 10px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.ml-10 {
  margin-left: 10px;
}
.mr-10 {
  margin-right: 10px;
}
.mb-10 {
  margin-bottom: 10px;
}
.pd-10 {
  padding: 10px;
}
input,
select,
textarea {
  display: inline-block;
  // width: 100%;
  padding: 5px 10px !important;
  border: 1px solid #ddd;
  border-radius: 5px;
  outline: none;
  &:focus {
    border: 1px solid $blue;
  }
}
.grid-4 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 10px;
}
.grid-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 10px;
}
.multiSelect {
  input {
    width: auto !important;
    border: none !important;
  }
}
// .ql-container {
//   &.ql-snow {
//     height: 200px !important;
//   }
// }
.b-bottom {
  border: none !important;
  border-bottom: 1px solid #ddd !important;
  margin-bottom: 10px !important;
}
.table {
  width: 100%;
}
.bus-table {
  thead {
    background: rgb(222, 239, 241);
    th {
      padding: 5px;
    }
  }
}

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  .left {
    display: flex;
    align-items: center;
    .search {
      input {
        padding: 0.5rem;
        border: 1px solid #ccc;
        border-radius: 5px;
        outline: none;
        width: 300px;
        @media screen and (max-width: 1500px) {
          font-size: 12px;
          padding: 0.25rem;
        }
      }
    }
  }
  .right {
    button {
      padding: 0.65rem 1rem;
      width: 150px;
      border: none;
      outline: none;
      border-radius: 5px;
      background: $blue;
      color: #fff;
      cursor: pointer;
      font-weight: 600;
    }
  }
}
th {
  text-align: left;
}

.btn-lg {
  padding: 0.65rem 1rem;
  width: 150px;
  border: none;
  outline: none;
  border-radius: 5px;
  background: $blue;
  color: #fff;
  cursor: pointer;
  font-weight: 600;
}
.btn-md {
  padding: 0.65rem 1rem !important;
  border: none;
  outline: none;
  border-radius: 5px;
  background: $blue;
  color: #fff;
  cursor: pointer;
  font-weight: 600;
  margin-right: 10px;
}
.btn {
  padding: 0.65rem 1rem !important;
  border: none;
  outline: none;
  border-radius: 5px;
  background: $blue;
  color: #fff;
  cursor: pointer;
  font-weight: 600;
  margin-right: 10px;
  .delete {
    padding: 0.65rem 1rem !important;
    border-radius: 5px !important;
    background: #a44d4d !important;
    color: #fff !important;
    display: inline-block !important;
  }
}
.group-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
}
.group-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 10px;
}
.group-4 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 10px;
}
.group-5 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  column-gap: 10px;
}




.filter-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 2rem;
  row-gap: 1rem;
  label {
    font-size: 1rem;
    font-weight: bolder;
    @media screen and (max-width: 1500px) {
      font-size: 0.7rem;
    }
  }
  .form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    column-gap: 20px;
    width: 100%;
    .form-group {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      column-gap: 20px;
      padding: 0 1rem;
      width: 100%;
      select {
        width: 100% !important;
        border-radius: 0px;
        border: none;
        border-bottom: 1px solid #eee;
        padding: 5px 10px;
        outline: none;
        font-size: 0.75rem;
        font-weight: 400;
        color: #1f1f1f;
        cursor: pointer;
        @media screen and (max-width: 1500px) {
          font-size: 0.6rem;
          padding: 5px 5px;
          height: fit-content !important;
        }
        @media (max-width: 1200px) {
        }
        @media (max-width: 800px) {
        }
      }
    }
    .group-4 {
      select {
        width: 150px;
      }
    }
    .submit {
      width: 100px;
      height: 40px;
      border-radius: 4px;
      border: none;
      outline: none;
      background-color: $blue;
      color: #fff;
      font-size: 1rem;
      font-weight: 600;
      cursor: pointer;
    }
  }

  .no-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    label {
      font-size: 1rem;
      font-weight: 500;
      color: #474747;
    }
    p {
      font-size: 0.8rem;
      color: #757575;
      font-weight: 400;
    }
  }
}


.header-widget {
    margin-top: 3rem;
    .header-sections {
      display: flex;
      column-gap: 1rem;
      row-gap: 1rem;
      .header-section {
        position: relative;
        border-radius: 20px;
        overflow: hidden;
        border: 1px solid #ccc;
        width: 200px;
        height: 350px;
        cursor: pointer;
        transition: 0.5s ease-in-out;
        &.active {
          width: 100%;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          background: #f2f2f2;
          // inner-shadow
          box-shadow: inset 0 0 1000px rgba(0, 0, 0, 0.2);
          cursor: pointer;
        }
        label {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 100%;
          padding: 1rem;
          color: #fff;
          font-size: 0.8rem;
          font-weight: 600;
          transition: 1s ease-in-out;
          text-align: right;
          text-shadow: 0 0 10px rgba(0, 0, 0, 0.438);
          display: flex;
          justify-content: flex-end;
          cursor: pointer;
          flex-direction: column;
          background: linear-gradient(
            to top,
            rgba(0, 0, 0, 0.8) 0%,
            rgba(0, 0, 0, 0.5) 30%,
            rgba(0, 0, 0, 0) 50%,
            rgba(0, 0, 0, 0) 100%
          );
          span {
            font-size: 1rem;
            font-weight: 400;
            height: 100%;
            display: flex;
            align-items: flex-end;
            width: 100%;
          }
          .explore {
            display: flex;
            align-items: flex-end;
            width: 100%;
            button {
              background: white;
              color: black;
              padding: 10px 20px;
              border-radius: 3px;
              font-size: 12px;
              font-weight: 600;
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
</style>