<template>
  <div class="Categories">
    <super-admin-topbar :pageTitle="'Agricultural Products'" />
    <div class="page-content">
      <div class="levels-table">
        <div class="table-header">
          <div class="left">
            <div class="search">
              <input
                type="text"
                placeholder="Search"
                @keyup="searchData()"
                v-model="search"
              />
            </div>
          </div>
          <div class="right">
            <!-- <button @click="popupOpen = true">Add Product</button> -->
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Product</th>
              <th>Quantity</th>
              <th>Owner</th>
              <th>Owner Type</th>
              <th>Client</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items" :key="item.id">
              <td class="img">
                <img
                  :src="$file(item.production.images[0])"
                  alt=""
                  @error="$imageurlalt"
                />
              </td>
              <td>{{ item.product.product_name }}</td>
              <td>{{ item.order_quantity }}</td>
              <td>
                {{ item.production.contact_name }}<br />{{
                  item.production.contact_phone
                }}<br />{{ item.production.contact_email }}
              </td>
              <td>{{ item.owner_type }}</td>
              <td>
                {{ item.user.full_names }}<br />{{ item.user.telephone
                }}<br />{{ item.user.email_address }}
              </td>
              <td>
                <p class="status" :class="item.order_status">
                  {{ item.order_status }}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "Categories",
  data() {
    return {
      search: "",
      popupOpen: false,
      editPopupOpen: false,
      selectedItem: {},
      deletePopupOpen: false,
      items: [],
    };
  },
  methods: {
    searchData() {
      if (this.search == "") {
        this.items = this.allItems;
      } else {
        this.items = this.allItems.filter((item) => {
          return (
            item.product.product_name
              .toLowerCase()
              .indexOf(this.search.toLowerCase()) > -1
          );
        });
      }
    },
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_AGRIORDERS", {
          data: { user_id: this.$getUser().userId },
        })
        .then((res) => {
          this.allItems = res.data.data;
          this.allItems.forEach((item) => {
            item.production.images = JSON.parse(item.production.images);
          });
          this.items = this.allItems;
          this.$stopLoader();
        })
        .catch((err) => {
          this.$stopLoader();
        });
    },
  },
  mounted() {
    this.getItems();
  },
};
</script>
  
  <style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.page-content {
  padding: 1rem;
}
.Categories {
  .levels-table {
    .table-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
      .left {
        display: flex;
        align-items: center;
        .search {
          input {
            padding: 0.5rem;
            border: 1px solid #ccc;
            border-radius: 5px;
            outline: none;
            width: 300px;
          }
        }
      }
      .right {
        button {
          padding: 0.65rem 1rem;
          width: 150px;
          border: none;
          outline: none;
          border-radius: 5px;
          background: $blue;
          color: #fff;
          cursor: pointer;
          font-weight: 600;
        }
      }
    }
    table {
      width: 100%;
      border-collapse: collapse;
      thead {
        tr {
          th {
            padding: 10px;
            border-bottom: 1px solid #ccc;
            text-align: left;
          }
        }
      }
      tbody {
        tr {
          td {
            padding: 10px;
            border-bottom: 1px solid #ccc;
            button {
              background: transparent;
              border: none;
              outline: none;
              cursor: pointer;
              margin-right: 1rem;
              img {
                width: 30px;
              }
            }
          }
        }
      }
    }
  }
  .addLevel {
    .form-group {
      margin-bottom: 1rem;
      label {
        display: block;
        margin-bottom: 0.5rem;
      }
      input {
        padding: 0.5rem;
        border: 1px solid #ccc;
        border-radius: 5px;
        outline: none;
        width: 100%;
      }
      button {
        padding: 0.65rem 1rem;
        width: 150px;
        border: none;
        outline: none;
        border-radius: 5px;
        background: $blue;
        color: #fff;
        cursor: pointer;
        font-weight: 600;
      }
    }
  }
}

p{
  &.pending{
    background: #f1c40f;
    color: #fff;
    border-radius: 500px;
    padding: 0.15rem .7rem;
    display: inline-block;
    font-size: .7rem;
  }
  &.approved{
    background: #2ecc71;
    color: #fff;
    border-radius: 500px;
    padding: 0.15rem .7rem;
    display: inline-block;
    font-size: .7rem;
  }
  &.rejected{
    background: #e74c3c;
    color: #fff;
    border-radius: 500px;
    padding: 0.15rem .7rem;
    display: inline-block;
    font-size: .7rem;
  }
}
</style>