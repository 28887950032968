

import axios from 'axios'
import { API_URL, AGRICULTURE_API_URL } from '../../variables';
const GET_AGRISECTORS_URL = AGRICULTURE_API_URL + '/sector/getProductSectors';
const DELETE_AGRISECTOR_URL = AGRICULTURE_API_URL + '/sector/deleteProductSector';
const ADD_AGRISECTOR_URL = AGRICULTURE_API_URL + '/sector/addProductSector';
const EDIT_AGRISECTOR_URL = AGRICULTURE_API_URL + '/sector/updateProductSector';

// categories
const GET_AGRICATEGORIES_URL = AGRICULTURE_API_URL + '/category/getCategories';
const GET_AGRICATEGORIES_BYSECTOR_URL = AGRICULTURE_API_URL + '/category/getCategoriesBySector';
const DELETE_AGRICATEGORIES_URL = AGRICULTURE_API_URL + '/category/deleteCategory';
const ADD_AGRICATEGORIES_URL = AGRICULTURE_API_URL + '/category/addCategory';
const EDIT_AGRICATEGORIES_URL = AGRICULTURE_API_URL + '/category/updateCategory';

// units
const GET_AGRIUNITS_URL = AGRICULTURE_API_URL + '/units/getUnits';
const ADD_AGRIUNIT_URL = AGRICULTURE_API_URL + '/units/addUnit';
const EDIT_AGRIUNIT_URL = AGRICULTURE_API_URL + '/units/updateUnit';
const DELETE_AGRIUNIT_URL = AGRICULTURE_API_URL + '/units/deleteUnit';

// products
const GET_AGRIPRODUCTS_URL = AGRICULTURE_API_URL + '/product/getProducts';
const GET_AGRIPRODUCTSBYCATEGORY_URL = AGRICULTURE_API_URL + '/product/getProductsByCategory';
const GET_AGRIPRODUCTSBYAGRISECTOR_URL = AGRICULTURE_API_URL + '/product/getProductsByProductSector';
const ADD_AGRIPRODUCT_URL = AGRICULTURE_API_URL + '/product/addProduct';
const EDIT_AGRIPRODUCT_URL = AGRICULTURE_API_URL + '/product/updateProduct';
const DELETE_AGRIPRODUCT_URL = AGRICULTURE_API_URL + '/product/deleteProduct';

// production
const GET_AGRIPRODUCTIONS_URL = AGRICULTURE_API_URL + '/production/getProduction';
const GET_AGRIPRODUCTIONSBYAGRISECTOR_URL = AGRICULTURE_API_URL + '/production/getProductionBySector';
const GET_AGRIPRODUCTIONSBYCATEGORY_URL = AGRICULTURE_API_URL + '/production/getProductionByCategory';
const GET_AGRIPRODUCTIONBYPRODUCT_URL = AGRICULTURE_API_URL + '/production/getProductionByProduct';
const GET_AGRIPRODUCTIONBYCOOPERATIVE_URL = AGRICULTURE_API_URL + '/production/getProductionByCooperative';
const GET_MY_AGRIPRODUCTION_URL = AGRICULTURE_API_URL + '/production/getMyProduction';
const ADD_AGRIPRODUCTION_URL = AGRICULTURE_API_URL + '/production/addProduction';
const EDIT_AGRIPRODUCTION_URL = AGRICULTURE_API_URL + '/production/updateProduction';
const DELETE_AGRIPRODUCTION_URL = AGRICULTURE_API_URL + '/production/deleteProduction';


// orders
const GET_AGRIORDERS_URL = AGRICULTURE_API_URL + '/order/getOrders';
const GET_AGRIORDERSBYUSER_URL = AGRICULTURE_API_URL + '/order/getOrdersByUser';
const GET_AGRIORDERSBYPRODUCTION_URL = AGRICULTURE_API_URL + '/order/getOrdersByProduction';
const GET_AGRIORDERSBYPRODUCT_URL = AGRICULTURE_API_URL + '/order/getOrdersByProduct';
const GET_AGRIORDERSBYOWNER_URL = AGRICULTURE_API_URL + '/order/getOrdersByOwner';
const ADD_AGRIORDER_URL = AGRICULTURE_API_URL + '/order/addOrder';
const EDIT_AGRIORDER_URL = AGRICULTURE_API_URL + '/order/updateOrder';
const DELETE_AGRIORDER_URL = AGRICULTURE_API_URL + '/order/deleteOrder';
const agriculturalProducts = {
    state: {
    },
    getters: {},
    mutations: {},
    actions: {
        ['GET_AGRISECTORS']: ({ commit }, payload) => {
            return axios({
                url: GET_AGRISECTORS_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                }
            });
        },
        ['DELETE_AGRISECTOR']: ({ commit }, payload) => {
            return axios({
                url: DELETE_AGRISECTOR_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                }
            });
        },
        ['ADD_AGRISECTOR']: ({ commit }, payload) => {
            return axios({
                url: ADD_AGRISECTOR_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                }
            });
        },
        ['EDIT_AGRISECTOR']: ({ commit }, payload) => {
            return axios({
                url: EDIT_AGRISECTOR_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                },
            });
        },


        // categories
        ['GET_AGRICATEGORIES']: ({ commit }, payload) => {
            return axios({
                url: GET_AGRICATEGORIES_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                }
            });
        },
        ['GET_AGRICATEGORIES_BYSECTOR']: ({ commit }, payload) => {
            return axios({
                url: GET_AGRICATEGORIES_BYSECTOR_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                }
            });
        },
        ['DELETE_AGRICATEGORIES']: ({ commit }, payload) => {
            return axios({
                url: DELETE_AGRICATEGORIES_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                }
            });
        },
        ['ADD_AGRICATEGORIES']: ({ commit }, payload) => {
            return axios({
                url: ADD_AGRICATEGORIES_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                }
            });
        },
        ['EDIT_AGRICATEGORIES']: ({ commit }, payload) => {
            return axios({
                url: EDIT_AGRICATEGORIES_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                },
            });
        },


        // units
        ['GET_AGRIUNITS']: ({ commit }, payload) => {
            return axios({
                url: GET_AGRIUNITS_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                }
            });
        },
        ['ADD_AGRIUNIT']: ({ commit }, payload) => {
            return axios({
                url: ADD_AGRIUNIT_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                }
            });
        },
        ['EDIT_AGRIUNIT']: ({ commit }, payload) => {
            return axios({
                url: EDIT_AGRIUNIT_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                }
            });
        },
        ['DELETE_AGRIUNIT']: ({ commit }, payload) => {
            return axios({
                url: DELETE_AGRIUNIT_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                },
            });
        },
        ['GET_AGRIPRODUCTS']: ({ commit }, payload) => {
            return axios({
                url: GET_AGRIPRODUCTS_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                },
            });
        },
        ['GET_AGRIPRODUCTSBYCATEGORY']: ({ commit }, payload) => {
            return axios({
                url: GET_AGRIPRODUCTSBYCATEGORY_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                },
            });
        },
        ['GET_AGRIPRODUCTSBYAGRISECTOR']: ({ commit }, payload) => {
            return axios({
                url: GET_AGRIPRODUCTSBYAGRISECTOR_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                },
            });
        },
        ['ADD_AGRIPRODUCT']: ({ commit }, payload) => {
            return axios({
                url: ADD_AGRIPRODUCT_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                },
            });
        },
        ['EDIT_AGRIPRODUCT']: ({ commit }, payload) => {
            return axios({
                url: EDIT_AGRIPRODUCT_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                },
            });
        },
        ['DELETE_AGRIPRODUCT']: ({ commit }, payload) => {
            return axios({
                url: DELETE_AGRIPRODUCT_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                },
            });
        },

        // production
        ['GET_AGRIPRODUCTIONS']: ({ commit }, payload) => {
            return axios({
                url: GET_AGRIPRODUCTIONS_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                },
            });
        },
        ['GET_AGRIPRODUCTIONSBYAGRISECTOR']: ({ commit }, payload) => {
            return axios({
                url: GET_AGRIPRODUCTIONSBYAGRISECTOR_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                },
            });
        },
        ['GET_AGRIPRODUCTIONSBYCATEGORY']: ({ commit }, payload) => {
            return axios({
                url: GET_AGRIPRODUCTIONSBYCATEGORY_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                },
            });
        },
        ['GET_AGRIPRODUCTIONBYPRODUCT']: ({ commit }, payload) => {
            return axios({
                url: GET_AGRIPRODUCTIONBYPRODUCT_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                },
            });
        },
        ['GET_AGRIPRODUCTIONBYCOOPERATIVE']: ({ commit }, payload) => {
            return axios({
                url: GET_AGRIPRODUCTIONBYCOOPERATIVE_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                },
            });
        },
        ['GET_MY_AGRIPRODUCTION']: ({ commit }, payload) => {
            return axios({
                url: GET_MY_AGRIPRODUCTION_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                },
            });
        },
        ['ADD_AGRIPRODUCTION']: ({ commit }, payload) => {
            return axios({
                url: ADD_AGRIPRODUCTION_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                },
            });
        },
        ['EDIT_AGRIPRODUCTION']: ({ commit }, payload) => {
            return axios({
                url: EDIT_AGRIPRODUCTION_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                },
            });
        },
        ['DELETE_AGRIPRODUCTION']: ({ commit }, payload) => {
            return axios({
                url: DELETE_AGRIPRODUCTION_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                },
            });
        },

        // orders
        ['GET_AGRIORDERS']: ({ commit }, payload) => {
            return axios({
                url: GET_AGRIORDERS_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                },
            });

        },
        ['GET_AGRIORDERSBYUSER']: ({ commit }, payload) => {
            return axios({
                url: GET_AGRIORDERSBYUSER_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                },
            });

        },
        ['GET_AGRIORDERSBYPRODUCTION']: ({ commit }, payload) => {
            return axios({
                url: GET_AGRIORDERSBYPRODUCTION_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                },
            });

        },
        ['GET_AGRIORDERSBYPRODUCT']: ({ commit }, payload) => {
            return axios({
                url: GET_AGRIORDERSBYPRODUCT_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                },
            });

        },
        ['GET_AGRIORDERSBYOWNER']: ({ commit }, payload) => {
            return axios({
                url: GET_AGRIORDERSBYOWNER_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                },
            });

        },
        ['ADD_AGRIORDER']: ({ commit }, payload) => {
            return axios({
                url: ADD_AGRIORDER_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                },
            });

        },
        ['EDIT_AGRIORDER']: ({ commit }, payload) => {
            return axios({
                url: EDIT_AGRIORDER_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                },
            });

        },
        ['DELETE_AGRIORDER']: ({ commit }, payload) => {
            return axios({
                url: DELETE_AGRIORDER_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                },
            });
        },
        ['APPROVE_AGRIORDER']: ({ commit }, payload) => {
            return axios({
                url: AGRICULTURE_API_URL + '/order/approveOrder',
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                },
            });

        },
        ['REJECT_AGRIORDER']: ({ commit }, payload) => {
            return axios({
                url: AGRICULTURE_API_URL + '/order/rejectOrder',
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                },
            });

        },


    },
    modules: {},
}
export default agriculturalProducts;