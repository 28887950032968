import { API_URL } from '../variables';
import axios from 'axios'
const ADD_SCHOOL_URL = API_URL + '/schools';
const GET_SCHOOLS_URL = API_URL + '/schools';
const GET_ALL_SCHOOLS_URL = API_URL + '/schools/all';
const GET_SCHOOL_ACADEMICYEARS_URL = API_URL + '/schools/academicyears';
const ADD_SCHOOL_PRESENTER_URL = API_URL + '/schools/representer';
const GET_DEGREES_URL = API_URL + '/schools/domain/degrees';
const ADD_SCHOOL_PROPERTY_URL = API_URL + '/schools/properties'
const GET_PROPERTY_CHECKS_URL = API_URL + '/schools/properties/checks';
const GET_ALL_SCHOOL_PROPERTIES_URL = API_URL + '/schools/properties/all';
const GET_SCHOOL_BY_ID_URL = API_URL + '/schools/';
const GET_ALL_SUPERADMIN_SCHOOLS_URL = API_URL + '/superadmin/schools';
const VERIFY_SCHOOL_URL = API_URL + '/schools/verify';
const GET_SCHOOLS_BY_ADDRESS_URL = API_URL + '/schools/getSchoolsByAddress';
const GET_FILTERED_SCHOOLS_URL = API_URL + '/reports/getFilteredSchools';
const GET_FILTERED_SCHOOLS_BY_ADDRESS_URL = API_URL + '/schools/filterAll';

const GET_DEPARTMENTS_URL = API_URL + '/departments'


const translation = {
    state: {
    },
    getters: {},
    mutations: {},
    actions: {

        ['ADD_SCHOOL']: ({ commit }, payload) => {
            return axios({
                url: ADD_SCHOOL_URL,
                method: "POST",
                data: payload,
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
        ['GET_DEPARTMENTS']: ({ commit }, payload) => {
            return axios({
                url: GET_DEPARTMENTS_URL,
                method: "GET",
                data: payload,
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
        ['GET_SCHOOL_BY_ID']: ({ commit }, payload) => {
            return axios({
                url: GET_SCHOOL_BY_ID_URL + payload.schoolId,
                method: "GET",
                data: payload,
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }

            })
        },
        ['GET_SCHOOL_PROPERTIES']: ({ commit }, payload) => {
            return axios({
                url: GET_ALL_SCHOOL_PROPERTIES_URL,
                method: "POST",
                data: payload,
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
        ['GET_PROPERTIES_CHECK']: ({ commit }, payload) => {
            return axios({
                url: GET_PROPERTY_CHECKS_URL,
                method: "GET",
                data: payload,
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },

        ['ADD_SCHOOL_PROPERTY']: ({ commit }, payload) => {
            return axios({
                url: ADD_SCHOOL_PROPERTY_URL,
                method: "POST",
                data: payload,
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
        ['GET_SCHOOLS']: ({ commit }, payload) => {
            return axios({
                url: GET_SCHOOLS_URL,
                method: "GET",
                data: payload,
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
        ['GET_ALL_SCHOOLS']: ({ commit }, payload) => {
            return axios({
                url: GET_ALL_SCHOOLS_URL,
                method: "GET",
                data: payload
            })
        },
        ['GET_SCHOOL_ACADEMICYEARS']: ({ commit }, payload) => {
            return axios({
                url: GET_SCHOOL_ACADEMICYEARS_URL,
                method: "GET",
                data: payload
            })
        },
        ['ADD_SCHOOL_PRESENTER']: ({ commit }, payload) => {
            return axios({
                url: ADD_SCHOOL_PRESENTER_URL,
                method: "POST",
                data: payload
            })
        },
        ['GET_DEGREES']: ({ commit }, payload) => {
            return axios({
                url: GET_DEGREES_URL,
                method: "GET",
                data: payload,
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },

        ['GET_ALL_SUPERADMIN_SCHOOLS']: ({ commit }, payload) => {
            return axios({
                url: GET_ALL_SUPERADMIN_SCHOOLS_URL,
                method: "GET",
                data: payload,
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
        ['VERIFY_SCHOOL']: ({ commit }, payload) => {
            return axios({
                url: VERIFY_SCHOOL_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
        ['GET_SCHOOLS_BY_ADDRESS']: ({ commit }, payload) => {
            return axios({
                url: GET_SCHOOLS_BY_ADDRESS_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
        ['GET_FILTERED_SCHOOLS']: ({ commit }, payload) => {
            return axios({
                url: GET_FILTERED_SCHOOLS_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
        ['GET_FILTERED_SCHOOLS_BY_ADDRESS']: ({ commit }, payload) => {
            return axios({
                url: GET_FILTERED_SCHOOLS_BY_ADDRESS_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },

    },
    modules: {},
}
export default translation;