<template>
  <div class="ViewWorker">
    <div
      class="dialog"
      :class="dialogActive ? 'active' : ''"
      v-click-out-side="customMethod2"
    >
      <div class="dialog-header">
        <h1>{{ $txt("View worker") }}</h1>
        <button @click="closePopup">&times;</button>
      </div>
      <div class="dialog-body">
        <div class="worker-item">
          <div class="top-section">
            <div class="image">
              <img :src="$file(item.worker_image)" alt="" @error="$imageurlalt()" />
            </div>
            <div class="info">
              <div class="name">
                <h4>{{ item.user.full_names }}</h4>
                <p>{{ item.district_name }}, {{ item.sector_name }}</p>
              </div>
            </div>
          </div>
          <div class="bottom">
            <div class="middle-section">
              <!-- reviews, availablity, sector, category -->
              <div class="availability">
                <label for="">Availability</label>
                <p v-if="item.worker_availability">
                  <i
                    class="fa fa-circle"
                    :class="item.worker_availability ? 'active' : ''"
                  ></i>
                  Available Now
                </p>
                <p v-else><i class="fa fa-circle"></i> Not available</p>
              </div>
              <div class="sector">
                <label for="">Career</label>
                <p>{{ item.careersector.sector_name }}</p>
              </div>
              <div class="category">
                <label for="">Skills</label>
                <div class="skills">
                  <div class="skills-list">
                    <span v-for="skill in JSON.parse(item.worker_skills)" :key="skill">
                      {{ skill.skill_name }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="bottom-section">
              <div class="title">
                <label for="">{{ item.worker_title }}</label>
              </div>
              <div class="bio">
                <p class="desc" v-html="item.worker_bio"></p>
              </div>
              <div class="reviews-section">
                <div class="all-reviews">
                  <div class="title-label">Work History</div>
                  <div class="reviews-list">
                    <div
                      class="review-item"
                      v-for="review in item.reviews"
                      :key="review.review_id"
                    >
                      <div class="top">
                        <div class="info">
                          <div class="name">
                            <h4>{{ review.sender.full_names }}</h4>
                            <p>{{ $dateToDay(review.created_at) }}</p>
                          </div>
                        </div>
                      </div>
                      <div class="bottom">
                        <p>"{{ review.message }}"</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import clickOutSide from "@mahdikhashan/vue3-click-outside";
export default {
  name: "ViewWorker",
  props: {
    item: {
      type: Object,
      default: {
        id: 1,
        name: "Mugisha Faustin",
        image: "/assets/images/pro1.jpg",
        skills: ["Carpenter", "Plumber", "Electrician"],
        district: "Kicukiro",
        sector: "Kanombe",
        skillSector: "IT",
        category: "Software Developer",
        availability: true,
        title: "Software Developer",
        bio: "<p>I am an Experienced WordPress Developer ready to be at Your Service Anytime. I specialize in WordPress, Elementor Pro, UX & UI Design, Shopify, HTML, CSS, JavaScript, Bootstrap, MySQL, PHP, Adobe Photoshop & Illustrator.</p><p>Are you looking for a WordPress developer who can turn your Figma designs into beautiful and responsive WordPress websites? Do you need someone who is proficient in using Elementor, the most popular and powerful page builder for WordPress? Do you want to set up an online store with Woocommerce, the best e-commerce plugin for WordPress?</p><p>If you answered yes to any of these questions, then you are in the right place. I am a WordPress developer with over 5 years of experience in creating stunning WordPress websites from Figma designs. I can use Elementor to customize every aspect of your website, from the layout, colors, fonts, animations, and more. I can also integrate Woocommerce to your website and help you sell your products or services online.</p><p>I have worked with clients from various industries and niches, such as fashion, education, health, travel, and more. I always deliver high-quality work that meets or exceeds the client's expectations. I also communicate effectively and provide regular updates on the project's progress.</p><p>If you are interested in working with me, please send me a message and let me know more about your project. I would love to hear from you and discuss how we can create a WordPress website that suits your needs and goals.</p>",
        reviews: 20,
      },
    },
  },
  data() {
    return {
      dialogActive: false,
      clickCount: 0,
      showRejectPopup: false,
      showConfirmPopup: false,
      message: "",
      deduct: "Yes",
      startContact: false,
    };
  },
  directives: {
    clickOutSide,
  },
  methods: {
    closePopup() {
      this.dialogActive = false;
      setTimeout(() => {
        this.$emit("closePopup");
        document.body.style.overflowY = "auto";
      }, 500);
    },
    openForm() {
      this.clickCount = 0;
      this.startContact = true;
    },
    customMethod2() {
      this.clickCount++;
      if (this.clickCount > 1) {
        this.closePopup();
      }
    },
  },
  mounted() {
    // setTimeout
    setTimeout(() => {
      this.dialogActive = true;
    }, 10);
    document.body.style.overflow = "hidden";
  },
};
</script>
  
  <style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.ViewWorker {
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 9999;
  .dialog {
    width: 50%;
    height: 100%;
    background-color: #eee;
    position: relative;
    right: -60%;
    top: 0;
    transition: all 0.5s ease-in-out;
    border-radius: 20px 0px 0px 20px;
    overflow-y: auto;
    &.active {
      right: 0;
    }
    .dialog-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 20px;
      border-bottom: 1px #e5e5e5 solid;
      h1 {
        font-size: 20px;
        font-weight: 600;
      }
      button {
        background-color: transparent;
        border: none;
        font-size: 30px;
        font-weight: 400;
        cursor: pointer;
      }
    }
    .dialog-body {
      width: 100%;
      height: calc(100% - 60px);
      padding: 1rem 20px;
      overflow-y: auto;
      .worker-item {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        border-bottom: 1px solid #ccc;
        cursor: pointer;
        transition: 0.5s ease-in-out;
        padding: 0 10px;
        &:hover {
          background: #eee;
        }
        .top-section {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 1rem;
          border-bottom: 1px solid #ccc;
          .image {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            overflow: hidden;
            border: 3px solid #ccc;
            margin-right: 10px;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              background: #f2f2f2;
            }
          }
          .info {
            width: calc(100% - 100px);
            .name {
              h4 {
                font-size: 1.5rem;
                font-weight: 500;
                color: $blue;
              }
              label {
                font-size: 1.25rem;
                font-weight: 500;
                color: #000;
              }
              p {
                font-size: 0.9rem;
                font-weight: 400;
                color: #949494;
              }
            }
          }
        }
        .bottom {
          display: flex;
          .middle-section {
            display: flex;
            flex-direction: column;
            padding: 1rem 1rem;
            width: 300px;
            border-right: 1px solid #ccc;
            row-gap: 1rem;
            .skills {
              margin-top: 15px;
              label {
                font-size: 0.9rem;
                font-weight: 400;
                color: #fff;
              }
              .skills-list {
                display: flex;
                flex-wrap: wrap;
                column-gap: 0.5rem;
                row-gap: 0.5rem;
                span {
                  padding: 3px 10px;
                  border-radius: 50px;
                  background: $blue;
                  font-size: 0.9rem;
                  font-weight: 400;
                  color: #fff;
                }
              }
            }
            .availability {
              label {
                font-size: 0.9rem;
                font-weight: 400;
                color: #949494;
              }
              p {
                font-size: 0.9rem;
                font-weight: 400;
                i {
                  color: orange;
                  &.active {
                    color: #58b658;
                  }
                }
              }
            }
            .reviews {
              label {
                font-size: 0.9rem;
                font-weight: 400;
                color: #949494;
              }
              p {
                font-size: 1.1rem;
                font-weight: 600;
                color: black;
              }
            }
            .sector {
              label {
                font-size: 0.9rem;
                font-weight: 400;
                color: #949494;
              }
              p {
                font-size: 1.1rem;
                font-weight: 600;
                color: black;
              }
            }
            .category {
              label {
                font-size: 0.9rem;
                font-weight: 400;
                color: #949494;
              }
              p {
                font-size: 1.1rem;
                font-weight: 600;
                color: black;
              }
            }
          }
          .bottom-section {
            width: calc(100% - 300px);
            padding: 1rem;
            .bio {
              p {
                font-size: 0.9rem;
                font-weight: 400;
                color: #949494;
                &.desc {
                  font-size: 14px;
                  font-weight: 400;
                  margin-top: 5px;
                  color: #494949;
                  text-align: left;
                  span {
                    font-weight: 600;
                  }
                }
              }
            }
            .title {
              label {
                font-size: 1.8rem;
                font-weight: 500;
                color: #181818;
              }
            }
            .reviews-section {
              width: 100%;
              padding: 1rem;
              border-top: 1px solid #ccc;
              margin-top: 1rem;
              .all-reviews {
                width: 100%;
                .title-label {
                  font-size: 1.8rem;
                  font-weight: 500;
                  color: #181818;
                }
                .reviews-list {
                  width: 100%;
                  .review-item {
                    display: flex;
                    flex-direction: column;
                    padding: 1rem;
                    border-bottom: 1px solid #ccc;
                    .top {
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      margin-bottom: 10px;
                      .info {
                        width: calc(100% - 50px);
                        .name {
                          h4 {
                            font-size: 0.9rem;
                            font-weight: 500;
                            color: $blue;
                          }
                          label {
                            font-size: 1.25rem;
                            font-weight: 500;
                            color: #000;
                          }
                          p {
                            font-size: 0.9rem;
                            font-weight: 400;
                            color: #949494;
                          }
                        }
                      }
                    }
                    .bottom {
                      p {
                        font-size: 0.85rem;
                        font-weight: 400;
                        color: #161616;
                        font-style: italic;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>