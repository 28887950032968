import axios from 'axios'
import { API_URL, BUSS_API_URL } from '../../variables';
const GET_ALL_BUSINESSES_URL = '/businesses/getAllBusinesses';
const VERIFY_BUSINESS_URL = '/businesses/verifyBusiness';
const GET_MY_BUSINESSES_URL = '/businesses/getMyBusinesses';
const GET_BUSINESSES_BY_USER_ID = '/businesses/getAllBusinessesByUserId';
const GET_BUSINESSES_BY_LOCATION = '/businesses/getBusinessByLocation';
const GET_BUSINESSES_BY_BUSINESS_SECTOR = '/businesses/getBusinessByBusinessSector';
const GET_BUSINESS_BY_ID = '/businesses/getBusinessById';
const GET_VERIFIED_BUSINESSES = '/businesses/getAllVerifiedBusinesses';
const ADD_BUSINESS_URL = '/businesses/createBusiness';
const UPDATE_BUSINESS_URL = '/businesses/updateBusiness';
const DELETE_BUSINESS_URL = '/businesses/deleteBusiness';
const businessMainData = {
    state: {
    },
    getters: {},
    mutations: {},
    actions: {
        ['ADD_BUSINESS']: ({ commit }, payload) => {
            return axios({
                url: BUSS_API_URL + ADD_BUSINESS_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                },
            })
        },
        ['GET_ALL_BUSINESSES']: ({ commit }, payload) => {
            return axios({
                url: BUSS_API_URL + GET_ALL_BUSINESSES_URL,
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                }
            })
        },
        ['VERIFY_BUSINESS']: ({ commit }, payload) => {
            return axios({
                url: BUSS_API_URL + VERIFY_BUSINESS_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer '+ payload.token
                },
            })
        },
        ['GET_MY_BUSINESSES']: ({ commit }, payload) => {
            return axios({
                url: BUSS_API_URL + GET_MY_BUSINESSES_URL,
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                },
                data: payload.data
            })
        },
        ['GET_BUSINESSES_BY_USER_ID']: ({ commit }, payload) => {
            return axios({
                url: BUSS_API_URL + GET_BUSINESSES_BY_USER_ID,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                },
                data: payload.data
            })
        },
        ['GET_BUSINESSES_BY_LOCATION']: ({ commit }, payload) => {
            return axios({
                url: BUSS_API_URL + GET_BUSINESSES_BY_LOCATION,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                },
            })
        },
        ['GET_BUSINESSES_BY_BUSINESS_SECTOR']: ({ commit }, payload) => {
            return axios({
                url: BUSS_API_URL + GET_BUSINESSES_BY_BUSINESS_SECTOR,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                },
            })
        },
        ['GET_BUSINESS_BY_ID']: ({ commit }, payload) => {
            return axios({
                url: BUSS_API_URL + GET_BUSINESS_BY_ID,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                },
            })
        },
        ['GET_VERIFIED_BUSINESSES']: ({ commit }, payload) => {
            return axios({
                url: BUSS_API_URL + GET_VERIFIED_BUSINESSES,
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                },
            })
        },
        ['UPDATE_BUSINESS']: ({ commit }, payload) => {
            return axios({
                url: BUSS_API_URL + UPDATE_BUSINESS_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                },
            })
        },
        ['DELETE_BUSINESS']: ({ commit }, payload) => {
            return axios({
                url: BUSS_API_URL + DELETE_BUSINESS_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                },
            })
        },

    },
    modules: {},
}
export default businessMainData;