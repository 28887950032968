<template>
  <div class="Schools">
    <super-admin-topbar :pageTitle="'Schools'" />
    <div class="page-content">
      <div class="levels-table">
        <div class="table-header">
          <div class="left">
            <div class="search">
              <input
                type="text"
                placeholder="Search"
                @keyup="searchData()"
                v-model="search"
              />
            </div>
          </div>
          <div class="right">
            <!-- <button @click="popupOpen = true">Add Level</button> -->
          </div>
        </div>
        <div class="filter-form">
          <label for="">Filter: </label>
          <div class="form">
            <div class="form-group group-4">
              <!-- Province, Disctict, sector, cell -->
              <select
                name="province"
                id=""
                v-model="school.province"
                @change="getDistricts(school.province)"
              >
                <option value="null">{{ $txt("All Provinces") }}</option>
                <option
                  v-for="province in provinces"
                  :key="province.provinceId"
                  :value="province.provinceId"
                >
                  {{ province.provinceName }}
                </option>
              </select>
              <select
                name="district"
                id=""
                v-model="school.district"
                @change="getSectors(school.district)"
              >
                <option value="null">{{ $txt("All Districts") }}</option>
                <option
                  v-for="district in districts"
                  :key="district.districtId"
                  :value="district.districtId"
                >
                  {{ district.districtName }}
                </option>
              </select>
              <select
                name="sector"
                id=""
                v-model="school.sector"
                @change="getCells(school.sector)"
              >
                <option value="null">{{ $txt("All Sectors") }}</option>
                <option
                  v-for="sector in sectors"
                  :key="sector.sectorId"
                  :value="sector.sectorId"
                >
                  {{ sector.sectorName }}
                </option>
              </select>
              <select name="cell" id="" v-model="school.cell_id">
                <option value="null">{{ $txt("All Cells") }}</option>
                <option
                  v-for="cell in cells"
                  :key="cell.cellId"
                  :value="cell.cellId"
                >
                  {{ cell.cellName }}
                </option>
              </select>
            </div>
            <button class="submit" @click="getFilteredData()">Filter</button>
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th>School Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>District</th>
              <th>Sector</th>
              <th>Cell</th>
              <th>Status</th>
              <th width="100">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="school in schools" :key="school.id">
              <td>{{ school.schoolName }}</td>
              <td>{{ school.schoolEmail }}</td>
              <td>{{ school.schoolTelephone }}</td>
              <td>{{ school.district.districtName }}</td>
              <td>{{ school.sector.sectorName }}</td>
              <td>{{ school.cell.cellName }}</td>
              <td>
                <span v-if="school.verifyStatus == 'Verified'" class="verified">
                  <i class="fa fa-check" aria-hidden="true"></i
                ></span>
                <span
                  v-if="school.verifyStatus == 'Not Verified'"
                  class="unverified"
                >
                  <i class="fa fa-times" aria-hidden="true"></i>
                </span>
              </td>
              <td style="width: 200px">
                <button
                  @click="verifySchool(school)"
                  class="verify"
                  v-if="school.verifyStatus == 'Not Verified'"
                >
                  <label for="">Verify</label>
                </button>
                <button @click="unverifySchool(school)" class="unverify" v-else>
                  <label for="">Unverify</label>
                </button>
                <button @click="deleteSchool(school)" class="delete">
                  <label for="">Delete</label>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Schools",
  data() {
    return {
      schools: [],
      allSchools: [],
      popupOpen: false,
      editPopupOpen: false,
      editAction: "add",
      search: "",
      newLevel: {
        levelName: "",
      },
      selectedLevel: {},
      school: {
        province: "null",
        district: "null",
        sector: "null",
        cell_id: "null",
      },
      provinces: [],
      districts: [],
      sectors: [],
      cells: [],
    };
  },
  methods: {
    getSchools() {
      this.$startLoader();
      this.$store
        .dispatch("GET_ALL_SUPERADMIN_SCHOOLS", { token: this.$getToken() })
        .then((res) => {
          this.schools = res.data.data.data;
          this.allSchools = res.data.data.data;
          this.$stopLoader();
        })
        .catch((err) => {
          console.log(err);
          this.$notify({
            title: "Error",
            type: "error",
            text: err.response.data.error,
          });
          this.$stopLoader();
        });
    },
    searchData() {
      if (this.search.length > 0) {
        this.filterData();
      } else {
        this.schools = this.allSchools;
      }
    },
    filterData() {
      this.schools = this.allSchools.filter((school) => {
        return school.schoolName
          .toLowerCase()
          .includes(this.search.toLowerCase());
      });
    },
    verifySchool(school) {
      this.$startLoader();
      this.$store
        .dispatch("VERIFY_SCHOOL", {
          token: this.$getToken(),
          data: {
            school_id: school.id,
          },
        })
        .then((res) => {
          if (res.data.status) {
            this.$notify({
              title: "Success",
              type: "success",
              text: "School verified successfully",
            });
          }
          this.getSchools();
        })
        .catch((err) => {
          console.log(err);
          this.$notify({
            title: "Error",
            type: "error",
            text: err.response.data.error,
          });
          this.$stopLoader();
        });
    },
    unverifySchool(school) {
      this.$startLoader();
      this.$store
        .dispatch("VERIFY_SCHOOL", {
          token: this.$getToken(),
          data: {
            school_id: school.id,
          },
        })
        .then((res) => {
          if (res.data.status) {
            this.$notify({
              title: "Success",
              type: "success",
              text: "School unverified successfully",
            });
          }
          this.getSchools();
        })
        .catch((err) => {
          console.log(err);
          this.$notify({
            title: "Error",
            type: "error",
            text: err.response.data.error,
          });
          this.$stopLoader();
        });
    },
    getProvinces() {
      this.$startLoader();
      this.$store
        .dispatch("GET_PROVINCES", { token: this.$getToken() })
        .then((res) => {
          this.provinces = res.data.data.data;
          this.districts = [];
          this.sectors = [];
          this.cells = [];
          this.$stopLoader();
        });
    },
    getDistricts(provinceId) {
      if (provinceId == "" || provinceId == undefined || provinceId == null) {
        this.districts = [];
        this.sectors = [];
        this.cells = [];
        return;
      }
      this.$startLoader();
      this.$store
        .dispatch("GET_DISTRICTS", {
          token: this.$getToken(),
          provinceId: provinceId,
        })
        .then((res) => {
          this.districts = res.data.data.data;
          this.sectors = [];
          this.cells = [];
          this.$stopLoader();
        });
    },
    getSectors(districtId) {
      if (districtId == "" || districtId == undefined || districtId == null) {
        this.sectors = [];
        this.cells = [];
        return;
      }
      this.$startLoader();
      this.$store
        .dispatch("GET_SECTORS", {
          token: this.$getToken(),
          districtId: districtId,
        })
        .then((res) => {
          this.sectors = res.data.data.data;
          this.cells = [];
          this.$stopLoader();
        });
    },
    getCells(sectorId) {
      if (sectorId == "" || sectorId == undefined || sectorId == null) {
        this.cells = [];
        return;
      }
      this.$startLoader();
      this.$store
        .dispatch("GET_CELLS", { token: this.$getToken(), sectorId: sectorId })
        .then((res) => {
          this.cells = res.data.data.data;
          this.$stopLoader();
        });
    },
    getFilteredData() {
      if (
        this.school.province == "null" &&
        this.school.district == "null" &&
        this.school.sector == "null" &&
        this.school.cell_id == "null"
      ) {
        this.getSchools();
      } else {
        this.$startLoader();
        this.$store
          .dispatch("GET_FILTERED_SCHOOLS_BY_ADDRESS", {
            token: this.$getToken(),
            data: {
              provinceId: this.school.province,
              districtId: this.school.district,
              sectorId: this.school.sector,
              cellId: this.school.cell_id,
            },
          })
          .then((res) => {
            this.schools = res.data.data.data;
            this.allSchools = res.data.data.data;
            this.$stopLoader();
          })
          .catch((err) => {
            console.log(err);
            this.$notify({
              title: "Error",
              type: "error",
              text: err.response.data.error,
            });
            this.$stopLoader();
          });
      }
    },
  },
  mounted() {
    this.getSchools();
    this.getProvinces();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.Schools {
  .levels-table {
    padding: 1rem;
    .table-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
      .left {
        display: flex;
        align-items: center;
        .search {
          input {
            padding: 0.5rem;
            border: 1px solid #ccc;
            border-radius: 5px;
            outline: none;
            width: 300px;
          }
        }
      }
      .right {
        button {
          padding: 0.65rem 1rem;
          width: 150px;
          border: none;
          outline: none;
          border-radius: 5px;
          background: $blue;
          color: #fff;
          cursor: pointer;
          font-weight: 600;
        }
      }
    }
    .filter-form {
      display: flex;
      flex-direction: column;
      margin: 2rem 1rem;
      row-gap: 2rem;
      label {
        font-size: 1.2rem;
        font-weight: 600;
        margin-right: 1rem;
      }
      .form {
        display: flex;
        align-items: center;
        column-gap: 20px;
        .form-group {
          display: flex;
          align-items: center;
          column-gap: 20px;
          select {
            width: 200px;
            height: 40px;
            border-radius: 4px;
            border: 1px solid $blue;
            padding: 0 10px;
            outline: none;
          }
        }
        .group-4 {
          select {
            width: 150px;
          }
        }
        .submit {
          width: 100px;
          height: 40px;
          border-radius: 4px;
          border: none;
          outline: none;
          background-color: $blue;
          color: #fff;
          font-size: 1rem;
          font-weight: 600;
          cursor: pointer;
        }
      }

      .no-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        label {
          font-size: 1rem;
          font-weight: 500;
          color: #474747;
        }
        p {
          font-size: 0.8rem;
          color: #757575;
          font-weight: 400;
        }
      }
    }
    table {
      width: 100%;
      border-collapse: collapse;
      thead {
        tr {
          th {
            padding: 10px;
            border-bottom: 1px solid #ccc;
            text-align: left;
          }
        }
      }
      tbody {
        tr {
          td {
            padding: 10px;
            border-bottom: 1px solid #ccc;
            button {
              border: none;
              outline: none;
              cursor: pointer;
              margin-right: 5px;
              font-size: 15px;
              width: 80px;
              img {
                width: 30px;
              }
              label {
                cursor: pointer;
              }
            }
            .verify {
              padding: 0.15rem 0.8rem;
              border-radius: 5px;
              background: #4da24d;
              color: #fff;
              display: inline-block;
            }
            .unverify {
              padding: 0.15rem 0.8rem;
              border-radius: 5px;
              background: #2e2e2e;
              color: #fff;
              display: inline-block;
            }
            .delete {
              padding: 0.15rem 0.8rem;
              border-radius: 5px;
              background: #a44d4d;
              color: #fff;
              display: inline-block;
            }
            span {
              font-size: 10px;
            }
            .verified {
              padding: 0.1rem 0.8rem;
              border-radius: 50px;
              background: #4da24d;
              color: #fff;
              display: inline-block;
            }
            .unverified {
              padding: 0.1rem 0.8rem;
              border-radius: 50px;
              background: #a44d4d;
              color: #fff;
              display: inline-block;
            }
          }
        }
      }
    }
  }
  .addLevel {
    .form-group {
      margin-bottom: 1rem;
      label {
        display: block;
        margin-bottom: 0.5rem;
      }
      input {
        padding: 0.5rem;
        border: 1px solid #ccc;
        border-radius: 5px;
        outline: none;
        width: 100%;
      }
      button {
        padding: 0.65rem 1rem;
        width: 150px;
        border: none;
        outline: none;
        border-radius: 5px;
        background: $blue;
        color: #fff;
        cursor: pointer;
        font-weight: 600;
      }
    }
  }
}
</style>