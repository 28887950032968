<template>
  <div class="max-w-md mx-auto bg-white rounded-lg overflow-hidden shadow-lg">
    <div class="px-6 py-4">
      <div class="font-bold text-xl mb-2">{{ business.name }}</div>
      <p class="text-gray-700 text-base">{{ business.description || 'No description available' }}</p>
      <p class="text-gray-700 text-base">Address: {{ business.province }} - {{ business.district }} - {{ business.sector }}</p>
      <p class="text-gray-700 text-base">Phone: 
        <a :href="'tel:' + business.phone" class="text-blue-500">
          <i class="fas fa-phone"></i> {{ business.phone || 'No phone number available' }}
        </a>
      </p>
      <p class="text-gray-700 text-base">Email: 
        <a :href="'mailto:' + business.email" class="text-blue-500">
          <i class="fas fa-envelope"></i> {{ business.email || 'No email available' }}
        </a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    business: Object,
  },
};
</script>

<style>
</style>
