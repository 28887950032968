<template>
  <div class="Sectors">
    <super-admin-topbar :pageTitle="'Agricultural sectors'" />
    <div class="page-content">
      <div class="levels-table">
        <div class="table-header">
          <div class="left">
            <div class="search">
              <input
                type="text"
                placeholder="Search"
                @keyup="searchData()"
                v-model="search"
              />
            </div>
          </div>
          <div class="right">
            <button @click="popupOpen = true">Add Sector</button>
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in AgriSectors" :key="item.product_sector_id">
              <td>{{ item.product_sector_name }}</td>
              <td>
                <button @click="editItem(item)">
                  <img src="/assets/images/edit.svg" alt="" />
                </button>
                <button @click="deleteItem(item)">
                  <img src="/assets/images/delete.svg" alt="" />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <model
        :modelTitle="'Add Sector'"
        :largeModel="false"
        :smallModel="true"
        v-show="popupOpen"
        @closeModel="popupOpen = false"
      >
        <form action="" class="addLevel" @submit.prevent="confirmAdd()">
          <div class="form-group">
            <label for="">Sector Name</label>
            <input
              type="text"
              placeholder="Sector Name"
              v-model="newData.product_sector_name"
            />
          </div>
          <div class="form-group">
            <button type="submit">Save</button>
          </div>
        </form>
      </model>
      <model
        :modelTitle="'Edit Sector'"
        :largeModel="false"
        :smallModel="true"
        v-show="editPopupOpen"
        @closeModel="editPopupOpen = false"
      >
        <form action="" class="addLevel" @submit.prevent="confirmEdit()">
          <div class="form-group">
            <label for="">Sector Name</label>
            <input
              type="text"
              placeholder="Sector Name"
              v-model="selectedItem.product_sector_name"
            />
          </div>
          <div class="form-group">
            <button type="submit">Save</button>
          </div>
        </form>
      </model>
      <confirm-model
        :modelTitle="'Delete Sector'"
        :smallModel="true"
        v-if="deletePopupOpen"
        @closeModel="deletePopupOpen = false"
        @confirmModel="confirmDelete()"
        :displayText="'Are you sure you want to delete this sector?'"
      ></confirm-model>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "Sectors",
  data() {
    return {
      AgriSectors: [],
      allAgriSectors: [],
      search: "",
      popupOpen: false,
      editPopupOpen: false,
      newData: {
        product_sector_name: "",
      },
      selectedItem: {},
      deletePopupOpen: false,
    };
  },
  methods: {
    getSectors() {
      this.$startLoader();
      this.$store
        .dispatch("GET_AGRISECTORS", { token: this.$getToken() })
        .then((res) => {
          this.AgriSectors = res.data.data;
          this.allAgriSectors = res.data.data;
          this.$stopLoader();
        })
        .catch((err) => {
          this.$stopLoader();
        });
    },
    searchData() {
      this.AgriSectors = this.allAgriSectors.filter((agrisector) => {
        return agrisector.product_sector_name
          .toLowerCase()
          .includes(this.search.toLowerCase());
      });
    },
    editItem(agrisector) {
      this.selectedItem = agrisector;
      this.editPopupOpen = true;
    },
    deleteItem(agrisector) {
      this.selectedItem = agrisector;
      this.deletePopupOpen = true;
    },
    confirmDelete() {
      this.$startLoader();
      this.$store
        .dispatch("DELETE_AGRISECTOR", {
          token: this.$getToken(),
          data: {
            product_sector_id: this.selectedItem.product_sector_id,
          },
        })
        .then((res) => {
          if (res.data.status) {
            this.$notify({
              title: "Success",
              type: "success",
              text: "sector deleted successfully",
            });
          }
          this.deletePopupOpen = false;
          this.getSectors();
        })
        .catch((err) => {
          console.log(err);
          this.$notify({
            title: "Error",
            type: "error",
            text: err.response.data.error,
          });
          this.$stopLoader();
        });
    },
    confirmAdd() {
      this.$startLoader();
      this.$store
        .dispatch("ADD_AGRISECTOR", {
          token: this.$getToken(),
          data: this.newData,
        })
        .then((res) => {
          if (res.data.status) {
            this.$notify({
              title: "Success",
              type: "success",
              text: "sector added successfully",
            });
          }
          this.popupOpen = false;
          this.getSectors();
        })
        .catch((err) => {
          console.log(err);
          this.$notify({
            title: "Error",
            type: "error",
            text: err.response.data.error,
          });
          this.$stopLoader();
        });
    },
    confirmEdit() {
      this.$startLoader();
      this.$store
        .dispatch("EDIT_AGRISECTOR", {
          token: this.$getToken(),
          data: this.selectedItem,
        })
        .then((res) => {
          if (res.data.status) {
            this.$notify({
              title: "Success",
              type: "success",
              text: "sector edited successfully",
            });
          }
          this.editPopupOpen = false;
          this.getSectors();
        })
        .catch((err) => {
          console.log(err);
          this.$notify({
            title: "Error",
            type: "error",
            text: err.response.data.error,
          });
          this.$stopLoader();
        });
    },
  },
  mounted() {
    this.getSectors();
  },
};
</script>
  
  <style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.page-content {
  padding: 1rem;
}
.Sectors {
  .levels-table {
    .table-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
      .left {
        display: flex;
        align-items: center;
        .search {
          input {
            padding: 0.5rem;
            border: 1px solid #ccc;
            border-radius: 5px;
            outline: none;
            width: 300px;
          }
        }
      }
      .right {
        button {
          padding: 0.65rem 1rem;
          width: 150px;
          border: none;
          outline: none;
          border-radius: 5px;
          background: $blue;
          color: #fff;
          cursor: pointer;
          font-weight: 600;
        }
      }
    }
    table {
      width: 100%;
      border-collapse: collapse;
      thead {
        tr {
          th {
            padding: 10px;
            border-bottom: 1px solid #ccc;
            text-align: left;
          }
        }
      }
      tbody {
        tr {
          td {
            padding: 10px;
            border-bottom: 1px solid #ccc;
            button {
              background: transparent;
              border: none;
              outline: none;
              cursor: pointer;
              margin-right: 1rem;
              img {
                width: 30px;
              }
            }
          }
        }
      }
    }
  }
  .addLevel {
    .form-group {
      margin-bottom: 1rem;
      label {
        display: block;
        margin-bottom: 0.5rem;
      }
      input {
        padding: 0.5rem;
        border: 1px solid #ccc;
        border-radius: 5px;
        outline: none;
        width: 100%;
      }
      button {
        padding: 0.65rem 1rem;
        width: 150px;
        border: none;
        outline: none;
        border-radius: 5px;
        background: $blue;
        color: #fff;
        cursor: pointer;
        font-weight: 600;
      }
    }
  }
}
</style>