<template>
  <div class="Workers">
    <site-navbar />
    <div class="search-section">
      <form action="">
        <label>
          {{ $txt("Workers") }}
        </label>
        <p for="">{{ $txt("Hire proffesional experts and find jobs.") }}</p>
      </form>
    </div>
    <div class="whole-content">
      <div class="workers-section">
        <div class="left">
          <div class="filters">
            <div class="title">
              <h4>Filter By</h4>
            </div>
            <div class="filter-item">
              <div class="header" @click="showSector = !showSector">
                <label for="">Career</label>
                <i class="fa fa-angle-down"></i>
              </div>
              <div class="content" v-if="showSector">
                <select
                  v-model="filterData.skills_sector"
                  @change="startSearch()"
                  style="width: 100%"
                >
                  <option value="All">All careers</option>
                  <option
                    v-for="sector in careerSectors"
                    :value="sector.sector_id"
                    :key="sector.sector_id"
                  >
                    {{ sector.sector_name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="filter-form">
              <label for="">{{ $txt(`Precise location`) }}: </label>
              <div class="form">
                <div class="form-group group-4">
                  <!-- Province, Disctict, sector, cell -->
                  <select
                    name="province"
                    id=""
                    v-model="filterData.province"
                    @change="
                      getDistricts(filterData.province);
                      startSearch();
                    "
                  >
                    <option value="">{{ $txt("All Provinces") }}</option>
                    <option
                      v-for="province in provinces"
                      :key="province.provinceId"
                      :value="province.provinceId"
                    >
                      {{ province.provinceName }}
                    </option>
                  </select>
                  <select
                    name="district"
                    id=""
                    v-model="filterData.district"
                    @change="
                      getSectors(filterData.district);
                      startSearch();
                    "
                  >
                    <option value="">{{ $txt("All Districts") }}</option>
                    <option
                      v-for="district in districts"
                      :key="district.districtId"
                      :value="district.districtId"
                    >
                      {{ district.districtName }}
                    </option>
                  </select>
                  <select
                    name="sector"
                    id=""
                    v-model="filterData.sector"
                    @change="
                      getCells(filterData.sector);
                      startSearch();
                    "
                  >
                    <option value="">{{ $txt("All Sectors") }}</option>
                    <option
                      v-for="sector in sectors"
                      :key="sector.sectorId"
                      :value="sector.sectorId"
                    >
                      {{ sector.sectorName }}
                    </option>
                  </select>
                  <select
                    name="cell"
                    id=""
                    v-model="filterData.cell"
                    @change="startSearch()"
                  >
                    <option value="">{{ $txt("All Cells") }}</option>
                    <option
                      v-for="cell in cells"
                      :key="cell.cellId"
                      :value="cell.cellId"
                    >
                      {{ cell.cellName }}
                    </option>
                  </select>
                </div>
                <!-- <button class="submit" @click="getFilteredData()">Filter</button> -->
              </div>
            </div>
            <!-- <div class="filter-item">
              <div class="header" @click="showAvailability = !showAvailability">
                <label for="">Availability</label>
                <i class="fa fa-angle-down"></i>
              </div>
              <div class="content" v-if="showAvailability">
                <ul>
                  <li><input type="checkbox" name="" id="" />Open to hire</li>
                  <li><input type="checkbox" name="" id="" />Not available</li>
                </ul>
              </div>
            </div> -->
          </div>
        </div>
        <div class="right">
          <div class="results-section">
            <div class="results-header">
              <div class="add-business">
                <label for="">Showing {{ workers.length }}+ workers</label>
                <button @click="popupOpen = true">Create worker profile</button>
              </div>
              <form action="" class="search" @submit.prevent="startSearch()">
                <input
                  type="text"
                  name=""
                  id=""
                  :placeholder="$txt('Search')"
                  v-model="searchQuery"
                  @keyup="startSearch()"
                />
                <!-- <button type="submit" @click="startSearch()">
                  <i class="fa fa-search"></i>
                </button>
                <i class="fa fa-times clear"></i> -->
              </form>
              <!-- <p>
                Showing <span>{{ workers.length }}</span> results from
                {{ allworkers.length }} workers
              </p> -->
            </div>
            <div class="worker-items">
              <div
                class="worker-item"
                v-for="worker in workers"
                :key="worker.id"
                @click="
                  viewWorker = true;
                  selectItem = worker;
                "
              >
                <div class="top-section">
                  <div class="image">
                    <img
                      :src="$file(worker.worker_image)"
                      alt=""
                      @error="$imageurlalt()"
                    />
                  </div>
                  <div class="info">
                    <div class="name">
                      <h4>{{ worker.user.full_names }}</h4>
                      <label for="">{{ worker.worker_title }}</label>
                      <p>
                        {{ worker.district_name }},
                        {{ worker.sector_name }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="middle-section">
                  <!-- reviews, availablity, sector, category -->
                  <div class="availability">
                    <label for="">Availability</label>
                    <p v-if="worker.worker_availability">
                      <i
                        class="fa fa-circle"
                        :class="worker.worker_availability ? 'active' : ''"
                      ></i>
                      Open to hire
                    </p>
                    <p v-else><i class="fa fa-circle"></i> Not available</p>
                  </div>
                  <div class="reviews">
                    <label for="">Reviews</label>
                    <p>{{ worker.reviews.length }}</p>
                  </div>
                  <div class="sector">
                    <label for="">Career</label>
                    <p>{{ worker.careersector.sector_name }}</p>
                  </div>
                  <div class="category">
                    <label for="">Title</label>
                    <p>{{ worker.worker_title }}</p>
                  </div>
                </div>
                <div class="bottom-section">
                  <div class="bio">
                    <p class="desc" v-html="worker.worker_bio"></p>
                  </div>
                  <div class="skills">
                    <div class="skills-list">
                      <span
                        v-for="skill in JSON.parse(worker.worker_skills)"
                        :key="skill"
                      >
                        {{ skill.skill_name }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <pagination
              class="pagination"
              :totalItems="allworkers.length"
              :itemsPerPage="itemsPerPage"
              @pageChanged="
                (page) => {
                  changePage(page);
                }
              "
            />
          </div>
        </div>
      </div>
      <div class="header-widget">
        <div class="header-sections">
          <div
            class="header-section"
            :class="selectedSection == 1 ? 'active' : ''"
            @mouseover="selectedSection = 1"
          >
            <img src="/assets/images/tea.jpg" alt="" @error="$imageurlalt()" />
            <label for="" v-show="selectedSection == 1"
              ><span>
                {{
                  $txt(
                    "Find the best coach or school for yourself or your kids"
                  )
                }}</span
              >
              <div class="explore" v-show="selectedSection == 1">
                <button>{{ $txt("Explore") }}</button>
              </div>
            </label>
          </div>
          <div
            class="header-section"
            :class="selectedSection == 2 ? 'active' : ''"
            @mouseover="selectedSection = 2"
          >
            <img
              src="/assets/images/cover/tech.jpg"
              alt=""
              @error="$imageurlalt()"
            />
            <label for="" v-show="selectedSection == 2"
              ><span>{{ $txt("Hire a proffesional and find jobs.") }}"</span>
              <div class="explore" v-show="selectedSection == 2">
                <button>{{ $txt("Explore") }}</button>
              </div>
            </label>
          </div>
          <div
            class="header-section"
            :class="selectedSection == 3 ? 'active' : ''"
            @mouseover="selectedSection = 3"
          >
            <img
              src="/assets/images/cover/edu.jpg"
              alt=""
              @error="$imageurlalt()"
            />
            <label for="" v-show="selectedSection == 3">
              <span>{{
                $txt("Browse and know more about the best schools in Rwanda")
              }}</span>
              <div class="explore" v-show="selectedSection == 3">
                <button>{{ $txt("Explore") }}</button>
              </div>
            </label>
          </div>
          <div
            class="header-section"
            :class="selectedSection == 4 ? 'active' : ''"
            @mouseover="selectedSection = 4"
          >
            <img
              src="/assets/images/cover/rnit.jpg"
              alt=""
              @error="$imageurlalt()"
            />
            <label for="" v-show="selectedSection == 4">
              <span
                >{{ $txt("Boost your business and brand awareness") }}"</span
              >
              <div class="explore" v-show="selectedSection == 4">
                <button>{{ $txt("Explore") }}</button>
              </div>
            </label>
          </div>
          <div
            class="header-section"
            :class="selectedSection == 5 ? 'active' : ''"
            @mouseover="selectedSection = 5"
          >
            <img
              src="/assets/images/cover/trucks.jpg"
              alt=""
              @error="$imageurlalt()"
            />
            <label for="" v-show="selectedSection == 5"
              ><span>{{
                $txt(
                  "Transport your goods and materiels, Find transport clients for your vehicles"
                )
              }}</span>
              <div class="explore" v-show="selectedSection == 5">
                <button>{{ $txt("Explore") }}</button>
              </div>
            </label>
          </div>
          <div
            class="header-section"
            :class="selectedSection == 6 ? 'active' : ''"
            @mouseover="selectedSection = 6"
          >
            <img
              src="/assets/images/burger.jpg"
              alt=""
              @error="$imageurlalt()"
            />
            <label for="" v-show="selectedSection == 6"
              ><span>{{
                $txt(
                  "Sell or buy products and services to new customers and boost your sales"
                )
              }}</span>
              <div class="explore" v-show="selectedSection == 6">
                <button>{{ $txt("Explore") }}</button>
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>
    <model
      v-if="popupOpen"
      @closeModel="popupOpen = false"
      :modelTitle="'Create worker profile'"
      :largeModel="true"
    >
      <form @submit.prevent="createWorker()">
        <div class="form-group group-3">
          <div class="input-group">
            <label for="">{{ $txt("Employee names") }}</label>
            <input
              type="text"
              name=""
              id=""
              placeholder="Employee names"
              v-model="newData.names"
              disabled
            />
          </div>
          <div class="input-group">
            <label for="">{{ $txt("Email") }}</label>
            <input
              type="email"
              name=""
              id=""
              placeholder="Email"
              v-model="newData.worker_email"
            />
          </div>
          <div class="input-group">
            <label for="">{{ $txt("Phone") }}</label>
            <input
              type="text"
              name=""
              id=""
              placeholder="Phone"
              v-model="newData.worker_phone"
            />
          </div>
        </div>
        <div class="form-group group-3">
          <div class="input-group">
            <label for=""
              >{{ $txt("Work Title") }}
              <span>(ex: Software developer,...)</span></label
            >
            <input
              type="text"
              name=""
              id=""
              placeholder="work title"
              v-model="newData.worker_title"
            />
          </div>
          <div class="input-group">
            <label for="">{{ $txt("Skills Sector") }}</label>

            <select v-model="newData.skills_sector">
              <option value="">Select Career sector</option>
              <option
                v-for="sector in careerSectors"
                :key="sector.sector_id"
                :value="sector.sector_id"
              >
                {{ sector.sector_name }}
              </option>
            </select>
          </div>
          <div class="input-group">
            <label for="">{{ $txt("Availability") }}</label>
            <select name="" id="" v-model="newData.worker_availability">
              <option value="">Select</option>
              <option :value="true">Open to hire</option>
              <option :value="false">Not available</option>
            </select>
          </div>
        </div>
        <div class="form-group group-4">
          <div class="input-group">
            <label for="">{{ $txt("Province") }}</label>
            <select
              name=""
              id=""
              v-model="newData.worker_province"
              @change="getDistricts(newData.worker_province)"
            >
              <option value="">Select</option>
              <option
                v-for="province in provinces"
                :key="province.provinceId"
                :value="province.provinceId"
              >
                {{ province.provinceName }}
              </option>
            </select>
          </div>
          <div class="input-group">
            <label for="">{{ $txt("District") }}</label>
            <select
              name=""
              id=""
              v-model="newData.worker_district"
              @change="getSectors(newData.worker_district)"
            >
              <option value="">Select</option>
              <option
                v-for="district in districts"
                :key="district.districtId"
                :value="district.districtId"
              >
                {{ district.districtName }}
              </option>
            </select>
          </div>
          <div class="input-group">
            <label for="">{{ $txt("Sector") }}</label>
            <select
              name=""
              id=""
              v-model="newData.worker_sector"
              @change="getCells(newData.worker_sector)"
            >
              <option value="">Select</option>
              <option
                v-for="sector in sectors"
                :key="sector.sectorId"
                :value="sector.sectorId"
              >
                {{ sector.sectorName }}
              </option>
            </select>
          </div>
          <div class="input-group">
            <label for="">{{ $txt("Cell") }}</label>
            <select name="" id="" v-model="newData.worker_cell">
              <option value="">Select</option>
              <option
                v-for="cell in cells"
                :key="cell.cellId"
                :value="cell.cellId"
              >
                {{ cell.cellName }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group group-1">
          <div class="input-group">
            <label for="">{{ $txt("Picture") }}</label>
            <o-upload
              :multiple="false"
              :uploadUrl="'files/upload'"
              @imagesUploaded="
                (data) => {
                  newData.worker_image = data;
                }
              "
              @imagesUploadedFailed="
                (data) => {
                  console.log('failed');
                }
              "
              :value="newData.worker_image"
              @startLoader="() => $startLoader()"
              @stopLoader="() => $stopLoader()"
            />
          </div>
        </div>
        <div class="form-group group-1">
          <div class="input-group">
            <label for="">{{ $txt("Skills") }}</label>

            <VueMultiselect
              placeholder="Select up to 10 skills"
              v-if="skills.length > 0"
              v-model="newData.worker_skills"
              :options="skills"
              :multiple="true"
              :close-on-select="true"
              :hide-selected="true"
              label="skill_name"
              track-by="skill_name"
              class="multiSelect"
              :max="10"
            >
            </VueMultiselect>
          </div>
        </div>
        <div class="form-group group-1">
          <div class="input-group">
            <label for="">{{ $txt("Bio") }}</label>
            <quill-editor
              theme="snow"
              v-model:content="newData.worker_bio"
              contentType="html"
              style="height: 200px"
            ></quill-editor>
          </div>
        </div>
        <div class="form-group group-1">
          <div class="input-group">
            <button type="submit">
              {{ $txt("Create") }}
            </button>
          </div>
        </div>
      </form>
    </model>
    <view-worker
      v-if="viewWorker"
      :item="selectItem"
      @closePopup="viewWorker = false"
    />
    <site-footer />
  </div>
</template>

<script>
export default {
  name: "Workers",
  components: {},
  data() {
    return {
      allworkers: [],
      workers: [],
      ismodules: [],
      modules: [],
      selectedSection: 1,
      searchQuery: "",
      viewProduct: false,
      selectItem: {},
      showType: false,
      showLocation: false,
      showSector: true,
      showCategory: false,
      showAvailability: false,
      currentPage: 1,
      itemsPerPage: 15,
      viewWorker: false,
      popupOpen: false,
      provinces: [],
      districts: [],
      sectors: [],
      cells: [],
      newData: {
        worker_email: "",
        worker_phone: "",
        worker_province: "",
        worker_district: "",
        worker_sector: "",
        worker_category: "",
        worker_availability: true,
        worker_title: "",
        worker_bio: "",
        worker_skills: "",
      },
      filterData: {
        location: "",
        skills_sector: "All",
        province: "",
        district: "",
        sector: "",
        cell: "",
      },
      careerSectors: [],
      skills: [],
    };
  },
  methods: {
    changePage(page) {
      this.currentPage = page;
      this.workers = this.$changePage(this.allworkers, page, this.itemsPerPage);
      window.scrollTo(0, 0);
    },
    startSearch() {
      let allResults = [...this.allworkers];
      let results1 = [];
      if (this.searchQuery) {
        results1 = allResults.filter((worker) => {
          return (
            worker.user.full_names.toLowerCase().includes(this.searchQuery) ||
            worker.worker_email.toLowerCase().includes(this.searchQuery) ||
            worker.worker_phone.toLowerCase().includes(this.searchQuery) ||
            worker.worker_title.toLowerCase().includes(this.searchQuery) ||
            worker.worker_bio.toLowerCase().includes(this.searchQuery) ||
            worker.worker_skills.toLowerCase().includes(this.searchQuery) ||
            worker.sector_name.toLowerCase().includes(this.searchQuery) ||
            worker.district_name.toLowerCase().includes(this.searchQuery) ||
            worker.province_name.toLowerCase().includes(this.searchQuery) ||
            worker.cell_name.toLowerCase().includes(this.searchQuery) ||
            worker.careersector.sector_name
              .toLowerCase()
              .includes(this.searchQuery)
          );
        });
      } else {
        results1 = allResults;
      }

      let results2 = [];
      if (this.filterData.skills_sector != "All") {
        results2 = results1.filter((worker) => {
          return worker.careersector.sector_id == this.filterData.skills_sector;
        });
      } else {
        results2 = results1;
      }

      let results3 = this.searchByLocation(results2);
      this.workers = results3;
    },
    searchByLocation(searchResults1) {
      let searchResults2 = [];
      if (this.filterData.province == "") {
        this.filterData.district = "";
        this.filterData.sector = "";
        this.filterData.cell = "";
      }
      if (this.filterData.district == "") {
        this.filterData.sector = "";
        this.filterData.cell = "";
      }
      if (this.filterData.sector == "") {
        this.filterData.cell = "";
      }
      if (this.filterData.cell && this.filterData.cell != "") {
        searchResults2 = searchResults1.filter((product) => {
          return parseInt(product.worker_cell) == this.filterData.cell;
        });
      } else {
        if (this.filterData.sector && this.filterData.sector != "") {
          searchResults2 = searchResults1.filter((product) => {
            return parseInt(product.worker_sector) == this.filterData.sector;
          });
        } else {
          if (this.filterData.district && this.filterData.district != "") {
            searchResults2 = searchResults1.filter((product) => {
              return (
                parseInt(product.worker_district) == this.filterData.district
              );
            });
          } else {
            if (this.filterData.province && this.filterData.province != "") {
              console.log(this.filterData.province);
              searchResults2 = searchResults1.filter((product) => {
                return (
                  parseInt(product.worker_province) == this.filterData.province
                );
              });
            } else {
              searchResults2 = searchResults1;
            }
          }
        }
      }
      return searchResults2;
    },
    getProvinces() {
      // this.$startLoader();
      this.$store
        .dispatch("GET_PROVINCES", { token: this.$getToken() })
        .then((res) => {
          this.provinces = res.data.data.data;
          this.districts = [];
          this.sectors = [];
          this.cells = [];
          // this.$stopLoader();
        });
    },
    getDistricts(provinceId) {
      this.$startLoader();
      this.$store
        .dispatch("GET_DISTRICTS", {
          token: this.$getToken(),
          provinceId: provinceId,
        })
        .then((res) => {
          this.districts = res.data.data.data;
          this.sectors = [];
          this.cells = [];
          this.$stopLoader();
        });
    },
    getSectors(districtId) {
      this.$startLoader();
      this.$store
        .dispatch("GET_SECTORS", {
          token: this.$getToken(),
          districtId: districtId,
        })
        .then((res) => {
          this.sectors = res.data.data.data;
          this.cells = [];
          this.$stopLoader();
        });
    },
    getCells(sectorId) {
      this.$startLoader();
      this.$store
        .dispatch("GET_CELLS", { token: this.$getToken(), sectorId: sectorId })
        .then((res) => {
          this.cells = res.data.data.data;
          this.$stopLoader();
        });
    },
    getSkills() {
      // this.$startLoader();
      // this.$store
      //   .dispatch("GET_WORKER_SKILLS", { token: this.$getBussToken() })
      //   .then((res) => {
      //     // this.skills = res.data.data.data;
      //     this.$stopLoader();
      //   });
    },
    filterLocation() {
      this.workers = this.allworkers.filter((worker) => {
        return (
          worker.province_name
            .toLowerCase()
            .includes(this.filterData.location.toLowerCase()) ||
          worker.cell_name
            .toLowerCase()
            .includes(this.filterData.location.toLowerCase()) ||
          worker.district_name
            .toLowerCase()
            .includes(this.filterData.location.toLowerCase()) ||
          worker.sector_name
            .toLowerCase()
            .includes(this.filterData.location.toLowerCase())
        );
      });
    },
    getCareerSectors() {
      // this.$startLoader();
      this.$store
        .dispatch("GET_BUSS_SECTORS", { token: this.$getToken() })
        .then((res) => {
          this.careerSectors = res.data.data;
          // this.$stopLoader();
        })
        .catch((err) => {
          console.log(err);
          // this.$stopLoader();
        });
    },
    getSkills() {
      // this.$startLoader();
      this.$store
        .dispatch("GET_WORKER_SKILLS", { token: this.$getToken() })
        .then((res) => {
          this.skills = res.data.data;
          // this.$stopLoader();
        })
        .catch((err) => {
          console.log(err);
          // this.$stopLoader();
        });
    },
    createWorker() {
      this.$startLoader();
      let newData = { ...this.newData };
      newData.worker_skills = JSON.stringify(newData.worker_skills);
      this.$store
        .dispatch("CREATE_WORKER", {
          token: this.$getToken(),
          data: this.newData,
        })
        .then((res) => {
          this.$stopLoader();
          if (res.data.status == "success") {
            this.$notify({
              title: "Success",
              type: "success",
              text: res.data.message,
            });
            this.popupOpen = false;
            document.body.style.overflowY = "auto";
          } else {
            this.$notify({
              title: "Failed",
              type: "error",
              text: res.data.message,
            });
            this.popupOpen = false;
            document.body.style.overflowY = "auto";
          }
        })
        .catch((err) => {
          this.$stopLoader();
          this.$notify({
            title: "Error",
            type: "error",
            text: "Something went wrong",
          });
        });
    },
    getWorker() {
      this.$startLoader();
      this.$store
        .dispatch("GET_ALL_WORKERS", {
          token: this.$getToken(),
        })
        .then((res) => {
          this.allworkers = res.data.data;
          this.changePage(1);
          this.$stopLoader();
        })
        .catch((err) => {
          console.log(err);
          this.$stopLoader();
        });
    },
    getWorkersByCareer() {
      this.$startLoader();
      this.$store
        .dispatch("GET_WORKER_BY_SECTOR_ID", {
          token: this.$getToken(),
          data: this.filterData,
        })
        .then((res) => {
          this.allworkers = res.data.data;
          this.changePage(1);
          this.$stopLoader();
        })
        .catch((err) => {
          console.log(err);
          this.$stopLoader();
        });
    },
  },
  mounted() {
    this.getWorker();
    this.getProvinces();
    this.getSkills();
    this.getCareerSectors();
    if (this.$getUser()) {
      this.newData.names = this.$getUser().names;
      this.newData.worker_email = this.$getUser().emailAddress;
      this.newData.worker_phone = this.$getUser().telephone;
    }
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.search-section {
    form {
      @media screen and (max-width: 1500px) {
        margin-top: 1rem !important;
      }
      label {
        @media screen and (max-width: 1500px) {
          font-size: 1rem !important;
        }
      }
      p {
        font-size: 0.8rem !important;
      }
    }
  }
.whole-content {
  padding: 2rem 5rem;
  background: #fff;
  @media screen and (max-width: 1500px) {
    padding: 2rem 1rem;
  }
  .workers-section {
    display: flex;
    column-gap: 1rem;
    background: #fff;
    padding: 1rem;
    .left {
      width: 300px;
      @media screen and (max-width: 1500px) {
        width: 200px;
      }
      .filters {
        .title {
          h4 {
            font-size: 1rem;
            font-weight: 600;
            color: black;
            @media screen and (max-width: 1500px) {
              font-size: 0.7rem !important;
            }
          }
        }
        .filter-item {
          margin-top: 1rem;
          .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            label {
              font-size: 0.8rem;
              font-weight: 400;
              color: #1f1f1f;
              cursor: pointer;
              @media (max-width: 1500px) {
                font-size: 0.5rem;
              }
              @media (max-width: 1200px) {
              }
              @media (max-width: 800px) {
              }
            }
            i {
              font-size: 0.8rem;
              color: #1f1f1f;
              cursor: pointer;
              @media (max-width: 1500px) {
                font-size: 0.6rem;
              }
              @media (max-width: 1200px) {
              }
              @media (max-width: 800px) {
              }
            }
          }
          .content {
            margin-top: 1rem;
            padding-left: 1rem;
            .help {
              font-size: 1rem;
              font-weight: 400;
              color: #0439e7;
              margin-top: 0.5rem;
              text-decoration: underline;
              cursor: pointer;
              @media (max-width: 1500px) {
                font-size: 0.7rem;
              }
              @media (max-width: 1200px) {
              }
              @media (max-width: 800px) {
              }
            }
            ul {
              li {
                font-size: 1rem;
                font-weight: 400;
                color: black;
                margin-top: 0.5rem;
                input {
                  margin-right: 0.5rem;
                }
              }
            }
            input,
            select {
              width: 100% !important;
              border-radius: 0px;
              border: none;
              border-bottom: 1px solid #eee;
              padding: 5px 10px;
              outline: none;
              font-size: 0.75rem;
              font-weight: 400;
              color: #1f1f1f;
              cursor: pointer;
              @media screen and (max-width: 1500px) {
                font-size: 0.6rem;
                padding: 5px 5px;
                height: fit-content !important;
              }
              @media (max-width: 1200px) {
              }
              @media (max-width: 800px) {
              }
            }
          }
        }
        .apply-filter {
          margin-top: 1rem;
          display: flex;
          justify-content: space-between;
          button {
            background: $blue;
            color: white;
            padding: 10px 20px;
            border-radius: 3px;
            font-size: 15px;
            font-weight: 500;
            outline: none;
            border: none;
            cursor: pointer;
            width: 48%;
            &.clear {
              background: white;
              color: $blue;
              border: 1px solid $blue;
              &:hover {
                background: $blue;
                color: white;
                border: 1px solid $blue;
              }
            }
          }
        }
      }
    }
    .right {
      width: calc(100% - 300px);
      background: #fff;
      overflow: hidden;
      @media screen and (max-width: 1500px) {
        width: calc(100% - 200px);
      }
      .results-section {
        border: 1px solid #ccc;
        border-radius: 20px;
        overflow: hidden;
        .results-header {
          padding: 1rem;
          padding-bottom: 2rem;
          display: flex;
          flex-direction: column;
          margin-bottom: 1rem;
          border-bottom: 1px solid #ccc;
          overflow: hidden;
          @media screen and (max-width: 1500px) {
            padding: 0.7rem;
            padding-bottom: 1rem;
          }
          .search {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            position: relative;
            input {
              padding: 10px 20px;
              border-radius: 5px 0px 0px 5px;
              border: 1px solid #ccc;
              font-size: 15px;
              font-weight: 500;
              width: 100%;
              outline: none;
              @media screen and (max-width: 1500px) {
                padding: 5px 10px;
                font-size: 0.8rem;
              }
            }
            button {
              background: $blue;
              color: white;
              padding: 10px 20px;
              border-radius: 0px 5px 5px 0px;
              font-size: 15px;
              font-weight: 500;
              outline: none;
              border: none;
              cursor: pointer;
              @media screen and (max-width: 1500px) {
                padding: 5px 10px;
                font-size: 0.8rem;
              }
            }
            .clear {
              font-size: 1.2rem;
              color: #949494;
              margin-left: 1rem;
              cursor: pointer;
              position: absolute;
              right: 4rem;
              @media screen and (max-width: 1500px) {
                font-size: 0.8rem;
              }
            }
          }
          p {
            font-size: 1rem;
            font-weight: 400;
            color: #5c5c5c;
            margin-top: 1rem;
            @media screen and (max-width: 1500px) {
              font-size: 0.6rem;
            }
            span {
              font-weight: 600;
              color: #000;
            }
          }
        }
        .worker-items {
          display: flex;
          flex-direction: column;
          .worker-item {
            display: flex;
            flex-direction: column;
            overflow: hidden;
            border-bottom: 1px solid #ccc;
            cursor: pointer;
            transition: 0.5s ease-in-out;
            padding: 0 10px;
            &:hover {
              background: #eee;
            }
            .top-section {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 1rem;
              .image {
                width: 100px;
                height: 100px;
                border-radius: 50%;
                overflow: hidden;
                border: 3px solid #ccc;
                margin-right: 10px;
                @media screen and (max-width: 1500px) {
                  width: 60px;
                  height: 60px;
                }
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  background: #f2f2f2;
                }
              }
              .info {
                width: calc(100% - 100px);
                .name {
                  h4 {
                    font-size: 1rem;
                    font-weight: 500;
                    color: $blue;
                    @media screen and (max-width: 1500px) {
                      font-size: 0.7rem;
                    }
                  }
                  label {
                    font-size: 1.25rem;
                    font-weight: 500;
                    color: #000;
                    @media screen and (max-width: 1500px) {
                      font-size: 0.8rem;
                    }
                  }
                  p {
                    font-size: 0.9rem;
                    font-weight: 400;
                    color: #949494;
                    @media screen and (max-width: 1500px) {
                      font-size: 0.6rem;
                    }
                  }
                }
              }
            }
            .middle-section {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 0 1rem;
              .availability {
                label {
                  font-size: 0.9rem;
                  font-weight: 400;
                  color: #949494;
                  @media screen and (max-width: 1500px) {
                    font-size: 0.6rem;
                  }
                }
                p {
                  font-size: 0.9rem;
                  font-weight: 400;
                  @media screen and (max-width: 1500px) {
                    font-size: 0.6rem;
                  }
                  i {
                    color: orange;
                    &.active {
                      color: #58b658;
                    }
                  }
                }
              }
              .reviews {
                label {
                  font-size: 0.9rem;
                  font-weight: 400;
                  color: #949494;
                  @media screen and (max-width: 1500px) {
                    font-size: 0.6rem;
                  }
                }
                p {
                  font-size: 1.1rem;
                  font-weight: 600;
                  color: $blue;
                  @media screen and (max-width: 1500px) {
                    font-size: 0.6rem;
                  }
                }
              }
              .sector {
                label {
                  font-size: 0.9rem;
                  font-weight: 400;
                  color: #949494;
                  @media screen and (max-width: 1500px) {
                    font-size: 0.6rem;
                  }
                }
                p {
                  font-size: 1.1rem;
                  font-weight: 600;
                  color: $blue;
                  @media screen and (max-width: 1500px) {
                    font-size: 0.7rem;
                  }
                }
              }
              .category {
                label {
                  font-size: 0.9rem;
                  font-weight: 400;
                  color: #949494;
                  @media screen and (max-width: 1500px) {
                    font-size: 0.6rem;
                  }
                }
                p {
                  font-size: 1.1rem;
                  font-weight: 600;
                  color: $blue;
                  @media screen and (max-width: 1500px) {
                    font-size: 0.7rem;
                  }
                }
              }
            }
            .bottom-section {
              padding: 1rem;
              .bio {
                p {
                  font-size: 0.9rem;
                  font-weight: 400;
                  color: #949494;
                  @media screen and (max-width: 1500px) {
                    font-size: 0.6rem;
                  }
                  &.desc {
                    font-size: 14px;
                    font-weight: 400;
                    margin-top: 5px;
                    color: #494949;
                    text-align: left;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    @media screen and (max-width: 1500px) {
                      font-size: 0.6rem;
                    }
                    span {
                      font-weight: 600;
                    }
                  }
                }
              }
              .skills {
                margin-top: 15px;
                label {
                  font-size: 0.9rem;
                  font-weight: 400;
                  color: #949494;
                  @media screen and (max-width: 1500px) {
                    font-size: 0.6rem;
                  }
                }
                .skills-list {
                  display: flex;
                  flex-wrap: wrap;
                  column-gap: 0.5rem;
                  row-gap: 0.5rem;
                  span {
                    padding: 3px 10px;
                    border-radius: 50px;
                    background: #d8d8d8;
                    font-size: 0.9rem;
                    font-weight: 400;
                    color: #303030;
                    @media screen and (max-width: 1500px) {
                      font-size: 0.6rem;
                      padding: 3px 5px;
                    }
                  }
                }
              }
            }
          }
        }
        .pagination {
          margin: 1rem 0;
        }
      }
    }
  }
}
</style>