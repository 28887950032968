<template>
  <site-navbar/>
  
  <section class="">
  <div class="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
      <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 ">Contact Us</h2>
      <p class="mb-8 lg:mb-16 font-light text-center text-gray-500 sm:text-xl">Got a technical issue? Want to send feedback about a  feature? Need details about Infomaster App? Let us know.</p>
      <form  class="space-y-8">
          <div>
              <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Your email</label>
              <input type="email" id="email" class="shadow-sm  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5" placeholder="info@master.org" required>
          </div>
          <div>
              <label for="subject" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Subject</label>
              <input type="text" id="subject" class="block p-3 w-full text-sm text-gray-900  rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500" placeholder="Let us know how we can help you" required>
          </div>
          <div class="sm:col-span-2">
              <label for="message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Your message</label>
              <textarea id="message" rows="6" class="block p-2.5 w-full text-sm text-gray-900  rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500" placeholder="Leave a comment..."></textarea>
          </div>
          <div class="flex items-center justify-center mt-2">
          <i class="fa fa-envelope mr-2 "></i>
          <span>support@mail.com</span>
          <i class="fa fa-phone mr-2"></i>
          <span>07812435487</span>
        </div>
          <button type="submit" class="py-3 px-5 text-sm font-medium text-center text-white rounded-lg sm:w-fit  ">Send message</button>
      </form>
  </div>
</section>
  </template>
  
  <script>
  export default {
    data() {
      return {
        formData: {
          name: '',
          email: '',
          message: ''
        }
      };
    },
    methods: {

    }
  };
  </script>
  
  <style scoped lang="scss">
  @import "@/assets/scss/main.scss";
  i{
    border-radius: 50%;
    height: 50px;
    width: 50px;
    color: $blue;
    padding: 16px;
    margin-left: 12px;
  }

  textarea{
    height: 40px;
  }
  i:hover{
    color: #1d3557;
    background-color: white;
    border: 1px solid #1d3557;
  }
  button{
    background-color: #1d3557 !important;
  }
</style>
  