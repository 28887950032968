<template>
  <div class="ResetPassword">
    <site-navbar />
    <div class="page-content">
      <!-- username/email, password -->
      <form action="" @submit.prevent="userLogin()" v-if="!sendForm">
        <div class="form-header">
          <label for="" class="main-head">{{
            $txt("Create new password")
          }}</label>
        </div>
        <div class="form-body">
          <div class="form-group">
            <p>New password</p>
            <input
              type="password"
              name="username"
              :placeholder="$txt('New password')"
              v-model="user.password"
            />
          </div>
          <div class="form-group">
            <p>Confirm password</p>
            <input
              type="password"
              name="username"
              :placeholder="$txt('Re-type password')"
              v-model="user.password_confirmation"
            />
          </div>
          <div class="form-group">
            <button type="submit" class="submit">
              {{ $txt("Save password") }}
            </button>
          </div>
        </div>
        <div class="form-footer">
          <h4>{{ $txt("Contact info@infomaster.rw for support") }}</h4>
        </div>
      </form>

      <div class="check" v-else>
        <div class="div-header">
          <img src="/assets/images/check.png" alt="" />
          <label for="" class="main-head">{{ $txt("Password updated") }}</label>
        </div>
        <div class="div-body">
          <p>
            {{
              $txt(
                "The password has been changed successfully. You can now login with your new password."
              )
            }}.
          </p>
          <router-link :to="{ name: 'Login' }">Login</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ResetPassword",
  data() {
    return {
      user: {
        password: "",
        password_confirmation: "",
        token: "",
      },
      sendForm: false,
    };
  },
  methods: {
    userLogin() {
      if (
        !this.user.password ||
        !this.user.password_confirmation ||
        !this.user.token
      ) {
        this.$notify({
          title: "Error",
          type: "error",
          text: "Please fill in all fields",
        });
        return;
      }
      if (this.user.password !== this.user.password_confirmation) {
        this.$notify({
          title: "Error",
          type: "error",
          text: "Passwords do not match",
        });
        return;
      }
      this.$startLoader();
      this.$store
        .dispatch("RESET_PASSWORD", this.user)
        .then((res) => {
          if (res.data.status) {
            this.$notify({
              title: "Success",
              type: "success",
              text: res.data.data.message,
            });
            this.sendForm = true;
            this.$stopLoader();
          } else {
            this.$notify({
              title: "Error",
              type: "error",
              text: res.data.error,
            });
            this.$stopLoader();
          }
        })
        .catch((err) => {
          this.$notify({
            title: "Error",
            type: "error",
            text: err.response.data.error,
          });
          this.$stopLoader();
        });
    },
  },
  mounted() {
    this.user.token = this.$route.params.token;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.page-content {
  width: 100%;
  display: flex;
  justify-content: center;
  form {
    width: 100%;
    max-width: 600px;
    height: fit-content;
    padding: 4rem 5rem;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    position: relative;
    flex-wrap: wrap;
    margin-top: 2rem;

    .form-header {
      margin-bottom: 20px;
      .main-head {
        display: block;
        text-align: center;
        font-size: 20px;
        margin-bottom: 10px;
        span {
          color: $blue;
          font-weight: 600;
        }
      }
      .dont,
      .forgot {
        display: block;
        text-align: center;
        span,
        a {
          color: $blue;
          font-weight: 600;
        }
      }
    }
    .form-body {
      .form-group {
        margin-bottom: 20px;
        position: relative;
        p {
          display: block;
          margin-bottom: 5px;
          top: 5px;
          left: 10px;
          font-size: 13px;
        }
        input {
          width: 100%;
          padding: 10px;
          border: 1px solid #ddd;
          border-radius: 5px;
          outline: none;
        }
        .submit {
          width: 100%;
          padding: 10px;
          border: 1px solid #ddd;
          border-radius: 5px;
          outline: none;
          background: $blue;
          color: #fff;
          font-weight: 600;
          cursor: pointer;
          &:hover {
            background: darken($blue, 10%);
          }
        }
      }
    }
    .form-footer {
      position: absolute;
      bottom: 20px;
      width: calc(100% - 10rem);
      h4 {
        text-align: center;
      }
    }
  }
  .check {
    width: 100%;
    max-width: 600px;
    height: fit-content;
    padding: 4rem 5rem;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    position: relative;
    flex-wrap: wrap;
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100px;
      text-align: center;
    }
    .div-header {
      margin-bottom: 20px;
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    .div-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 1rem;
      p {
        text-align: center;
      }
    }
    a {
      text-align: center;
      font-weight: 600;
      background: $blue;
      color: #fff;
      padding: 10px 2rem;
      border-radius: 5px;
      text-decoration: none;
      &:hover {
        background: darken($blue, 10%);
      }
    }
  }
}
</style>