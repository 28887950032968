<template>
  <div class="container mx-auto">
    <h1 class="text-2xl font-bold mb-4 text-center">Worker Reviews</h1>

    <div v-for="review in worker.reviews" :key="review.id" class="max-w-lg mx-auto bg-white rounded-lg overflow-hidden shadow-lg mb-4">
      <div class="px-6 py-4">
        <div class="font-bold text-xl mb-2">{{ worker.name }}</div>
        <p class="text-gray-700 text-base">Role: {{ worker.role }}</p>
        <p class="text-gray-700 text-base">Department: {{ worker.department }}</p>
      </div>

      <div class="px-6 py-4 border-t border-gray-300">
        <h2 class="text-lg font-bold mb-2">Review</h2>
        <div class="mb-4">
          <div class="flex justify-between items-center mb-2">
            <div class="flex items-center">
              <div class="font-bold mr-2">{{ review.rating }}/5</div>
              <div class="text-gray-700">{{ review.comment }}</div>
            </div>
          </div>
          <div>
            <a class="text-green-500 font-semibold">{{ review.userName }}</a>
            <p class="text-gray-700 text-sm">Gig Done: {{ review.gigDone }}</p>
            <p class="text-gray-700 text-sm">Work Completed: {{ review.workCompleted }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      worker: {
        id: 1,
        name: 'John Doe',
        role: 'Developer',
        department: 'Engineering',
        reviews: [
          {
            id: 1,
            userId: 101,
            userName: 'Alice Smith',
            rating: 4,
            comment: 'Great worker, always delivers on time.',
            gigDone: 'Frontend Development',
            workCompleted: 'Redesign of Landing Page'
          },
          {
            id: 2,
            userId: 102,
            userName: 'Bob Johnson',
            rating: 5,
            comment: 'Outstanding performance on the backend.',
            gigDone: 'Backend API Integration',
            workCompleted: 'Database Optimization'
          },
        ]
      }
    };
  }
};
</script>

<style scoped>
</style>
