<template>
  <div class="flex">
    <div class="ProfileMenu">
      <div class="top-section">
        <div class="profile-image">
          <img src="https://via.placeholder.com/150" alt="Profile Image" />
        </div>
        <div class="profile-name" v-if="user">
          <h3>{{ getOneWord(user.names) }}</h3>
        </div>
      </div>
      <div class="nav-links">
        <router-link :to="{ name: 'Profile' }">
          <i class="fa fa-user"></i>Profile
        </router-link>
        <router-link :to="{ name: 'Reviews' }">
          <i class="fa fa-star"></i>
          Reviews
        </router-link>
        <router-link :to="{ name: 'MyProduction' }">
          <i class="fa fa-list"></i>
          Agriculture
        </router-link>
        <router-link :to="{ name: 'MyBusinesses' }">
          <i class="fa fa-list"></i>
          My Businesses
        </router-link>
        <router-link :to="{ name: 'Vehicles' }">
          <i class="fa fa-car"></i>
          Transport
        </router-link>
        <router-link :to="{ name: 'ChangePassword' }">
          <i class="fa fa-lock"></i>
          Security
        </router-link>
        <router-link to="/profile/logout" @click="$userLogout()">
          <i class="fa fa-sign-out"></i>
          Logout
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: null,
    };
  },
  name: "ProfileMenu",
  components: {},
  methods: {
    getOneWord(name) {
      return name.split(" ")[0];
    },
  },

  mounted() {
    if (this.$getUser()) {
      this.user = this.$getUser();
    } else {
      this.$router.push({ name: "Login" });
    }
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/main.scss";
.ProfileMenu {
  display: flex;
  flex-direction: column;
  width: 200px;
  height: calc(100vh - 105px);
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 100;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  @media screen and (max-width: 1500px) {
    width: 200px;
    height: calc(100vh - 100px);
  }
  .top-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-bottom: 1px solid #ccc;
    padding: 2rem;
    @media screen and (max-width: 1500px) {
      padding: 1rem;
    }
    .profile-image {
      width: 100%;
      display: flex;
      justify-content: center;
      img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        @media screen and (max-width: 1500px) {
          width: 70px;
          height: 70px;
        }
      }
    }
    .profile-name {
      width: 100%;
      h3 {
        text-align: center;
        @media screen and (max-width: 1500px) {
          font-size: 0.8rem;
        }
      }
    }
  }
  .nav-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    a {
      width: 100%;
      padding: 1rem;
      text-decoration: none;
      color: #000;
      font-weight: 600;
      font-size: 0.9rem;
      @media screen and (max-width: 1500px) {
        font-size: 0.7rem;
      }
      &:hover {
        color: $blue;
        background: #f7f7f7;
      }
      i {
        margin-right: 1rem;
        color: $blue;
      }
    }
    .router-link-active,
    .router-link-exact-active {
      color: $blue;
      background: #f7f7f7;
    }
  }
}
</style>
