<template>
  <div class="Production">
    <agriProfileMenu />
    <br>
    <div class="table-header">
      <div class="left">
        <div class="search">
          <input
            type="text"
            placeholder="Search"
            @keyup="searchData()"
            v-model="search"
          />
        </div>
      </div>
      <div class="right">
      </div>
    </div>
    <table class="table bus-table">
      <thead>
        <tr>
          <th>#</th>
          <th>Product</th>
          <th>Quantity</th>
          <th>Client</th>
          <th>Status</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in items" :key="item.id">
          <td class="img">
            <img
              :src="$file(item.production.images[0])"
              alt=""
              @error="$imageurlalt"
            />
          </td>
          <td>{{ item.product.product_name }}</td>
          <td>{{ item.order_quantity }}</td>
          <td>
            {{ item.user.full_names }}<br />{{ item.user.telephone }}<br />{{
              item.user.email_address
            }}
          </td>
          <td>
            <p class="status" :class="item.order_status">
              {{ item.order_status }}
            </p>
          </td>
          <td>
            <button
              class="verify"
              @click="
                selectedItem = item;
                editPopup = true;
              "
            >
              <label for="">{{ $txt("Approve") }}</label>
            </button>
            <button
              class="delete"
              @click="
                selectedItem = item;
                rejectPopup = true;
              "
            >
              <label for="">{{ $txt("Reject") }}</label>
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <model
      :modelTitle="'Reject Production'"
      :smallModel="true"
      v-if="rejectPopup"
      @closeModel="rejectPopup = false"
    >
      <form action="" @submit.prevent="rejectOrder()">
        <!-- reason -->
        <div class="form-group">
          <label for="">Reason</label>
          <textarea
            name=""
            id=""
            cols="30"
            rows="5"
            v-model="selectedItem.message"
            :placeholder="$txt('Reason for rejecting the order')"
          ></textarea>
        </div>
        <div class="form-group">
          <button class="btn-lg unverify" type="submit">
            {{ $txt("Reject") }}
          </button>
        </div>
      </form>
    </model>
    <model
      :modelTitle="'Approve Production'"
      :smallModel="true"
      v-if="editPopup"
      @closeModel="editPopup = false"
    >
      <form action="" @submit.prevent="ApproveOrder()">
        <div class="form-group">
          <label for="">Delivery Date</label>
          <input type="date" v-model="selectedItem.delivery_date" />
        </div>
        <!-- message -->
        <div class="form-group">
          <label for="">Message</label>
          <textarea
            name=""
            id=""
            cols="30"
            rows="5"
            v-model="selectedItem.message"
          ></textarea>
        </div>
        <div class="form-group">
          <button class="btn-lg verify" type="submit">
            {{ $txt("Approve") }}
          </button>
        </div>
      </form>
    </model>
  </div>
</template>

<script>
import AddProduction from "./AddProduction.vue";
import EditProduction from "./EditProduction.vue";
export default {
  components: { AddProduction, EditProduction },
  data() {
    return {
      allItems: [
        {
          id: 1,
          images: ["maize.jpg"],
          price: "3000Rwf/kg",
          quantity: 10,
          unit: "Tons",
          district_name: "KICUKIRO",
          sector_name: "Kanombe",
          product: {
            product_name: "Ibigori",
          },
        },
      ],
      items: [],
      search: "",
      rejectPopup: false,
      editPopup: false,
      selectedItem: null,
      deletePopup: false,
      sellOutPopup: false,
    };
  },
  methods: {
    searchData() {
      this.items = this.allItems.filter((item) => {
        return (
          item.product.product_name
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          item.sector_name.toLowerCase().includes(this.search.toLowerCase()) ||
          item.district_name.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_AGRIORDERSBYUSER", {
          data: { user_id: this.$getUser().userId },
        })
        .then((res) => {
          this.allItems = res.data.data;
          this.allItems.forEach((item) => {
            item.production.images = JSON.parse(item.production.images);
          });
          this.items = this.allItems;
          this.$stopLoader();
        })
        .catch((err) => {
          this.$stopLoader();
        });
    },
    deleteItem() {
      this.$startLoader();
      this.$store
        .dispatch("DELETE_AGRIPRODUCTION", {
          token: this.$getToken(),
          data: {
            production_id: this.selectedItem.production_id,
          },
        })
        .then((res) => {
          if (res.data.status == "success") {
            this.$notify({
              title: "Success",
              type: "success",
              text: res.data.message,
            });
            this.getItems();
          } else {
            this.$notify({
              title: "Error",
              type: "warn",
              text: res.data.message,
            });
            this.$stopLoader();
          }
          this.deletePopupOpen = false;
        })
        .catch((err) => {
          console.log(err);
          this.$notify({
            title: "Error",
            type: "error",
            text: err.response.data.error,
          });
          this.$stopLoader();
        });
    },
    sellOut() {
      this.$startLoader();
      this.$store
        .dispatch("DELETE_AGRIPRODUCTION", {
          token: this.$getToken(),
          data: {
            production_id: this.selectedItem.production_id,
          },
        })
        .then((res) => {
          if (res.data.status == "success") {
            this.$notify({
              title: "Success",
              type: "success",
              text: this.$txt("Production marked as sold out"),
            });
            this.getItems();
          } else {
            this.$notify({
              title: "Error",
              type: "warn",
              text: res.data.message,
            });
            this.$stopLoader();
          }
          this.deletePopupOpen = false;
        })
        .catch((err) => {
          console.log(err);
          this.$notify({
            title: "Error",
            type: "error",
            text: "Something went wrong",
          });
          this.$stopLoader();
        });
    },
    ApproveOrder() {
      this.$startLoader();
      this.$store
        .dispatch("APPROVE_AGRIORDER", {
          token: this.$getToken(),
          data: {
            order_id: this.selectedItem.order_id,
            delivery_date: this.selectedItem.delivery_date,
            message: this.selectedItem.message,
          },
        })
        .then((res) => {
          if (res.data.status == "success") {
            this.$notify({
              title: "Success",
              type: "success",
              text: res.data.message,
            });
            this.getItems();
          } else {
            this.$notify({
              title: "Error",
              type: "warn",
              text: res.data.message,
            });
            this.$stopLoader();
          }
          this.editPopup = false;
        })
        .catch((err) => {
          console.log(err);
          this.$notify({
            title: "Error",
            type: "error",
            text: err.response.data.error,
          });
          this.$stopLoader();
        });
    },
    rejectOrder() {
      this.$startLoader();
      this.$store
        .dispatch("REJECT_AGRIORDER", {
          token: this.$getToken(),
          data: {
            order_id: this.selectedItem.order_id,
            message: this.selectedItem.message,
          },
        })
        .then((res) => {
          if (res.data.status == "success") {
            this.$notify({
              title: "Success",
              type: "success",
              text: res.data.message,
            });
            this.getItems();
          } else {
            this.$notify({
              title: "Error",
              type: "warn",
              text: res.data.message,
            });
            this.$stopLoader();
          }
          this.rejectPopup = false;
        })
        .catch((err) => {
          console.log(err);
          this.$notify({
            title: "Error",
            type: "error",
            text: err.response.data.error,
          });
          this.$stopLoader();
        });
    },
  },
  mounted() {
    this.getItems();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.Production {
  padding: 1rem;
}
p{
  &.pending{
    background: #f1c40f;
    color: #fff;
    border-radius: 500px;
    padding: 0.15rem .7rem;
    display: inline-block;
    font-size: .7rem;
  }
  &.approved{
    background: #2ecc71;
    color: #fff;
    border-radius: 500px;
    padding: 0.15rem .7rem;
    display: inline-block;
    font-size: .7rem;
  }
  &.rejected{
    background: #e74c3c;
    color: #fff;
    border-radius: 500px;
    padding: 0.15rem .7rem;
    display: inline-block;
    font-size: .7rem;
  }
}
</style>