<template>
  <div class="Reports">
    <super-admin-topbar :pageTitle="'Reports'" />
    <reports-navigation :activePage="'all'" />
    <div class="page-content">
      <div class="reports-pies">
        <div class="pie-item">
          <div class="header">
            <label for="">Schools by Provinces</label>
            <div class="right">
              <select name="" id="" v-model="exportSchByProvince">
                <option value="">Select format</option>
                <option>PDF</option>
                <option>Excel</option>
              </select>
              <button>Export</button>
            </div>
          </div>
          <div class="pie-diagram">
            <apexchart
              type="pie"
              width="450"
              :options="schByProvince.chartOptions"
              :series="schByProvince.series"
            ></apexchart>
          </div>
        </div>
        <div class="pie-item">
          <div class="header">
            <label for="">Schools by District</label>
            <div class="right">
              <select name="" id="" v-model="exportSchByDistrict">
                <option value="">Select format</option>
                <option>PDF</option>
                <option>Excel</option>
              </select>
              <button>Export</button>
            </div>
          </div>
          <div class="pie-diagram">
            <apexchart
              type="pie"
              width="450"
              :options="schByDistrict.chartOptions"
              :series="schByDistrict.series"
            ></apexchart>
          </div>
        </div>
        <!-- schools by category -->
        <div class="pie-item">
          <div class="header">
            <label for="">Schools by Category</label>
            <div class="right">
              <select name="" id="" v-model="exportSchByDistrict">
                <option value="">Select format</option>
                <option>PDF</option>
                <option>Excel</option>
              </select>
              <button>Export</button>
            </div>
          </div>
          <div class="pie-diagram">
            <apexchart
              type="pie"
              width="465"
              :options="schByCategory.chartOptions"
              :series="schByCategory.series"
            ></apexchart>
          </div>
        </div>
        <!-- schools by domain of education -->
        <div class="pie-item">
          <div class="header">
            <label for="">Schools by Domain of Education</label>
            <div class="right">
              <select name="" id="" v-model="exportSchByDistrict">
                <option value="">Select format</option>
                <option>PDF</option>
                <option>Excel</option>
              </select>
              <button>Export</button>
            </div>
          </div>
          <div class="pie-diagram">
            <apexchart
              type="pie"
              :options="schByDomain.chartOptions"
              :series="schByDomain.series"
            ></apexchart>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Reports",
  data() {
    return {
      schools: [],
      allDistricts: [],
      allProvinces: [],
      schoolsByProvince: [],
      schoolsByDistrict: [],
      schByProvince: {
        series: [],
        chartOptions: {
          chart: {
            width: 380,
            type: "pie",
          },
          colors: ["#1d62f1", "#939374", "#5d02f1", "#975474", "#9d62b1"],
          labels: [],
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        },
      },
      schByDistrict: {
        series: [],
        chartOptions: {
          chart: {
            width: 380,
            type: "pie",
          },
          colors: ["#1d62f1", "#939374", "#5d02f1", "#975474", "#9d62b1"],
          labels: [],
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        },
      },
      schByCategory: {
        series: [],
        chartOptions: {
          chart: {
            width: 380,
            type: "pie",
          },
          colors: ["#1d62f1", "#939374", "#5d02f1", "#975474", "#9d62b1"],
          labels: [],
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        },
      },
      schByDomain: {
        series: [],
        chartOptions: {
          chart: {
            width: 380,
            type: "pie",
          },
          colors: ["#1d62f1", "#939374", "#5d02f1", "#975474", "#9d62b1"],
          labels: [],
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        },
      },
      exportSchByProvince: "",
      exportSchByDistrict: "",
    };
  },
  methods: {
    getSchools() {
      this.$startLoader();
      this.$store
        .dispatch("GET_ALL_SUPERADMIN_SCHOOLS", { token: this.$getToken() })
        .then((res) => {
          this.schools = res.data.data.data;
          this.allSchools = res.data.data.data;
          this.$stopLoader();
        })
        .catch((err) => {
          console.log(err);
          this.$notify({
            title: "Error",
            type: "error",
            text: err.response.data.error,
          });
          this.$stopLoader();
        });
    },
    getDistricts() {},
    getProvinces() {
      this.$startLoader();
      this.$store
        .dispatch("GET_PROVINCES", { token: this.$getToken() })
        .then((res) => {
          this.allProvinces = res.data.data.data;
          this.$stopLoader();
        });
    },
    getSchoolsByProvince() {
      this.$startLoader();
      this.$store
        .dispatch("GET_SCHOOLS_BY_ADDRESS", {
          token: this.$getToken(),
          data: { level: "province" },
        })
        .then((res) => {
          this.schoolsByProvince = res.data.data.data;
          this.schoolsByProvince.forEach((province) => {
            this.schByProvince.series.push(province.schools);
            this.schByProvince.chartOptions.labels.push(province.name);
          });
          this.$stopLoader();
        });
    },
    getSchoolsByDistrict() {
      this.$startLoader();
      this.$store
        .dispatch("GET_SCHOOLS_BY_ADDRESS", {
          token: this.$getToken(),
          data: { level: "district" },
        })
        .then((res) => {
          this.schoolsByDistrict = res.data.data.data;
          this.schoolsByDistrict.forEach((district) => {
            this.schByDistrict.series.push(district.schools);
            this.schByDistrict.chartOptions.labels.push(district.name);
          });
          this.$stopLoader();
        });
    },
    geSchoolsByCategory() {
      this.$startLoader();
      this.$store
        .dispatch("GET_FILTERED_SCHOOLS", {
          token: this.$getToken(),
          data: { filterType: "category" },
        })
        .then((res) => {
          this.schoolsByCategory = res.data.data.data;
          this.schByCategory.series.push(this.schoolsByCategory.Public);
          this.schByCategory.series.push(this.schoolsByCategory.Private);
          this.schByCategory.series.push(
            this.schoolsByCategory.GovernmentAided
          );
          this.schByCategory.chartOptions.labels.push("Public");
          this.schByCategory.chartOptions.labels.push("Private");
          this.schByCategory.chartOptions.labels.push("Government Aided");
          this.$stopLoader();
        });
    },
    geSchoolsByDomain() {
      this.$startLoader();
      this.$store
        .dispatch("GET_FILTERED_SCHOOLS", {
          token: this.$getToken(),
          data: { filterType: "domains" },
        })
        .then((res) => {
          this.schoolsByDomain = res.data.data.data;
          this.schoolsByDomain.forEach((domain) => {
            this.schByDomain.series.push(domain.schools);
            this.schByDomain.chartOptions.labels.push(domain.domain);
          });
          this.$stopLoader();
        });
    },
  },
  mounted() {
    this.getSchools();
    this.getProvinces();
    this.getSchoolsByProvince();
    this.getSchoolsByDistrict();
    this.geSchoolsByCategory();
    this.geSchoolsByDomain();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.Reports {
  .page-content {
    .reports-pies {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 2rem;
      row-gap: 2rem;
      padding: 2rem;
      .pie-item {
        width: 100%;
        .header {
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;
          .right {
            display: flex;
            align-items: center;
            select {
              padding: 5px 10px;
              border: none;
              border-bottom: 1px solid #ddd;
              outline: none;
              margin-right: 10px;
            }
            button {
              padding: 5px 20px;
              border: 1px solid #ddd;
              border-radius: 5px;
              outline: none;
              background: $blue;
              color: #fff;
              font-weight: 600;
              cursor: pointer;
            }
          }
        }
        label {
          font-size: 17px;
          font-weight: 600;
          margin-bottom: 10px;
        }
        .pie-diagram {
          border: 1px solid #ddd;
          border-radius: 5px;
          padding: 10px;
        }
      }
    }
  }
}
</style>