<template>
  <div class="Search">
    <site-navbar />
    <div class="whole-content">
      <div class="search-section">
        <form action="">
          <p>
            {{ $txt("Search across") }} <span>1000+</span>
            {{ $txt("resources on") }} <span>Infomaster</span>
          </p>
          <input
            type="text"
            placeholder="search"
            v-model="searchQuery"
            @keyup="searchNow()"
          />
        </form>
      </div>
      <div class="search-results">
        <p
          v-if="
            results.schools.length == 0 &&
            results.farmers.length == 0 &&
            results.workers.length == 0 &&
            results.businesses.length == 0 &&
            results.ads.length == 0 &&
            searchQuery == ''
          "
        >
          Type something in the above textbox to search
        </p>
        <p
          v-if="
            results.schools.length == 0 &&
            results.farmers.length == 0 &&
            results.workers.length == 0 &&
            results.businesses.length == 0 &&
            results.ads.length == 0 &&
            searchQuery != ''
          "
        >
          No results found. Try a different search
        </p>
        <div
          class="results"
          v-if="
            results.schools.length != 0 ||
            results.farmers.length != 0 ||
            results.workers.length != 0 ||
            results.businesses.length != 0 ||
            results.ads.length != 0
          "
        >
          <div class="schools" v-if="results.schools.length != 0">
            <h3>{{ $txt("Schools") }}</h3>
            <div
              class="school"
              v-for="school in results.schools"
              :key="school.id"
            >
              <div class="image">
                <img :src="`/assets/images/${school.image}`" alt="" />
              </div>
              <div class="content">
                <h4>{{ school.name }}</h4>
                <p>{{ school.description }}</p>
              </div>
            </div>
          </div>
          <div class="farmers" v-if="results.farmers.length != 0">
            <h3>{{ $txt("Farmers") }}</h3>
            <div class="farm" v-for="farm in results.farmers" :key="farm.id">
              <div class="image">
                <img :src="`/assets/images/${farm.image}`" alt="" />
              </div>
              <div class="content">
                <h4>{{ farm.name }}</h4>
                <p>{{ farm.mainProduct }}</p>
                <p>{{ farm.district }}</p>
                <p>{{ farm.sector }}</p>
              </div>
            </div>
          </div>
          <div class="workers" v-if="results.workers.length != 0">
            <h3>{{ $txt("Workers") }}</h3>
            <div
              class="worker"
              v-for="worker in results.workers"
              :key="worker.id"
            >
              <div class="image">
                <img :src="`/assets/images/${worker.image}`" alt="" />
              </div>
              <div class="content">
                <h4>{{ worker.name }}</h4>
                <p>{{ worker.distric }}</p>
                <p>{{ worker.sector }}</p>
                <p>{{ worker.skills.join(", ") }}</p>
              </div>
            </div>
          </div>
          <div class="businesses" v-if="results.businesses.length != 0">
            <h3>{{ $txt("Businesses") }}</h3>
            <div
              class="business"
              v-for="business in results.businesses"
              :key="business.id"
            >
              <div class="image">
                <img :src="`/assets/images/${business.image}`" alt="" />
              </div>
              <div class="content">
                <h4>{{ business.name }}</h4>
                <p>{{ business.category }}</p>
                <p>{{ business.ownership }}</p>
              </div>
            </div>
          </div>
          <div class="ads" v-if="results.ads.length != 0">
            <h3>{{ $txt("Ads") }}</h3>
            <div class="ad" v-for="ad in results.ads" :key="ad.id">
              <div class="image">
                <img :src="ad.image" alt="" />
              </div>
              <div class="content">
                <h4>{{ ad.name }}</h4>
                <p>{{ ad.owner }}</p>
                <p>{{ ad.description }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <site-footer />
  </div>
</template>

<script>
export default {
  name: "Search",
  components: {},
  data() {
    return {
      schools: [
        {
          id: 1,
          name: "King David Academy",
          description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatum! Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatum! Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatum!",
          image: "king.jpg",
        },
        {
          id: 2,
          name: "Rwanda Coding Academy",
          description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatum! Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatum! Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatum!",
          image: "rca.png",
        },
      ],
      farmers: [
        {
          id: 1,
          name: "Kanombe ICC Farm",
          image: "pou1.jpg",
          mainProduct: "Poultry Eggs",
          district: "Kicukiro",
          sector: "Kanombe",
        },
        {
          id: 2,
          name: "Munenega farm",
          image: "pou2.jpg",
          mainProduct: "Cattle Meat",
          district: "Gasabo",
          sector: "Kimironko",
        },
        {
          id: 3,
          name: "Mugisha farm",
          image: "pou3.jpg",
          mainProduct: "Poultry Meat",
          district: "Burera",
          sector: "Butaro",
        },
      ],
      workers: [
        {
          id: 1,
          name: "Mugisha Faustin",
          image: "pro1.jpg",
          skills: ["Carpenter", "Plumber", "Electrician"],
          distric: "Kicukiro",
          sector: "Kanombe",
        },
        {
          id: 2,
          name: "Kalisa Emmanuel",
          image: "pro2.jpg",
          skills: ["Lawyer", "Accountant", "Engineer"],
          distric: "Gasabo",
          sector: "Kimironko",
        },
        {
          id: 3,
          name: "Eric Kanamugire",
          image: "pro3.jpg",
          skills: ["Domestic worker", "Driver", "Security guard"],
          distric: "Burera",
          sector: "Butaro",
        },
      ],
      techs: [
        {
          id: 1,
          name: "Eng. Mugabo Faustin",
          image: "pro1.jpg",
        },
        {
          id: 2,
          name: "Eng. Kalisa Emmanuel",
          image: "pro1.jpg",
        },
        {
          id: 3,
          name: "Eng. Eric Kanamugire",
          image: "pro1.jpg",
        },
      ],
      businesses: [
        {
          id: 1,
          name: "Ireme Technologies",
          image: "ireme.png",
          category: "Information Technology",
          ownership: "Individual",
        },
        {
          id: 2,
          name: "Infomaster",
          image: "infomaster.png",
          category: "Engineering",
          ownership: "Company",
        },
        {
          id: 3,
          name: "Ireme Technologies",
          image: "ireme.png",
          category: "Transport",
          ownership: "Cooperative",
        },
      ],
      ads: [
        {
          id: 1,
          name: "Burgers",
          image: "/assets/images/burger.jpg",
          owner: "Burger Planet",
          description:
            "Get 20% off on all burgers before 10:00 AM every day with Burger Planet.",
        },
        {
          id: 2,
          name: "Jordan shoes",
          image: "/assets/images/shoe.png",
          owner: "Bryan store",
          description:
            "Get the best Jordan shoes at the best price with Bryan. Shop the latest selection of Jordan Shoes. Find the hottest sneakers.",
        },
        {
          id: 3,
          name: "Sofa set",
          image: "/assets/images/home.png",
          owner: "Mika furniture",
          description:
            "Customizable living room furniture sofa set fabric sofa. Customized High quality living room furniture living room sofa set fabric sofa",
        },
        {
          id: 4,
          name: "Accessories",
          image: "/assets/images/dish.png",
          owner: "MIKA Group",
          description:
            "Dish washing machine. When we enjoy cooking, we always have to face the problem of washing dishes. Dishwashers are not only a convenient way to clean up after a meal, but also save a considerable amount of water compared to washing dishes by hand.Dishwashers can clean tableware, fruits and vegetables, and also use the drying function after cleaning to keep tableware dry and tasteless. Hot temperature and hot wind are suitable for baby products and oily tableware to give glassware a sparkling clean finish.",
        },
      ],
      ismodules: [],
      modules: [],
      selectedSection: 1,
      searchQuery: "",
      results: {
        schools: [],
        farmers: [],
        workers: [],
        businesses: [],
        ads: [],
      },
    };
  },
  methods: {
    searchNow() {
      this.results.schools = this.schools.filter((school) => {
        return (
          school.name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          school.description
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase())
        );
      });
      this.results.farmers = this.farmers.filter((farm) => {
        return (
          farm.name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          farm.mainProduct
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase()) ||
          farm.district
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase()) ||
          farm.sector.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      });
      this.results.workers = this.workers.filter((worker) => {
        return (
          worker.name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          worker.distric
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase()) ||
          worker.sector
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase()) ||
          worker.skills
            .toString()
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase())
        );
      });
      this.results.businesses = this.businesses.filter((business) => {
        return (
          business.name
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase()) ||
          business.category
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase()) ||
          business.ownership
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase())
        );
      });
      this.results.ads = this.ads.filter((ad) => {
        return (
          ad.name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          ad.owner.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          ad.description.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      });
    },
  },
  mounted() {},
  computed: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.whole-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .search-section {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $blue;
    position: relative;
    padding-bottom: 3rem;
    form {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top: 5rem;
      p {
        font-size: 1rem;
        font-weight: 600;
        color: white;
        span {
          color: $yellow;
        }
      }
      input {
        width: 50%;
        border-radius: 500px;
        border: none;
        outline: none;
        padding: 1rem;
        margin-top: 20px;
        font-size: 1.2rem;
        font-weight: 600;
        color: #242424;
        border: 1px solid $blue;
        position: absolute;
        bottom: -1.75rem;

        &::placeholder {
          color: #646464;
          font-weight: 300;
        }
      }
    }
  }
  .search-results {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 5rem;
    padding-top: 3rem;
    min-height: calc(100vh - 300px);
    p {
      font-size: 0.9rem;
      font-weight: 400;
      color: #242424;
    }
    .results {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
    row-gap: 2rem;
      .schools {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        h3 {
          font-size: 1.2rem;
          font-weight: 600;
          color: #242424;
          margin-bottom: 1rem;
        }
        .school {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 1rem;
          &:hover {
            background-color: #f5f5f5;
            cursor: pointer;
          }
          .image {
            width: 100px;
            height: 100px;
            border-radius: 5px;
            overflow: hidden;
            margin-right: 1rem;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .content {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            cursor: pointer;
            h4 {
              font-size: 1rem;
              font-weight: 600;
              color: #242424;
              margin-bottom: 0.5rem;
            }
            p {
              font-size: 0.9rem;
              font-weight: 400;
              color: #242424;
            }
          }
        }
      }
      .farmers {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        h3 {
          font-size: 1.2rem;
          font-weight: 600;
          color: #242424;
          margin-bottom: 1rem;
        }
        .farm {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 1rem;
          &:hover {
            background-color: #f5f5f5;
            cursor: pointer;
          }
          .image {
            width: 100px;
            height: 100px;
            border-radius: 5px;
            overflow: hidden;
            margin-right: 1rem;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .content {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            cursor: pointer;
            h4 {
              font-size: 1rem;
              font-weight: 600;
              color: #242424;
              margin-bottom: 0.5rem;
            }
            p {
              font-size: 0.9rem;
              font-weight: 400;
              color: #242424;
            }
          }
        }
      }
      .workers {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        h3 {
          font-size: 1.2rem;
          font-weight: 600;
          color: #242424;
          margin-bottom: 1rem;
        }
        .worker {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 1rem;
          &:hover {
            background-color: #f5f5f5;
            cursor: pointer;
          }
          .image {
            width: 100px;
            height: 100px;
            border-radius: 5px;
            overflow: hidden;
            margin-right: 1rem;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .content {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            cursor: pointer;
            h4 {
              font-size: 1rem;
              font-weight: 600;
              color: #242424;
              margin-bottom: 0.5rem;
            }
            p {
              font-size: 0.9rem;
              font-weight: 400;
              color: #242424;
            }
          }
        }
      }
      .businesses {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        h3 {
          font-size: 1.2rem;
          font-weight: 600;
          color: #242424;
          margin-bottom: 1rem;
        }
        .business {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 1rem;
          &:hover {
            background-color: #f5f5f5;
            cursor: pointer;
          }
          .image {
            width: 100px;
            height: 100px;
            border-radius: 5px;
            overflow: hidden;
            margin-right: 1rem;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .content {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            cursor: pointer;
            h4 {
              font-size: 1rem;
              font-weight: 600;
              color: #242424;
              margin-bottom: 0.5rem;
            }
            p {
              font-size: 0.9rem;
              font-weight: 400;
              color: #242424;
            }
          }
        }
      }
      .ads {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        h3 {
          font-size: 1.2rem;
          font-weight: 600;
          color: #242424;
          margin-bottom: 1rem;
        }
        .ad {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 1rem;
          &:hover {
            background-color: #f5f5f5;
            cursor: pointer;
          }
          .image {
            width: 100px;
            height: 100px;
            border-radius: 5px;
            overflow: hidden;
            margin-right: 1rem;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .content {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            cursor: pointer;
            h4 {
              font-size: 1rem;
              font-weight: 600;
              color: #242424;
              margin-bottom: 0.5rem;
            }
            p {
              font-size: 0.9rem;
              font-weight: 400;
              color: #242424;
            }
          }
        }
      }
    }
  }
}
</style>