import axios from 'axios'
import { API_URL, BUSS_API_URL } from '../../variables';
const GET_ALL_WORKERS_URL = '/workers/getAllWorkers';
const CREATE_WORKER_URL = '/workers/createWorker';
const UPDATE_WORKER_URL = '/workers/updateWorker';
const DELETE_WORKER_URL = '/workers/deleteWorker';
const GET_WORKER_BY_ID_URL = '/workers/getWorkerById';
const GET_WORKER_BY_SECTOR_ID_URL = '/workers/getWorkerBySkillsSector';
const GET_WORKER_BY_LOCATION_URL = '/workers/getWorkerByLocation';

const workersOthers = {
    state: {
    },
    getters: {},
    mutations: {},
    actions: {
        ['GET_ALL_WORKERS']: ({ commit }, payload) => {
            return axios({
                url: BUSS_API_URL + GET_ALL_WORKERS_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                }
            })
        },
        ['CREATE_WORKER']: ({ commit }, payload) => {
            return axios({
                url: BUSS_API_URL + CREATE_WORKER_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                }
            })
        },
        ['UPDATE_WORKER']: ({ commit }, payload) => {
            return axios({
                url: BUSS_API_URL + UPDATE_WORKER_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                }
            })
        },
        ['DELETE_WORKER']: ({ commit }, payload) => {
            return axios({
                url: BUSS_API_URL + DELETE_WORKER_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                }
            })
        },
        ['GET_WORKER_BY_ID']: ({ commit }, payload) => {
            return axios({
                url: BUSS_API_URL + GET_WORKER_BY_ID_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                }
            })
        },
        ['GET_WORKER_BY_SECTOR_ID']: ({ commit }, payload) => {
            return axios({
                url: BUSS_API_URL + GET_WORKER_BY_SECTOR_ID_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                }
            })
        },
        ['GET_WORKER_BY_LOCATION']: ({ commit }, payload) => {
            return axios({
                url: BUSS_API_URL + GET_WORKER_BY_LOCATION_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                }
            })
        },
    },
    modules: {},

}
export default workersOthers;