import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// plugs
import axios from 'axios'
import VueAxios from 'vue-axios'
import '@/assets/css/tailwind.css';
import VueMultiselect from 'vue-multiselect'
import Notifications from '@kyvg/vue3-notification'
// mixin
import CanvasJSChart from '@canvasjs/vue-charts';
import Mixin from './mixins/mixin.vue'
import VueApexCharts from "vue3-apexcharts";
// components
import SiteNavbar from './views/components/SiteNavbar.vue'
import SiteFooter from './views/components/SiteFooter.vue'
import ChangeLang from './views/components/ChangeLang.vue'
import loggedUser from './views/components/loggedUser.vue'
import Model from './views/components/Model.vue'
import confirmModel from './views/components/confirmModel.vue'
import SuperAdminTopbar from "./views/superAdmin/components/Topbar.vue";
import UsersNavigation from "./views/superAdmin/components/UsersNavigation.vue";
import ReportsNavigation from "./views/superAdmin/components/ReportsNavigation.vue";
import IPagination from "./views/components/Pagination.vue";
import MarketNavigation from "./views/superAdmin/components/MarketNavigation.vue";
import ViewProduct from "./views/components/ViewProduct.vue";
import ViewWorker from "./views/site/workers/ViewWorker.vue";
import leftView from "./views/components/leftView.vue";
import navSmall from "./views/components/navSmall.vue";
import Topbar from "./views/dashboard/components/Topbar.vue";
import Sidebar from "./views/dashboard/components/Sidebar.vue";
import ProfileNav from "./views/components/ProfileNav.vue";
import AddBusiness from "./views/site/business directory/AddBusiness.vue";
import LoginModel from "./views/components/LoginModel.vue"
import agriProfileMenu from "./views/site/agriculture/agriProfileMenu.vue";
// import { Hooper, Slide } from 'hooper-vue3';
// import 'hooper-vue3/dist/hooper.css';
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import OUpload from './components/OUpload.vue'
import Dropdown from 'vue-simple-search-dropdown';




import { QuillEditor } from '@vueup/vue-quill'


const vue = createApp(App)
vue.component('site-navbar', SiteNavbar)
vue.component('site-footer', SiteFooter)
vue.component('change-lang', ChangeLang)
vue.component('logged-user', loggedUser)
vue.component('quill-editor', QuillEditor)
vue.component('VueMultiselect', VueMultiselect)
vue.component('model', Model)
vue.component('confirm-model', confirmModel)
vue.component('super-admin-topbar', SuperAdminTopbar)
vue.component('users-navigation', UsersNavigation)
vue.component('reports-navigation', ReportsNavigation)
vue.component('pagination', IPagination)
vue.component('market-navigation', MarketNavigation)
vue.component('carousel', Carousel)
vue.component('slide', Slide)
vue.component('cou-pagination', Pagination)
vue.component('navigation', Navigation)
vue.component('view-product', ViewProduct)
vue.component('view-worker', ViewWorker)
vue.component('left-view', leftView)
vue.component('nav-small', navSmall)
vue.component('VueDatePicker', VueDatePicker)
vue.component('topbar', Topbar)
vue.component('sidebar', Sidebar)
vue.component('add-business', AddBusiness)
vue.component('profile-menu', ProfileNav)
vue.component('o-upload', OUpload)
vue.component('Login-Model', LoginModel)
vue.component('agriProfileMenu', agriProfileMenu)

vue.use(Notifications)
vue.use(CanvasJSChart)
vue.use(VueApexCharts)
vue.use(Dropdown)

vue.use(VueAxios, axios)
vue.mixin(Mixin)
vue.use(store)
vue.use(router)
vue.mount('#app')