<template>
  <div class="GiReports">
    <super-admin-topbar :pageTitle="'General information'" />
    <reports-navigation :activePage="'GI'" />
    <div class="content-widget">
      <div class="filter-form">
        <label for="">Filter: </label>
        <div class="form">
          <div class="form-group group-4">
            <!-- Province, Disctict, sector, cell -->
            <select
              name="province"
              id=""
              v-model="school.province"
              @change="getDistricts(school.province)"
            >
              <option value="">{{ $txt("All Provinces") }}</option>
              <option
                v-for="province in provinces"
                :key="province.provinceId"
                :value="province.provinceId"
              >
                {{ province.provinceName }}
              </option>
            </select>
            <select
              name="district"
              id=""
              v-model="school.district"
              @change="getSectors(school.district)"
            >
              <option value="">{{ $txt("All Districts") }}</option>
              <option
                v-for="district in districts"
                :key="district.districtId"
                :value="district.districtId"
              >
                {{ district.districtName }}
              </option>
            </select>
            <select
              name="sector"
              id=""
              v-model="school.sector"
              @change="getCells(school.sector)"
            >
              <option value="">{{ $txt("All Sectors") }}</option>
              <option
                v-for="sector in sectors"
                :key="sector.sectorId"
                :value="sector.sectorId"
              >
                {{ sector.sectorName }}
              </option>
            </select>
            <select name="cell" id="" v-model="school.cell_id">
              <option value="">{{ $txt("All Cells") }}</option>
              <option
                v-for="cell in cells"
                :key="cell.cellId"
                :value="cell.cellId"
              >
                {{ cell.cellName }}
              </option>
            </select>
          </div>
          <button class="submit" @click="filterData()">Filter</button>
        </div>
        <div class="profile-section row" v-if="schoolData.schools != 0">
          <p class="section-title">Properties</p>
          <br />
          <div class="info">
            <strong>School Assets:</strong>
            <ul class="property-list">
              <div class="left">
                <li
                  v-for="(value, property) in schoolData.properties"
                  :key="property"
                >
                  <div v-if="property == 'totalClasses'">
                    Classes :
                    <span v-if="value != 0">{{ value }}</span>
                    <span v-else style="color: dimgray; font-size: 13px"
                      >Not available</span
                    >
                  </div>
                  <div v-if="property == 'totalTeachers'">
                    Teachers :
                    <span v-if="value != 0">{{ value }}</span>
                    <span v-else style="color: dimgray; font-size: 13px"
                      >Not available</span
                    >
                  </div>
                  <div v-if="property == 'electricityConnector'">
                    Electricity connector :
                    <span v-if="value != 0">{{ value }}</span>
                    <span v-else style="color: dimgray; font-size: 13px"
                      >Not available</span
                    >
                  </div>
                  <div v-if="property == 'waterConnector'">
                    Water connector :
                    <span v-if="value != 0">{{ value }}</span>
                    <span v-else style="color: dimgray; font-size: 13px"
                      >Not available</span
                    >
                  </div>
                  <div v-if="property == 'waterFilters'">
                    Water filters :
                    <span v-if="value != 0">{{ value }}</span>
                    <span v-else style="color: dimgray; font-size: 13px"
                      >Not available</span
                    >
                  </div>
                  <div v-if="property == 'hasInternet'">
                    Internet :
                    <span v-if="value != 0">{{ value }}</span>
                    <span v-else style="color: dimgray; font-size: 13px"
                      >Not available</span
                    >
                  </div>
                  <div v-if="property == 'solarSystem'">
                    Solar system :
                    <span v-if="value != 0">{{ value }}</span>
                    <span v-else style="color: dimgray; font-size: 13px"
                      >Not available</span
                    >
                  </div>
                </li>
              </div>
              <div class="mid-1">
                <li
                  v-for="(value, property) in schoolData.properties"
                  :key="property"
                >
                  <div v-if="property == 'totalDesktops'">
                    Desktops :
                    <span v-if="value != 0">{{ value }}</span>
                    <span v-else style="color: dimgray; font-size: 13px"
                      >Not available</span
                    >
                  </div>
                  <div v-if="property == 'totalLaptops'">
                    Laptops :
                    <span v-if="value != 0">{{ value }}</span>
                    <span v-else style="color: dimgray; font-size: 13px"
                      >Not available</span
                    >
                  </div>
                  <div v-if="property == 'totalPrinters'">
                    Printers :
                    <span v-if="value != 0">{{ value }}</span>
                    <span v-else style="color: dimgray; font-size: 13px"
                      >Not available</span
                    >
                  </div>
                  <div v-if="property == 'flatScreens'">
                    Flat screens :
                    <span v-if="value != 0">{{ value }}</span>
                    <span v-else style="color: dimgray; font-size: 13px"
                      >Not available</span
                    >
                  </div>
                  <div v-if="property == 'speakers'">
                    Speakers :
                    <span v-if="value != 0">{{ value }}</span>
                    <span v-else style="color: dimgray; font-size: 13px"
                      >Not available</span
                    >
                  </div>
                  <div v-if="property == 'microphones'">
                    Microphones :
                    <span v-if="value != 0">{{ value }}</span>
                    <span v-else style="color: dimgray; font-size: 13px"
                      >Not available</span
                    >
                  </div>
                  <div v-if="property == 'mixers'">
                    Mixers :
                    <span v-if="value != 0">{{ value }}</span>
                    <span v-else style="color: dimgray; font-size: 13px"
                      >Not available</span
                    >
                  </div>
                </li>
              </div>
              <div class="mid-2">
                <li
                  v-for="(value, property) in schoolData.properties"
                  :key="property"
                >
                  <div v-if="property == 'area'">
                    Land area size :
                    <span v-if="value != 0">{{ value }} m<sup>2</sup></span>
                    <span v-else style="color: dimgray; font-size: 13px"
                      >Not available</span
                    >
                  </div>
                  <div v-if="property == 'agriculturalLand'">
                    Agricultural land :
                    <span v-if="value != 0">{{ value }} m<sup>2</sup></span>
                    <span v-else style="color: dimgray; font-size: 13px"
                      >Not available</span
                    >
                  </div>
                  <div v-if="property == 'cars'">
                    Cars :
                    <span v-if="value != 0">{{ value }}</span>
                    <span v-else style="color: dimgray; font-size: 13px"
                      >Not available</span
                    >
                  </div>
                  <div v-if="property == 'motocycle'">
                    Motocycles :
                    <span v-if="value != 0">{{ value }}</span>
                    <span v-else style="color: dimgray; font-size: 13px"
                      >Not available</span
                    >
                  </div>
                  <div v-if="property == 'bicycles'">
                    Bicycles :
                    <span v-if="value != 0">{{ value }}</span>
                    <span v-else style="color: dimgray; font-size: 13px"
                      >Not available</span
                    >
                  </div>
                </li>
              </div>
              <div class="right">
                <li
                  v-for="(value, property) in schoolData.properties"
                  :key="property"
                >
                  <div v-if="property == 'Dustbins'">
                    Dustbins :
                    <span v-if="value != 0">{{ value }}</span>
                    <span v-else style="color: dimgray; font-size: 13px"
                      >Not available</span
                    >
                  </div>
                  <div v-if="property == 'fireInsurance'">
                    Fire insurance :
                    <span v-if="value != 0">{{ value }}</span>
                    <span v-else style="color: dimgray; font-size: 13px"
                      >Not available</span
                    >
                  </div>
                  <div v-if="property == 'fireExtinguishers'">
                    Fire extinguishers :
                    <span v-if="value != 0">{{ value }}</span>
                    <span v-else style="color: dimgray; font-size: 13px"
                      >Not available</span
                    >
                  </div>
                  <div v-if="property == 'lightningProtector'">
                    Lightning protector :
                    <span v-if="value != 0">{{ value }}</span>
                    <span v-else style="color: dimgray; font-size: 13px"
                      >Not available</span
                    >
                  </div>
                </li>
              </div>
            </ul>
          </div>
        </div>
        <div class="no-content" v-else>
          <label>No data available</label>
          <p>Change filter parameters to get data</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GiReports",
  data() {
    return {
      school: {
        province: "",
        district: "",
        sector: "",
        cell_id: "",
      },
      provinces: [],
      districts: [],
      sectors: [],
      cells: [],
      schoolData: {
        schools: 0,
      },
    };
  },
  methods: {
    getProvinces() {
      this.$startLoader();
      this.$store
        .dispatch("GET_PROVINCES", { token: this.$getToken() })
        .then((res) => {
          this.provinces = res.data.data.data;
          this.districts = [];
          this.sectors = [];
          this.cells = [];
          this.$stopLoader();
        });
    },
    getDistricts(provinceId) {
      if (provinceId == "" || provinceId == undefined || provinceId == null) {
        this.districts = [];
        this.sectors = [];
        this.cells = [];
        return;
      }
      this.$startLoader();
      this.$store
        .dispatch("GET_DISTRICTS", {
          token: this.$getToken(),
          provinceId: provinceId,
        })
        .then((res) => {
          this.districts = res.data.data.data;
          this.sectors = [];
          this.cells = [];
          this.$stopLoader();
        });
    },
    getSectors(districtId) {
      if (districtId == "" || districtId == undefined || districtId == null) {
        this.sectors = [];
        this.cells = [];
        return;
      }
      this.$startLoader();
      this.$store
        .dispatch("GET_SECTORS", {
          token: this.$getToken(),
          districtId: districtId,
        })
        .then((res) => {
          this.sectors = res.data.data.data;
          this.cells = [];
          this.$stopLoader();
        });
    },
    getCells(sectorId) {
      if (sectorId == "" || sectorId == undefined || sectorId == null) {
        this.cells = [];
        return;
      }
      this.$startLoader();
      this.$store
        .dispatch("GET_CELLS", { token: this.$getToken(), sectorId: sectorId })
        .then((res) => {
          this.cells = res.data.data.data;
          this.$stopLoader();
        });
    },
    filterData() {
      this.$startLoader();
      if (this.school.province == null || this.school.province == undefined) {
        this.school.province = "";
      }
      if (this.school.district == null || this.school.district == undefined) {
        this.school.district = "";
      }
      if (this.school.sector == null || this.school.sector == undefined) {
        this.school.sector = "";
      }
      if (this.school.cell_id == null || this.school.cell_id == undefined) {
        this.school.cell_id = "";
      }
      if (
        this.school.province == "" &&
        this.school.district == "" &&
        this.school.sector == "" &&
        this.school.cell_id == ""
      ) {
        this.$notify({
          group: "notify",
          type: "error",
          title: "Error",
          text: "Please select at least one filter",
        });
        this.$stopLoader();
        return;
      }
      this.$store
        .dispatch("GET_FILTERED_SCHOOLS", {
          token: this.$getToken(),
          data: {
            filterType: "properties",
            province: this.school.province,
            district: this.school.district,
            sector: this.school.sector,
            cell: this.school.cell_id,
          },
        })
        .then((res) => {
          this.schoolData = res.data.data.data;
          var k = JSON.parse(
            JSON.stringify(
              this.schoolData.properties,
              [
                "totalClasses",
                "totalTeachers",
                "electricityConnector",
                "waterConnector",
                "waterFilters",
                "hasInternet",
                "solarSystem",
                "totalDesktops",
                "totalLaptops",
                "totalPrinters",
                "flatScreens",
                "speakers",
                "microphones",
                "mixers",
                "area",
                "agriculturalLand",
                "cars",
                "motocycle",
                "bicycles",
                "Dustbins",
                "fireInsurance",
                "fireExtinguishers",
                "lightningProtector",
              ],
              4
            )
          );
          this.schoolData.properties = k;
          this.$stopLoader();
        });
    },
  },
  mounted() {
    this.getProvinces();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.GiReports {
  .content-widget {
    margin-top: 2rem;
    .filter-form {
      display: flex;
      flex-direction: column;
      margin: 2rem 1rem;
      row-gap: 2rem;
      label {
        font-size: 1.2rem;
        font-weight: 600;
        margin-right: 1rem;
      }
      .form {
        display: flex;
        align-items: center;
        column-gap: 20px;
        .form-group {
          display: flex;
          align-items: center;
          column-gap: 20px;
          select {
            width: 200px;
            height: 40px;
            border-radius: 4px;
            border: 1px solid $blue;
            padding: 0 10px;
            outline: none;
          }
        }
        .group-4 {
          select {
            width: 150px;
          }
        }
        .submit {
          width: 100px;
          height: 40px;
          border-radius: 4px;
          border: none;
          outline: none;
          background-color: $blue;
          color: #fff;
          font-size: 1rem;
          font-weight: 600;
          cursor: pointer;
        }
      }

      .profile-section {
        margin-bottom: 2rem;
        padding: 1rem;
        background-color: #ffffff;
        border: 1px solid #ccc;
        border-radius: 5px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        &.row {
          grid-column: 1 / 3;
        }
      }
      .no-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        label {
          font-size: 1rem;
          font-weight: 500;
          color: #474747;
        }
        p {
          font-size: .8rem;
          color: #757575;
          font-weight: 400;
        }
      }
    }
  }
}
.property-list {
  list-style: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.property-list li {
  // text-transform: capitalize;
  margin-bottom: 0;
}
</style>