
import axios from 'axios'
import { API_URL, AGRICULTURE_API_URL } from '../../variables';
const GET_COOPERATIVES_URL = AGRICULTURE_API_URL + '/cooperative/getCooperative';
const GET_COOPERATIVE_BY_ID_URL = AGRICULTURE_API_URL + '/cooperative/getCooperativeById';
const GET_MY_COOPERATIVE_URL = AGRICULTURE_API_URL + '/cooperative/getMyCooperative';
const ADD_COOPERATIVE_URL = AGRICULTURE_API_URL + '/cooperative/addCooperative';
const EDIT_COOPERATIVE_URL = AGRICULTURE_API_URL + '/cooperative/updateCooperative';
const DELETE_COOPERATIVE_URL = AGRICULTURE_API_URL + '/cooperative/deleteCooperative';

const cooperatives = {
    state: {
    },
    getters: {},
    mutations: {},
    actions: {
        ['GET_COOPERATIVES']: ({ commit }, payload) => {
            return axios({
                url: GET_COOPERATIVES_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                }
            })
        },
        ['GET_COOPERATIVE_BY_ID']: ({ commit }, payload) => {
            return axios({
                url: GET_COOPERATIVE_BY_ID_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                }
            })
        },
        ['GET_MY_COOPERATIVE']: ({ commit }, payload) => {
            return axios({
                url: GET_MY_COOPERATIVE_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                }
            })
        },
        ['ADD_COOPERATIVE']: ({ commit }, payload) => {
            return axios({
                url: ADD_COOPERATIVE_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                }
            })
        },
        ['EDIT_COOPERATIVE']: ({ commit }, payload) => {
            return axios({
                url: EDIT_COOPERATIVE_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                }
            })
        },
        ['DELETE_COOPERATIVE']: ({ commit }, payload) => {
            return axios({
                url: DELETE_COOPERATIVE_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                }
            })
        },
    },
    modules: {},
}
export default cooperatives;