<template>
  <div class="ViewFarmer">
    <super-admin-topbar :pageTitle="user.name" />
    <div class="page-content">
      <div class="view-farmer">
        <div class="top-content">
          <div class="img">
            <img :src="user.image" alt="" />
          </div>
          <div class="identification">
            <div class="name">
              <label for="">Name:</label>
              <p>{{ user.name }}</p>
            </div>
            <div class="phone">
              <label for="">Phone:</label>
              <p>{{ user.phone }}</p>
            </div>
            <div class="email">
              <label for="">Email:</label>
              <p>{{ user.email }}</p>
            </div>
            <!-- <div class="location">
              <label for="">Farms:</label>
              <p>{{ user.farms }}</p>
            </div> -->
          </div>
        </div>
        <!-- <div class="bottom-content">
          <div class="title">
            <h3>Farms</h3>
          </div>
          <div class="farms">
            <div class="farm" v-for="farm in user.farmsData" :key="farm.id">
              <div class="name">
                <label for="">Name:</label>
                <p>{{ farm.name }}</p>
              </div>
              <div class="size">
                <label for="">Size:</label>
                <p>{{ farm.size }} m<sup>2</sup></p>
              </div>
              <div class="district">
                <label for="">Location:</label>
                <p>{{ farm.district }}, {{ farm.sector }}, {{ farm.cell }}</p>
              </div>
              <div class="type">
                <label for="">Type:</label>
                <p>{{ farm.type }}</p>
              </div>
              <div class="productivity">
                <label for="">Productivity:</label>
                <p>{{ farm.productivity }}</p>
              </div>
            </div>
          </div>
        </div> -->
        <div class="posts">
          <div class="title">
            <h3>Posts</h3>
          </div>
          <div class="posts-list">
            <div class="post" v-for="post in user.posts" :key="post.id">
              <div class="header">
                <div class="img">
                  <img :src="post.image" alt="" />
                </div>
                <div class="right">
                  <div class="header-title">
                    <h4>{{ post.title }}</h4>
                  </div>
                  <div class="header-content">
                    <p>{{ post.content }}</p>
                  </div>
                </div>
              </div>
              <div class="content">
                <div class="countNumber">
                  <label for="">Count:</label>
                  <p>{{ post.countNumber }}</p>
                </div>
                <div class="price">
                  <label for="">Price:</label>
                  <p>{{ $comma(post.price) }} Rwf</p>
                </div>
                <div class="date">
                  <label for="">Added:</label>
                  <p>{{ $dateToDay(post.date) }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "ViewFarmer",
  data() {
    return {
      user: {
        id: 2,
        name: "Umulisa Eric",
        farms: 4,
        phone: "0789178645",
        email: "eric@kinazi.co",
        image: "/assets/images/pou1.jpg",
        farmsData: [
          {
            id: 1,
            name: "Farm 1",
            size: 250,
            district: "Kicukiro",
            sector: "Masaka",
            cell: "Masaka",
            village: "Masaka",
            type: "Poultry-eggs",
            productivity: "2000 eggs/month",
          },
          {
            id: 2,
            name: "Farm 2",
            size: 150,
            district: "Kicukiro",
            sector: "Masaka",
            cell: "Masaka",
            village: "Masaka",
            type: "Poultry-meat",
            productivity: "200 chickens/month",
          },
          {
            id: 3,
            name: "Farm 3",
            size: 320,
            district: "Nyarugenge",
            sector: "Masaka",
            cell: "Masaka",
            village: "Masaka",
            type: "Pork-meat",
            productivity: "100 pigs/month",
          },
          {
            id: 4,
            name: "Farm 4",
            size: 750,
            district: "Gasabo",
            sector: "Masaka",
            cell: "Masaka",
            village: "Masaka",
            type: "Cattle-meat",
            productivity: "50 cows/month",
          },
        ],
        posts: [
          {
            id: 1,
            title: "Amagi",
            content: "Ndagurisha Amagi meza yamanyarwanda",
            image: "/assets/images/pou1.jpg",
            date: "2021-05-12",
            countNumber: 1000,
            price: 2500,
          },
          {
            id: 2,
            title: "Ingurube",
            content: "Ndagurisha Ingurube nziza z'imyaka 13",
            image: "/assets/images/pig1.jpg",
            date: "2021-05-12",
            countNumber: 10,
            price: 355000,
          },
          {
            id: 3,
            title: "Ingurube",
            content: "Ndagurisha Ingurube nziza z'imyaka 15",
            image: "/assets/images/pig2.jpg",
            date: "2021-10-12",
            countNumber: 5,
            price: 500000,
          },
          {
            id: 4,
            title: "Amagi",
            content: "Ndagurisha Amagi meza yamanyarwanda",
            image: "/assets/images/pou1.jpg",
            date: "2021-05-12",
            countNumber: 1000,
            price: 2500,
          },
        ],
      },
    };
  },
  methods: {},
  mounted() {},
};
</script>
  
  <style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.ViewFarmer {
  .page-content {
    padding: 2rem 1rem;
    .view-farmer {
      border: 1px #b6b6b6 solid;
      border-radius: 10px;
      overflow: hidden;
    }
    .top-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #eee;
      padding: 0.75rem;
      .img {
        width: 120px;
        img {
          width: 120px;
          height: 120px;
          object-fit: cover;
          border-radius: 50%;
        }
      }
      .identification {
        width: calc(100% - 250px);
        display: flex;
        justify-content: space-between;
        align-items: center;
        .name,
        .phone,
        .email,
        .location {
          width: 25%;
          p {
            font-size: 1.2rem;
            font-weight: 600;
          }
        }
      }
    }
    .bottom-content {
      margin-top: 2rem;
      padding: 1rem 2rem;
      .title {
        margin-bottom: 1rem;
        h3 {
          font-size: 1.5rem;
          font-weight: 600;
        }
      }
      .farms {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 2rem;
        .farm {
          padding: 1rem;
          border: 1px solid #ccc;
          border-radius: 5px;
          .name,
          .size,
          .district,
          .type,
          .productivity {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: .5rem;
            p {
              font-size: 1rem;
              font-weight: 600;
            }
            label{
              font-size: .9rem;
            }
          }
        }
      }
    }
    .posts {
      margin-top: 2rem;
      padding: 1rem 2rem;
      .title {
        margin-bottom: 1rem;
        h3 {
          font-size: 1.5rem;
          font-weight: 600;
        }
      }
      .posts-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 1rem;
        row-gap: 1rem;

        .post {
          border-radius: 10px;
          overflow: hidden;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #eee;

            .img {
              width: 100px;
              img {
                width: 100px;
                height: 100px;
                object-fit: cover;
                border-radius: 5px 0 0 5px;
                overflow: hidden;
              }
            }
            .right {
              width: calc(100% - 120px);
              padding-left: 10px;
              .header-title {
                margin-bottom: 1rem;
                h4 {
                  font-size: 1.25rem;
                  font-weight: 600;
                }
              }
              .header-content {
                p {
                  font-size: 0.9rem;
                  font-weight: 400;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 3;
                  -webkit-box-orient: vertical;
                }
              }
            }
          }
          .content {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 10px;
            .date,
            .countNumber,
            .price {
              display: flex;
              justify-content: space-between;
              flex-direction: row;
              align-items: center;
              margin-bottom: .5rem;
              width: 100%;
              p {
                font-size: .9rem;
                font-weight: 600;
              }
              label{
                font-size: .9rem;
              }
            }
          }
        }
      }
    }
  }
}
</style>