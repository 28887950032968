<template>
  <div class="Sidebar">
    <div class="sidebar-header">
      <label for="Infomaster">{{
        $isSuperAdmin() ? "Management Dashboard" : "Management Dashboard"
      }}</label>
    </div>
    <div class="sidebar-links">
      <div
        class="sidebar-link"
        @click="redirectTo('Dashboard')"
        v-if="$isSuperAdmin()"
      >
        <label for="Module name">Dashboard</label>
      </div>
      <div
        class="sidebar-link multilink"
        id="IEDU"
        :class="$urlContains('admin/education') ? 'active' : 'inactive'"
      >
        <label for="Module name" @click="toggleSubLinks('IEDU')"
          >Education</label
        >
        <div
          class="sub-links"
          :class="
            this.$store.state.activeModule == 'IEDU' ? 'active' : 'inactive'
          "
        >
          <router-link :to="{ name: 'SuperAdminSchools' }">
            <label for="">Schools</label>
          </router-link>
          <router-link :to="{ name: 'SuperAdminLevels' }">
            <label for="">Levels</label>
          </router-link>
          <router-link :to="{ name: 'SuperAdminDomains' }">
            <label for="">Education domains</label>
          </router-link>
          <router-link :to="{ name: 'SuperAdminDegrees' }">
            <label for="">Degrees</label>
          </router-link>
          <router-link :to="{ name: 'SuperAdminAcademicYears' }">
            <label for="">Academic Years</label>
          </router-link>
          <router-link :to="{ name: 'SuperAdminReports' }">
            <label for="">Reports</label>
          </router-link>
        </div>
      </div>
      <div
        class="sidebar-link multilink"
        id="IRAB"
        :class="$urlContains('admin/agriculture') ? 'active' : ''"
      >
        <label for="Module name" @click="toggleSubLinks('IRAB')"
          >Agriculture</label
        >
        <div
          class="sub-links"
          :class="this.$store.state.activeModule == 'IRAB' ? 'active' : ''"
        >
          <router-link :to="{ name: 'adminAgricultureSectors' }">
            <label for="">Sectors</label>
          </router-link>
          <router-link :to="{ name: 'adminAgricultureCategories' }">
            <label for="">Categories</label>
          </router-link>
          <router-link :to="{ name: 'adminAgricultureUnits' }">
            <label for="">Units</label>
          </router-link>
          <router-link :to="{ name: 'adminAgricultureProducts' }">
            <label for="">Products</label>
          </router-link>
          <router-link :to="{ name: 'adminAgricultureOrders' }">
            <label for="">Orders</label>
          </router-link>
          <!-- <router-link :to="{ name: 'adminAgricultureCooperatives' }">
            <label for="">Cooperatives</label>
          </router-link>
          <router-link :to="{ name: 'AdminFarmers' }">
            <label for="">Farmers</label>
          </router-link>
          <router-link :to="{ name: 'AgricultureMarket' }">
            <label for="">Market</label>
          </router-link> -->
        </div>
      </div>
      <div
        class="sidebar-link multilink"
        id="IBUS"
        :class="$urlContains('admin/transport') ? 'active' : ''"
      >
        <label for="Module name" @click="toggleSubLinks('IBUS')"
          >Transport</label
        >
        <div
          class="sub-links"
          :class="this.$store.state.activeModule == 'IBUS' ? 'active' : ''"
        >
          <router-link :to="{ name: 'adminAgricultureSectors' }">
            <label for="">Car Bodies</label>
          </router-link>
          <router-link :to="{ name: 'adminAgricultureCategories' }">
            <label for="">Car Brands</label>
          </router-link>
          <router-link :to="{ name: 'adminAgricultureUnits' }">
            <label for="">Vehicles</label>
          </router-link>
          <!-- <router-link :to="{ name: 'adminAgricultureCooperatives' }">
            <label for="">Cooperatives</label>
          </router-link>
          <router-link :to="{ name: 'AdminFarmers' }">
            <label for="">Farmers</label>
          </router-link>
          <router-link :to="{ name: 'AgricultureMarket' }">
            <label for="">Market</label>
          </router-link> -->
        </div>
      </div>
      <div
        class="sidebar-link multilink"
        id="IRAB"
        :class="$urlContains('admin/business') ? 'active' : ''"
      >
        <label for="Module name" @click="toggleSubLinks('IBUSS')"
          >Business Directory</label
        >
        <div
          class="sub-links"
          :class="this.$store.state.activeModule == 'IBUSS' ? 'active' : ''"
        >
          <router-link :to="{ name: 'adminAllBusiness' }">
            <label for="">All Business</label>
          </router-link>
          <router-link :to="{ name: 'AdminBusinessSectors' }">
            <label for="">Business sectors</label>
          </router-link>
        </div>
      </div>
      <div
        class="sidebar-link multilink"
        id="IRAB"
        :class="$urlContains('admin/workers') ? 'active' : ''"
      >
        <label for="Module name" @click="toggleSubLinks('IWORK')"
          >Workers</label
        >
        <div
          class="sub-links"
          :class="this.$store.state.activeModule == 'IWORK' ? 'active' : ''"
        >
          <router-link :to="{ name: 'adminWorkers' }">
            <label for="">All Workers</label>
          </router-link>
          <router-link :to="{ name: 'adminWorkerSkills' }">
            <label for="">Skills</label>
          </router-link>
        </div>
      </div>
      <div
        class="sidebar-link"
        @click="redirectTo('SuperAdminModules')"
        :class="$urlContains('admin/modules') ? 'active' : ''"
      >
        <label for="Module name">Modules</label>
      </div>
      <div class="sidebar-link multilink">
        <label for="Module name" @click="toggleSubLinks('Deals')"
          >Special Deals</label
        >
        <div
          class="sub-links"
          :class="this.$store.state.activeModule == 'Deals' ? 'active' : ''"
        >
          <router-link :to="{ name: `SuperAdminDeals` }">
            <label for="">All Deals</label>
          </router-link>
          <router-link :to="{ name: `SuperAdminDealCategories` }">
            <label for="">Product Categories</label>
          </router-link>
        </div>
      </div>
      <div class="sidebar-link" @click="redirectTo('Users')">
        <label for="Module name">Users</label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Sidebar",
  data() {
    return {
      activeModule: "",
      userRole: "",
    };
  },
  methods: {
    toggleSubLinks(id) {
      if (this.$store.state.activeModule == id) {
        this.$store.state.activeModule = "";
      } else {
        this.$store.state.activeModule = id;
      }
    },
    redirectTo(id) {
      if (id == "Dashboard") {
        this.$router.push({ name: "SuperAdminDashboard" });
      }
      if (id == "Users") {
        this.$router.push({ name: "SuperAdminUsers" });
      }
      if (id == "SuperAdminModules") {
        this.$router.push({ name: "SuperAdminModules" });
      }
      if (id == "Deals") {
        this.$router.push({ name: "SuperAdminDeals" });
      }
    },
    authorizeBusiness() {
      this.$store
        .dispatch("AUTHORIZE_BUSINESS", {
          token: this.$getToken(),
          businessId: this.$route.params.id,
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.$businessLogin(res.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    if (this.$getUser() && this.$getUser() != null) {
      if (this.$isSuperAdmin() || this.$isAdmin()) {
        this.userRole = this.$getUser();
      } else {
        this.$notify({
          title: "Error",
          type: "error",
          text: "You are not authorized to access this page",
        });
        this.$router.push({ name: "Login" });
      }
      console.log("mounted");
      if (this.$urlContains("admin/education")) {
        this.$store.state.activeModule = "IEDU";
      }
      if (this.$urlContains("admin/agriculture")) {
        this.$store.state.activeModule = "IRAB";
      }
    } else {
      this.$notify({
        title: "Error",
        type: "error",
        text: "You are not authorized to access this page",
      });
      this.$router.push({ name: "Login" });
    }

    // this.authorizeBusiness();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.Sidebar {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    270deg,
    rgba(0, 0, 0, 0.7287289915966386) 0%,
    rgba(31, 65, 144, 1) 0%,
    rgba(14, 3, 126, 1) 100%
  );
  border-right: 1px solid #fff;
  box-shadow: 0 0 10px #0000003d;
  border-radius: 0px 20px 20px 0px;
  .sidebar-header {
    padding: 17px 0;
    label {
      font-size: 0.7rem;
      font-weight: 600;
      color: #fff;
      text-align: center;
      display: block;
    }
  }
  .sidebar-links {
    .sidebar-link {
      display: block;
      border-bottom: 1px #eeeeee1d dashed;
      transition: all 0.3s ease-in-out;
      &.multilink {
        &.active {
          cursor: pointer;
        }
      }
      &:hover,
      &.active {
        label {
          background: #ffffff28;
          cursor: pointer;
          border-radius: 5px;
        }
      }
      label {
        margin: 5px 10px;
        padding: 10px;
        font-size: 0.8rem;
        font-weight: 600;
        color: #fff;
        cursor: pointer;
        display: block;
        transition: all 0.5s ease-in-out;
        @media screen and (max-width: 1500px) {
          font-size: 9px;
          padding: 7px 10px;
        }
      }
      .sub-links {
        background: rgba(3, 36, 114, 0.197);
        display: flex;
        flex-direction: column;
        height: 0;
        overflow: hidden;
        padding: 0;
        &.active {
          height: fit-content;
        }
        .router-link-exact-active {
          label {
            background: #ffffff1f;
          }
        }
        a {
          display: block;
          padding-left: 10px;
          &:hover {
            label {
              background: #ffffff1f;
              color: #fff;
            }
          }
          label {
            color: #ffffffce;
            font-size: 0.75rem;
            font-weight: 400;
            padding: 10px;
            background: transparent;
            transition: all 0.5s ease-in-out;
            @media screen and (max-width: 1500px) {
              font-size: 9px;
              padding: 7px 10px;
            }
          }
        }
      }
    }
  }
}
</style>