<template>
  <div class="Login">
    <site-navbar />
    <div class="page-content">
      <!-- username/email, password -->
      <form action="" @submit.prevent="userLogin()">
        <div class="form-header">
          <label for="" class="main-head"
            >{{ $txt("Log into") }} <span>Infomaster</span></label
          >
        </div>
        <div class="form-body">
          <div class="form-group">
            <input
              type="text"
              name="username"
              :placeholder="$txt('Username/email')"
              v-model="user.username"
            />
          </div>
        <div class="form-group">
          <input
          :type="showPassword ? 'text' : 'password'"
            name="password"
            :placeholder="$txt('Password')"
            v-model="user.password"
          />
          <span class="view-password" @click="showPassword = !showPassword">
            <i v-if="showPassword" class="fas fa-eye-slash"></i>
           <i v-else class="fas fa-eye"></i>
          </span>
        </div>

          <div class="form-group">
            <button type="submit" class="submit">{{ $txt("Login") }}</button>
          </div>
        </div>
        <div class="form-header">
          <label for="" class="dont"
            >{{ $txt("Don't have an account?") }}
            <router-link :to="{ name: 'Register' }">{{
              $txt("Register")
            }}</router-link>
          </label>
          <!-- forgot password -->
          <label for="" class="forgot"
            >{{ $txt("Forgot password?") }}
            <router-link :to="{ name: 'ForgotPassword' }">{{
              $txt("Reset it")
            }}</router-link></label
          >
        </div>
        <div class="form-footer">
          <h4>{{ $txt("Contact info@infomaster.rw for support") }}</h4>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      user: {
        username: "",
        password: "",
      },
      showPassword: false, 

    };
  },
  methods: {
    userLogin() {
      if (!this.user.username) {
        this.$notify({
          title: "Error",
          type: "error",
          text: "Username is required",
        });
        return;
      }
      if (!this.user.password) {
        this.$notify({
          title: "Error",
          type: "error",
          text: "Password is required",
        });
        return;
      }
            this.$startLoader();
      this.$store
        .dispatch("LOGIN_USER", this.user)
        .then((res) => {
                    if (res.data.status) {
            this.$notify({
              title: "Success",
              type: "success",
              text: res.data.data.message,
            });
            this.$userLogin(res.data.data.user, res.data.data.token);
                        this.$stopLoader();
          } else {
            this.$notify({
              title: "Error",
              type: "error",
              text: res.data.error,
            });
            this.$stopLoader();
          }
        })
        .catch((err) => {
          this.$notify({
            title: "Error",
            type: "error",
            text: err.response.data.error,
          });
          this.$stopLoader();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.view-password {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

.view-password i {
  font-size: 1.2em;
  color: #ccc;
}
.page-content {
  width: 100%;
  display: flex;
  justify-content: center;
  form {
    width: 100%;
    max-width: 600px;
    height: 500px;
    padding: 4rem 5rem;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    position: relative;
    flex-wrap: wrap;
    margin-top: 2rem;

    .form-header {
      margin-bottom: 20px;
      .main-head {
        display: block;
        text-align: center;
        font-size: 20px;
        margin-bottom: 10px;
        span {
          color: $blue;
          font-weight: 600;
        }
      }
      .dont,
      .forgot {
        display: block;
        text-align: center;
        span,
        a {
          color: $blue;
          font-weight: 600;
        }
      }
    }
    .form-body {
      .form-group {
        margin-bottom: 20px;
        position: relative;
        label {
          display: block;
          margin-bottom: 5px;
          position: absolute;
          top: 5px;
          left: 10px;
          font-size: 13px;
        }
        input {
          width: 100%;
          padding: 10px;
          border: 1px solid #ddd;
          border-radius: 5px;
          outline: none;
        }
        .submit {
          width: 100%;
          padding: 10px;
          border: 1px solid #ddd;
          border-radius: 5px;
          outline: none;
          background: $blue;
          color: #fff;
          font-weight: 600;
          cursor: pointer;
          &:hover {
            background: darken($blue, 10%);
          }
        }
      }
    }
    .form-footer {
      position: absolute;
      bottom: 20px;
      width: calc(100% - 10rem);
      h4 {
        text-align: center;
      }
    }
  }
}
</style>