<template>
  <site-navbar />
  <div class="mailContainer mx-auto mt-10 text-center" v-if="userVerified">
    <h2 class="text-2xl font-semibold mb-4">Email Verification</h2>
    <p class="mb-4">
      Thank you for verifying your email address, click below to login.
    </p>
    <button
      class="mailButton text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
      @click="userRedirect('Login')"
    >
      Login
    </button>
  </div>
  <div
    class="mailContainer mx-auto mt-10 text-center"
    v-if="userVerified == false"
  >
    <h2 class="text-2xl font-semibold mb-4">Email Verification</h2>
    <p class="mb-4">
      Verification failed or expired, Contact support at support@infomaster.rw
    </p>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      verificationEmail: "hortanceirakoze@gmail.com",
      userVerified: null,
    };
  },
  methods: {
    openEmailApp() {
      const mailtoLink = `mailto:${this.verificationEmail}`;
      window.location.href = mailtoLink;
    },
    userRedirect(route) {
      this.$router.push({ name: route });
    },
    verifyEmail() {
      this.$startLoader();
      const token = this.$route.params.token;
      this.$store.dispatch("VERIFY_EMAIL", { token: token }).then((res) => {
        if (res.data.status) {
          this.userVerified = true;
          this.$router.push({ name: "Login" });
          this.$notify({
            title: "Success",
            type: "success",
            text: "Your aaccount is verified. You can login now.",
          });
          this.$stopLoader();
        } else {
          this.userVerified = false;
          this.$stopLoader();
        }
      });
    },
  },
  mounted() {
    this.verifyEmail();
  },
};
</script>
  
  <style scoped lang="scss">
@import "@/assets/scss/main.scss";

.mailContainer {
  width: 850px;
  height: 200px;
  background: white;
  border: 1px solid grey;
  border-radius: 5px;
  margin-top: 180px;
}
.mailButton {
  width: 40%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  outline: none;
  background: $blue;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    background: darken($blue, 10%);
  }
}
</style>
  