<template>
    <form class="container mx-auto p-6" @submit="editWorkerStatus">
      <h1 class="text-2xl font-bold mb-4 text-center">Edit Worker Profile</h1>
  
      <div class="mb-4">
        <label for="status" class="block text-gray-700 text-sm font-bold mb-2">Status</label>
        <div class="flex items-center">
          <input v-model="worker.status" type="radio" id="available" value="available" class="mr-2">
          <label for="available">Available</label>
  
          <input v-model="worker.status" type="radio" id="notAvailable" value="notAvailable" class="ml-4 mr-2">
          <label for="notAvailable">Not Available</label>
        </div>
      </div>
  
      <div class="mb-4">
        <label for="careerTitle" class="block text-gray-700 text-sm font-bold mb-2">Career Title</label>
        <input v-model="worker.careerTitle" type="text" class="border rounded w-full py-2 px-3 text-gray-700 leading-tight" required>
      </div>
  
      <div class="flex justify-center">
        <button type="submit" class="text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
          Save Changes
        </button>
      </div>
    </form>
  </template>
  
  <script>
  export default {
    data() {
      return {
        worker:{
        status: 'available',
        careerTitle: '',
        },
        workerStatus:null
      };
    },
    methods:{
      editWorkerStatus(){
        this.$startLoader();
      this.$store
        .dispatch("EDIT_WORKER_STATUS", { 
          token: this.$getToken(),
          data: this.worker
        })
        .then((res) => {
          this.$stopLoader();
          this.workerStatus = res.data.data.data;
          console.log(this.workerStatus);
          this.$emit('data-sent');
          this.$notify({
            title: "Success",
            type: "success",
            text: "Worker status edited successfully",
          });
        })
        .catch((err) => {
          this.$stopLoader();
          this.$notify({
          title: "Error",
          type: "error",
          text: "Unable to edit worker status",
        });
        });
      }
    },

  };
  </script>
  
  <style scoped lang="scss">
  @import "@/assets/scss/main.scss";

  button{
    background-color: $blue;
  }
  </style>
  