<template>
  <div class="container mx-auto p-4">
    <div class="form-group group-3">
      <div class="input-group">
        <label class="block mb-2">Company Name</label>
        <input
          class="w-full p-2 border rounded"
          type="text"
          v-model="item.business_name"
        />
      </div>
      <div class="input-group">
        <label class="block mb-2">Ownership</label>
        <select
          class="w-full p-2 border rounded"
          v-model="item.business_ownership_id"
        >
          <option value="">Select</option>
          <option
            v-for="ownership in ownerships"
            :key="ownership.ownership_id"
            :value="ownership.ownership_id"
          >
            {{ ownership.ownership_name }}
          </option>
        </select>
      </div>
      <div class="input-group">
        <label class="block mb-2">Business Sector</label>
        <select
          class="w-full p-2 border rounded"
          v-model="item.business_sector_id"
        >
          <option value="">Select sector</option>
          <option
            :value="businessSector.sector_id"
            v-for="businessSector in businessSectors"
            :key="businessSector.sector_id"
          >
            {{ businessSector.sector_name }}
          </option>
        </select>
      </div>
    </div>
    <div class="form-group group-3">
      <div class="input-group">
        <label class="block mb-2">Email</label>
        <input
          class="w-full p-2 border rounded"
          type="email"
          v-model="item.business_email"
        />
      </div>
      <div class="input-group">
        <label class="block mb-2">Telephone</label>
        <input
          class="w-full p-2 border rounded"
          type="phone"
          v-model="item.business_phone"
        />
      </div>
      <div class="input-group">
        <label class="block mb-2">Website</label>
        <input
          class="w-full p-2 border rounded"
          type="url"
          v-model="item.business_website"
        />
      </div>
    </div>

    <div class="form-group group-3">
      <div class="input-group">
        <label class="block mb-2">Representative</label>
        <input
          class="w-full p-2 border rounded"
          type="text"
          v-model="item.business_representative"
        />
      </div>
      <div class="input-group">
        <label class="block mb-2">Representative Phone</label>
        <input
          class="w-full p-2 border rounded"
          type="phone"
          v-model="item.business_representative_phone"
        />
      </div>
      <div class="input-group">
        <label class="block mb-2">Representative Email</label>
        <input
          class="w-full p-2 border rounded"
          type="email"
          v-model="item.business_representative_email"
        />
      </div>
    </div>
    <div class="form-group group-4">
      <div class="input-group">
        <label class="block mb-2">{{ $txt("Province") }}</label>
        <select
          name="province"
          id=""
          v-model="item.business_province"
          @change="getDistricts(item.business_province)"
          class="w-full md:w-1/4 p-2"
        >
          <option value="">{{ $txt("Province") }}</option>
          <option
            v-for="province in provinces"
            :key="province.provinceId"
            :value="province.provinceId"
          >
            {{ province.provinceName }}
          </option>
        </select>
      </div>
      <div class="input-group">
        <label class="block mb-2">{{ $txt("District") }}</label>
        <select
          name="district"
          id=""
          v-model="item.business_district"
          @change="getSectors(item.business_district)"
          class="w-full md:w-1/4 p-2"
        >
          <option value="">{{ $txt("District") }}</option>
          <option
            v-for="district in districts"
            :key="district.districtId"
            :value="district.districtId"
          >
            {{ district.districtName }}
          </option>
        </select>
      </div>
      <div class="input-group">
        <label class="block mb-2">{{ $txt("Sector") }}</label>
        <select
          name="sector"
          id=""
          v-model="item.business_sector"
          @change="getCells(item.business_sector)"
          class="w-full md:w-1/4 p-2"
        >
          <option value="">{{ $txt("Sector") }}</option>
          <option
            v-for="sector in sectors"
            :key="sector.sectorId"
            :value="sector.sectorId"
          >
            {{ sector.sectorName }}
          </option>
        </select>
      </div>
      <div class="input-group">
        <label class="block mb-2">{{ $txt("Cell") }}</label>
        <select
          name="cell"
          id=""
          v-model="item.business_cell"
          class="w-full md:w-1/4 p-2"
        >
          <option value="">{{ $txt("Cell") }}</option>
          <option v-for="cell in cells" :key="cell.cellId" :value="cell.cellId">
            {{ cell.cellName }}
          </option>
        </select>
      </div>
    </div>
    <div class="form-group group-3">
      <div class="input-group">
        <label>RDB Certificate</label>
        <o-upload
          :multiple="false"
          :uploadUrl="'files/upload'"
          @imagesUploaded="
            (data) => {
              item.business_rdb_certificate = data;
            }
          "
          @imagesUploadedFailed="
            (data) => {
              console.log('failed');
            }
          "
          :value="item.business_rdb_certificate"
          @startLoader="() => $startLoader()"
          @stopLoader="() => $stopLoader()"
        />
      </div>
      <div class="input-group">
        <label>Logo</label>
        <o-upload
          :multiple="false"
          :uploadUrl="'files/upload'"
          @imagesUploaded="
            (data) => {
              item.business_logo = data;
            }
          "
          @imagesUploadedFailed="
            (data) => {
              console.log('failed');
            }
          "
          :value="item.business_logo"
          @startLoader="() => $startLoader()"
          @stopLoader="() => $stopLoader()"
        />
      </div>
      <div class="input-group">
        <label>Image</label>
        <o-upload
          :multiple="false"
          :uploadUrl="'files/upload'"
          @imagesUploaded="
            (data) => {
              item.business_image = data;
            }
          "
          @imagesUploadedFailed="
            (data) => {
              console.log('failed');
            }
          "
          :value="item.business_image"
          @startLoader="() => $startLoader()"
          @stopLoader="() => $stopLoader()"
        />
      </div>
    </div>
    <div>
      <div class="form-group">
        <div class="input-group">
          <label>Description</label>
          <quill-editor
            theme="snow"
            v-model:content="item.business_description"
            contentType="html"
            style="height: 200px"
          ></quill-editor>
        </div>
      </div>
    </div>
  </div>
  <button @click.prevent="saveItem()">Save Business</button>
</template>
  
  <script>
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
export default {
  props: {
    item: {
      type: Object,
      default: {
        id: 1,
      },
    },
  },
  data() {
    return {
      ownerships: [],
      businessSectors: [],
      provinces: [],
      districts: [],
      sectors: [],
      cells: [],
    };
  },
  methods: {
    closeModel() {
      document.body.style.overflowY = "auto";
      this.$emit("closePopup");
    },
    getOwnerships() {
      this.$startLoader();
      this.$store
        .dispatch("GET_BUSS_OWNERSHIPS", { token: this.$getBussToken() })
        .then((response) => {
          this.ownerships = response.data.data;
        });
    },
    getBusinessSectors() {
      this.$startLoader();
      this.$store
        .dispatch("GET_BUSS_SECTORS", { token: this.$getBussToken() })
        .then((response) => {
          this.businessSectors = response.data.data;
        });
    },
    getProvinces() {
      this.$startLoader();
      this.$store
        .dispatch("GET_PROVINCES", { token: this.$getToken() })
        .then((res) => {
          this.$stopLoader();
          this.provinces = res.data.data.data;
        })
        .catch((err) => {});
    },
    getDistricts(provinceId) {
      this.$startLoader();
      this.$store
        .dispatch("GET_DISTRICTS", {
          token: this.$getToken(),
          provinceId: provinceId,
        })
        .then((res) => {
          this.$stopLoader();
          this.districts = res.data.data.data;
        })
        .catch((err) => {});
    },
    getSectors(districtId) {
      this.$startLoader();
      this.$store
        .dispatch("GET_SECTORS", {
          token: this.$getToken(),
          districtId: districtId,
        })
        .then((res) => {
          this.$stopLoader();
          this.sectors = res.data.data.data;
        })
        .catch((err) => {});
    },
    getCells(sectorId) {
      this.$startLoader();
      this.$store
        .dispatch("GET_CELLS", {
          token: this.$getToken(),
          sectorId: sectorId,
        })
        .then((res) => {
          this.$stopLoader();
          this.cells = res.data.data.data;
        })
        .catch((err) => {});
    },
    saveItem() {
      // check if all fields are filled
      if (
        this.item.business_name == "" ||
        this.item.business_province == "" ||
        this.item.business_district == "" ||
        this.item.business_sector == "" ||
        this.item.business_cell == "" ||
        this.item.business_email == "" ||
        this.item.business_phone == "" ||
        this.item.business_website == "" ||
        this.item.business_representative == "" ||
        this.item.business_representative_phone == "" ||
        this.item.business_representative_email == "" ||
        this.item.business_rdb_certificate == "" ||
        this.item.business_logo == "" ||
        this.item.business_image == "" ||
        this.item.business_description == "" ||
        this.item.business_ownership_id == "" ||
        this.item.business_sector_id == ""
      ) {
        this.$notify({
          title: "Error",
          text: "Please fill all fields",
          type: "error",
        });
        return;
      } else {
        this.$startLoader();
        this.$store
          .dispatch("UPDATE_BUSINESS", {
            token: this.$getToken(),
            data: this.item,
          })
          .then((res) => {
            this.$stopLoader();
            this.$notify({
              title: "Success",
              text: "Business aupdated successfully.",
              type: "success",
            });
            this.closeModel();
          })
          .catch((err) => {
            this.$stopLoader();
            this.$notify({
              title: "Error",
              text: err.response.data.message,
              type: "error",
            });
          });
      }
    },
  },
  mounted() {
    this.getOwnerships();
    this.getBusinessSectors();
    this.getProvinces();
    if (this.item.business_province) {
      this.getDistricts(this.item.business_province);
    }
    this.getSectors(this.item.business_district);
    this.getCells(this.item.business_sector);
  },
};
</script>
  
  <style lang="scss" scoped>
button {
  border: none;
  background-color: #1d3557;
  margin-left: 1rem;
  border-radius: 5px;
  padding: 15px 10px;
  color: #fff;
  display: block;
  width: 300px;
}
</style>
  