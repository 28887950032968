<template>
  <div class="Profile">
    <div class="profile-widgets" v-if="user">
      <div class="widget-item profile">
        <div class="widget-header">
          <h4 class="header">{{ $txt(`My Profile`) }}</h4>
        </div>
        <div class="widget-body">
          <div class="widget-left">
            <div class="widget-img">
              <label for="">Profile photo</label>
              <o-upload
                :multiple="false"
                :uploadUrl="'files/upload'"
                @imagesUploaded="
                  (data) => {
                    user.profile.photo = data;
                  }
                "
                @imagesUploadedFailed="
                  (data) => {
                    console.log('failed');
                  }
                "
                :value="user.profile.photo"
                @startLoader="() => $startLoader()"
                @stopLoader="() => $stopLoader()"
              />
            </div>
          </div>
          <div class="widget-right">
            <h3 class="widget-item-text name">
              <input
                type="text"
                class="b-bottom"
                v-model="user.full_names"
                placeholder="Fullname"
              />
            </h3>
            <p class="widget-text-text">
              <input
                type="text"
                class="b-bottom"
                v-model="user.email_address"
                placeholder="Email address"
                disabled
              />
            </p>
            <p class="widget-text-text">
              <input
                type="text"
                class="b-bottom"
                v-model="user.telephone"
                placeholder="Telephone"
              />
            </p>
          </div>
        </div>
      </div>
      <div class="widget-item profile">
        <div class="widget-header">
          <h4 class="header">{{ $txt(`General Infomation`) }}</h4>
        </div>
        <div class="widget-body">
          <div class="widget-right">
            <div class="grid-3">
              <div>
                <p class="widget-item-text name">Nationality</p>
                <h4 class="widget-text-text">
                  <select name="" id="" v-model="user.national_id">
                    <option value="">Select nationality</option>
                    <option
                      :value="country.countryId"
                      v-for="country in $store.state.countries"
                      :key="country.countryId"
                    >
                      {{ country.countryName }}
                    </option>
                  </select>
                </h4>
              </div>
              <div>
                <p class="widget-item-text name">ID/Passport</p>
                <h4 class="widget-text-text">
                  <input
                    type="text"
                    class="form-control"
                    v-model="user.national_id"
                  />
                </h4>
              </div>
              <div>
                <p class="widget-item-text name">Gender</p>
                <h4 class="widget-text-text">
                  <select name="" id="" v-model="user.gender">
                    <option value="">Select</option>
                    <option>Male</option>
                    <option>Female</option>
                  </select>
                </h4>
              </div>
            </div>
            <p class="widget-item-text name mt-10">Current Location</p>
            <div class="grid-4">
              <h4 class="widget-text-text">
                <select
                  name=""
                  id=""
                  v-model="user.profile.currentLocation.province"
                  @change="getDistricts(user.profile.currentLocation.province)"
                >
                  <option
                    v-for="province in provinces"
                    :key="province.provinceId"
                    :value="province.provinceId"
                  >
                    {{ province.provinceName }}
                  </option>
                </select>
              </h4>
              <h4 class="widget-text-text">
                <select
                  name=""
                  id=""
                  v-model="user.profile.currentLocation.district"
                  @change="getSectors(user.profile.currentLocation.district)"
                >
                  <option
                    v-for="district in districts"
                    :key="district.districtId"
                    :value="district.districtId"
                  >
                    {{ district.districtName }}
                  </option>
                </select>
              </h4>
              <h4 class="widget-text-text">
                <select
                  name=""
                  id=""
                  v-model="user.profile.currentLocation.sector"
                  @change="getCells(user.profile.currentLocation.sector)"
                >
                  <option
                    v-for="sector in sectors"
                    :key="sector.sectorId"
                    :value="sector.sectorId"
                  >
                    {{ sector.sectorName }}
                  </option>
                </select>
              </h4>
              <h4 class="widget-text-text">
                <select
                  name=""
                  id=""
                  v-model="user.profile.currentLocation.cell"
                >
                  <option
                    v-for="cell in cells"
                    :key="cell.cellId"
                    :value="cell.cellId"
                  >
                    {{ cell.cellName }}
                  </option>
                </select>
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div class="widget-item profile no-b">
        <div class="widget-header">
          <h4 class="header">{{ $txt(`Other Infomation`) }}</h4>
        </div>
        <div class="widget-body">
          <div class="widget-right" style="width: 100%">
            <p class="widget-item-text name">Biography</p>
            <div>
              <quill-editor
                theme="snow"
                style="width: 100%; min-height: 200px"
              ></quill-editor>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EditProfile from "./EditProfile.vue";
import EditWorkerProfile from "./EditWorkerProfile.vue";

export default {
  name: "Profile",
  data() {
    return {
      popupOpen: false,
      modalOpen: false,
      user: null,
      profile: null,
      provinces: [],
      districts: [],
      sectors: [],
      cells: [],
    };
  },
  components: { EditProfile, EditWorkerProfile },
  methods: {
    openPopup() {
      this.popupOpen = true;
    },

    closePopup() {
      this.popupOpen = false;
      this.$refs.popupOpen.hide();
    },

    openModal() {
      this.modalOpen = true;
    },
    closeModal() {
      this.modalOpen = false;
      this.$refs.modalOpen.hide();
    },
    getProvinces() {
      this.$startLoader();
      this.$store
        .dispatch("GET_PROVINCES", { token: this.$getToken() })
        .then((res) => {
          this.$stopLoader();
          this.provinces = res.data.data.data;
        });
    },
    getDistricts(provinceId) {
      this.$startLoader();
      this.$store
        .dispatch("GET_DISTRICTS", {
          token: this.$getToken(),
          provinceId: provinceId,
        })
        .then((res) => {
          this.$stopLoader();
          this.districts = res.data.data.data;
        });
    },
    getSectors(districtId) {
      this.$startLoader();
      this.$store
        .dispatch("GET_SECTORS", {
          token: this.$getToken(),
          districtId: districtId,
        })
        .then((res) => {
          this.$stopLoader();
          this.sectors = res.data.data.data;
        });
    },
    getCells(sectorId) {
      this.$startLoader();
      this.$store
        .dispatch("GET_CELLS", {
          token: this.$getToken(),
          sectorId: sectorId,
        })
        .then((res) => {
          this.$stopLoader();
          this.cells = res.data.data.data;
        });
    },

    getUserProfile() {
      this.$startLoader();
      this.$store
        .dispatch("GET_USER_PROFILE", { token: this.$getToken() })
        .then((res) => {
          this.user = res.data.data.user;
          if (this.user.profile.currentLocation == null) {
            this.user.profile.currentLocation = {
              province: "",
              district: "",
              sector: "",
              cell: "",
            };
          }
          if (this.user.profile.currentLocation.province != "") {
            this.getDistricts(this.user.profile.currentLocation.province);
            this.getSectors(this.user.profile.currentLocation.district);
            this.getCells(this.user.profile.currentLocation.sector);
          }
          this.$stopLoader();
          this.getProvinces();
        })
        .catch((err) => {
          this.$stopLoader();
          if (err.response.status == 401) {
            this.$userLogout();
          } else {
            this.$notify({
              title: "Error",
              type: "error",
              text: "Unable to get user profile",
            });
          }
        });
    },
  },

  mounted() {
    // this.user = this.$getUser();
    this.getUserProfile();
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/main.scss";

.Profile {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 2rem 2rem;
  box-sizing: border-box;
  border-radius: 10px;
  padding-bottom: 4rem;
  .profile-widgets {
    display: flex;
    flex-direction: column;
    .widget-item {
      border-bottom: 1px dashed #bbbbbb;
      &.no-b {
        border-bottom: none;
      }
      .widget-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        border-radius: 10px 10px 0px 0px;
        margin-bottom: 20px;
        h4 {
          font-size: 18px;
          font-weight: 600;
          color: #000;
        }
      }
      .widget-body {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px;
        border-radius: 0px 0px 10px 10px;
        margin-bottom: 20px;
        column-gap: 1rem;
        img {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          object-fit: cover;
        }
        .widget-right {
          display: flex;
          flex-direction: column;
          column-gap: 1rem;
          h3 {
            font-size: 1.25rem;
            font-weight: 600;
            color: #000;
          }
          p {
            font-size: 13px;
            font-weight: 400;
            color: #797979;
          }
        }
      }
    }
  }
}
</style>
