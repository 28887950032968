<template>
  <div class="Vehicles">
    <div class="table-header">
      <div class="left">
        <div class="search">
          <input
            type="text"
            placeholder="Search"
            @keyup="searchData()"
            v-model="search"
          />
        </div>
      </div>
      <div class="right">
        <button @click="popupOpen = true" class="btn-lg unverify">
          Add Vehicle
        </button>
      </div>
    </div>
    <table class="table bus-table">
      <thead>
        <tr>
          <th>#</th>
          <th>Brand</th>
          <th>Car body</th>
          <th>Driver</th>
          <th>Driver's phone</th>
          <th>Location</th>
          <th>Vehicle type</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in items" :key="item.id">
          <td class="img">
            <img
              :src="$file(item.vehicle_images[0])"
              alt=""
              @error="$imageurlalt"
            />
          </td>
          <td>{{ item.vehicle_brand }}</td>
          <td>{{ item.car_body.car_body_name }}</td>
          <td>{{ item.vehicle_driver }}</td>
          <td>{{ item.vehicle_driver_phone }}</td>
          <td>{{ item.district_name }}, {{ item.sector_name }}</td>
          <td v-if="item.is_for_transport && item.is_for_transport != '0'">
            Moving car
          </td>
          <td v-else>For Rent</td>
          <td>
            <button
              class="unverify"
              @click="
                selectedItem = item;
                editPopupOpen = true;
              "
            >
              <label for="">Edit</label>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <pagination
      :totalItems="allItems.length"
      :itemsPerPage="itemsPerPage"
      @pageChanged="
        (page) => {
          changePage(page);
        }
      "
    />
    <model
      :modelTitle="'Add Vehicle'"
      :largeModel="false"
      v-if="popupOpen"
      @closeModel="closePopup"
    >
      <AddVehicle
        @closePopup="
          getItems();
          popupOpen = false;
        "
      />
    </model>
    <model
      :modelTitle="'Edit Vehicle'"
      :largeModel="false"
      v-if="editPopupOpen"
      @closeModel="editPopupOpen = false"
    >
      <EditVehicle
        @closePopup="
          getItems();
          editPopupOpen = false;
        "
        :newItem="selectedItem"
      />
    </model>
  </div>
  <model
    :modelTitle="'Schedule Vehicle'"
    :largeModel="false"
    v-if="scheduleModalOpen"
    @closeModel="closeScheduleModal"
  >
    <Schedule />
  </model>
</template>
  
  <script>
import AddVehicle from "./AddVehicle.vue";
import EditVehicle from "./EditVehicle.vue";
import Schedule from "./Schedule.vue";
export default {
  data() {
    return {
      allItems: [],
      items: [],
      popupOpen: false,
      editPopupOpen: false,
      openModal: false,
      scheduleModalOpen: false,
      filterVehicles: "",
      search: "",
      selectedItem: null,
    };
  },
  components: { AddVehicle, EditVehicle, Schedule },

  methods: {
    openPopup() {
      this.popupOpen = true;
    },

    closePopup() {
      this.popupOpen = false;
    },
    openScheduleModal(vehicle) {
      this.scheduleModalOpen = true;
    },

    closeScheduleModal() {
      this.scheduleModalOpen = false;
    },

    openModal() {
      this.modalOpen = true;
    },
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_MY_VEHICLES", {
          token: this.$getToken(),
        })
        .then((res) => {
          this.allItems = res.data.data;
          this.allItems.forEach((item) => {
            console.log(item.vehicle_images);
            item.vehicle_images = JSON.parse(item.vehicle_images);
            console.log(item.vehicle_images);
          });
          this.items = this.allItems;
          this.$stopLoader();
        })
        .catch((err) => {
          console.log(err);
          this.$stopLoader();
        });
    },
    searchData() {
      this.items = this.allItems.filter((item) => {
        return (
          item.vehicle_brand
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          item.car_body.car_body_name
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          item.district_name
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          item.sector_name.toLowerCase().includes(this.search.toLowerCase()) ||
          item.cell_name.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
  },
  mounted() {
    this.getItems();
  },
};
</script>
  
  <style scoped lang="scss">
@import "@/assets/scss/main.scss";
.Vehicles {
  width: 100%;
  padding: 10px;
  padding-top: 1.5rem;
}
</style>
  