<template>
    <div class="Change">
      <div class="page-content">
        <form action="" @submit.prevent="changePassword()" >
          <div class="form-header">
            <label for="" class="main-head">{{
              $txt("Change your password")
            }}</label>
          </div>
          <div class="form-body">
            <div class="form-group">
              <p>Enter your current password</p>
              <input
                type="password"
                name="old_password"
                :placeholder="$txt('Current Password')"
                v-model="user.old_password"
              />
            </div>

            <div class="form-group">
              <p>New Password: </p>
              <input
                type="password"
                name="passwordConfirmation"
                :placeholder="$txt('New Password')"
                v-model="user.password"
              />
            </div>

            <div class="form-group">
              <p>Confirm  new Password: </p>
              <input
                type="password"
                name="c_password"
                :placeholder="$txt('confirm')"
                v-model="user.passwordConfirmation"
              />
            </div>
            <div class="form-group">
              <button type="submit" class="submit">
                {{ $txt("Change Password") }}
              </button>
            </div>
          </div>
          <div class="form-footer">
            <h4>{{ $txt("Contact info@infomaster.rw for support") }}</h4>
          </div>
        </form>

      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "ChangePassword",
    data() {
      return {
        user: {
          old_password: "",
          passwordConfirmation:'',
          password:'',
        },
      };
    },
    methods: {
  async changePassword() {
    try {

      if (this.user.passwordConfirmation !== this.user.password) {
        this.$notify({
          title: "Error",
          type: "error",
          text: "New password and confirm password do not match.",
        });
        return;
      }

      await this.$store.dispatch('UPDATE_PASSWORD', {
        password: this.user.password,
        passwordConfirmation: this.user.passwordConfirmation,
        old_password: this.user.old_password,
      });


      this.$notify({
        title: "Success",
        type: "success",
        text: "Password changed successfully.",
      });
      this.$router.push({name:"Login"})

    } catch (error) {
      console.error(error);
      this.$notify({
        title: "Error",
        type: "error",
        text: "An error occurred while changing the password.",
      });
    }
  },
},

  }
  
  </script>
  
  <style lang="scss" scoped>
  @import "@/assets/scss/main.scss";
  .page-content {
    width: 100%;
    display: flex;
    justify-content: center;
    form {
      width: 100%;
      max-width: 600px;
      height: fit-content;
      padding: 4rem 5rem;
      border-radius: 5px;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
      position: relative;
      flex-wrap: wrap;
      margin-top: 2rem;
  
      .form-header {
        margin-bottom: 20px;
        .main-head {
          display: block;
          text-align: center;
          font-size: 20px;
          margin-bottom: 10px;
          span {
            color: $blue;
            font-weight: 600;
          }
        }
        .dont,
        .forgot {
          display: block;
          text-align: center;
          span,
          a {
            color: $blue;
            font-weight: 600;
          }
        }
      }
      .form-body {
        .form-group {
          margin-bottom: 20px;
          position: relative;
          p {
            display: block;
            margin-bottom: 5px;
            top: 5px;
            left: 10px;
            font-size: 13px;
          }
          input {
            width: 100%;
            padding: 10px;
            border: 1px solid #ddd;
            border-radius: 5px;
            outline: none;
          }
          .submit {
            width: 100%;
            padding: 10px;
            border: 1px solid #ddd;
            border-radius: 5px;
            outline: none;
            background: $blue;
            color: #fff;
            font-weight: 600;
            cursor: pointer;
            &:hover {
              background: darken($blue, 10%);
            }
          }
        }
      }
      .form-footer {
        position: absolute;
        bottom: 20px;
        width: calc(100% - 10rem);
        h4 {
          text-align: center;
        }
      }
    }
    .check {
      width: 100%;
      max-width: 600px;
      height: fit-content;
      padding: 4rem 5rem;
      border-radius: 5px;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
      position: relative;
      flex-wrap: wrap;
      margin-top: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100px;
        text-align: center;
      }
      .div-header {
        margin-bottom: 20px;
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }
      .div-body {
        p {
          text-align: center;
        }
      }
    }
  }
  </style>