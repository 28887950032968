import axios from 'axios'
import { API_URL, BUSS_API_URL } from '../../variables';
const GET_BUSS_OWNERSHIPS_URL = '/businesses/getAllOwnerships';
const GET_BUSS_SECTORS_URL = '/businesses/getAllSectors';
const AUTHORIZE_BUSINESS_URL = '/authorize';
const ADD_SECTOR_URL = '/businesses/createSector';
const EDIT_SECTOR_URL = '/businesses/updateSector';
const DELETE_SECTOR_URL = '/businesses/deleteSector';
const ADD_BUSINESS_REVIEW = '/businesses/createBusinessReview';

const businessOthers = {
    state: {
    },
    getters: {},
    mutations: {},
    actions: {
        ['GET_BUSS_OWNERSHIPS']: ({ commit }, payload) => {
            return axios({
                url: BUSS_API_URL + GET_BUSS_OWNERSHIPS_URL,
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                }
            })
        },
        ['GET_BUSS_SECTORS']: ({ commit }, payload) => {
            return axios({
                url: BUSS_API_URL + GET_BUSS_SECTORS_URL,
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                }
            })
        },
        ['AUTHORIZE_BUSINESS']: ({ commit }, payload) => {
            return axios({
                url: BUSS_API_URL + AUTHORIZE_BUSINESS_URL,
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                },
                data: {
                    auth_key: payload.token,
                }
            })
        },
        ['ADD_SECTOR']: ({ commit }, payload) => {
            return axios({
                url: BUSS_API_URL + ADD_SECTOR_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                },
            })
        },
        ['EDIT_SECTOR']: ({ commit }, payload) => {
            return axios({
                url: BUSS_API_URL + EDIT_SECTOR_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                },
            })
        },
        ['DELETE_SECTOR']: ({ commit }, payload) => {
            return axios({
                url: BUSS_API_URL + DELETE_SECTOR_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                },
            })
        },
        ['ADD_BUSINESS_REVIEW']: ({ commit }, payload) => {
            return axios({
                url: BUSS_API_URL + ADD_BUSINESS_REVIEW,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                },
            })
        }
    },
    modules: {},
}
export default businessOthers;