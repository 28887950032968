<template>
  <div class="CooperativeMembers">
    <super-admin-topbar
      :pageTitle="'Kinazi Cooperative Member (' + users.length + ')'"
    />
    <div class="page-content">
      <div class="levels-table">
        <div class="table-header">
          <div class="left">
            <div class="search">
              <input
                type="text"
                placeholder="Search"
                @keyup="searchData()"
                v-model="search"
              />
            </div>
          </div>
          <div class="right"></div>
        </div>
        <div class="item-per-page">
          <label for="">Items per page: </label>
          <select name="" id="" v-model="itemsPerPage" @change="changePage(1)">
            <option>5</option>
            <option>10</option>
            <option>25</option>
            <option>50</option>
            <option>100</option>
            <option>500</option>
          </select>
        </div>
        <table>
          <thead>
            <tr>
              <th>N<sup>o</sup></th>
              <th>Names</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Role</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(user, index) in users" :key="user.id">
              <td>{{ index + 1 }}</td>
              <td>{{ user.user.names }}</td>
              <td>{{ user.user.phone }}</td>
              <td>{{ user.user.email }}</td>
              <td>{{ user.user.roleName }}</td>
              <td>{{ user.status }}</td>
              <td style="width: 200px">
                <router-link
                  :to="{ name: 'ViewFarmer', params: { id: user.user.id } }"
                  class="View"
                >
                  <label for="">View</label>
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
        <pagination
          :totalItems="allUsers.length"
          :itemsPerPage="itemsPerPage"
          @pageChanged="
            (page) => {
              changePage(page);
            }
          "
        />
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "CooperativeMembers",
  data() {
    return {
      domains: [],
      allDomains: [],
      search: "",
      popupOpen: false,
      editPopupOpen: false,
      newDomain: {
        domainName: "",
      },
      selectedDomain: {},
      deletePopupOpen: false,
      school: {
        province: "null",
        district: "null",
        sector: "null",
        cell_id: "null",
      },
      provinces: [],
      districts: [],
      sectors: [],
      cells: [],
      itemsPerPage: 5,
      allUsers: [],
      users: [],
    };
  },
  methods: {
    searchData() {
      this.users = this.allUsers.filter((user) => {
        return (
          user.name.toLowerCase().includes(this.search.toLowerCase()) ||
          user.role.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
    changePage(page) {
      this.currentPage = page;
      this.users = this.$changePage(this.allUsers, page, this.itemsPerPage);
    },
    getMembers() {
      this.$startLoader();
      this.$store
        .dispatch("GET_COOPERATIVE_MEMBERS", {
          token: this.$getToken(),
          data: { cooperative_id: this.$route.params.id },
        })
        .then((res) => {
          this.allUsers = res.data.data;
          this.users = this.$changePage(
            this.allUsers,
            this.currentPage,
            this.itemsPerPage
          );
          this.$stopLoader();
        })
        .catch((err) => {
          this.$stopLoader();
          if (err.response.status == 401) {
            this.$notify({
              title: "Error",
              type: "error",
              text: err.response.data.message,
            });
            this.$router.push({ name: "Login" });
          } else {
            this.$notify({
              title: "Error",
              type: "error",
              text: err.response.data.message,
            });
          }
        });
    },
  },
  mounted() {
    this.getMembers();
    this.changePage(1);
  },
};
</script>
  
  <style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.CooperativeMembers {
  .levels-table {
    padding: 2rem 1rem;
    .table-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
      .left {
        display: flex;
        align-items: center;
        .search {
          input {
            padding: 0.5rem;
            border: 1px solid #ccc;
            border-radius: 5px;
            outline: none;
            width: 300px;
          }
        }
      }
      .right {
        button {
          padding: 0.65rem 1rem;
          width: 150px;
          border: none;
          outline: none;
          border-radius: 5px;
          background: $blue;
          color: #fff;
          cursor: pointer;
          font-weight: 600;
        }
      }
    }
    table {
      width: 100%;
      border-collapse: collapse;
      thead {
        tr {
          th {
            padding: 10px;
            border-bottom: 1px solid #ccc;
            text-align: left;
          }
        }
      }
    }
  }
  .addLevel {
    .form-group {
      margin-bottom: 1rem;
      label {
        display: block;
        margin-bottom: 0.5rem;
      }
      input {
        padding: 0.5rem;
        border: 1px solid #ccc;
        border-radius: 5px;
        outline: none;
        width: 100%;
      }
      button {
        padding: 0.65rem 1rem;
        width: 150px;
        border: none;
        outline: none;
        border-radius: 5px;
        background: $blue;
        color: #fff;
        cursor: pointer;
        font-weight: 600;
      }
    }
  }
}
</style>