<!-- DateRangeForm.vue -->
<template>
    <form @submit.prevent="submitForm">
      <div class="form-group">
        <label for="fromDate">From:</label>
        <VueDatePicker v-model="fromDate" id="fromDate" class="form-control" />
      </div>
      <div class="form-group">
        <label for="toDate">To:</label>
        <VueDatePicker v-model="toDate" id="toDate" class="form-control" />
      </div>
      <div class="mb-4">
  <label for="location" class="block text-gray-700 text-sm font-bold mb-2">Starting Point</label>
  
  <div class="flex">
    <select
      name="province"
      id=""
      v-model="current_locations.province"
      @change="getDistricts(current_locations.province)"
      class="mr-2"
    >
      <option value="">{{ $txt("Province") }}</option>
      <option
        v-for="province in provinces"
        :key="province.provinceId"
        :value="province.provinceId"
      >
        {{ province.provinceName }}
      </option>
    </select>
    <select
      name="district"
      id=""
      v-model="current_locations.district"
      @change="getSectors(current_locations.district)"
      class="mr-2" 
    >
      <option value="">{{ $txt("District") }}</option>
      <option
        v-for="district in districts"
        :key="district.districtId"
        :value="district.districtId"
      >
        {{ district.districtName }}
      </option>
    </select>
    <select
      name="sector"
      id=""
      v-model="current_locations.sector"
      @change="getCells(current_locations.sector)"
      class="mr-2" 
    >
      <option value="">{{ $txt("Sector") }}</option>
      <option
        v-for="sector in sectors"
        :key="sector.sectorId"
        :value="sector.sectorId"
      >
        {{ sector.sectorName }}
      </option>
    </select>
    <select
      name="cell"
      id=""
      v-model="current_locations.cell"
    >
      <option value="">{{ $txt("Cell") }}</option>
      <option
        v-for="cell in cells"
        :key="cell.cellId"
        :value="cell.cellId"
      >
        {{ cell.cellName }}
      </option>
    </select>
  </div>
      </div>

      <div class="mb-4">
  <label for="location" class="block text-gray-700 text-sm font-bold mb-2">Ending Point</label>
  
  <div class="flex">
    <select
      name="province"
      id=""
      v-model="current_locations.province"
      @change="getDistricts(current_locations.province)"
      class="mr-2"
    >
      <option value="">{{ $txt("Province") }}</option>
      <option
        v-for="province in provinces"
        :key="province.provinceId"
        :value="province.provinceId"
      >
        {{ province.provinceName }}
      </option>
    </select>
    <select
      name="district"
      id=""
      v-model="current_locations.district"
      @change="getSectors(current_locations.district)"
      class="mr-2" 
    >
      <option value="">{{ $txt("District") }}</option>
      <option
        v-for="district in districts"
        :key="district.districtId"
        :value="district.districtId"
      >
        {{ district.districtName }}
      </option>
    </select>
    <select
      name="sector"
      id=""
      v-model="current_locations.sector"
      @change="getCells(current_locations.sector)"
      class="mr-2" 
    >
      <option value="">{{ $txt("Sector") }}</option>
      <option
        v-for="sector in sectors"
        :key="sector.sectorId"
        :value="sector.sectorId"
      >
        {{ sector.sectorName }}
      </option>
    </select>
    <select
      name="cell"
      id=""
      v-model="current_locations.cell"
    >
      <option value="">{{ $txt("Cell") }}</option>
      <option
        v-for="cell in cells"
        :key="cell.cellId"
        :value="cell.cellId"
      >
        {{ cell.cellName }}
      </option>
    </select>
  </div>
      </div>
      <button type="submit" >Submit</button>
    </form>
  </template>
  
  <script>
  import Datepicker from 'vue-datepicker';
  
  export default {
    components: {
      Datepicker
    },
    data() {
      return {
        fromDate: null,
        toDate: null,
        current_locations: {
          province: "",
          district: "",
          sector: "",
          cell: "",
        },
      provinces: [],
      districts: [],
      sectors: [],
      cells: [],
      };

    },
    methods: {
      getProvinces() {
    this.$startLoader();
    this.$store
      .dispatch("GET_PROVINCES", { token: this.$getToken() })
      .then((res) => {
        this.provinces = res.data.data.data;
        this.districts = [];
        this.sectors = [];
        this.cells = [];
        this.$stopLoader();
      });
  },
  getDistricts(provinceId) {
    this.$startLoader();
    this.$store
      .dispatch("GET_DISTRICTS", {
        token: this.$getToken(),
        provinceId: provinceId,
      })
      .then((res) => {
        this.districts = res.data.data.data;
        this.sectors = [];
        this.cells = [];
        this.$stopLoader();
      });
  },
  getSectors(districtId) {
    this.$startLoader();
    this.$store
      .dispatch("GET_SECTORS", {
        token: this.$getToken(),
        districtId: districtId,
      })
      .then((res) => {
        this.sectors = res.data.data.data;
        this.cells = [];
        this.$stopLoader();
      });
  },
  getCells(sectorId) {
    this.$startLoader();
    this.$store
      .dispatch("GET_CELLS", { token: this.$getToken(), sectorId: sectorId })
      .then((res) => {
        this.cells = res.data.data.data;
        this.$stopLoader();
      });
  },
    },

    mounted() {
      this.getProvinces();
    },
  };
  </script>
  
  <style scoped lang="scss">
@import "@/assets/scss/main.scss";

button{
    background-color: $blue;
    margin-top: 1rem;
    color: white;
    padding: 12px;
    border-radius: 5px;
}

select{
  padding: 12px;
  width:40%
}
  </style>
  