<template>
  <div class="levels-table">
    <div class="table-header">
      <div class="left">
        <div class="search">
          <input
            type="text"
            placeholder="Search"
            @keyup="searchData()"
            v-model="search"
          />
        </div>
      </div>
      <div class="right">
        <button @click="popupOpen = true" class="btn-lg unverify">
          Add Business
        </button>
      </div>
    </div>
    <table class="table bus-table">
      <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th>phone</th>
          <th>email</th>
          <th>Owner</th>
          <th>Owner's phone</th>
          <th>Location</th>
          <th>Business sector</th>
          <th>Status</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in items" :key="item.id">
          <td class="img">
            <img
              :src="$file(item.business_logo)"
              alt=""
              @error="$imageurlalt"
            />
          </td>
          <td>{{ item.business_name }}</td>
          <td>{{ item.business_phone }}</td>
          <td>{{ item.business_email }}</td>
          <td>{{ item.business_representative }}</td>
          <td>{{ item.business_representative_phone }}</td>
          <td>{{ item.district_name }}, {{ item.sector_name }}</td>
          <td>{{ item.businesssector.sector_name }}</td>
          <td v-if="item.verified"><label style="color: green;">Verified</label></td>
          <td v-else><label style="color: orange;">Not Verified</label></td>
          <td>
            <button
              class="unverify"
              @click="
                selectedItem = item;
                editPopupOpen = true;
              "
            >
              <label for="">Edit</label>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <pagination
      :totalItems="allItems.length"
      :itemsPerPage="itemsPerPage"
      @pageChanged="
        (page) => {
          changePage(page);
        }
      "
    />
  </div>

  <model
    :modelTitle="'Add Business'"
    :largeModel="true"
    v-if="popupOpen"
    @closeModel="
      popupOpen = false;
      getitems();
    "
  >
    <add-business />
  </model>
  <model
    :modelTitle="'Edit Business'"
    :largeModel="true"
    v-if="editPopupOpen"
    @closeModel="
      editPopupOpen = false;
      getitems();
    "
  >
    <edit-business
      :item="selectedItem"
      @closePopup="
        editPopupOpen = false;
        getitems();
      "
    />
  </model>
</template>

<script>
import AddBusiness from "./AddBusiness.vue";
import EditBusiness from "./EditBusiness.vue";
import ViewSingleBusiness from "./ViewSingleBusiness.vue";
export default {
  data() {
    return {
      allItems: [],
      items: [],
      popupOpen: false,
      editPopupOpen: false,
      selectedItem: null,
      openModal: false,
      showReviews: false,
      myBusinesses: null,
      itemsPerPage: 10,
      currentPage: 1,
    };
  },
  components: { AddBusiness, EditBusiness, ViewSingleBusiness },
  methods: {
    openPopup() {
      this.popupOpen = true;
    },

    closePopup() {
      this.popupOpen = false;
    },

    openModal() {
      this.openModal = true;
    },

    toggleReviews() {
      this.showReviews = !this.showReviews;
    },

    getitems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_MY_BUSINESSES", {
          token: this.$getToken(),
        })
        .then((res) => {
          this.allItems = res.data.data;
          this.items = this.allItems;
          this.$stopLoader();
        })
        .catch((err) => {
          console.log(err);
          this.$stopLoader();
        });
    },
    searchData() {
      this.items = this.allItems.filter((item) => {
        return (
          item.business_name
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          item.business_phone
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          item.business_email
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          item.business_representative
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          item.district_name
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          item.sector_name.toLowerCase().includes(this.search.toLowerCase()) ||
          item.businesssector.sector_name
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          item.province_name.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
    changePage(page) {
      this.currentPage = page;
      this.items = this.$changePage(this.allItems, page, this.itemsPerPage);
    },
  },

  mounted() {
    this.getitems();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

* {
  margin-top: 12px;
}

button {
  background-color: $blue !important;
}

.button-container {
  display: flex;
}

.button-container button {
  flex: 1;
  margin: 0;
}

.button-container button:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.button-container button:last-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

p {
  margin-left: 120px;
}
i {
  border-radius: 50%;
  height: 50px;
  width: 50px;
  color: $blue;
  padding: 16px;
  margin-left: 12px;
}

.reviews {
  margin-top: 10px;
}

.review {
  margin-top: 5px;
}
i:hover {
  color: #1d3557;
  background-color: white;
  border: 1px solid #1d3557;
}
.levels-table {
  padding: 10px;
}
</style>
