<template>
  <div class="AgricultureMarket">
    <super-admin-topbar :pageTitle="'Farmers Market'" />
    <market-navigation :activePage="'Farmers'" />
    <div class="page-content">
      <div class="levels-table">
        <div class="table-header">
          <div class="left">
            <div class="search">
              <input
                type="text"
                placeholder="Search"
                @keyup="searchData()"
                v-model="search"
              />
            </div>
          </div>
          <div class="right"></div>
        </div>
        <div class="filter-form">
          <label for="">Filter: </label>
          <div class="form">
            <div class="form-group group-4">
              <!-- Province, Disctict, sector, cell -->
              <select
                name="province"
                id=""
                v-model="school.province"
                @change="getDistricts(school.province)"
              >
                <option value="null">{{ $txt("All Provinces") }}</option>
                <option
                  v-for="province in provinces"
                  :key="province.provinceId"
                  :value="province.provinceId"
                >
                  {{ province.provinceName }}
                </option>
              </select>
              <select
                name="district"
                id=""
                v-model="school.district"
                @change="getSectors(school.district)"
              >
                <option value="null">{{ $txt("All Districts") }}</option>
                <option
                  v-for="district in districts"
                  :key="district.districtId"
                  :value="district.districtId"
                >
                  {{ district.districtName }}
                </option>
              </select>
              <select
                name="sector"
                id=""
                v-model="school.sector"
                @change="getCells(school.sector)"
              >
                <option value="null">{{ $txt("All Sectors") }}</option>
                <option
                  v-for="sector in sectors"
                  :key="sector.sectorId"
                  :value="sector.sectorId"
                >
                  {{ sector.sectorName }}
                </option>
              </select>
              <select name="cell" id="" v-model="school.cell_id">
                <option value="null">{{ $txt("All Cells") }}</option>
                <option
                  v-for="cell in cells"
                  :key="cell.cellId"
                  :value="cell.cellId"
                >
                  {{ cell.cellName }}
                </option>
              </select>
            </div>
            <button class="submit" @click="getFilteredData()">Filter</button>
          </div>
        </div>
        <div class="item-per-page">
          <label for="">Items per page: </label>
          <select name="" id="" v-model="itemsPerPage" @change="changePage(1)">
            <option>5</option>
            <option>10</option>
            <option>25</option>
            <option>50</option>
            <option>100</option>
            <option>500</option>
          </select>
        </div>
        <table>
          <thead>
            <tr>
              <th>N<sup>o</sup></th>
              <th>Product</th>
              <th>Farmer</th>
              <th>Phone</th>
              <th>Amount</th>
              <th>Price</th>
              <th>Village</th>
              <th>Cell</th>
              <th>Sector</th>
              <th>District</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(product, index) in products" :key="product.id">
              <td>{{ index + 1 }}</td>
              <td>{{ product.name }}</td>
              <td>{{ product.farmer }}</td>
              <td>{{ product.phone }}</td>
              <td>{{ product.quantity }}</td>
              <td>{{ $comma(product.price) }} Rwf</td>
              <td>{{ product.village }}</td>
              <td>{{ product.cell }}</td>
              <td>{{ product.sector }}</td>
              <td>{{ product.district }}</td>
              <td style="width: 200px">
                <router-link
                  :to="{ name: 'ViewFarmer', params: { id: product.id } }"
                  class="View"
                >
                  <label for="">View</label>
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
        <pagination
          :totalItems="allProducts.length"
          :itemsPerPage="itemsPerPage"
          @pageChanged="
            (page) => {
              changePage(page);
            }
          "
        />
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "AgricultureMarket",
  data() {
    return {
      domains: [],
      allDomains: [],
      search: "",
      popupOpen: false,
      editPopupOpen: false,
      newDomain: {
        domainName: "",
      },
      selectedDomain: {},
      deletePopupOpen: false,
      school: {
        province: "null",
        district: "null",
        sector: "null",
        cell_id: "null",
      },
      provinces: [],
      districts: [],
      sectors: [],
      cells: [],
      itemsPerPage: 5,
      allProducts: [
        {
          id: 1,
          name: "Beans",
          price: 500,
          quantity: "3000 kg",
          district: "Kicukiro",
          sector: "Gahanga",
          cell: "Gahanga",
          village: "Gahanga",
          farmer: "Kalisa Erneste",
          phone: "0789178645",
          content: "Beans are good for health",
        },
        {
          id: 2,
          name: "Pork-meat",
          price: 1000,
          quantity: "300 pigs",
          district: "Kicukiro",
          sector: "Gahanga",
          cell: "Gahanga",
          village: "Gahanga",
          farmer: "Kaneza Sandrine",
          phone: "0789178645",
          content: "Ingurube are good for health",
        },
        {
          id: 3,
          name: "Poultry-eggs",
          price: 2500,
          quantity: "3000 eggs",
          district: "Kicukiro",
          sector: "Gahanga",
          cell: "Gahanga",
          village: "Gahanga",
          farmer: "Muneza George",
          phone: "0789178645",
          content: "Eggs are good for health",
        },
        {
          id: 4,
          name: "Cattle-Milk",
          price: 500,
          quantity: "3000 litters",
          district: "Kicukiro",
          sector: "Gahanga",
          cell: "Gahanga",
          village: "Gahanga",
          farmer: "Mutoni Christella",
          phone: "0789178645",
          content: "Milk is good for health",
        },
        {
          id: 5,
          name: "Cattle-Meat",
          price: 500000,
          quantity: "3000",
          district: "Kicukiro",
          sector: "Gahanga",
          cell: "Gahanga",
          village: "Gahanga",
          farmer: "Uwera Emerance",
          phone: "0789178645",
          content: "Milk is good for health",
        },
        {
          id: 6,
          name: "Cattle-Milk",
          price: 500,
          quantity: "3000 litters",
          district: "Kicukiro",
          sector: "Gahanga",
          cell: "Gahanga",
          village: "Gahanga",
          farmer: "Mugisha Alain",
          phone: "0789178645",
          content: "Milk is good for health",
        },
      ],
      products: [],
    };
  },
  methods: {
    searchData() {
      this.products = this.allProducts.filter((product) => {
        return (
          product.name.toLowerCase().includes(this.search.toLowerCase()) ||
          product.farmer
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          product.district.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
    getProvinces() {
      this.$startLoader();
      this.$store
        .dispatch("GET_PROVINCES", { token: this.$getToken() })
        .then((res) => {
          this.provinces = res.data.data.data;
          this.districts = [];
          this.sectors = [];
          this.cells = [];
          this.$stopLoader();
        });
    },
    getDistricts(provinceId) {
      if (provinceId == "" || provinceId == undefined || provinceId == null) {
        this.districts = [];
        this.sectors = [];
        this.cells = [];
        return;
      }
      this.$startLoader();
      this.$store
        .dispatch("GET_DISTRICTS", {
          token: this.$getToken(),
          provinceId: provinceId,
        })
        .then((res) => {
          this.districts = res.data.data.data;
          this.sectors = [];
          this.cells = [];
          this.$stopLoader();
        });
    },
    getSectors(districtId) {
      if (districtId == "" || districtId == undefined || districtId == null) {
        this.sectors = [];
        this.cells = [];
        return;
      }
      this.$startLoader();
      this.$store
        .dispatch("GET_SECTORS", {
          token: this.$getToken(),
          districtId: districtId,
        })
        .then((res) => {
          this.sectors = res.data.data.data;
          this.cells = [];
          this.$stopLoader();
        });
    },
    getCells(sectorId) {
      if (sectorId == "" || sectorId == undefined || sectorId == null) {
        this.cells = [];
        return;
      }
      this.$startLoader();
      this.$store
        .dispatch("GET_CELLS", { token: this.$getToken(), sectorId: sectorId })
        .then((res) => {
          this.cells = res.data.data.data;
          this.$stopLoader();
        });
    },
    getFilteredData() {
      if (
        this.school.province == "null" &&
        this.school.district == "null" &&
        this.school.sector == "null" &&
        this.school.cell_id == "null"
      ) {
        this.getSchools();
      } else {
        this.$startLoader();
        this.$store
          .dispatch("GET_FILTERED_SCHOOLS_BY_ADDRESS", {
            token: this.$getToken(),
            data: {
              provinceId: this.school.province,
              districtId: this.school.district,
              sectorId: this.school.sector,
              cellId: this.school.cell_id,
            },
          })
          .then((res) => {
            this.schools = res.data.data.data;
            this.allSchools = res.data.data.data;
            this.$stopLoader();
          })
          .catch((err) => {
            console.log(err);
            this.$notify({
              title: "Error",
              type: "error",
              text: err.response.data.error,
            });
            this.$stopLoader();
          });
      }
    },
    changePage(page) {
      this.currentPage = page;
      this.products = this.$changePage(
        this.allProducts,
        page,
        this.itemsPerPage
      );
    },
  },
  mounted() {
    this.getProvinces();
    this.changePage(1);
  },
};
</script>
  
  <style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.AgricultureMarket {
  .levels-table {
    padding: 2rem 1rem;
    .table-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
      .left {
        display: flex;
        align-items: center;
        .search {
          input {
            padding: 0.5rem;
            border: 1px solid #ccc;
            border-radius: 5px;
            outline: none;
            width: 300px;
          }
        }
      }
      .right {
        button {
          padding: 0.65rem 1rem;
          width: 150px;
          border: none;
          outline: none;
          border-radius: 5px;
          background: $blue;
          color: #fff;
          cursor: pointer;
          font-weight: 600;
        }
      }
    }
    table {
      width: 100%;
      border-collapse: collapse;
      thead {
        tr {
          th {
            padding: 10px;
            border-bottom: 1px solid #ccc;
            text-align: left;
          }
        }
      }
    }
  }
  .addLevel {
    .form-group {
      margin-bottom: 1rem;
      label {
        display: block;
        margin-bottom: 0.5rem;
      }
      input {
        padding: 0.5rem;
        border: 1px solid #ccc;
        border-radius: 5px;
        outline: none;
        width: 100%;
      }
      button {
        padding: 0.65rem 1rem;
        width: 150px;
        border: none;
        outline: none;
        border-radius: 5px;
        background: $blue;
        color: #fff;
        cursor: pointer;
        font-weight: 600;
      }
    }
  }
}
</style>