import { API_URL, REGISTER_CALLBACK } from '../variables';
import axios from 'axios'
const GET_ALL_MODULES_URL = API_URL + '/modules';
const GET_MODULE_BY_CODE_URL = API_URL + '/modules/';
const EDIT_MODULE_URL = API_URL + '/modules/update';

const translation = {
    state: {
    },
    getters: {},
    mutations: {},
    actions: {
        ['GET_ALL_MODULES']: ({ commit }, payload) => {
            return axios({
                url: GET_ALL_MODULES_URL,
                method: "GET",
                data: payload
            })
        },
        ['GET_MODULE_BY_CODE']: ({ commit }, payload) => {
            return axios({
                url: GET_MODULE_BY_CODE_URL + payload,
                method: "GET",
                data: payload
            })
        },
        ['EDIT_MODULE']: ({ commit }, payload) => {
            return axios({
                url: EDIT_MODULE_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
    },
    modules: {},
}
export default translation;