import axios from 'axios'
import { API_URL, BUSS_API_URL } from '../../variables';
const GET_WORKER_SKILLS_URL = '/skills/getAllSkills';
const DELETE_WORK_SKILL = '/skills/deleteSkill';
const ADD_WORK_SKILL = '/skills/createSkill';
const EDIT_WORK_SKILL = '/skills/updateSkill';
const EDIT_WORKER_STATUS_URL=BUSS_API_URL + '/employment/status/'
const workersOthers = {
    state: {
    },
    getters: {},
    mutations: {},
    actions: {
        ['GET_WORKER_SKILLS']: ({ commit }, payload) => {
            return axios({
                url: BUSS_API_URL + GET_WORKER_SKILLS_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                }
            })
        },
        ['DELETE_WORK_SKILL']: ({ commit }, payload) => {
            return axios({
                url: BUSS_API_URL + DELETE_WORK_SKILL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                }
            })
        },
        ['ADD_WORK_SKILL']: ({ commit }, payload) => {
            return axios({
                url: BUSS_API_URL + ADD_WORK_SKILL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                }
            })
        },
        ['EDIT_WORKER_STATUS']: ({ commit }, payload) => {
            return axios ({
                url: EDIT_WORKER_STATUS_URL,
                method: "POST",
                data: payload.data, 
                headers:{
                    'Authorization': 'Bearer ' + payload.token
                }
            })
    },
        ['EDIT_WORK_SKILL']: ({ commit }, payload) => {
            return axios({
                url: BUSS_API_URL + EDIT_WORK_SKILL,
                method: "POST",
                data: payload.data,
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                }
            })
        },

},
modules: {},

}
export default workersOthers;