<template>
  <div class="iloader">
    <img src="/assets/images/load.gif" alt="" />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.iloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffffbb;
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 60px;
  }
}
</style>