<template>
  <div class="Education">
    <site-navbar />
    <div class="page-content">
      <div class="page-intro">
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum
          facilis quo, quos impedit ratione consequuntur nemo id laboriosam
          voluptas? Quo quia beatae corrupti amet. Dicta, magni. Illum eum nam
          vitae? Lorem ipsum dolor, sit amet consectetur adipisicing elit.
          Asperiores eveniet quisquam officiis nostrum placeat dolores fugiat
          vitae voluptates suscipit architecto. Atque facere beatae et
          voluptates totam nobis sequi quae porro?
        </p>
        <div class="right">
          <router-link :to="{ name: 'AddSchool' }" class="btn btn-primary"
            >Manage Your School</router-link
          >
        </div>
      </div>
      <div class="page-body">
        <div class="filter-schools">
          <div class="filter-left">
            <div class="most-liked">
              <input type="checkbox" name="most-liked" id="" />
              <label for="">{{ $txt("Most&nbsp;liked") }}</label>
            </div>
            <select name="province" id="">
              <option value="">{{ $txt("Province") }}</option>
              <option value="">{{ $txt("Kigali City") }}</option>
              <option value="">{{ $txt("North") }}</option>
              <option value="">{{ $txt("South") }}</option>
              <option value="">{{ $txt("East") }}</option>
              <option value="">{{ $txt("West") }}</option>
            </select>
            <select name="district" id="">
              <option value="">{{ $txt("District") }}</option>
            </select>
            <select name="sector" id="">
              <option value="">{{ $txt("Sector") }}</option>
            </select>
            <select name="cell" id="">
              <option value="">{{ $txt("Cell") }}</option>
            </select>
            <select name="category" id="">
              <option value="">{{ $txt("Category") }}</option>
              <option value="">{{ $txt("Primary") }}</option>
              <option value="">{{ $txt("Secondary") }}</option>
              <option value="">{{ $txt("University") }}</option>
            </select>
            <select name="level" id="">
              <option value="">{{ $txt("Level") }}</option>
              <option value="">{{ $txt("Ordinary") }}</option>
              <option value="">{{ $txt("Advanced") }}</option>
            </select>
            <select name="special" id="">
              <option value="">{{ $txt("Special") }}</option>
              <option value="">{{ $txt("Yes") }}</option>
              <option value="">{{ $txt("No") }}</option>
            </select>
          </div>
          <div class="filter-right">
            <form class="search">
              <input type="text" :placeholder="$txt('Search')" />
              <i class="fa fa-search"></i>
            </form>
          </div>
        </div>
        <div class="schools-list">
          <div class="school-item" v-for="(school, index) in 5" :key="index">
            <div class="school-item-left">
              <img src="/assets/images/king.jpg" alt="" />
            </div>
            <div class="school-item-right">
              <div class="left">
                <h3 class="school-name">King David Academy</h3>
                <p>info@kingdavic.ac.rw</p>
                <p>250784730546</p>
                <p>kingdavidacademy.co</p>
                <p>Primary, Secondary</p>
              </div>
              <div class="director">
                <p>Director/Principal/HM:</p>
                <label>ANET MUTAMULIZA</label>
                <p class="message">
                  K.D.A provides an enriched and diverse learning environment
                  for our students. Our teachers are passionate, experienced and
                  eager to pass on their knowledge and experience to their
                  students. Are you ready to start your future with us?
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Education",
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.Education {
  .page-content {
    padding: 2rem 5rem;
    .page-intro {
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        width: 50%;
      }
      .right {
        width: 40%;
        display: flex;
        justify-content: center;
        a {
          width: 300px;
          background: $blue;
          color: #fff;
          padding: 10px 20px;
          border-radius: 5px;
          text-align: center;
        }
      }
    }
    .page-body {
      margin-top: 4rem;
      .filter-schools {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .filter-left {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: fit-content;
          .most-liked {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: row;
            input {
              margin-right: 10px;
              font-size: 12px;
            }
            label {
              display: block;
              font-size: 12px;
            }
          }
          select {
            width: 15%;
            height: 40px;
            border: none;
            border-bottom: 1px solid #ccc;
            padding: 0 10px;
            font-size: 12px;
            margin-left: 10px;
          }
        }
        .filter-right {
          width: 300px;
          .search {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            position: relative;
            input {
              width: 100%;
              height: 40px;
              border-radius: 5px;
              border: 1px solid #ccc;
              padding: 0 10px;
              padding-right: 40px;
            }
            i {
              height: 40px;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              position: absolute !important;
              right: 10px;
            }
          }
        }
      }
      .schools-list {
        margin-top: 2rem;
        .school-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 20px;
          padding-bottom: 2rem;
          border-bottom: 1px solid #ccc;
          .school-item-left {
            width: 20%;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .school-item-right {
            width: 80%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .left {
              width: 60%;
              margin-left: 10px;
              .school-name {
                font-size: 20px;
                font-weight: bold;
                margin-bottom: 10px;
                color: $blue;
              }
              p {
                font-size: 13px;
                margin-bottom: 5px;
              }
            }
            .director {
              width: 40%;
              p {
                font-size: 13px;
                margin-bottom: 5px;
              }
              label{
                font-size: 13px;
                margin-bottom: 5px;
                font-weight: bold;
                color: $blue;
              }
              .message {
                font-size: 12px;
                margin-bottom: 5px;
                color: #1f1f1f;
              }
            }
          }
        }
      }
    }
  }
}


</style>