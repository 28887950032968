<template>
  <div class="ViewProduct">
    <div
      class="dialog"
      :class="dialogActive ? 'active' : ''"
      v-click-out-side="customMethod2"
    >
      <div class="dialog-header">
        <h1>{{ $txt("View deal") }}</h1>
        <button @click="closePopup">&times;</button>
      </div>
      <div class="dialog-body">
        <div class="product">
          <div class="product-image">
            <img :src="$file(item.product_coverImage)" alt="" />
          </div>
          <div class="product-details">
            <h1>{{ item.product_name }}</h1>
            <p>{{ item.product_description }}</p>
            <div class="product-price">
              <h1>
                <span>{{ $txt("Owner:") }} </span>
                <span>{{ item.ownerNames }}</span>
              </h1>
              <h1>
                <span>{{ $txt("Owner's Phone:") }} </span>
                <span>{{ item.ownerPhone }}</span>
              </h1>
              <h1>
                <span>{{ $txt("Owner's Email:") }} </span>
                <span>{{ item.ownerEmail }}</span>
              </h1>
              <h1>
                <span>{{ $txt("Product condition") }} </span>
                <span v-if="item.is_new">{{ $txt("New") }}</span>
                <span v-else>{{ $txt("Used") }}</span>
              </h1>
              <h1>
                <span>{{ $txt("Product location") }} </span>
                <span>{{ item.product_location }}</span>
              </h1>
              <h1>
                <span>{{ $txt("Price:") }} </span>
                <label>{{ $comma(item.product_price) }} Rwf</label>
              </h1>
            </div>
          </div>
          <!-- <div class="contact-form" v-show="startContact">
            <form action="" @submit.prevent="">
              <div class="input-group">
                <input
                  type="text"
                  name="names"
                  id="names"
                  :placeholder="$txt('Your name')"
                />
              </div>
              <div class="input-group">
                <input
                  type="email"
                  name="email"
                  id="email"
                  :placeholder="$txt('Your email')"
                />
              </div>
              <div class="input-group">
                <textarea
                  name="message"
                  id="message"
                  cols="30"
                  rows="10"
                  :placeholder="$txt('Message to owner')"
                ></textarea>
              </div>
            </form>
          </div> -->
          <div class="buttons">
            <button v-if="startContact">{{ $txt("Send") }}</button>
            <!-- <button v-if="!startContact" @click="openForm">
              {{ $txt("Contact owner") }}
            </button> -->
            <button v-if="!startContact" @click="callowner">
              {{ $txt("Contact owner") }}
            </button>
            <!-- <button v-if="!startContact" @click="openForm">
              {{ $txt("Contact owner") }}
            </button> -->
            <button @click="closePopup" class="cancel">
              {{ $txt("Cancel") }}
            </button>
          </div>
          <div class="for-more-info">
            <p>
              {{ $txt("For more info, contact the support@infomaster.rw") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import clickOutSide from "@mahdikhashan/vue3-click-outside";
export default {
  name: "ViewProduct",
  props: {
    item: {
      type: Object,
      default: {
        id: 1,
        name: "Burgers",
        image: "/assets/images/burger.jpg",
        owner: "Burger Planet",
        description:
          "Get 20% off on all burgers before 10:00 AM every day with Burger Planet.",
        price: 2000,
      },
    },
  },
  data() {
    return {
      dialogActive: false,
      clickCount: 0,
      showRejectPopup: false,
      showConfirmPopup: false,
      message: "",
      deduct: "Yes",
      startContact: false,
    };
  },
  directives: {
    clickOutSide,
  },
  methods: {
    closePopup() {
      this.dialogActive = false;
      setTimeout(() => {
        this.$emit("closePopup");
        document.body.style.overflowY = "auto";
      }, 500);
    },
    openForm() {
      this.clickCount = 0;
      this.startContact = true;
    },
    customMethod2() {
      this.clickCount++;
      if (this.clickCount > 1) {
        this.closePopup();
      }
    },
    callowner() {
      window.open(`tel:${this.item.ownerPhone}`, "_blank");
    },
  },
  mounted() {
    // setTimeout
    setTimeout(() => {
      this.dialogActive = true;
    }, 10);
    document.body.style.overflow = "hidden";
  },
};
</script>
  
  <style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.ViewProduct {
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 9999;
  .dialog {
    width: 600px;
    height: 100%;
    background-color: #eee;
    position: relative;
    right: -60%;
    top: 0;
    transition: all 0.5s ease-in-out;
    border-radius: 20px 0px 0px 20px;
    overflow-y: auto;
    &.active {
      right: 0;
    }
    .dialog-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 20px;
      border-bottom: 1px #e5e5e5 solid;
      h1 {
        font-size: 20px;
        font-weight: 600;
      }
      button {
        background-color: transparent;
        border: none;
        font-size: 30px;
        font-weight: 400;
        cursor: pointer;
      }
    }
    .dialog-body {
      width: 100%;
      height: calc(100% - 60px);
      padding: 1rem 20px;
      overflow-y: auto;
      .product {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;
        .product-image {
          width: 100%;
          height: 230px;
          background: #fff;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        .product-details {
          width: 100%;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          h1 {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 10px;
            margin-top: 1rem;
          }
          p {
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 10px;
          }
          .product-price {
            width: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            padding-right: 10px;
            h1 {
              font-size: 20px;
              font-weight: 600;
              display: flex;
              justify-content: space-between;
              width: 100%;
              span {
                font-size: 14px;
                font-weight: 400;
              }
              label {
                font-size: 20px;
                font-weight: 600;
                color: #16a562;
              }
            }
          }
        }
        .buttons {
          width: 100%;
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 10px;
          button {
            height: 50px;
            border: none;
            background-color: $blue;
            color: #fff;
            font-size: 14px;
            font-weight: 600;
            cursor: pointer;
            &:hover {
              background-color: $darkblue;
            }
            &.cancel {
              background-color: #2b2b2b;
              &:hover {
                background-color: #000;
              }
            }
          }
        }
        .for-more-info {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 20px;
          p {
            font-size: 14px;
            font-weight: 400;
            color: #2b2b2b;
          }
        }
        .contact-form {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 20px;
          form {
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            .input-group {
              width: 100%;
              display: flex;
              justify-content: space-between;
              margin-bottom: 10px;
              input,
              textarea {
                width: 100%;
                height: 50px;
                border: 1px #e5e5e5 solid;
                padding: 0 10px;
                font-size: 14px;
                font-weight: 400;
                &:focus {
                  outline: none;
                }
              }
              textarea {
                height: 100px;
              }
            }
          }
        }
      }
    }
  }
}
</style>