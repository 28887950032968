<template>
  <div class="pagination">
    <ul>
      <li>
        <a
          href="#"
          aria-label="Previous"
          @click.prevent="goToFirst()"
          :class="{ disabled: pagination.current_page <= 1 }"
        >
          <span aria-hidden="true"><i class="fa fa-angles-left"></i></span>
        </a>
      </li>
      <li>
        <a
          href="#"
          aria-label="Previous"
          @click.prevent="changePage(pagination.current_page - 1)"
          :class="{ disabled: pagination.current_page <= 1 }"
        >
          <span aria-hidden="true"><i class="fa fa-angle-left"></i></span>
        </a>
      </li>
      <li v-for="page in pagesNumber" :key="page">
        <a
          href="#"
          @click.prevent="changePage(page)"
          :class="[pagination.current_page == page ? 'active' : '']"
          v-if="
            page < pagination.current_page + 4 &&
            page > pagination.current_page - 4
          "
          >{{ page }}</a
        >
      </li>
      <li>
        <a
          href="#"
          aria-label="Next"
          @click.prevent="changePage(pagination.current_page + 1)"
          :class="{ disabled: pagination.current_page >= pagesNumber }"
        >
          <span aria-hidden="true"><i class="fa fa-angle-right"></i></span>
        </a>
      </li>
      <li>
        <a
          href="#"
          aria-label="Next"
          @click.prevent="goToLast()"
          :class="{ disabled: pagination.current_page >= pagesNumber }"
        >
          <span aria-hidden="true"><i class="fa fa-angles-right"></i></span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "pagination",
  props: {
    totalItems: {
      type: Number,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      default: 5,
    },
  },
  data() {
    return {
      pagination: {
        current_page: 1,
      },
      pagesNumber: 1,
    };
  },
  methods: {
    changePage(page) {
      this.pagination.current_page = page;
      this.$emit("pageChanged", page);
    },
    calculatePagesNumber() {
      this.pagesNumber = Math.ceil(this.totalItems / this.itemsPerPage);
    },
    goToLast() {
      this.pagination.current_page = this.pagesNumber;
      this.$emit("pageChanged", this.pagesNumber);
    },
    goToFirst() {
      this.pagination.current_page = 1;
      this.$emit("pageChanged", 1);
    },
  },
  watch: {
    totalItems() {
      this.calculatePagesNumber();
      this.pagination.current_page = 1;
    },
    itemsPerPage() {
      this.calculatePagesNumber();
      this.pagination.current_page = 1;
    },
  },
  mounted() {
    this.calculatePagesNumber();
    this.pagination.current_page = 1;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.pagination {
  margin-top: 1rem;
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      a {
        margin: 0 5px;
        display: flex;
        width: 35px;
        height: 35px;
        border: 1px solid #ddd;
        border-radius: 50%;
        outline: none;
        color: #5e5e5e;
        justify-content: center;
        align-items: center;
        &:hover {
          background: #ddd;
        }
        &.active {
          background: $blue !important;
          color: #fff;
        }
        &.disabled {
          pointer-events: none;
          color: #ddd;
        }
      }
    }
  }
}
</style>