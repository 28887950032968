
import axios from 'axios'
import { API_URL, AGRI_API_URL } from '../../variables';
const GET_ALL_AGRICULTURE_PRODUCT_CATEGORIES_URL = AGRI_API_URL + '/public/products/categories';
const GET_ALL_AGRICULTURE_PRODUCTS_URL = AGRI_API_URL + '/products/all';
const GET_ALL_AGRICULTURE_PRODUCTS_BY_CATEGORY_URL = AGRI_API_URL + '/products/categories/';
const GET_ALL_AGRICULTURE_PRODUCTS_BY_ID_URL = AGRI_API_URL + '/products/categories/';
const SEND_ORDER_URL = AGRI_API_URL +'/orders'
const agriculturalProducts = {
    state: {
    },
    getters: {},
    mutations: {},
    actions: {
        ['GET_ALL_AGRICULTURE_PRODUCT_CATEGORIES']: ({ commit }, payload) => {
            return axios({
                url: GET_ALL_AGRICULTURE_PRODUCT_CATEGORIES_URL,
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                }
            });
        },
        ['GET_ALL_AGRICULTURE_PRODUCTS']: ({ commit }, payload) => {
            return axios({
                url: GET_ALL_AGRICULTURE_PRODUCTS_URL,
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                }
            });
        },
        ['GET_ALL_AGRICULTURE_PRODUCTS_BY_CATEGORY']: ({ commit }, payload) => {
            return axios({
                url: GET_ALL_AGRICULTURE_PRODUCTS_BY_CATEGORY_URL + payload.data.category_id,
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                }
            });
        },
        ['GET_ALL_AGRICULTURE_PRODUCTS_BY_ID']: ({ commit }, payload) => {
            return axios({
                url: GET_ALL_AGRICULTURE_PRODUCTS_BY_ID_URL,
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                },
            });
        },

        ['SEND_ORDER']: ({ commit }, payload) => {
            return axios({
                url: SEND_ORDER_URL + payload.data.product_id,
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + payload.token
                }
            });
        },
        
    },
    modules: {},
}
export default agriculturalProducts;